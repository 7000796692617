import { z } from 'zod'
import { EnvironmentType } from '../envType.ts'

const AzureDevOpsTestConnectionConfigSchema = z.object({
	organization_name: z.string(),
	log_analytics_workspace_id: z.string(),
	personal_access_token: z.string(),
})
const SalesforceTestConnectionConfigSchema = z.object({
	domain: z.string(),
	consumer_key: z.string(),
	consumer_secret: z.string(),
})

export const IntegrationTestConnectionRequestSchema = z.object({
	environment_type: z.nativeEnum(EnvironmentType),
	config: z.union([AzureDevOpsTestConnectionConfigSchema, SalesforceTestConnectionConfigSchema]),
})

export type IntegrationTestConnectionRequest = z.infer<typeof IntegrationTestConnectionRequestSchema>

export const IntegrationTestConnectionResponseSchema = z.object({
	success: z.boolean(),
	message: z.string(),
})

export type IntegrationTestConnectionResponse = z.infer<typeof IntegrationTestConnectionResponseSchema>
