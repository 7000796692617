import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent } from '@ag-grid-community/core'
import { OktaUserXcSchema } from '../schemas/identities/oktaUserXcSchema.ts'

export const OktaUserXcTable: React.FunctionComponent<{ data: OktaUserXcSchema[] }> = ({ data }) => {
	const gridRef = useRef<AgGridReact<OktaUserXcSchema>>(null)
	const onGridReady = useCallback((_params: GridReadyEvent<OktaUserXcSchema>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	const columnDefs = useMemo<ColDef<OktaUserXcSchema>[]>(
		() => [
			{ field: 'profile.email' },
			{ field: 'profile.secondEmail' },
			{ field: 'profile.displayName' },
			{ field: 'profile.title' },
			{ field: 'status' },
			{ field: 'lastLogin' },
		],
		[],
	)

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				ref={gridRef}
				rowData={data}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				detailRowAutoHeight={true}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
