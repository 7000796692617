import { Edge } from '@xyflow/react'
import { IdentityNodeType } from '../common/IdentityNode.tsx'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { Identity } from '../../../../schemas/identity.ts'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { OktaUserNodeType } from '../okta/OktaUserNode.tsx'
import { EntraIDUserNodeType } from '../entraId/EntraIDUserNode.tsx'
import { processAwsRoles } from '../aws/crossAwsGraphUtils.ts'
import { processGcpProjects } from '../gcp/crossGcpGraphUtils.ts'
import { getEdge } from '../graphUtils/nodesAndEdges.ts'

const nodeLogicalTypeToColumnId = {
	gcpRole: 0,
	gcpProject: 1,
	generalIssue: 2,
	identity: 3,
	awsAccount: 4,
	awsRoles: 5,
	awsPolicy: 6,
}

const identityNodeRowIndex = 0
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`

export const getJumpcloudNodesAndEdges = (identity: Identity): [BareNodesColumnsType[], Edge[]] => {
	const edges: Edge[] = []
	const identityNodes: Array<
		BareNodeType<IdentityNodeType> | BareNodeType<OktaUserNodeType> | BareNodeType<EntraIDUserNodeType>
	> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []
	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		generalIssueNodes.push({
			type: 'issue',
			data: { issue },
			id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
		})
	})

	const hasAws = !!identity.jumpcloudUser?.awsIamRolesXc?.length
	const hasGcp = !!identity.jumpcloudUser?.gcpProjectsXc?.length

	// Process GCP projects
	const { gcpProjectNodes, gcpRoleNodes, gcpEdges } = processGcpProjects(
		identityNodeId,
		identity.jumpcloudUser?.gcpProjectsXc ?? null,
		{ gcpProject: nodeLogicalTypeToColumnId.gcpProject, gcpRole: nodeLogicalTypeToColumnId.gcpRole },
		hasAws ? 'leftToRight' : 'rightToLeft',
	)
	edges.push(...gcpEdges)

	// Process AWS roles
	const { awsRolesNodes, awsAccountNodes, policyNodes, awsEdges } = processAwsRoles(
		identityNodeId,
		identity.jumpcloudUser?.awsIamRolesXc ?? null,
		nodeLogicalTypeToColumnId,
		'rightToLeft',
	)
	edges.push(...awsEdges)

	// Add edges for general issues
	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})
	let nodes: BareNodesColumnsType[]

	if (hasAws && hasGcp) {
		nodes = [
			{ yPosition: 'center', nodes: gcpRoleNodes },
			{ yPosition: 'center', nodes: gcpProjectNodes },
			{
				yPosition: 'center',
				nodes: [
					...generalIssueNodes.map((node) => ({ ...node, yPosition: 'top' as const })),
					...identityNodes.map((node) => ({ ...node, yPosition: 'center' as const })),
				],
			},
			{ yPosition: 'center', nodes: awsAccountNodes },
			{ yPosition: 'center', nodes: awsRolesNodes },
			{ yPosition: 'center', nodes: policyNodes },
		]
	} else if (hasAws) {
		nodes = [
			{ yPosition: 'top', nodes: generalIssueNodes },
			{ yPosition: 'center', nodes: identityNodes },
			{ yPosition: 'center', nodes: awsAccountNodes },
			{ yPosition: 'center', nodes: awsRolesNodes },
			{ yPosition: 'center', nodes: policyNodes },
		]
	} else if (hasGcp) {
		nodes = [
			{ yPosition: 'top', nodes: generalIssueNodes },
			{ yPosition: 'center', nodes: identityNodes },
			{ yPosition: 'center', nodes: gcpProjectNodes },
			{ yPosition: 'center', nodes: gcpRoleNodes },
		]
	} else {
		nodes = [
			{ yPosition: 'top', nodes: generalIssueNodes },
			{ yPosition: 'center', nodes: identityNodes },
		]
	}

	return [nodes, edges]
}
