import { z } from 'zod'
import { camelizeSchema } from '../utils.ts'
import { ServerAbstractEnvironmentModelSchema } from './serverAbstractEnvironmentSchema.ts'
import { ServerGoogleWorkspaceEnvironmentSchema } from './googleWorkspaceEnvironment.ts'
import { ServerGcpProjectSchema } from './gcpProject.ts'

export const ServerGcpEnvironmentSchema = ServerAbstractEnvironmentModelSchema.extend({
	org_id: z.string().nullish(),
	gcp_projects: z.array(ServerGcpProjectSchema),
	google_workspace_envs: z.array(ServerGoogleWorkspaceEnvironmentSchema),
})

export type ServerGcpEnvironment = z.infer<typeof ServerGcpEnvironmentSchema>

export const GcpEnvironmentSchema = camelizeSchema(ServerGcpEnvironmentSchema.transform((val) => val))
export type GcpEnvironment = z.infer<typeof GcpEnvironmentSchema>
