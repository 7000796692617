import { z } from 'zod'
import { camelizeSchema } from '../../../utils.ts'

export const ServerAzureDevopsUserSchema = z.object({
	directory_alias: z.string().nullish(),
	domain: z.string(),
	principal_name: z.string(),
	mail_address: z.string(),
	origin: z.string(),
	origin_id: z.string(),
	display_name: z.string(),
	descriptor: z.string(),
})

export const AzureDevopsUserSchema = camelizeSchema(ServerAzureDevopsUserSchema.transform((val) => val))
export type AzureDevopsUser = z.infer<typeof AzureDevopsUserSchema>
