import React from 'react'
import { Tooltip } from 'antd'
import { priorityColors } from '../../../schemas/colors.ts'

const benchmarkPostureScore = 8
const sliderWidth = 256
const gradientBackground = `linear-gradient(90deg, ${priorityColors.PRIORITY_CRITICAL_COLOR} 10%, ${priorityColors.PRIORITY_HIGH_COLOR} 30%, ${priorityColors.PRIORITY_MEDIUM_COLOR} 50%, ${priorityColors.PRIORITY_LOW_COLOR} 70%)`
const handleWidth = 4

function getPostureScoreColor(postureScore: number): string {
	if (postureScore >= 6) {
		return priorityColors.PRIORITY_LOW_COLOR
	}

	if (postureScore >= 4) {
		return priorityColors.PRIORITY_MEDIUM_COLOR
	}

	if (postureScore >= 2) {
		return priorityColors.PRIORITY_HIGH_COLOR
	}

	return priorityColors.PRIORITY_CRITICAL_COLOR
}

const IdentityPostureSliderHandle = ({ postureScore }: { postureScore?: number }) => {
	const tooltipText = postureScore ? 'My Score' : 'Benchmark'
	const score = postureScore ?? benchmarkPostureScore
	const handleStyle = { left: `${score * 10}%`, backgroundColor: getPostureScoreColor(score), width: handleWidth }
	return (
		<Tooltip title={`${tooltipText} ${score.toFixed(1)}`}>
			<div className="absolute h-4 bottom-[-4px]" style={handleStyle} />
		</Tooltip>
	)
}

const IdentityPostureSliderLegendItem = ({ postureScore }: { postureScore?: number }) => {
	const score = postureScore ?? benchmarkPostureScore
	const legendText = postureScore ? 'My Score' : 'Benchmark'
	return (
		<div className="flex gap-1 items-center">
			<div className="w-2 h-2 rounded" style={{ backgroundColor: getPostureScoreColor(score) }}>
				{' '}
			</div>
			<div className="flex gap-1">
				<span>{legendText}</span>
				<span className="font-semibold text-neutral-900">{score.toFixed(1)}</span>
			</div>
		</div>
	)
}

const IdentityPostureSliderWindow = ({ postureScore }: { postureScore: number }) => {
	const percentageSize = Math.abs(benchmarkPostureScore - postureScore) * 10
	const width = Math.max((percentageSize * sliderWidth) / 100 - handleWidth, 0)
	const leftAnchor = Math.min(postureScore, benchmarkPostureScore)
	const handleStyle = {
		left: `calc(${leftAnchor * 10}% + ${handleWidth}px)`,
		opacity: '50%',
		backgroundColor: 'white',
		width,
		borderTop: '1px solid rgba(0, 0, 0, 0.2)',
		borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
		boxShadow: '0px 1px 3px 0px #0000000F',
	}

	return <div className="absolute h-4 bottom-[-4px]" style={handleStyle} />
}

const IdentityPostureSlider = ({ postureScore }: { postureScore: number }) => {
	return (
		<div className="flex flex-col gap-2">
			<div className="flex gap-2 text-xs text-neutral-500 justify-evenly">
				{postureScore > benchmarkPostureScore ? (
					<>
						<IdentityPostureSliderLegendItem />
						<IdentityPostureSliderLegendItem postureScore={postureScore} />
					</>
				) : (
					<>
						<IdentityPostureSliderLegendItem postureScore={postureScore} />
						<IdentityPostureSliderLegendItem />
					</>
				)}
			</div>
			<div
				className="h-2 relative rounded-lg"
				style={{
					background: gradientBackground,
					width: sliderWidth,
				}}
			>
				<IdentityPostureSliderHandle postureScore={postureScore} />
				<IdentityPostureSliderHandle />
				<IdentityPostureSliderWindow postureScore={postureScore} />
			</div>
		</div>
	)
}

export const IdentityPosture: React.FunctionComponent<{
	postureScore: number
}> = ({ postureScore }) => {
	const color = getPostureScoreColor(postureScore)
	return (
		<div className="flex gap-3">
			<span style={{ color }}>{postureScore.toFixed(1)}</span>
			<IdentityPostureSlider postureScore={postureScore} />
		</div>
	)
}
