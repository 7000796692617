import { Identity, IdentitySource } from '../../../schemas/identity.ts'
import { AwsTag } from '../../../schemas/identities/awsPoliciesSchema.ts'
import { AwsTagsTable } from '../../../tables/aws/AwsTagsTable.tsx'

type SidePanelTagsProps = {
	identity: Identity
}

export const SidePanelTags = ({ identity }: SidePanelTagsProps) => {
	if (identity.source === IdentitySource.AWS_IAM_USER || identity.source === IdentitySource.AWS_IAM_ROLE) {
		const tags: AwsTag[] =
			identity.awsIamUser?.awsIamUserDetailsXc?.tags || identity.awsIamRole?.awsIamRoleDetailsXc?.tags || []

		if (!tags.length) {
			return 'No tags data'
		}

		return <AwsTagsTable tags={tags} />
	}

	return `Tags are not supported for ${identity.source}`
}
