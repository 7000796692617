import { Identity } from '../../../schemas/identity.ts'
import { getCombinedAwsIamRolePolicy } from '../../../utils/awsIdentityUtils.ts'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { IamPermissionsTable } from '../../../tables/IamPermissionsTable.tsx'
import { useHighlightedNodesContext } from '../HighlightedNodesContext.tsx'
import { CellMouseOverEvent } from '@ag-grid-community/core'
import { CombinedAwsIamRolePolicy } from '../../../schemas/identities/awsIamRoleXcSchema.ts'
import { GcpProjectsTable } from '../../../tables/gcp/GcpProjectsTable.tsx'
import { DatabricksRolesTable } from '../../../tables/DatabricksRolesTable.tsx'
import { LastSelectedNode } from '../IdentityPageBody.tsx'
import { EntraIDRolesTable } from '../../../tables/entraId/EntraIDRolesTable.tsx'
import { SnowflakeRolesTable } from '../../../tables/SnowflakeRolesTable.tsx'
import type { SnowflakeRoleXc } from '../../../schemas/identities/snowflake/snowflakeRoleXcSchema.ts'
import { GithubAppPermissionsTable } from '../../../tables/github/GithubAppPermissionsTable.tsx'
import { SalesforcePermissionSetsTable } from '../../../tables/salesforce/SalesforcePermissionSetsTable.tsx'
import { SalesforceProfileTable } from '../../../tables/salesforce/SalesforceProfileTable.tsx'
import { Collapse, CollapseProps } from 'antd'
import { CollapsibleItemLabel } from '../../../components/common/CollaplsibleItemLabel.tsx'
import SalesforceIcon from '../../../assets/salesforce_icon.svg?react'
import { SalesforceConnectedMetadataTable } from '../../../tables/salesforce/SalesforceConnectedMetadataTable.tsx'

export type PermissionsCollapseItemKeys =
	| 'salesforceProfile'
	| 'salesforcePermissionSet'
	| 'salesforceConnectedAppMetadata'

type PermissionsCollapseItem = Required<CollapseProps>['items'][number] & {
	key: PermissionsCollapseItemKeys
}

type SidePanelPermissionsProps = {
	identity: Identity
	lastSelectedNode?: LastSelectedNode | null
	activePermissionsKeys: PermissionsCollapseItemKeys[]
	setActivePermissionsKeys: Dispatch<SetStateAction<PermissionsCollapseItemKeys[]>>
}

export const SidePanelPermissions = ({
	identity,
	lastSelectedNode,
	activePermissionsKeys,
	setActivePermissionsKeys,
}: SidePanelPermissionsProps) => {
	const awsPolicies = useMemo(
		() =>
			getCombinedAwsIamRolePolicy(
				identity.awsIamUser?.awsIamUserDetailsXc,
				identity.awsIamRole?.awsIamRoleDetailsXc,
				identity.awsIamRole?.permissionBoundary,
				identity.awsIamUser?.permissionBoundary,
			),
		[identity],
	)
	const { setHighlightedNodes } = useHighlightedNodesContext()

	const onCellMouseOut = () => {
		setHighlightedNodes([])
	}
	const gcpRolesXc = identity.gcpServiceAccount?.serviceAccountProjectsRolesXc
	const databricksRoles = identity?.databricksServicePrincipal?.roles || identity?.databricksUser?.roles
	const entraIdRoles =
		identity.entraIdUser?.entraIdRoleAssignments || identity.entraIdServicePrincipal?.entraIdRoleAssignments
	const snowflakeRoles = identity.snowflakeUser?.snowflakeRolesXc
	const githubAppPermissions = identity.githubAppInstallation?.permissions
	const salesforcePermissionSets = identity.salesforceUser?.permissionSets
	const salesforceProfile = identity.salesforceUser?.profile
	const salesforceConnectedAppOAuthMetadata = identity.salesforceConnectedApplication?.appMetadata

	const salesforceItems: PermissionsCollapseItem[] = useMemo(() => {
		const items: PermissionsCollapseItem[] = []

		if (salesforceProfile) {
			items.push({
				label: <CollapsibleItemLabel label="Salesforce Profile" icon={SalesforceIcon} />,
				headerClass: '!items-center',
				key: 'salesforceProfile',
				children: (
					<SalesforceProfileTable
						data={salesforceProfile}
						lastSelectedProfile={
							lastSelectedNode?.type === 'salesforceProfile' ? lastSelectedNode.id : undefined
						}
					/>
				),
			})
		}

		if (salesforcePermissionSets?.length) {
			items.push({
				label: <CollapsibleItemLabel label="Salesforce Permission Sets" icon={SalesforceIcon} />,
				headerClass: '!items-center',
				key: 'salesforcePermissionSet',
				children: (
					<SalesforcePermissionSetsTable
						data={salesforcePermissionSets}
						lastSelectedPermissionSet={
							lastSelectedNode?.type === 'salesforcePermissionSet' ? lastSelectedNode.id : undefined
						}
					/>
				),
			})
		}

		if (salesforceConnectedAppOAuthMetadata) {
			items.push({
				label: <CollapsibleItemLabel label="Salesforce Connected Application Metadata" icon={SalesforceIcon} />,
				headerClass: '!items-center',
				key: 'salesforceConnectedAppMetadata',
				children: (
					<SalesforceConnectedMetadataTable
						data={salesforceConnectedAppOAuthMetadata}
						lastSelectedMetadata={
							lastSelectedNode?.type === 'salesforceConnectedAppMetadata'
								? lastSelectedNode.id
								: undefined
						}
					/>
				),
			})
		}

		return items
	}, [salesforceProfile, salesforcePermissionSets, salesforceConnectedAppOAuthMetadata, lastSelectedNode])

	const onPermissionsCollapseChange = useCallback(
		(key: string | string[]) => {
			const keyList = Array.isArray(key)
				? (key as PermissionsCollapseItemKeys[])
				: [key as PermissionsCollapseItemKeys]
			setActivePermissionsKeys(keyList)
		},
		[setActivePermissionsKeys],
	)

	if (
		!awsPolicies?.length &&
		!gcpRolesXc?.length &&
		!databricksRoles?.length &&
		!entraIdRoles?.length &&
		!snowflakeRoles?.length &&
		!githubAppPermissions?.length &&
		!salesforcePermissionSets?.length &&
		!salesforceProfile &&
		!salesforceConnectedAppOAuthMetadata
	) {
		return (
			<div className="flex gap-1">
				<span>No permissions found for identity</span>
				<span className="font-semibold">{identity.literalFriendlyName || identity.literal}</span>
			</div>
		)
	}

	if (gcpRolesXc?.length) {
		return <GcpProjectsTable gcpProjectsXc={gcpRolesXc} />
	}

	if (databricksRoles?.length) {
		return <DatabricksRolesTable data={databricksRoles} />
	}

	if (entraIdRoles?.length) {
		return (
			<EntraIDRolesTable
				data={entraIdRoles}
				lastSelectedRole={lastSelectedNode?.type === 'detailedEntraIdRole' ? lastSelectedNode.id : undefined}
			/>
		)
	}

	if (githubAppPermissions?.length) {
		return <GithubAppPermissionsTable data={githubAppPermissions} />
	}

	if (snowflakeRoles?.length) {
		return (
			<SnowflakeRolesTable
				data={snowflakeRoles}
				lastSelectedRole={lastSelectedNode?.type === 'snowflakeRole' ? lastSelectedNode.id : undefined}
				onCellMouseOver={(event: CellMouseOverEvent<SnowflakeRoleXc>) => {
					setHighlightedNodes([{ type: 'snowflakeRole', id: event.node.id! }])
				}}
				onCellMouseOut={onCellMouseOut}
			/>
		)
	}

	if (salesforcePermissionSets?.length || salesforceProfile || salesforceConnectedAppOAuthMetadata) {
		return (
			<Collapse
				onChange={onPermissionsCollapseChange}
				activeKey={activePermissionsKeys}
				items={salesforceItems}
				style={{ backgroundColor: 'white' }}
				expandIconPosition={'end'}
			/>
		)
	}

	return (
		<IamPermissionsTable
			data={awsPolicies}
			lastSelectedPolicyId={lastSelectedNode?.type === 'awsPolicy' ? lastSelectedNode.id : undefined}
			onCellMouseOver={(event: CellMouseOverEvent<CombinedAwsIamRolePolicy>) => {
				setHighlightedNodes([{ type: 'awsPolicy', id: event.node.id! }])
			}}
			onCellMouseOut={onCellMouseOut}
		/>
	)
}
