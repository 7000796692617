import { Node, NodeProps } from '@xyflow/react'
import { GcpPrincipalInfoXc } from '../../../../schemas/identities/gcp/gcpProjectXcSchema.ts'
import GcpIcon from '../../../../assets/gcp_project_icon.svg?react'
import { BaseEnvironmentNode } from '../common/baseNodes/BaseEnvironmentNode.tsx'
import { NodeGroupBadge } from '../common/NodeGroupBadge.tsx'

export type GcpProjectsNodeType = Node<{ principalInfoXcs: GcpPrincipalInfoXc[] }, 'gcpProjects'>
type GcpProjectsNodeProps = NodeProps<GcpProjectsNodeType>

export const GcpProjectsNode = ({ data: { principalInfoXcs } }: GcpProjectsNodeProps) => {
	const badgeTooltipContent = principalInfoXcs.map((principalInfoXc) => (
		<span key={principalInfoXc.project.projectId}>{principalInfoXc.project.displayName || 'Unknown project'}</span>
	))

	return (
		<BaseEnvironmentNode type="GCP Projects" icon={<GcpIcon />}>
			<NodeGroupBadge items={principalInfoXcs} nodeColorType="environment" tooltipContent={badgeTooltipContent} />
		</BaseEnvironmentNode>
	)
}
