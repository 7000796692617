import React, { useCallback, useMemo, useRef, useEffect } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent, ICellRendererParams, GetRowIdParams } from '@ag-grid-community/core'
import { JsonViewer } from '../../components/common/JsonViewer'
import { SalesforceConnectedAppMetadata } from '../../schemas/identities/salesforce/salesforceConnectedApplicationSchema.ts'

export const SalesforceConnectedMetadataTable: React.FunctionComponent<{
	data: SalesforceConnectedAppMetadata
	lastSelectedMetadata?: string
}> = ({ data, lastSelectedMetadata }) => {
	const gridRef = useRef<AgGridReact<SalesforceConnectedAppMetadata>>(null)
	const highlightRow = useCallback((rowId?: string | null) => {
		if (!rowId) {
			return
		}

		const rowNode = gridRef.current?.api?.getRowNode(rowId)
		if (!rowNode) {
			return
		}

		gridRef.current?.api?.ensureNodeVisible(rowNode, 'middle')
		gridRef.current?.api?.flashCells({ rowNodes: [rowNode] })
	}, [])

	const onGridReady = useCallback(
		(_params: GridReadyEvent<SalesforceConnectedAppMetadata>) => {
			highlightRow(lastSelectedMetadata)
		},
		[highlightRow, lastSelectedMetadata],
	)

	const columnDefs = useMemo<ColDef<SalesforceConnectedAppMetadata>[]>(() => {
		return [
			{
				headerName: 'OAuth Config',
				flex: 1,
				cellRenderer: (params: ICellRendererParams<SalesforceConnectedAppMetadata>) => {
					return <JsonViewer data={params.data?.oauthConfig || {}} title="OAuth Config" />
				},
			},
			{
				headerName: 'OAuth Policy',
				flex: 1,
				cellRenderer: (params: ICellRendererParams<SalesforceConnectedAppMetadata>) => {
					return <JsonViewer data={params.data?.oauthPolicy || {}} title="OAuth Policy" />
				},
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	useEffect(() => {
		highlightRow(lastSelectedMetadata)
	}, [lastSelectedMetadata, highlightRow])

	if (!data) {
		return <div>No Application Metadata Available</div>
	}

	return (
		<div className="h-80 overflow-auto">
			<AgGridReact
				ref={gridRef}
				rowData={[data]}
				getRowId={(rowData: GetRowIdParams<SalesforceConnectedAppMetadata>) => rowData.data.fullName}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				rowHeight={54}
			/>
		</div>
	)
}
