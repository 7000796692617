import { DateSchema } from '../../common.ts'
import { z } from 'zod'
import { camelizeSchema } from '../../utils.ts'

export const ServerSalesforceApiTotalUsageLogSchema = z.object({
	timestamp: DateSchema,
	api_resource: z.string(),
	client_name: z.string(),
	http_method: z.string().nullish(),
	client_ip: z.string(),
	entity_name: z.string(),
	status_code: z.number().nullish(),
})

export const SalesforceApiTotalUsageLogSchema = camelizeSchema(
	ServerSalesforceApiTotalUsageLogSchema.transform((val) => val),
)

export type SalesforceApiTotalUsageLog = z.infer<typeof SalesforceApiTotalUsageLogSchema>

export const ServerSalesforceApiTotalUsageAggregatedLogReadModelSchema = z.object({
	day: DateSchema,
	client_ip: z.string(),
	status_codes: z.array(z.number()),
	total_requests: z.number(),
})

export const SalesforceApiTotalUsageAggregatedLogReadModelSchema = camelizeSchema(
	ServerSalesforceApiTotalUsageAggregatedLogReadModelSchema.transform((val) => val),
)

export type SalesforceApiTotalUsageAggregatedLogReadModel = z.infer<
	typeof SalesforceApiTotalUsageAggregatedLogReadModelSchema
>
