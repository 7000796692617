import { Node, NodeProps } from '@xyflow/react'
import { BaseIdentityNode } from '../common/baseNodes/BaseIdentityNode.tsx'

// Temporary interface to be replaced with the actual schema
export interface EntraIDUser {
	principalName?: string | null
	objectId?: string | null
}

export type EntraIDUserNodeType = Node<{ user: EntraIDUser }, 'entraIDUser'>
type EntraIDUserNodeProps = NodeProps<EntraIDUserNodeType>

export const EntraIDUserNode = ({ data: { user } }: EntraIDUserNodeProps) => (
	<BaseIdentityNode
		name={{
			text: user.principalName || 'Unknown User',
			tooltipText: user.objectId ? `ID: ${user.objectId}` : undefined,
		}}
		type="Entra ID User"
	/>
)
