import { z } from 'zod'
import { camelizeSchema } from '../../utils'
import { DateSchema } from '../../common'

export const ServerDatabricksPermissionSchema = z
	.object({
		permission_id: z.string(),
		role_id: z.string(),
		resource: z.string(),
		action: z.string(),
		created_at: DateSchema,
		updated_at: DateSchema,
	})
	.nullable()

export type ServerDatabricksPermission = z.infer<typeof ServerDatabricksPermissionSchema>

export const DatabricksPermissionSchema = camelizeSchema(ServerDatabricksPermissionSchema.transform((val) => val))
export type DatabricksPermission = z.infer<typeof DatabricksPermissionSchema>
