import { PriorityTypeEnum, PriorityTypeNameMap, PriorityTypeNameToNumber } from '../../../schemas/issue.ts'
import React from 'react'
import { MetricsTopBar } from '../../../schemas/metrics/metricsTopBar.ts'
import { Tooltip } from 'antd'
import { PriorityBar } from '../../Findings/stats/PriorityBar.tsx'
import { formatNumber } from '../../../utils/numberUtils.ts'
import { useNavigate } from '@tanstack/react-router'
import { RoutePaths } from '../../RoutePaths.tsx'
import { IdentitiesPageLens, IdentitiesPageSearch } from '../../../schemas/identity.ts'
import { useEntitlementToPaginatedInventory } from '../../../services/auth/hooks.ts'

export const TotalAdminsAtRisk: React.FunctionComponent<{
	metricsTopBar: MetricsTopBar
}> = ({ metricsTopBar }) => {
	const navigate = useNavigate({ from: RoutePaths.Metrics })
	const { isEntitled: isEntitledToPaginatedInventory } = useEntitlementToPaginatedInventory()
	const navigateToIdentities = (priorities?: string[]) => {
		const search: IdentitiesPageSearch = { lens: IdentitiesPageLens.ADMIN }
		if (priorities) {
			const convertedPriorities = priorities.map((priority) => {
				if (isEntitledToPaginatedInventory) {
					return `${PriorityTypeNameToNumber[priority as PriorityTypeEnum]}`
				}

				return PriorityTypeNameMap[priority as PriorityTypeEnum]
			})
			// eslint-disable-next-line camelcase
			search.identityFilter = { max_priority: { filterType: 'set', values: convertedPriorities } }
		}

		void navigate({
			to: RoutePaths.Identities,
			search,
		})
	}

	return (
		<div className="h-full flex gap-4">
			<div className="flex gap-1 items-end self-start">
				<span
					className="cursor-pointer"
					onClick={() => {
						navigateToIdentities([PriorityTypeEnum.HIGH, PriorityTypeEnum.CRITICAL])
					}}
				>
					{formatNumber(metricsTopBar.adminHighIssueCount + metricsTopBar.adminCriticalIssueCount)}
				</span>
				<Tooltip title="Total admins">
					<span
						className="font-normal text-neutral-400 text-base cursor-pointer"
						onClick={() => {
							navigateToIdentities()
						}}
					>{`/${formatNumber(metricsTopBar.adminCount)}`}</span>
				</Tooltip>
			</div>
			<PriorityBar
				findingsByPriority={[
					{ priority: PriorityTypeEnum.CRITICAL, count: metricsTopBar.adminCriticalIssueCount },
					{ priority: PriorityTypeEnum.HIGH, count: metricsTopBar.adminHighIssueCount },
				]}
				onPriorityNameTagClick={(priority) => {
					navigateToIdentities([priority])
				}}
			/>
		</div>
	)
}
