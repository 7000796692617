import { Node, NodeProps } from '@xyflow/react'
import { DatabricksPersonalAccessToken } from '../../../../schemas/identities/databricks/databricksUserSchema.ts'
import { BaseCredentialsNode } from '../common/baseNodes/BaseCredentialsNode.tsx'

export type DatabricksOAuthNodeType = Node<{ oauthToken: DatabricksPersonalAccessToken }, 'oauthToken'>
type DatabricksOAuthToken = NodeProps<DatabricksOAuthNodeType>

export const DatabricksOAuthNode = ({ data: { oauthToken } }: DatabricksOAuthToken) => (
	<BaseCredentialsNode type="OAuth Token" name={{ text: oauthToken?.tokenId || 'Unknown OAuth token' }} />
)
