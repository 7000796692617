import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, IRowNode } from '@ag-grid-community/core'
import { KubernetesResourceXc } from '../schemas/identities/kubernetes/kubernetesResourceXcSchema.ts'
import { removeNulls } from '../utils.ts'
import { JsonViewer } from '../components/common/JsonViewer.tsx'
import { KubernetesRole, KubernetesRoleBinding } from '../schemas/identities/kubernetesResourceSchema.ts'
import { KubernetesRolesTable } from './KubernetesRolesTable.tsx'

export const KubernetesRoleBindingsTable: React.FunctionComponent<{
	roleBindings: KubernetesRoleBinding[]
	roles: KubernetesRole[]
}> = ({ roleBindings, roles }) => {
	const gridRef = useRef<AgGridReact<KubernetesRoleBinding>>(null)
	const columnDefs = useMemo<ColDef<KubernetesRoleBinding>[]>(() => {
		return [
			{
				field: 'metadata.name',
				headerName: 'Role binding name',
				flex: 0.7,
				cellRenderer: 'agGroupCellRenderer',
			},
			{
				field: 'roleRef.name',
				headerName: 'Role name',
				flex: 0.7,
			},
			{
				field: 'roleRef.kind',
				headerName: 'Kind',
				flex: 0.7,
			},

			{
				field: 'metadata',
				cellRenderer: (params: { data?: KubernetesResourceXc; node: IRowNode }) => {
					return (
						<JsonViewer
							data={params.data?.metadata ? removeNulls(params.data?.metadata) : {}}
							title="Metadata"
						/>
					)
				},
				valueFormatter: (params) => JSON.stringify(params.value),
				flex: 0.7,
			},
			{
				field: 'roleRef',
				cellRenderer: (params: { data?: KubernetesResourceXc; node: IRowNode }) => {
					return (
						<JsonViewer
							data={params.data?.roleRef ? removeNulls(params.data.roleRef) : {}}
							title="roleRef"
						/>
					)
				},
				valueFormatter: (params) => JSON.stringify(params.value),
				flex: 0.7,
			},
			{
				field: 'subjects',
				cellRenderer: (params: { data?: KubernetesResourceXc; node: IRowNode }) => {
					return (
						<JsonViewer
							data={params.data?.subjects ? removeNulls(params.data.subjects) : []}
							title="Subjects"
						/>
					)
				},
				valueFormatter: (params) => JSON.stringify(params.value),
				flex: 0.7,
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback(() => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])
	const detailCellRenderer = useMemo(() => {
		return (params: { data: KubernetesRoleBinding; node: IRowNode }) => (
			<KubernetesRolesTable data={params.data} roles={roles} />
		)
	}, [roles])
	return (
		<div className="w-full h-full iam-roles-table">
			<AgGridReact
				className={'ag-theme-alpine overflow-x-auto'}
				ref={gridRef}
				rowData={roleBindings}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				detailRowHeight={200}
				defaultColDef={defaultColDef}
				detailCellRenderer={detailCellRenderer}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
