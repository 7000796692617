import { z } from 'zod'
import { camelizeSchema } from '../../utils.ts'

export const ServerEntraIdChangeLogSchema = z.object({
	event_time: z.string(),
	actor_principal_name: z.string().nullable(),
	actor_principal_id: z.string().nullable(),
	target_resources: z.array(z.any()),
	source_ip_address: z.string().nullable(),
	operation_name: z.string(),
})

//export the access key schema for use in other schemas
export const EntraIdChangeLogSchema = camelizeSchema(ServerEntraIdChangeLogSchema.transform((val) => val))
export type EntraIdChangeLog = z.infer<typeof EntraIdChangeLogSchema>

export interface EntraIdChangeLogsAggregated {
	actorPrincipalId: string
	actorPrincipalName: string
	operations: string[]
	sourceIpAddresses: string[]
	firstEventTime: Date
	lastEventTime: Date
	operationCount: number
}
