export const highRiskEntraIdUserLogs = [
	{
		id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0e1',
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-07-15T15:00:09.546241Z',
		updated_at: '2024-07-15T15:00:09.546241Z',
		arn: '123456123456',
		ip: '88.76.123.45',
		association: "Alex Sigdag's Windows 11",
		events: ['Microsoft Teams'],
		events_count: 2,
		day: '2024-07-15T00:00:00Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		global_ip_association: null,
		human_ip_associations: null,
		customer_ip_associations: null,
	},
	{
		id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0e2',
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-07-18T15:00:09.546241Z',
		updated_at: '2024-07-18T15:00:09.546241Z',
		arn: '123456123456',
		ip: '88.76.123.45',
		association: "Alex Sigdag's Windows 11",
		events: ['GitLab', 'Azure DevOps'],
		events_count: 5,
		day: '2024-07-18T00:00:00Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		global_ip_association: null,
		human_ip_associations: null,
		customer_ip_associations: null,
	},
	{
		id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0e3',
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-07-22T15:00:09.546241Z',
		updated_at: '2024-07-22T15:00:09.546241Z',
		arn: '123456123456',
		ip: '88.76.123.45',
		association: "Alex Sigdag's Windows 11",
		events: ['Microsoft Teams', 'Jira'],
		events_count: 14,
		day: '2024-07-22T00:00:00Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		global_ip_association: null,
		human_ip_associations: null,
		customer_ip_associations: null,
	},
	{
		id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0e4',
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-07-29T15:00:09.546241Z',
		updated_at: '2024-07-29T15:00:09.546241Z',
		arn: '123456123456',
		ip: '88.76.123.45',
		association: "Alex Sigdag's Windows 11",
		events: ['PagerDuty', 'Azure DevOps'],
		events_count: 7,
		day: '2024-07-29T00:00:00Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		global_ip_association: null,
		human_ip_associations: null,
		customer_ip_associations: null,
	},
	{
		id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0e5',
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-08-02T15:00:09.546241Z',
		updated_at: '2024-08-02T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.240.106.189',
		association: '(unknown)',
		events: ['Microsoft Teams', 'GitLab', 'Jira', 'Zendesk'],
		events_count: 11,
		day: '2024-08-02T00:00:00Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		global_ip_association: null,
		human_ip_associations: null,
		customer_ip_associations: null,
	},
]
