import { api } from './api'
import {
	AffectedEnvironmentWithEnv,
	AffectedEnvironmentWithEnvSchema,
	IdentitiesPageLens,
	IdentitiesTableRow,
	IdentitiesTableRowSchema,
	Identity,
	IdentityRelatedIssues,
	IdentityRelatedIssuesSchema,
	IdentitySchema,
	IdentitySource,
	IdentityUsageLogs,
	IdentityUsageLogsSchema,
	ServerIdentitiesQueryResponse,
	ServerIdentitiesQueryResponseSchema,
	ServerIdentitiesStats,
	ServerIdentitiesStatsSchema,
	ServerIdentitiesTableRow,
	ServerIdentity,
	ServerIdentityEnvironment,
	ServerIdentityEnvironmentSchema,
	ServerIdentityRelatedIssues,
	ServerIdentityUsageLogs,
} from '../schemas/identity'
import { useQuery } from '@tanstack/react-query'
import { ZodError } from 'zod'
import { QueryParameters } from '../schemas/query.ts'
import { queryClient } from './queryClient.ts'

async function getById(identityId: string): Promise<Identity> {
	try {
		const { data: identity } = await api.get<ServerIdentity>(`/api/identity/${identityId}`)
		return IdentitySchema.parse(identity)
	} catch (error) {
		if (error instanceof ZodError) {
			console.error('Validation error:', JSON.stringify(error.errors, null, 2))
			throw new Error(`Data validation failed: ${error.errors.map((e) => e.message).join(', ')}`)
		} else {
			console.error('Error fetching identity:', error)
			throw error
		}
	}
}

export function useIdentity(identityId: string) {
	return useQuery({
		queryKey: ['identityById', identityId],
		queryFn: () => getById(identityId),
	})
}

async function getRelatedIssues(identityId?: string): Promise<IdentityRelatedIssues | undefined> {
	if (!identityId) {
		return undefined
	}
	const { data: identityRelatedIssues } = await api.get<ServerIdentityRelatedIssues>(
		`/api/identity/${identityId}/related-issues`,
	)

	return IdentityRelatedIssuesSchema.parse(identityRelatedIssues)
}

export function useRelatedIssues(identityId?: string, disable?: boolean) {
	return useQuery({
		queryKey: ['relatedIssues', identityId],
		queryFn: () => getRelatedIssues(identityId),
		enabled: !disable && !!identityId,
	})
}

async function search(lens?: IdentitiesPageLens): Promise<IdentitiesTableRow[]> {
	try {
		const { data: identities } = await api.post<ServerIdentitiesTableRow[]>(`/api/identity/search`, { lens })
		return identities.map((identity) => IdentitiesTableRowSchema.parse(identity))
	} catch (error) {
		console.error(error)
		return []
	}
}

export function useSearchIdentities(lens?: IdentitiesPageLens, disabled?: boolean) {
	return useQuery({
		queryKey: ['searchIdentities', lens],
		queryFn: () => search(lens),
		enabled: !disabled,
	})
}

async function getUsageLogs(identityId: string, aggregated: boolean = false): Promise<IdentityUsageLogs> {
	const { data } = await api.get<ServerIdentityUsageLogs>(
		`/api/identity/${identityId}/usage-logs${aggregated ? '?aggregated=true' : ''}`,
	)
	return IdentityUsageLogsSchema.parse(data)
}

type UsageLogsOptions = {
	enabled?: boolean
	staleTime?: number
	refetchOnWindowFocus?: boolean
}

export function useIdentityUsageLogs(identityId: string, aggregated: boolean = false, options: UsageLogsOptions = {}) {
	const {
		enabled = true,
		staleTime = aggregated ? 5 * 60 * 1000 : 0, // 5 minutes cache for aggregated, no cache for detailed
		refetchOnWindowFocus = aggregated, // auto-refresh for aggregated only
	} = options

	return useQuery({
		queryKey: ['identityUsageLogs', identityId, aggregated],
		queryFn: () => getUsageLogs(identityId, aggregated),
		enabled,
		staleTime,
		refetchOnWindowFocus,
		// Keep data in cache when component unmounts
		cacheTime: 10 * 60 * 1000, // 10 minutes
		// Retry failed requests
		retry: 2,
		// Show stale data while revalidating
		keepPreviousData: true,
	})
}

async function queryIdentities(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
): Promise<ServerIdentitiesQueryResponse> {
	const { data } = await api.post<ServerIdentitiesQueryResponse>(`/api/identity/query`, { query, lens })
	return ServerIdentitiesQueryResponseSchema.parse(data)
}

export async function fetchIdentitiesQuery(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
): Promise<ServerIdentitiesQueryResponse> {
	return queryClient.fetchQuery({
		queryKey: ['queryIdentities', query, lens],
		queryFn: () => queryIdentities(query, lens),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

async function stats(query: QueryParameters, lens?: IdentitiesPageLens): Promise<ServerIdentitiesStats> {
	const { data } = await api.post<ServerIdentitiesStats>(`/api/identity/query-stats`, { query, lens })
	return ServerIdentitiesStatsSchema.parse(data)
}

export function useIdentityStats(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
	disabled?: boolean,
	keepPreviousData?: boolean,
) {
	return useQuery({
		queryKey: ['identityStats', query, lens],
		queryFn: () => stats(query, lens),
		enabled: !disabled,
		staleTime: 5 * 60 * 1000, // 5 minutes
		keepPreviousData,
	})
}

async function getIdentityEnvironments(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
): Promise<Array<ServerIdentityEnvironment>> {
	const { data } = await api.post<Array<ServerIdentityEnvironment>>(`/api/identity/environments`, { query, lens })
	return data.map((datum) => ServerIdentityEnvironmentSchema.parse(datum))
}

export async function fetchIdentityEnvironments(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
): Promise<Array<ServerIdentityEnvironment>> {
	return queryClient.fetchQuery({
		queryKey: ['identityEnvironments', query, lens],
		queryFn: () => getIdentityEnvironments(query, lens),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

async function getIdentitySources(query: QueryParameters, lens?: IdentitiesPageLens): Promise<Array<IdentitySource>> {
	const { data } = await api.post<Array<IdentitySource>>(`/api/identity/sources`, { query, lens })
	return data
}

export async function fetchIdentitySources(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
): Promise<Array<IdentitySource>> {
	return queryClient.fetchQuery({
		queryKey: ['identitySources', query, lens],
		queryFn: () => getIdentitySources(query, lens),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

async function getIdentityAffectedEnvironments(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
): Promise<Array<AffectedEnvironmentWithEnv>> {
	const { data } = await api.post<Array<AffectedEnvironmentWithEnv>>(`/api/identity/affected-environments`, {
		query,
		lens,
	})
	return data.map((datum) => AffectedEnvironmentWithEnvSchema.parse(datum))
}

export async function fetchIdentityAffectedEnvironments(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
): Promise<Array<AffectedEnvironmentWithEnv>> {
	return queryClient.fetchQuery({
		queryKey: ['identityAffectedEnvironments', query, lens],
		queryFn: () => getIdentityAffectedEnvironments(query, lens),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

async function getIdentityTagsWithEnvironment(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
): Promise<Array<string>> {
	const { data } = await api.post<Array<string>>(`/api/identity/tags-with-environment`, {
		query,
		lens,
	})
	return data
}

export async function fetchIdentityTagsWithEnvironment(
	query: QueryParameters,
	lens?: IdentitiesPageLens,
): Promise<Array<string>> {
	return queryClient.fetchQuery({
		queryKey: ['identityTagsWithEnvironment', query, lens],
		queryFn: () => getIdentityTagsWithEnvironment(query, lens),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}
