import { Identity } from '../../../schemas/identity.ts'
import { useHighlightedNodesContext } from '../HighlightedNodesContext.tsx'
import { Collapse, CollapseProps } from 'antd'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import OktaIcon from '../../../assets/okta_border_icon.svg?react'
import EntraIdIcon from '../../../assets/entra_id_icon_16.svg?react'
import AwsIcon from '../../../assets/aws_icon_16.svg?react'
import GcpIcon from '../../../assets/gcp_icon_20.svg?react'
import GithubIcon from '../../../assets/github_icon_20.svg?react'
import AzureIcon from '../../../assets/azure_icon.svg?react'
import JumpcloudIcon from '../../../assets/jumpcloud_icon_16.svg?react'
import { OktaUserXcTable } from '../../../tables/OktaUserXcTable.tsx'
import { IamRolesTable } from '../../../tables/IamRolesTable.tsx'
import { IamTrustRelationshipTable } from '../../../tables/iamTrustRelationshipTable.tsx'
import { GithubTeamsTable } from '../../../components/drawers/identities/github/GithubTeamsTable.tsx'
import { GithubReposTable } from '../../../components/drawers/identities/github/GithubReposTable.tsx'
import { EntraIdUserXcTable } from '../../../tables/EntraIdUserXcTable.tsx'
import { AzureRoleAssignmentsTable } from '../../../tables/Azure/AzureRoleAssignmentsTable.tsx'
import { LastSelectedNode } from '../IdentityPageBody.tsx'
import { CollapsibleItemLabel } from '../../../components/common/CollaplsibleItemLabel.tsx'
import { GcpProjectsTable } from '../../../tables/gcp/GcpProjectsTable.tsx'
import { DetailedAzureRoleAssignment } from '../../../schemas/identities/entraId/azureRoleAssignmentsXc.ts'
import { CellMouseOverEvent } from '@ag-grid-community/core'
import { JumpcloudUserXcTable } from '../../../tables/JumpcloudUserXcTable.tsx'
import { GithubTokensTable } from '../../../components/drawers/identities/github/GithubTokensTable.tsx'

export type CrossContextCollapseItemKeys =
	| 'okta'
	| 'trust-relationships'
	| 'trusted-principals'
	| 'github-teams'
	| 'github-repos'
	| 'github-tokens'
	| 'entraIDUser'
	| 'jumpcloudUser'
	| 'aws-roles'
	| 'gcp-projects'
	| 'azure-roles'

type CrossContextCollapseItem = Required<CollapseProps>['items'][number] & {
	key: CrossContextCollapseItemKeys
}

type SidePanelCrossContextProps = {
	identity: Identity
	activeCrossContextKeys: CrossContextCollapseItemKeys[]
	setActiveCrossContextKeys: Dispatch<SetStateAction<CrossContextCollapseItemKeys[]>>
	lastSelectedNode?: LastSelectedNode | null
}

export const SidePanelCrossContext = ({
	identity,
	activeCrossContextKeys,
	setActiveCrossContextKeys,
	lastSelectedNode,
}: SidePanelCrossContextProps) => {
	const { setHighlightedNodes } = useHighlightedNodesContext()
	const onCollapseChange = useCallback(
		(key: string | string[]) => {
			const keyList =
				typeof key === 'string'
					? [key as CrossContextCollapseItemKeys]
					: (key as CrossContextCollapseItemKeys[])
			setActiveCrossContextKeys(keyList)
		},
		[setActiveCrossContextKeys],
	)
	const oktaAwsXc = identity.awsIamUser?.oktaUserXc
	const oktaSnowflakeXc = identity.snowflakeUser?.oktaUserXc || identity.salesforceUser?.oktaUserXc
	const entraIdXc =
		identity.snowflakeUser?.entraIdUserXc ||
		identity.githubUser?.entraIdUserXc ||
		identity.salesforceUser?.entraIdUserXc
	const trustRelationshipsXc = identity.awsIamRole?.awsIamRoleDetailsXc?.assumeRolePolicyDocument
	const trustRelationshipsXcStatements = trustRelationshipsXc?.statement
	const crossAccountRolesXc = identity.awsIamRole?.awsIamCrossAccountRolesXc
	const githubReposXc = identity.githubUser?.githubReposXc
	const githubTeamsXc = identity.githubUser?.githubTeamsXc
	const githubAppRepos = identity.githubAppInstallation?.repositories
	const githubTokens = identity.githubUser?.tokens
	const awsIamRolesXc =
		identity.entraIdUser?.awsIamRolesXc || identity.jumpcloudUser?.awsIamRolesXc || identity.oktaUser?.awsIamRolesXc
	const gcpProjectsXc = identity.entraIdUser?.gcpProjectsXc || identity.jumpcloudUser?.gcpProjectsXc
	const azureRoleAssignmentsXc =
		identity.entraIdUser?.azureRoleAssignmentsXc || identity.entraIdServicePrincipal?.azureRoleAssignmentsXc
	const jumpcloudUserXc = identity.snowflakeUser?.jumpcloudUserXc

	const items: CrossContextCollapseItem[] = useMemo(() => {
		const ret: CrossContextCollapseItem[] = []
		if (jumpcloudUserXc) {
			ret.push({
				label: <CollapsibleItemLabel label={'Jumpcloud User'} icon={JumpcloudIcon} />,
				headerClass: '!items-center',
				key: 'jumpcloudUser',
				children: <JumpcloudUserXcTable data={[jumpcloudUserXc]} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'jumpcloudUser', id: '' }])
				},
			})
		}
		if (oktaAwsXc?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Okta Users'} icon={OktaIcon} />,
				headerClass: '!items-center',
				key: 'okta',
				children: <OktaUserXcTable data={oktaAwsXc} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'oktaUser', id: '' }])
				},
			})
		}

		if (oktaSnowflakeXc) {
			ret.push({
				label: <CollapsibleItemLabel label={'Okta Users'} icon={OktaIcon} />,
				headerClass: '!items-center',
				key: 'okta',
				children: <OktaUserXcTable data={[oktaSnowflakeXc]} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'oktaUser', id: '' }])
				},
			})
		}

		if (trustRelationshipsXc && trustRelationshipsXcStatements?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Trust Relationships'} icon={AwsIcon} />,
				headerClass: '!items-center',
				key: 'trust-relationships',
				children: <IamTrustRelationshipTable data={trustRelationshipsXc} />,
			})
		}

		if (crossAccountRolesXc?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Trusted Principals'} icon={AwsIcon} />,
				headerClass: '!items-center',
				key: 'trusted-principals',
				children: <IamRolesTable awsIamRolesXc={crossAccountRolesXc} />,
			})
		}

		if (githubTeamsXc?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'GitHub Teams'} icon={GithubIcon} />,
				headerClass: '!items-center',
				key: 'github-teams',
				children: <GithubTeamsTable githubTeams={githubTeamsXc} />,
			})
		}

		if (githubReposXc?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'GitHub Repositories'} icon={GithubIcon} />,
				headerClass: '!items-center',
				key: 'github-repos',
				children: <GithubReposTable githubRepos={githubReposXc} />,
			})
		}

		if (githubAppRepos?.length) {
			const githubRepositories = githubAppRepos.map((repoName) => ({
				name: repoName,
			}))

			ret.push({
				label: <CollapsibleItemLabel label={'GitHub Repositories'} icon={GithubIcon} />,
				headerClass: '!items-center',
				key: 'github-repos',
				children: <GithubReposTable githubRepos={githubRepositories} />,
			})
		}

		if (githubTokens?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'GitHub Tokens'} icon={GithubIcon} />,
				headerClass: '!items-center',
				key: 'github-tokens',
				children: <GithubTokensTable githubTokens={githubTokens} />,
			})
		}

		if (entraIdXc) {
			ret.push({
				label: <CollapsibleItemLabel label={'Entra ID User'} icon={EntraIdIcon} />,
				headerClass: '!items-center',
				key: 'entraIDUser',
				children: <EntraIdUserXcTable data={[entraIdXc]} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'entraIDUser', id: '' }])
				},
			})
		}

		if (awsIamRolesXc?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'AWS IAM Roles'} icon={AwsIcon} />,
				headerClass: '!items-center',
				key: 'aws-roles',
				children: <IamRolesTable awsIamRolesXc={awsIamRolesXc} />,
			})
		}

		if (gcpProjectsXc?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'GCP Projects'} icon={GcpIcon} />,
				headerClass: '!items-center',
				key: 'gcp-projects',
				children: <GcpProjectsTable gcpProjectsXc={gcpProjectsXc} />,
			})
		}

		if (azureRoleAssignmentsXc?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Azure Role Assignments'} icon={AzureIcon} />,
				headerClass: '!items-center',
				key: 'azure-roles',
				children: (
					<AzureRoleAssignmentsTable
						data={azureRoleAssignmentsXc}
						lastSelectedRole={
							lastSelectedNode?.type === 'enrichedAzureRole' ? lastSelectedNode.id : undefined
						}
						onCellMouseOver={(event: CellMouseOverEvent<DetailedAzureRoleAssignment>) => {
							setHighlightedNodes([{ type: 'enrichedAzureRole', id: event.node.id! }])
						}}
						onCellMouseOut={() => setHighlightedNodes([])}
					/>
				),
			})
		}

		return ret
	}, [identity, lastSelectedNode])

	if (
		!oktaAwsXc?.length &&
		!oktaSnowflakeXc &&
		!trustRelationshipsXcStatements?.length &&
		!crossAccountRolesXc?.length &&
		!githubTeamsXc?.length &&
		!githubReposXc?.length &&
		!githubAppRepos?.length &&
		!githubTokens?.length &&
		!entraIdXc &&
		!awsIamRolesXc?.length &&
		!gcpProjectsXc?.length &&
		!azureRoleAssignmentsXc?.length &&
		!jumpcloudUserXc
	) {
		return 'No cross-context data'
	}

	return (
		<div
			onMouseLeave={() => {
				setHighlightedNodes([])
			}}
		>
			<Collapse
				onChange={onCollapseChange}
				activeKey={activeCrossContextKeys}
				style={{ backgroundColor: 'white' }}
				expandIconPosition={'end'}
				items={items}
			/>
		</div>
	)
}
