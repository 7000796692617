import { z } from 'zod'
import { camelizeSchema } from '../utils'
import { DateSchema } from '../common'
import { ServerEndpointAccessXcSchema } from './endpointAccessXcSchema.ts'
import { ServerAwsEc2InstanceXcSchema } from './awsEc2InstanceXcSchema.ts'
import { ServerAwsUsageLogSchema } from './awsIamUserSchema.ts'
import { ServerEntraIdUserXcSchema } from './entraId/entraIdUserXcSchema.ts'

export const ServerPermissionSourceSchema = z.object({
	permission: z.string(),
	role_name: z.string().nullish(),
	source_type: z.string(),
	source_name: z.string(),
})
export const ServerGithubTeamSchema = z.object({
	name: z.string(),
	permissions_on_repos: z.array(ServerPermissionSourceSchema).nullish(),
})
export type ServerGithubTeam = z.infer<typeof ServerGithubTeamSchema>

export const GithubTeamSchema = camelizeSchema(ServerGithubTeamSchema.transform((val) => val))
export type GithubTeam = z.infer<typeof GithubTeamSchema>

export enum GithubResourceType {
	REPOSITORY = 'Repository',
	ORGANIZATION = 'Organization',
	TEAM = 'Team',
}

export const ServerGithubRepositorySchema = z.object({
	name: z.string(),
	permission: z.string().nullish(),
	permission_sources: z.array(ServerPermissionSourceSchema).nullish(),
})
export type ServerGithubRepository = z.infer<typeof ServerGithubRepositorySchema>

export const GithubRepositorySchema = camelizeSchema(ServerGithubRepositorySchema.transform((val) => val))
export type GithubRepository = z.infer<typeof GithubRepositorySchema>

export enum GithubTokenType {
	CLASSIC_PAT = 'classic_pat',
	FINE_GRAINED_PAT = 'fine_grained_pat',
	SSH_KEY = 'ssh_key',
}

export const GithubTokenTypeMap: Record<GithubTokenType, string> = {
	[GithubTokenType.CLASSIC_PAT]: 'Classic PAT',
	[GithubTokenType.FINE_GRAINED_PAT]: 'Fine-Grained PAT',
	[GithubTokenType.SSH_KEY]: 'SSH Key',
}

export const ServerGithubUserTokenSchema = z.object({
	id: z.number(),
	name: z.string().nullish(),
	type: z.nativeEnum(GithubTokenType),
	scopes: z.array(z.string()).nullish(),
	created_at: DateSchema.nullish(),
	last_used_at: DateSchema.nullish(),
	expires_at: DateSchema.nullish(),
})
export type ServerGithubUserToken = z.infer<typeof ServerGithubUserTokenSchema>
export const GithubUserTokenSchema = camelizeSchema(ServerGithubUserTokenSchema.transform((val) => val))
export type GithubUserToken = z.infer<typeof GithubUserTokenSchema>

export const ServerGithubUserLogSchema = z.object({
	action: z.string().nullish(),
	actor: z.string().nullish(),
	token_id: z.number(),
	created_at: DateSchema,
	raw_log: z.object({}).passthrough(),
})
export type ServerGithubUserLog = z.infer<typeof ServerGithubUserLogSchema>
export const GithubUserLogSchema = camelizeSchema(ServerGithubUserLogSchema.transform((val) => val))
export type GithubUserLog = z.infer<typeof GithubUserLogSchema>

export const ServerGithubUserSchema = z
	.object({
		id: z.string(),
		name: z.string().nullable(),
		last_activity: DateSchema.nullish(),
		customer_id: z.string().uuid(),
		sub_env_id: z.string().nullable(),
		created_at: DateSchema,
		updated_at: DateSchema.nullish(),
		role: z.string(),
		login: z.string(),
		email: z.string().nullable(),
		environment_id: z.string(),
		github_repos_xc: z.array(ServerGithubRepositorySchema),
		github_teams_xc: z.array(ServerGithubTeamSchema),
		tokens: z.array(ServerGithubUserTokenSchema).nullish(),
		entra_id_user_xc: ServerEntraIdUserXcSchema.nullish(),
		endpoint_access_xc: z.array(ServerEndpointAccessXcSchema).nullish(),
		usage_logs: z.array(ServerAwsUsageLogSchema).nullish(),
		audit_logs: z.array(ServerGithubUserLogSchema).nullish(),
		aws_ec2_instances_xc: z.array(ServerAwsEc2InstanceXcSchema).nullish(),
	})
	.nullable()

export type ServerGithubUser = z.infer<typeof ServerGithubUserSchema>

export const GithubUserSchema = camelizeSchema(ServerGithubUserSchema.transform((val) => val))
export type GithubUser = z.infer<typeof GithubUserSchema>
