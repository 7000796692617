import { z } from 'zod'
import { camelizeSchema } from '../../utils.ts'
import { ServerEntraIdApplicationXcSchema } from './entraIdApplicationXc.ts'
import { ServerDetailedAzureRoleAssignmentSchema } from './azureRoleAssignmentsXc.ts'
import { ServerEntraIdChangeLogSchema } from './entraIdChangeLogSchema.ts'
import { DetailedEntraIdRoleAssignmentSchema } from './entraIdRoleAssignmentsSchemas.ts'
import { ServerKubernetesResourceXcSchema } from '../kubernetes/kubernetesResourceXcSchema.ts'
import { ServerAzureVirtualMachineSchema } from '../azure/demoAzureVirtualMachine.ts'
import { ServerAzureUsageLogSchema } from './azureUsageLogSchema.ts'

export const ServicePrincipalTypeEnum = z.enum(['Application', 'ManagedIdentity', 'Legacy'])
export type ServicePrincipalType = z.infer<typeof ServicePrincipalTypeEnum>
export const ServerEntraIdServicePrincipalSchema = z
	.object({
		service_principal_id: z.string(),
		app_id: z.string(),
		account_enabled: z.boolean().nullish(),
		description: z.string().nullish(),
		display_name: z.string().nullish(),
		service_principal_type: ServicePrincipalTypeEnum.nullish(),
		last_sign_in_date_time: z.string().nullish(),
		entra_id_application_xc: ServerEntraIdApplicationXcSchema.nullish(),
		azure_role_assignments_xc: z.array(ServerDetailedAzureRoleAssignmentSchema).nullish(),
		entra_id_role_assignments: z.array(DetailedEntraIdRoleAssignmentSchema).nullish(),
		change_logs: z.array(ServerEntraIdChangeLogSchema),
		usage_logs: z.array(ServerAzureUsageLogSchema),
		kubernetes_resources_xc: z.array(ServerKubernetesResourceXcSchema).nullish(), // DEMO
		virtual_machines: z.array(ServerAzureVirtualMachineSchema).nullish(), // DEMO
	})
	.nullish()

//export the access key schema for use in other schemas
export const EntraIdServicePrincipalSchema = camelizeSchema(ServerEntraIdServicePrincipalSchema.transform((val) => val))
export type EntraIdServicePrincipal = z.infer<typeof EntraIdServicePrincipalSchema>
