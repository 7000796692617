import { Collapse } from 'antd'
import { ContextPanelHeader } from './ContextPanelHeader'
import { IdentitySource } from '../../../schemas/identity'
import { AwsIamRoleXc } from '../../../schemas/identities/awsIamRoleXcSchema.ts'
import { IamRolesTable } from '../../../tables/IamRolesTable.tsx'

export const AwsIamRoleContext: React.FunctionComponent<{
	awsIamRolesXc?: AwsIamRoleXc[] | null
	header?: React.ReactNode | null
}> = ({ awsIamRolesXc, header }) => {
	if (!awsIamRolesXc?.length) {
		return null
	}
	return (
		<Collapse expandIconPosition={'end'}>
			<Collapse.Panel
				header={header ? header : <ContextPanelHeader source={IdentitySource.AWS_IAM_ROLE} />}
				key={IdentitySource.AWS_IAM_ROLE}
			>
				<IamRolesTable awsIamRolesXc={awsIamRolesXc} />
			</Collapse.Panel>
		</Collapse>
	)
}
