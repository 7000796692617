import { AgChartOptions } from 'ag-charts-enterprise'
import { AgChartsReact } from 'ag-charts-react'
import { useKeyRotation } from '../api/metrics.ts'
import { DistributionData } from '../schemas/metrics/distributionData.ts'
import { useMemo } from 'react'
import { formatNumber } from '../utils/numberUtils.ts'
import { Skeleton } from 'antd'
import { themeColors } from '../utils/colorUtils.ts'

enum Labels {
	LESS_THAN_MONTH = '< 1 month',
	ONE_TO_THREE_MONTHS = '1-3 months',
	THREE_TO_SIX_MONTHS = '3-6 months',
	SIX_TO_TWELVE_MONTHS = '6-12 months',
	MORE_THAN_YEAR = '> 1 year',
}

const labelToFillColor: Record<Labels, string> = {
	[Labels.LESS_THAN_MONTH]: '#E3F1EF',
	[Labels.ONE_TO_THREE_MONTHS]: '#EDF1FF',
	[Labels.THREE_TO_SIX_MONTHS]: '#FFEFE5',
	[Labels.SIX_TO_TWELVE_MONTHS]: '#FFF0F1',
	[Labels.MORE_THAN_YEAR]: '#F6CFDE',
}

const labelToStrokeColor: Record<Labels, string> = {
	[Labels.LESS_THAN_MONTH]: '#A4D9C3',
	[Labels.ONE_TO_THREE_MONTHS]: '#ACBDFB',
	[Labels.THREE_TO_SIX_MONTHS]: '#FFDDC9',
	[Labels.SIX_TO_TWELVE_MONTHS]: '#FF9FAC',
	[Labels.MORE_THAN_YEAR]: '#E15689',
}

type RecordType = {
	label: Labels
	count: number
}

const formatData = (data: DistributionData): RecordType[] => {
	return [
		{ label: Labels.LESS_THAN_MONTH, count: data.lessThanOneMonth },
		{ label: Labels.ONE_TO_THREE_MONTHS, count: data.oneToThreeMonths },
		{ label: Labels.THREE_TO_SIX_MONTHS, count: data.threeToSixMonths },
		{ label: Labels.SIX_TO_TWELVE_MONTHS, count: data.sixToTwelveMonths },
		{ label: Labels.MORE_THAN_YEAR, count: data.moreThanTwelveMonths },
	]
}

export const KeysRotationChart = () => {
	const { data: keyRotationData, isLoading } = useKeyRotation()

	const formattedData = useMemo(() => {
		if (keyRotationData) return formatData(keyRotationData)
		return []
	}, [keyRotationData])

	if (isLoading) {
		return <Skeleton active />
	}

	const totalKeys = formattedData.reduce((acc, datum) => acc + datum.count, 0)

	const options: AgChartOptions = {
		data: formattedData,
		background: {
			fill: themeColors.neutral[100],
		},
		series: [
			{
				type: 'bar',
				xKey: 'label',
				yKey: 'count',
				tooltip: {
					renderer: ({ datum }: { datum: RecordType }) => {
						return {
							content: `${formatNumber(datum.count)} keys (${((datum.count / totalKeys) * 100).toFixed(2)}%) have last been rotated in ${datum.label}`,
						}
					},
				},
				formatter: ({ datum }: { datum: RecordType }) => {
					return {
						fill: labelToFillColor[datum.label],
						stroke: labelToStrokeColor[datum.label],
						strokeWidth: 2,
					}
				},
			},
		],
		axes: [
			{
				type: 'category',
				position: 'bottom',
				title: {
					text: 'Time since last rotation',
				},
			},
			{
				type: 'log',
				position: 'left',
				title: {
					text: 'Amount of keys',
				},
				label: {
					formatter: ({ value }) => formatNumber(value as number),
				},
			},
		],
	}

	return <AgChartsReact options={options} />
}
