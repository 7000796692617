import { Node, NodeProps } from '@xyflow/react'
import { TeamOutlined } from '@ant-design/icons'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import cx from 'classnames'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { AwsIamRoleXc } from '../../../../schemas/identities/awsIamRoleXcSchema.ts'
import { NodeGroupBadge } from '../common/NodeGroupBadge.tsx'

export type AwsRoleNodesType = Node<{ awsIamRoleXcs: AwsIamRoleXc[] }, 'awsIamRoles'>
type AwsRoleNodesProps = NodeProps<AwsRoleNodesType>

export const AwsRolesNode = ({ data: { awsIamRoleXcs } }: AwsRoleNodesProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} ${nodeColorClassNames.permission.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
	)

	const badgeTooltipContent = awsIamRoleXcs.map((awsIamRoleXc) => (
		<span key={awsIamRoleXc.roleId}>{awsIamRoleXc.roleName}</span>
	))

	return (
		<BaseNode type="Roles">
			<NodeGroupBadge items={awsIamRoleXcs} nodeColorType="permission" tooltipContent={badgeTooltipContent} />
			<div className={iconWrapperClassName}>
				<TeamOutlined className="text-2xl" />
			</div>
		</BaseNode>
	)
}
