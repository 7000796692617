import { Issue, IssueNameMap } from '../../schemas/issue.ts'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { IssueIcon } from '../../components/common/IssueIcon.tsx'
import { PriorityNameTag } from '../../components/common/PriorityNameTag.tsx'
import { Button, ConfigProvider, Tooltip } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { JiraIntegrationWrapper } from '../../jiraIntegration/components/JiraIntegrationWrapper.tsx'
import { IssueTitleStatus } from '../../components/drawers/issueDrawer/IssueTitleStatus.tsx'
import { formatDate } from '../../utils.ts'
import remarkGfm from 'remark-gfm'
import Markdown from 'react-markdown'
import { issuesRemediationStepsMap } from '../../components/remediationSteps/remediationStepsData.ts'
import { EnvironmentType } from '../../schemas/envType.ts'
import { IdentitySource } from '../../schemas/identity.ts'
import { RemediationSteps } from '../../components/remediationSteps/RemediationSteps.tsx'
import { SelectAssignee } from '../../components/drawers/issueDrawer/SelectAssignee.tsx'
import { RoutePaths } from '../RoutePaths.tsx'

const IssuesListNavigation = ({ issues, currentIssueIndex }: { issues: Issue[]; currentIssueIndex: number }) => {
	const navigate = useNavigate({ from: RoutePaths.Identity })
	const previousIssueId = currentIssueIndex === 0 ? undefined : issues[currentIssueIndex - 1].id
	const nextIssueId = currentIssueIndex === issues.length - 1 ? undefined : issues[currentIssueIndex + 1].id
	return (
		<ConfigProvider theme={{ token: { colorLink: 'black', colorLinkHover: 'gray' } }}>
			<div className="flex items-center">
				<Button
					type="link"
					disabled={!previousIssueId}
					icon={<LeftOutlined />}
					onClick={() => {
						void navigate({ search: (prev) => ({ ...prev, issueId: previousIssueId }) })
					}}
				/>
				<div className="text-zinc-500">
					<span className="text-gray-900 semibold">{currentIssueIndex + 1}</span>
					<span>{`/${issues.length}`}</span>
				</div>
				<Button
					disabled={!nextIssueId}
					type="link"
					icon={<RightOutlined />}
					onClick={() => {
						void navigate({ search: (prev) => ({ ...prev, issueId: nextIssueId }) })
					}}
				/>
			</div>
		</ConfigProvider>
	)
}

type IssuesViewProps = {
	issues: Issue[]
	identitySource: IdentitySource
	identityEnvType?: EnvironmentType | null
}

export const IssuesView = ({ issues, identityEnvType, identitySource }: IssuesViewProps) => {
	const { issueId } = useSearch({ from: RoutePaths.Identity })

	if (!issueId) {
		return null
	}

	const currentIssueIndex = issues.findIndex((issue) => issue.id === issueId)
	if (currentIssueIndex === -1) {
		return `Could not find issue ${issueId}`
	}

	const currentIssue = issues[currentIssueIndex]
	const remediationSteps =
		identityEnvType && currentIssue.issueName
			? issuesRemediationStepsMap[identityEnvType]?.[currentIssue.issueName]?.[identitySource]
			: undefined

	return (
		<div className="flex flex-col gap-4">
			<div className="flex justify-between gap-2 flex-wrap">
				<div className="flex gap-2 items-center -ml-2">
					{issues.length > 1 && (
						<IssuesListNavigation issues={issues} currentIssueIndex={currentIssueIndex} />
					)}
					<IssueIcon name={currentIssue.issueName} />
					<span className="font-semibold text-base">
						{currentIssue.issueName ? IssueNameMap[currentIssue.issueName] : 'Unknown Issue'}
					</span>
					<PriorityNameTag priority={currentIssue.priority} />
				</div>
				<div className="flex items-center gap-4">
					<JiraIntegrationWrapper issueId={currentIssue.id} />
					<SelectAssignee issueId={currentIssue.id} />
				</div>
			</div>
			<div className="flex gap-2 text-zinc-500">
				<IssueTitleStatus issueId={currentIssue.id} />
				{currentIssue.createdAt && (
					<Tooltip title={formatDate(currentIssue.createdAt)}>
						{`Date created ${formatDate(currentIssue.createdAt, false)}`}
					</Tooltip>
				)}
				{currentIssue.createdAt && currentIssue.lastSeen && '|'}
				{currentIssue.lastSeen && (
					<Tooltip title={formatDate(currentIssue.lastSeen)}>
						{`Last seen ${formatDate(currentIssue.lastSeen, false)}`}
					</Tooltip>
				)}
			</div>
			<div className="text-wrap whitespace-pre text-zinc-500 my-4">
				<Markdown remarkPlugins={[remarkGfm]} children={currentIssue.description || ''} />
			</div>
			{remediationSteps && (
				<div className="border-t pt-4 flex flex-col gap-2">
					<div className="semibold text-base text-zinc-900">Remediation</div>
					<RemediationSteps remediationSteps={remediationSteps} />
				</div>
			)}
		</div>
	)
}
