import { Edge } from '@xyflow/react'
import { IdentityNodeType } from '../common/IdentityNode.tsx'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { Identity } from '../../../../schemas/identity.ts'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { SnowflakeRoleNodeType } from './SnowflakeRoleNode.tsx'
import { BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { getEdge } from '../graphUtils/nodesAndEdges.ts'
import { OktaUserNodeType } from '../okta/OktaUserNode.tsx'
import { EntraIDUserNodeType } from '../entraId/EntraIDUserNode.tsx'
import { JumpcloudUserNodeType } from '../jumpcloud/JumpcloudUserNode.tsx'

const nodeLogicalTypeToColumnId = {
	generalIssue: 0,
	identity: 1,
	role: 2,
}

const identityNodeRowIndex = 0
const oktaUserNodeRowIndex = 1
const entraIdUserNodeRowIndex = 2
const jumpcloudUserNodeRowIndex = 3
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`

export const getSnowflakeNodesAndEdges = (identity: Identity): [BareNodesColumnsType[], Edge[]] => {
	const edges: Edge[] = []
	const identityNodes: Array<
		| BareNodeType<IdentityNodeType>
		| BareNodeType<OktaUserNodeType>
		| BareNodeType<EntraIDUserNodeType>
		| BareNodeType<JumpcloudUserNodeType>
	> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	if (identity.snowflakeUser?.oktaUserXc) {
		const details = identity.snowflakeUser.oktaUserXc
		const displayName = details.profile?.displayName || details.profile?.email
		const nodeId = `${nodeLogicalTypeToColumnId.identity}-${oktaUserNodeRowIndex}`
		identityNodes.push({
			type: 'oktaUser',
			data: { oktaUser: { type: 'Okta User', displayName } },
			id: nodeId,
		})

		edges.push(
			getEdge({
				source: identityNodeId,
				target: nodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	if (identity.snowflakeUser?.entraIdUserXc) {
		const details = identity.snowflakeUser.entraIdUserXc
		const nodeId = `${nodeLogicalTypeToColumnId.identity}-${entraIdUserNodeRowIndex}`
		identityNodes.push({
			type: 'entraIDUser',
			data: { user: { principalName: details.userPrincipalName, objectId: details.entraUserId } },
			id: nodeId,
		})

		edges.push(
			getEdge({
				source: identityNodeId,
				target: nodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}
	if (identity.snowflakeUser?.jumpcloudUserXc) {
		const jumpcloudUserXc = identity.snowflakeUser.jumpcloudUserXc
		const nodeId = `${nodeLogicalTypeToColumnId.identity}-${jumpcloudUserNodeRowIndex}`
		identityNodes.push({
			type: 'jumpcloudUser',
			data: { user: jumpcloudUserXc },
			id: nodeId,
		})

		edges.push(
			getEdge({
				source: identityNodeId,
				target: nodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []
	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		generalIssueNodes.push({
			type: 'issue',
			data: { issue },
			id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
		})
	})

	const roleNodes: Array<BareNodeType<SnowflakeRoleNodeType>> = []
	const snowflakeRoles = identity.snowflakeUser?.snowflakeRolesXc
	snowflakeRoles?.forEach((role, index) => {
		roleNodes.push({
			type: 'snowflakeRole',
			data: { role },
			id: `${nodeLogicalTypeToColumnId.role}-${index}`,
		})
	})

	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	roleNodes.forEach((_, index) => {
		const target = `${nodeLogicalTypeToColumnId.role}-${index}`
		edges.push(getEdge({ source: identityNodeId, target }))
	})

	const nodes: BareNodesColumnsType[] = [
		{ yPosition: 'center', nodes: generalIssueNodes },
		{ yPosition: 'center', nodes: identityNodes },
		{ yPosition: 'center', nodes: roleNodes },
	]
	return [nodes, edges]
}
