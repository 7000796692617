import { useMemo, useState } from 'react'
import { ColDef } from '@ag-grid-community/core'
import { AwsUsageLog } from '../../../schemas/identities/awsIamUserSchema.ts'
import { BaseCloudtrailLogsContent } from './BaseCloudtrailLogsContent.tsx'
import { AwsUsageLogsAggregationsTable, AwsUsageLogsContentEntry } from './AwsUsageLogsAggregationsTable.tsx'
import { Tooltip } from 'antd'

type AwsUsageLogsNewContentProps = {
	environmentId: string
	subEnvironmentId: string
	identityArn: string
	usageLogs: AwsUsageLog[]
}

export const AwsUsageLogsContent = ({
	environmentId,
	subEnvironmentId,
	usageLogs,
	identityArn,
}: AwsUsageLogsNewContentProps) => {
	const [usageLogColumnDefs] = useState<ColDef<AwsUsageLogsContentEntry>[]>([
		{
			field: 'ip',
			headerName: 'IP',
		},
		{
			field: 'day',
			headerName: 'Day',
		},
		{
			field: 'associations',
			headerName: 'Association',
			filter: 'agTextColumnFilter',
			valueFormatter: (params: { data?: AwsUsageLogsContentEntry }) => {
				return params.data?.associations?.join(', ') || ''
			},
		},
		{
			field: 'events',
			headerName: 'Actions',
			filterParams: {
				valueFormatter: (params: { value: string }) => {
					return params.value
				},
				keyCreator: (params: { value: string }) => {
					return params.value
				},
			},
			valueFormatter: (params: { data?: AwsUsageLogsContentEntry }) => {
				return params.data?.events?.join(', ') || ''
			},
			cellRenderer: (params: { value: string[] }) => {
				const stringValue = params.value.join(', ')
				if (params.value.length < 1) {
					return stringValue
				}

				return (
					<Tooltip
						placement="topLeft"
						title={
							<div className="flex flex-col">
								{params.value.map((action) => (
									<span key={action}>{action}</span>
								))}
							</div>
						}
					>
						<span>{stringValue}</span>
					</Tooltip>
				)
			},
		},
		{
			field: 'eventsCount',
			headerName: 'Events Count',
		},
	])

	const computedUsageLogs: AwsUsageLogsContentEntry[] = useMemo(() => {
		return usageLogs.map((log) => ({
			...log,
			associations: log.association ? [log.association] : [],
		}))
	}, [usageLogs])

	return (
		<BaseCloudtrailLogsContent
			environmentId={environmentId}
			subEnvironmentId={subEnvironmentId}
			identityArn={identityArn}
			entries={computedUsageLogs}
			entriesColumnDefs={usageLogColumnDefs}
			ContentTable={AwsUsageLogsAggregationsTable}
			title={'Usage'}
			modalTitle={'Usage'}
		/>
	)
}
