import React from 'react'
import { Menu, MenuProps, Tooltip } from 'antd'
import FindingsIcon from '../../assets/findings_clicked_icon_20.svg?react'
import FindingsNoBorderIcon from '../../assets/findings_not_clicked_icon_20.svg?react'
import IdentitiesNoBorderIcon from '../../assets/identities_not_clicked_icon_20.svg?react'
import IdentitiesIcon from '../../assets/identities_clicked_icon_20.svg?react'
import MetricsIcon from '../../assets/metrics_clicked_icon_20.svg?react'
import MetricsNoBorderIcon from '../../assets/metrics_not_clicked_icon_20.svg?react'
import ItdrNoBorderIcon from '../../assets/itdr_not_clicked_icon.svg?react'
import ItdrClickedIcon from '../../assets/itdr_clicked_icon.svg?react'
import Lottie from 'lottie-react'
import groovyWalkAnimation from '../../assets/token_animation_lottie.json'
import { useAuth, useAuthActions } from '../../services/auth'
import { UserLogo } from './UserLogo'
import { TenantMenuItem } from './TenantMenuItem.tsx'
import { useNavigate, useRouterState } from '@tanstack/react-router'
import { LogRocketTrackEvent, trackEvent } from '../../services/logrocket/logrocket.ts'
import { IssuesTabName } from '../../schemas/issue.ts'
import {
	useEntitlementToIntegrationsPage,
	useEntitlementToSettingsPage,
	useEntitlementToSwagger,
} from '../../services/auth/hooks.ts'
import { ApiFilled, ApiOutlined, SettingFilled, SettingOutlined } from '@ant-design/icons'
import { isDemo } from '../../utils/demoUtils.ts'
import { RoutePaths } from '../../routes/RoutePaths.tsx'

type MenuItem = Required<MenuProps>['items'][number]

export const SideBarMenu: React.FC = () => {
	const navigate = useNavigate({ from: RoutePaths.Index })
	const {
		location: { pathname },
	} = useRouterState()
	const { user, tenantsState } = useAuth()
	const { isEntitled: isEntitledToSettingsPage } = useEntitlementToSettingsPage()
	const { isEntitled: isEntitledToIntegrationsPage } = useEntitlementToIntegrationsPage()
	const { isEntitled: isEntitledToSwagger } = useEntitlementToSwagger()

	const { logout, showAdminPortal, switchTenant } = useAuthActions()

	let menuItems: MenuItem[] = []
	if (user) {
		const extraItems = []
		if (tenantsState && user.tenantIds.length > 1) {
			const sortedTenants = [...tenantsState.tenants].sort((t1, t2) =>
				t1.name.toLowerCase().localeCompare(t2.name.toLowerCase()),
			)

			extraItems.push(
				getItem('Switch Tenant', 'switch-tenant', () => {}, null, [
					...sortedTenants.map((tenant) => {
						return getItem(
							'',
							tenant.tenantId,
							() => {
								navigate({ to: RoutePaths.Index }).then(() => {
									switchTenant({
										tenantId: tenant.tenantId,
										callback: (_data, error) => {
											if (error) {
												console.error(error)
												return
											}
											window.location.reload()
										},
									})
								})
							},
							<TenantMenuItem tenant={tenant} />,
						)
					}),
				]),
			)
		}

		const profileItems = [
			...extraItems,
			getItem('Workspace Settings', 'settings', () => showAdminPortal()),
			getItem('Logout', 'logout', () => logout()),
		]

		if (isDemo || isEntitledToSwagger) {
			profileItems.splice(
				profileItems.length - 1,
				0,
				getItem('API', 'api', () => void navigate({ to: '/swagger' })),
			)
		}
		menuItems = [getItem('', 'profile', () => {}, <UserLogo user={user} />, profileItems)]
	}

	function getItem(
		label: React.ReactNode,
		key?: React.Key | null,
		onClick?: React.MouseEventHandler<HTMLElement>,
		icon?: React.ReactNode,
		children?: MenuItem[],
	): MenuItem {
		return {
			key,
			icon,
			children,
			label,
			onClick,
		} as MenuItem
	}

	const changeRoute = (route: RoutePaths) => {
		trackEvent(LogRocketTrackEvent.SideBarMenuClicked, { route })
		let searchParams = {}
		if (route === RoutePaths.Findings || route === RoutePaths.Itdr) {
			searchParams = { issueName: 'All', tabName: IssuesTabName.OPEN }
		}
		if (route === RoutePaths.Identities) {
			searchParams = {}
		}
		void navigate({
			to: route,
			search: () => ({ ...searchParams }),
		})
	}

	return (
		<div className="flex flex-col h-full w-full justify-between">
			{user ? (
				<>
					<button
						onClick={() => {
							changeRoute(RoutePaths.Metrics) // Metrics is currently the home page
						}}
						className="items-center justify-center content-center flex my-2"
					>
						<div className="border-transparent border-2 rounded transition items-center justify-center content-center flex w-[40px] h-[40px]">
							<Lottie animationData={groovyWalkAnimation} loop={true} />
						</div>
					</button>
					<div className="flex flex-col items-center justify-center space-y-8">
						<Tooltip title="Dashboard" placement="rightTop">
							<button
								onClick={() => changeRoute(RoutePaths.Metrics)}
								className="items-center justify-center content-center flex"
							>
								{pathname === (RoutePaths.Metrics as string) ? (
									<div className="border-gray-300 border-2 rounded items-center justify-center content-center flex flex-row w-[40px] h-[40px]">
										<MetricsIcon />
									</div>
								) : (
									<div className="hover:border-gray-300 border-transparent border-2 rounded transition items-center justify-center content-center flex w-[40px] h-[40px]">
										<MetricsNoBorderIcon />
									</div>
								)}
							</button>
						</Tooltip>
						<Tooltip title="Inventory" placement="rightTop">
							<button
								onClick={() => changeRoute(RoutePaths.Identities)}
								className="items-center justify-center content-center flex"
							>
								{pathname.startsWith(RoutePaths.Identities) ? (
									<div className="border-gray-300 border-2 rounded items-center justify-center content-center flex flex-row w-[40px] h-[40px]">
										<IdentitiesIcon />
									</div>
								) : (
									<div className="hover:border-gray-300 border-transparent border-2 rounded transition items-center justify-center content-center flex w-[40px] h-[40px]">
										<IdentitiesNoBorderIcon />
									</div>
								)}
							</button>
						</Tooltip>
						<Tooltip title={`${isDemo ? 'Security Posture' : 'Findings'}`} placement="rightTop">
							<button
								onClick={() => changeRoute(RoutePaths.Findings)}
								className="items-center justify-center content-center flex"
							>
								{pathname === (RoutePaths.Findings as string) ? (
									<div className="border-gray-300 border-2 rounded items-center justify-center content-center flex w-[40px] h-[40px]">
										<FindingsIcon />
									</div>
								) : (
									<div className="hover:border-gray-300 border-transparent border-2 rounded transition items-center justify-center content-center flex w-[40px] h-[40px]">
										<FindingsNoBorderIcon />
									</div>
								)}
							</button>
						</Tooltip>
						{isDemo && (
							<Tooltip title="ITDR" placement="rightTop">
								<button
									onClick={() => changeRoute(RoutePaths.Itdr)}
									className="items-center justify-center content-center flex"
								>
									{pathname === (RoutePaths.Itdr as string) ? (
										<div className="border-gray-300 border-2 rounded items-center justify-center content-center flex w-[40px] h-[40px]">
											<ItdrClickedIcon />
										</div>
									) : (
										<div className="hover:border-gray-300 border-transparent border-2 rounded transition items-center justify-center content-center flex w-[40px] h-[40px]">
											<ItdrNoBorderIcon />
										</div>
									)}
								</button>
							</Tooltip>
						)}
					</div>
					<div className="flex flex-col gap-2 items-center">
						{isEntitledToIntegrationsPage && !isDemo && (
							<Tooltip title="Integrations" placement="rightTop">
								<button
									onClick={() => changeRoute(RoutePaths.Integrations)}
									className="items-center justify-center content-center flex"
								>
									{pathname === (RoutePaths.Integrations as string) ? (
										<div className="border-gray-300 border-2 text-white rounded items-center justify-center content-center flex w-[40px] h-[40px]">
											<ApiFilled />
										</div>
									) : (
										<div className="hover:border-gray-300 text-white border-transparent border-2 rounded transition items-center justify-center content-center flex w-[40px] h-[40px]">
											<ApiOutlined />
										</div>
									)}
								</button>
							</Tooltip>
						)}
						{isEntitledToSettingsPage && (
							<Tooltip title="Settings" placement="rightTop">
								<button
									onClick={() => changeRoute(RoutePaths.Settings)}
									className="items-center justify-center content-center flex"
								>
									{pathname === (RoutePaths.Settings as string) ? (
										<div className="border-gray-300 border-2 text-white rounded items-center justify-center content-center flex w-[40px] h-[40px]">
											<SettingFilled />
										</div>
									) : (
										<div className="hover:border-gray-300 text-white border-transparent border-2 rounded transition items-center justify-center content-center flex w-[40px] h-[40px]">
											<SettingOutlined />
										</div>
									)}
								</button>
							</Tooltip>
						)}
						<Menu className="bg-transparent user-menu-sider" items={menuItems} />
					</div>
				</>
			) : null}
		</div>
	)
}
