import { useAuth as useFronteggAuth } from '@frontegg/react-hooks/auth'
import { useSlackUrlBuilder } from '../api/integrations'
import { useEffect, useState } from 'react'
import { useNavigate } from '@tanstack/react-router'
import { useLocation } from 'react-router-dom'
import { Typography, Layout, Button } from 'antd'
import { RoutePaths } from './RoutePaths'

export const SlackIntegration = () => {
	const { user: fronteggUser } = useFronteggAuth()
	const { data: slackUrl } = useSlackUrlBuilder(fronteggUser?.accessToken)

	const location = useLocation()
	const navigate = useNavigate()
	const [slackIntegrationResult, setSlackIntegrationResult] = useState('')

	useEffect(() => {
		const queryParam = new URLSearchParams(location.search)
		const slackParam = queryParam.get('slack-integration')

		if (slackParam) {
			setSlackIntegrationResult(slackParam)
			queryParam.delete('slack-integration')
			navigate({ to: location.pathname })
		}
	}, [])

	if (slackIntegrationResult !== '') {
		const { Title, Paragraph } = Typography
		let title,
			description = ''

		if (slackIntegrationResult === 'success') {
			title = 'Success'
			description = 'Slack integration successfully configured'
		}
		if (slackIntegrationResult === 'fail') {
			title = 'Error'
			description = 'Slack integration failed to configure'
		}
		if (slackIntegrationResult === 'exists') {
			title = 'Success'
			description = 'Slack integration already configured'
		}

		return (
			<Layout className="h-full rounded-md">
				<Layout.Content className="flex flex-col">
					<div style={{ margin: 10 }}>
						<Title level={2}>{title}</Title>
						<Paragraph>{description}</Paragraph>
						<Button
							onClick={() => {
								navigate({ to: RoutePaths.Metrics })
							}}
						>
							Back to dashboard
						</Button>
					</div>
				</Layout.Content>
			</Layout>
		)
	}

	if (!slackUrl) {
		return
	}
	window.location.href = slackUrl
	return null
}
