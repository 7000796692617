import { Node, NodeProps } from '@xyflow/react'
import { AzureSubscription } from '../../../../../schemas/identities/entraId/azureRoleAssignmentsXc.ts'
import { BaseEnvironmentNode } from '../../common/baseNodes/BaseEnvironmentNode.tsx'
import { NodeGroupBadge } from '../../common/NodeGroupBadge.tsx'

export type AzureSubscriptionsNodeType = Node<{ subscriptions: AzureSubscription[] }, 'azureSubscriptions'>
type AzureSubscriptionsNodeProps = NodeProps<AzureSubscriptionsNodeType>

export const AzureSubscriptionsNode = ({ data: { subscriptions } }: AzureSubscriptionsNodeProps) => {
	const badgeTooltipContent = subscriptions.map((subscription, index) => (
		<span key={subscription.subscriptionId || index}>{subscription.displayName || 'Unnamed subscription'}</span>
	))

	return (
		<BaseEnvironmentNode type="Azure Subscriptions">
			<NodeGroupBadge items={subscriptions} nodeColorType="environment" tooltipContent={badgeTooltipContent} />
		</BaseEnvironmentNode>
	)
}
