import { z } from 'zod'
import { camelizeSchema } from '../../utils'
import { DateSchema } from '../../common'
import { ServerSnowflakeRoleXcSchema } from './snowflakeRoleXcSchema.ts'
import { ServerOktaUserXcSchema } from '../oktaUserXcSchema.ts'
import { ServerEntraIdUserXcSchema } from '../entraId/entraIdUserXcSchema.ts'
import { JumpcloudUserXcSchema } from '../jumpcloud/jumpcloudUserXcSchema.ts'

export const ServerSnowflakeUserSchema = z
	.object({
		id: z.string(),
		user_id: z.string(),
		deleted_on: DateSchema.nullish(),
		first_name: z.string().nullable(),
		last_name: z.string().nullable(),
		display_name: z.string().nullable(),
		email: z.string().nullable(),
		must_change_password: z.boolean(),
		has_password: z.boolean(),
		comment: z.string().nullable(),
		disabled: z.boolean(),
		snowflake_lock: z.boolean(),
		default_role: z.string().nullable(),
		ext_authn_duo: z.boolean(),
		bypass_mfa_until: DateSchema.nullish(),
		expires_at: DateSchema.nullish(),
		has_rsa_public_key: z.boolean(),
		password_last_set_time: DateSchema.nullish(),
		owner: z.string().nullable(),
		name: z.string().nullable(),
		last_activity: DateSchema.nullish(),
		created_at: DateSchema.nullish(),
		updated_at: DateSchema.nullish(),
		environment_id: z.string(),
		sub_env_id: z.string().nullable(),
		snowflake_roles_xc: z.array(ServerSnowflakeRoleXcSchema).nullable(),
		okta_user_xc: ServerOktaUserXcSchema.nullish(),
		entra_id_user_xc: ServerEntraIdUserXcSchema.nullish(),
		jumpcloud_user_xc: JumpcloudUserXcSchema.nullish(),
	})
	.nullable()

export type ServerSnowflakeUser = z.infer<typeof ServerSnowflakeUserSchema>

export const SnowflakeUserSchema = camelizeSchema(ServerSnowflakeUserSchema.transform((val) => val))
export type SnowflakeUser = z.infer<typeof SnowflakeUserSchema>
