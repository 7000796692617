import React, { useMemo } from 'react'
import { AgChartsReact } from 'ag-charts-react'
import { AgChartOptions } from 'ag-charts-enterprise'
import { getPriorityColor } from '../tables/helpers.ts'
import { noDataOverlay } from './helpers.tsx'
import { IdentityTypeMap } from '../schemas/identity.ts'
import { IdentitiesDistByPriority } from '../schemas/metrics/identitiesDistByPriority.ts'
import { PriorityType } from '../schemas/issue.ts'
import { useIdentitiesDistByPriority } from '../api/metrics.ts'
import { MetricsChartContainer } from '../routes/Metrics/MetricsChartContainer.tsx'
import { themeColors } from '../utils/colorUtils.ts'
import { Skeleton } from 'antd'

export const IdentitiesByPriorityDistributionStackedBarChart: React.FunctionComponent = () => {
	const { data: identitiesDistByPriority, isLoading } = useIdentitiesDistByPriority()
	const normalizedData = useMemo(() => {
		return identitiesDistByPriority?.map((f) => ({
			...f,
			identityType: IdentityTypeMap[f.identityType],
		}))
	}, [identitiesDistByPriority])
	const options: AgChartOptions = {
		overlays: {
			noData: {
				renderer: noDataOverlay,
			},
		},
		data: normalizedData,
		background: {
			fill: themeColors.neutral[100],
		},
		axes: [
			{
				type: 'category',
				position: 'bottom',
				paddingInner: 0.6,
				paddingOuter: 1,
			},
			{
				type: 'number',
				position: 'left',
			},
		],
		legend: {
			item: {
				marker: {
					shape: 'circle',
				},
			},
		},
		series: [
			{
				type: 'bar',
				xKey: 'identityType',
				yKey: 'low',
				yName: 'Low',
				stacked: true,
				fill: getPriorityColor(PriorityType.LOW),
				tooltip: {
					renderer: (params) => {
						const identityDist = params.datum as IdentitiesDistByPriority
						if (!identityDist) return { title: '', content: '' }
						return {
							title: params.title,
							content: `${identityDist.identityType}: ${identityDist.low}`,
						}
					},
				},
			},
			{
				type: 'bar',
				xKey: 'identityType',
				yKey: 'medium',
				yName: 'Medium',
				stacked: true,
				fill: getPriorityColor(PriorityType.MEDIUM),
				tooltip: {
					renderer: (params) => {
						const identityDist = params.datum as IdentitiesDistByPriority
						if (!identityDist) return { title: '', content: '' }
						return {
							title: params.title,
							content: `${identityDist.identityType}: ${identityDist.medium}`,
						}
					},
				},
			},
			{
				type: 'bar',
				xKey: 'identityType',
				yKey: 'high',
				yName: 'High',
				stacked: true,
				fill: getPriorityColor(PriorityType.HIGH),
				tooltip: {
					renderer: (params) => {
						const identityDist = params.datum as IdentitiesDistByPriority
						if (!identityDist) return { title: '', content: '' }
						return {
							title: params.title,
							content: `${identityDist.identityType}: ${identityDist.high}`,
						}
					},
				},
			},
			{
				type: 'bar',
				xKey: 'identityType',
				yKey: 'critical',
				yName: 'Critical',
				stacked: true,
				fill: getPriorityColor(PriorityType.CRITICAL),
				tooltip: {
					renderer: (params) => {
						const identityDist = params.datum as IdentitiesDistByPriority
						if (!identityDist) return { title: '', content: '' }
						return {
							title: params.title,
							content: `${identityDist.identityType}: ${identityDist.critical}`,
						}
					},
				},
			},
		],
	}

	return (
		<MetricsChartContainer className="col-span-3" header="Identities Priority Distribution">
			{isLoading ? <Skeleton active /> : <AgChartsReact options={options} />}
		</MetricsChartContainer>
	)
}
