import { KeyOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { nodeColorClassNames } from '../../graphUtils/nodeColors.ts'
import { BaseNode, BaseNodeProps } from './BaseNode.tsx'

type BaseCredentialsNodeProps = {
	type: string
	isHighlighted?: boolean
	name?: BaseNodeProps['name']
}

export const BaseCredentialsNode = ({ type, isHighlighted, name }: BaseCredentialsNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.credentials.bgHover} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
		{
			[nodeColorClassNames.credentials.bg]: !isHighlighted,
			[nodeColorClassNames.credentials.bgHighlighted]: isHighlighted,
		},
	)

	return (
		<BaseNode type={type} name={name}>
			<div className={iconWrapperClassName}>
				<KeyOutlined />
			</div>
		</BaseNode>
	)
}
