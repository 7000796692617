import { z } from 'zod'
import { camelizeSchema } from '../utils.ts'
import { ServerAbstractEnvironmentModelSchema } from './serverAbstractEnvironmentSchema.ts'

export const ServerOktaEnvironmentSchema = ServerAbstractEnvironmentModelSchema.extend({
	env_domain: z.string(),
})

export type ServerOktaEnvironment = z.infer<typeof ServerOktaEnvironmentSchema>

export const OktaEnvironmentSchema = camelizeSchema(ServerOktaEnvironmentSchema.transform((val) => val))
export type OktaEnvironment = z.infer<typeof OktaEnvironmentSchema>
