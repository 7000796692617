import z from 'zod'
import { AnyFactories, ModelDefinition } from 'miragejs/-types'
import { Model, Registry } from 'miragejs'
import { ServerIssue, ServerIssueSchema } from '../schemas/issue.ts'
import { ServerIdentity, ServerIdentitySchema } from '../schemas/identity.ts'
import Schema from 'miragejs/orm/schema'

export const proxyObject = z.object({
	method: z.string(),
	integrationId: z.string().uuid(),
	queryParams: z
		.object({
			action: z.string().optional(),
			project: z.string().optional(),
		})
		.optional(),
	endpoint: z.string(),
})

export const CloudProviders = z.enum(['AWS', 'GCP', 'AZURE'])
export const IdentityProviders = z.enum(['OKTA', 'GOOGLE_WORKSPACE', 'JUMPCLOUD', 'ENTRA_ID'])

export const issueModel: ModelDefinition<ServerIssue> = Model.extend(ServerIssueSchema)
export const identityModel: ModelDefinition<ServerIdentity> = Model.extend(ServerIdentitySchema)

export const models = {
	issue: issueModel,
	identity: identityModel,
}
export type AppRegistry = Registry<typeof models, AnyFactories>
export type AppSchema = Schema<AppRegistry>
