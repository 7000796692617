import { tableFilterToQueryFilter } from '../../utils/tableQueryUtils.ts'
import {
	IdentitiesTableRow,
	inventoryUrlQuickSearchFilterParameter,
	ServerIdentitiesTableRow,
} from '../../schemas/identity.ts'
import { TableFilterEntry } from '../../schemas/tableFilter.ts'
import { GridApi, ISetFilter } from '@ag-grid-community/core'

const tableFieldToQueryFields = {
	// eslint-disable-next-line camelcase
	literal_friendly_name: ['literal_friendly_name', 'literal'],
	[inventoryUrlQuickSearchFilterParameter]: ['literal_friendly_name', 'literal'],
}

const tableArrayFieldsSet = new Set(['tags', 'tags_with_env'])
const tableSetToTextContainsFields = new Set(['affected_environments'])

export const getQueryFilterFromTableFilterModel = (filterModel: Record<string, TableFilterEntry>) =>
	tableFilterToQueryFilter(filterModel, {
		tableFieldToQueryFields,
		tableArrayFieldsSet,
		tableSetToTextContainsFields,
	})

const columnsWithDynamicFilterValues = ['source', 'account_literal', 'affected_environments', 'tags_with_env']

export function refreshDynamicFilterValues(
	gridApi?: GridApi<ServerIdentitiesTableRow> | GridApi<IdentitiesTableRow>,
	columnToIgnore?: string,
) {
	if (!gridApi) {
		return
	}

	columnsWithDynamicFilterValues.forEach((columnId) => {
		if (columnId === columnToIgnore) {
			return
		}

		gridApi.getColumnFilterInstance(columnId).then((setFilter) => {
			// eslint-disable-next-line no-extra-semi
			;(setFilter as ISetFilter).refreshFilterValues()
		})
	})
}
