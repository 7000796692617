import { Node, NodeProps } from '@xyflow/react'
import { TeamOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { DetailedAzureRoleAssignment } from '../../../../../schemas/identities/entraId/azureRoleAssignmentsXc.ts'
import { nodeColorClassNames } from '../../graphUtils/nodeColors.ts'

import { RiskNodeTagIcons } from '../../common/RiskNodeTagIcons.tsx'
import { BaseNode } from '../../common/baseNodes/BaseNode.tsx'
import { useHighlightedNodesContext } from '../../../HighlightedNodesContext.tsx'

// This is a production-ready azure-node component
export type EnrichedAzureRoleNodeType = Node<{ role: DetailedAzureRoleAssignment }, 'enrichedAzureRole'>
type AzureRoleNodeProps = NodeProps<EnrichedAzureRoleNodeType>

export const EnrichedAzureRoleNode = ({ data: { role } }: AzureRoleNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) =>
			highlightedNode.type === 'enrichedAzureRole' && highlightedNode.id === role.roleAssignment.id,
	)

	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
		{
			[nodeColorClassNames.permission.bg]: !isHighlighted,
			[nodeColorClassNames.permission.bgHighlighted]: isHighlighted,
		},
	)
	return (
		<BaseNode type="Azure Role" name={{ text: role.roleDefinition.roleName || 'Unknown Role' }}>
			<RiskNodeTagIcons tags={role.tags} />
			<div className={iconWrapperClassName}>
				<TeamOutlined />
			</div>
		</BaseNode>
	)
}
