import { NodeProps, Node } from '@xyflow/react'
import { GithubTokenTypeMap, GithubUserToken } from '../../../../schemas/identities/githubUserSchema.ts'
import { BaseCredentialsNode } from '../common/baseNodes/BaseCredentialsNode.tsx'

export type GithubUserTokenNodeType = Node<{ accessToken: GithubUserToken }, 'githubUserToken'>
type GithubUserTokenNodeProps = NodeProps<GithubUserTokenNodeType>

export const GithubUserTokenNode = ({ data: { accessToken } }: GithubUserTokenNodeProps) => (
	<BaseCredentialsNode type={GithubTokenTypeMap[accessToken.type]} name={{ text: accessToken.name ?? '' }} />
)
