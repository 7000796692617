import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent } from '@ag-grid-community/core'
import { GithubTokenTypeMap, GithubUserToken } from '../../../../schemas/identities/githubUserSchema.ts'
import { JsonViewer } from '../../../common/JsonViewer.tsx'

export const GithubTokensTable: React.FunctionComponent<{ githubTokens: GithubUserToken[] }> = ({ githubTokens }) => {
	const gridRef = useRef<AgGridReact<GithubUserToken>>(null)
	const columnDefs = useMemo<ColDef<GithubUserToken>[]>(() => {
		const baseColumns: ColDef<GithubUserToken>[] = [
			{ field: 'id', flex: 2 },
			{ field: 'name', flex: 2 },
			{
				flex: 2,
				field: 'type',
				cellRenderer: (params: { data?: GithubUserToken }) => {
					return params.data?.type ? GithubTokenTypeMap[params.data?.type] : 'Unknown Type'
				},
			},
			{ field: 'createdAt', flex: 2, sort: 'asc' },
			{ field: 'lastUsedAt', flex: 2 },
			{ field: 'expiresAt', flex: 2 },
			{
				flex: 2,
				field: 'scopes',
				cellRenderer: (params: { data?: GithubUserToken }) => {
					if (params.data?.scopes) {
						return <JsonViewer data={params.data.scopes} title="Scopes" />
					}
				},
			},
		]
		return baseColumns
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback((_params: GridReadyEvent<GithubUserToken>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	return (
		<div className="h-80 overflow-auto">
			<AgGridReact
				className={'ag-theme-alpine h-full w-full overflow-x-auto'}
				ref={gridRef}
				rowData={githubTokens}
				rowHeight={54}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
