import { Node, NodeProps } from '@xyflow/react'
import { FileTextOutlined } from '@ant-design/icons'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import cx from 'classnames'
import { SnowflakeRoleXc } from '../../../../schemas/identities/snowflake/snowflakeRoleXcSchema.ts'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'

export type SnowflakeRoleNodeType = Node<{ role: SnowflakeRoleXc }, 'snowflakeRole'>
type SnowflakeRoleNodeProps = NodeProps<SnowflakeRoleNodeType>

export const SnowflakeRoleNode = ({ data: { role } }: SnowflakeRoleNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'snowflakeRole' && highlightedNode.id === role.name,
	)
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} transition-colors duration-300 w-[50px] h-[50px] text-white rounded-full flex justify-center items-center`,
		{
			[nodeColorClassNames.permission.bg]: !isHighlighted,
			[nodeColorClassNames.permission.bgHighlighted]: isHighlighted,
		},
	)

	return (
		<BaseNode type="Snowflake Role" name={{ text: role.name }}>
			<div className={iconWrapperClassName}>
				<FileTextOutlined />
			</div>
		</BaseNode>
	)
}
