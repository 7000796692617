import { AgGridReact } from '@ag-grid-community/react'
import React, { useMemo } from 'react'
import { ColDef } from '@ag-grid-community/core'
import { AzureUsageLog } from '../../../../schemas/identities/entraId/azureUsageLogSchema.ts'

interface AggregatedAzureUsageLog {
	caller: string
	operations: string[]
	sourceIpAddresses: string[]
	firstEventTime: Date
	lastEventTime: Date
	operationCount: number
	association: string
}

export const AzureUsageLogsAggregationsTable: React.FunctionComponent<{
	usageLogs: AzureUsageLog[]
}> = ({ usageLogs }) => {
	const defaultColDef: ColDef = {
		sortable: true,
		resizable: true,
		filter: true,
		cellStyle: () => ({
			display: 'flex',
			alignItems: 'center',
		}),
		flex: 1,
	}

	const columnDefs: ColDef<AggregatedAzureUsageLog>[] = [
		{
			field: 'operations',
			headerName: 'Operations',
			valueFormatter: (params: { value: string[] | null | undefined }) => {
				return params.value?.join(', ') || ''
			},
		},
		{
			field: 'association',
			headerName: 'Association',
		},
		{
			field: 'operationCount',
			headerName: 'Count',
			sort: 'desc',
			flex: 0.8,
		},
		{
			field: 'sourceIpAddresses',
			headerName: 'Source IP Addresses',
			valueFormatter: (params: { value: string[] | null | undefined }) => {
				return params.value?.join(', ') || ''
			},
		},
		{
			field: 'firstEventTime',
			headerName: 'First Event',
		},
		{
			field: 'lastEventTime',
			headerName: 'Last Event',
		},
	]

	const aggregatedEntries = useMemo(() => {
		const aggregatedMap = usageLogs.reduce(
			(acc, entry) => {
				const key = `${entry.ip}`
				const existing = acc[key]

				if (existing) {
					// Update firstEventTime and lastEventTime
					existing.firstEventTime =
						existing.firstEventTime < new Date(entry.day) ? existing.firstEventTime : new Date(entry.day)
					existing.lastEventTime =
						existing.lastEventTime > new Date(entry.day) ? existing.lastEventTime : new Date(entry.day)

					// Increment operation count by the operations_count field
					existing.operationCount += entry.operationsCount

					// Add unique source IP addresses
					if (!existing.sourceIpAddresses.includes(entry.ip || '')) {
						existing.sourceIpAddresses.push(entry.ip || '')
					}

					// Add unique operations from the array
					entry.operations.forEach((operation) => {
						if (!existing.operations.includes(operation)) {
							existing.operations.push(operation)
						}
					})
				} else {
					// Initialize the new aggregated log entry
					acc[key] = {
						caller: entry.caller || '',
						operations: [...entry.operations],
						sourceIpAddresses: [entry.ip || ''],
						firstEventTime: new Date(entry.day),
						lastEventTime: new Date(entry.day),
						operationCount: entry.operationsCount,
						association: entry.association || '',
					}
				}
				return acc
			},
			{} as Record<string, AggregatedAzureUsageLog>,
		)

		return Object.values(aggregatedMap)
	}, [usageLogs])

	return (
		<AgGridReact
			className={'ag-theme-alpine h-full w-full overflow-x-auto'}
			rowHeight={54}
			rowData={aggregatedEntries}
			columnDefs={columnDefs}
			defaultColDef={defaultColDef}
			enableCellTextSelection
			suppressHorizontalScroll={false}
		/>
	)
}
