import { useCallback, useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { CellMouseOutEvent, CellMouseOverEvent, ColDef, GetRowIdParams, GridReadyEvent } from '@ag-grid-community/core'
import type { SnowflakeRoleXc } from '../schemas/identities/snowflake/snowflakeRoleXcSchema.ts'
import { TagIcons } from '../components/common/TagIcons.tsx'
import { type Tag, type TagSchemaType, TagsDisplayNameMap } from '../schemas/tags.ts'

export const SnowflakeRolesTable: React.FunctionComponent<{
	data: SnowflakeRoleXc[]
	lastSelectedRole?: string
	onCellMouseOver?: (event: CellMouseOverEvent<SnowflakeRoleXc>) => void
	onCellMouseOut?: (event: CellMouseOutEvent<SnowflakeRoleXc>) => void
}> = ({ data, lastSelectedRole, onCellMouseOver, onCellMouseOut }) => {
	const gridRef = useRef<AgGridReact<SnowflakeRoleXc>>(null)

	useEffect(() => {
		highlightRow(lastSelectedRole)
	}, [lastSelectedRole])

	const highlightRow = useCallback((rowId?: string | null) => {
		if (!rowId) {
			return
		}

		const rowNode = gridRef.current?.api?.getRowNode(rowId)
		if (!rowNode) {
			return
		}

		gridRef.current?.api?.ensureNodeVisible(rowNode, 'middle')
		gridRef.current?.api?.flashCells({ rowNodes: [rowNode] })
	}, [])

	const onGridReady = useCallback((_params: GridReadyEvent<SnowflakeRoleXc>) => {
		highlightRow(lastSelectedRole)
	}, [])

	const columnDefs = useMemo<ColDef<SnowflakeRoleXc>[]>(() => {
		return [
			{ field: 'name' },
			{ field: 'createdOn' },
			{ field: 'owner' },
			{
				headerName: 'Assignment',
				field: 'directUserAssignment',
				cellRenderer: (params: { data?: SnowflakeRoleXc }) => {
					return <div>{params?.data?.directUserAssignment ? 'Direct' : 'Indirect'}</div>
				},
			},
			{
				headerName: 'Risk',
				cellRenderer: (params: { data?: SnowflakeRoleXc }) => {
					return <TagIcons tags={params.data?.tags?.map((tag) => tag.name)} />
				},
				valueGetter: (params: { data?: SnowflakeRoleXc }) => {
					return params.data?.tags?.map((tag: TagSchemaType): string => TagsDisplayNameMap[tag.name as Tag])
				},
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	return (
		<div className="h-64 overflow-auto">
			<AgGridReact
				className={'ag-theme-alpine w-full'}
				rowData={data}
				rowHeight={54}
				getRowId={(rowData: GetRowIdParams<SnowflakeRoleXc>) => rowData.data.name}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
				ref={gridRef}
				onCellMouseOver={onCellMouseOver}
				onCellMouseOut={onCellMouseOut}
			/>
		</div>
	)
}
