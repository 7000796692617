import React, { useMemo } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GetRowIdParams } from '@ag-grid-community/core'
import { SalesforceIdentityOwnershipRecord } from '../../schemas/identities/salesforce/salesforceOwnershipRecordSchema.ts'
import { Tooltip } from 'antd'
import { formatDate, formatRelativeDateText } from '../../utils.ts'

export const SalesforceOwnershipTable: React.FunctionComponent<{
	data: SalesforceIdentityOwnershipRecord[]
}> = ({ data }) => {
	const columnDefs = useMemo<ColDef<SalesforceIdentityOwnershipRecord>[]>(() => {
		return [
			{ field: 'owner.username', headerName: 'Username', flex: 1 },
			{ field: 'owner.id', headerName: 'User ID', flex: 1 },
			{ field: 'actionType', headerName: 'Action Type', flex: 1 },
			{
				field: 'lastModifiedDate',
				headerName: 'Last Modified Date',
				flex: 1,
				cellRenderer: (params: { value: string }) => (
					<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
						<div className="text-gray-400">{formatRelativeDateText(params.value, true)}</div>
					</Tooltip>
				),
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	return (
		<div className="h-80 overflow-auto">
			<AgGridReact
				rowData={data}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				getRowId={(params: GetRowIdParams<SalesforceIdentityOwnershipRecord>) => params.data.owner.id}
				enableCellTextSelection
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				rowHeight={54}
			/>
		</div>
	)
}
