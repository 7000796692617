import { Collapse } from 'antd'
import React from 'react'
import MachineIcon from '../../../assets/machine_icon_20.svg?react'
import { EndpointAccessXc } from '../../../schemas/identities/endpointAccessXcSchema.ts'
import { EndpointAccessContextTable } from '../../../tables/EndpointAccessTable.tsx'

export const EndpointAccessContext: React.FunctionComponent<{
	endpointAccessXc: EndpointAccessXc[]
}> = ({ endpointAccessXc }) => {
	return (
		<>
			<Collapse expandIconPosition={'end'}>
				<Collapse.Panel
					header={
						<div className="flex items-center space-x-2">
							<MachineIcon className="h-7 mr-2" />
							<div>Endpoint Access</div>
						</div>
					}
					key="EndpointAccessXcResources"
				>
					<EndpointAccessContextTable endpointAccessXc={endpointAccessXc} />
				</Collapse.Panel>
			</Collapse>
		</>
	)
}
