import { Identity } from '../../schemas/identity.ts'
import { useMemo } from 'react'
import { Link } from '@tanstack/react-router'
import { LeftOutlined } from '@ant-design/icons'
import { Breadcrumb, Tooltip } from 'antd'
import { StatItem } from '../../components/common/StatItem.tsx'
import { PriorityNameTag } from '../../components/common/PriorityNameTag.tsx'
import { getIssuesMaxPriority } from '../../utils/issueUtils.ts'
import { IssueSourceIcon } from '../../components/common/IssueSourceIcon.tsx'
import { IdentityTypeTag } from '../../components/common/IdentityTypeTag.tsx'
import { TagIcons } from '../../components/common/TagIcons.tsx'
import { formatDate } from '../../utils.ts'
import { RoutePaths } from '../RoutePaths.tsx'

export const IdentityHeader = ({ identity }: { identity: Identity }) => {
	const breadcrumbsItems = useMemo(
		() => [
			{
				title: (
					<Link
						from={RoutePaths.Identity}
						to={RoutePaths.Identities}
						search={({ identitiesPage }) => ({ ...identitiesPage })}
						className="!flex items-center !h-full"
					>
						<LeftOutlined className="mr-2" />
						Inventory
					</Link>
				),
			},
			{ title: <Tooltip title={identity.literal}>{identity.literalFriendlyName || identity.literal}</Tooltip> },
		],
		[identity],
	)

	return (
		<div className="flex flex-col gap-4">
			<Breadcrumb className="text-base" items={breadcrumbsItems} />
			<div className="flex justify-between">
				<StatItem title="Risk Score">
					<PriorityNameTag priority={getIssuesMaxPriority(identity.issues)} />
				</StatItem>
				<StatItem title="Identity Source">
					<IssueSourceIcon source={identity.envType} />
				</StatItem>
				<StatItem title="Findings">
					<span className="text-2xl font-medium">{identity.issues?.length ?? 0}</span>
				</StatItem>
				<StatItem title="Identity Type">
					<IdentityTypeTag type={identity.type} />
				</StatItem>
				<StatItem title="Risk Type">
					{identity.tags?.length ? <TagIcons tags={identity.tags.map((tag) => tag.name)} /> : 'N/A'}
				</StatItem>
				<StatItem title="Date Created">
					<Tooltip title={formatDate(identity.createdAt)}>{formatDate(identity.createdAt, false)}</Tooltip>
				</StatItem>
				<StatItem title="Date Updated">
					<Tooltip title={formatDate(identity.updatedAt)}>{formatDate(identity.updatedAt, false)}</Tooltip>
				</StatItem>
				<StatItem title="Last Activity">
					<Tooltip title={formatDate(identity.lastActivity)}>
						{formatDate(identity.lastActivity, false)}
					</Tooltip>
				</StatItem>
			</div>
		</div>
	)
}
