import { Identity } from '../../../schemas/identity'
import { EnvironmentType } from '../../../schemas/envType.ts'
import React, { useEffect, useState } from 'react'
import { BaseIdentityDetail } from './baseIdentities/BaseIdentityDetail'
import { PostgresRole } from '../../../schemas/identities/postgresRoleSchema.ts'
import { IdentityTypeTag } from '../../common/IdentityTypeTag.tsx'
import { IssueSourceIcon } from '../../common/IssueSourceIcon.tsx'
import { formatDate } from '../../../utils.ts'
import PostgresIcon from '../../../assets/postgres_icon_20.svg?react'
import { Col, Divider, Row, Space, Tooltip } from 'antd'
import { AwsIamRoleContext } from './AwsIamRoleContext.tsx'
import { AwsEc2InstanceContext } from './AwsEc2InstanceContext.tsx'
import { AwsIamUserChangeLogsContext } from './AwsIamUserChangeLogsContext.tsx'
import { KubernetesResourceContext } from './kubernetes/KubernetesResourceContext.tsx'

export const PostgresRoleIdentity: React.FunctionComponent<{
	identity?: Identity | null
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const [contextAmount, setContentAmount] = useState(0)
	const postgresRole: PostgresRole | null | undefined = identity?.postgresRole
	useEffect(() => {
		calcAmountOfContext()
	})

	const calcAmountOfContext = () => {
		let amount = 0
		if (postgresRole?.awsIamRolesXc?.length) amount++
		if (postgresRole?.awsEc2InstancesXc?.length ?? 0 > 0) amount++
		if (postgresRole?.changeLogs?.length ?? 0 > 0) amount++
		if (postgresRole?.kubernetesResourcesXc?.length ?? 0 > 0) amount++
		setContentAmount(amount)
	}

	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail label="Role Name" value={postgresRole?.roleName} span={1} />

				<BaseIdentityDetail label="DB Name" value={postgresRole?.rdsInstance?.dbName} span={1} />

				<BaseIdentityDetail label="Instance ID" value={postgresRole?.rdsInstance?.instanceId} span={1} />

				<BaseIdentityDetail label="DB Host" value={postgresRole?.rdsInstance?.host} span={1} />

				<BaseIdentityDetail label="DB Region" value={postgresRole?.rdsInstance?.region} span={1} />

				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Account" span={1}>
					<div className="flex flex-row items-center space-x-2">
						<div className="flex -space-x-4 rtl:space-x-reverse">
							<IssueSourceIcon source={envType} />
							<Tooltip title="Postgres">
								<PostgresIcon />
							</Tooltip>
						</div>
						<Tooltip placement="topLeft" title={identity?.accountLiteral}>
							<div className="font-normal text-gray-600 items-end truncate">
								{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
							</div>
						</Tooltip>
					</div>
				</BaseIdentityDetail>

				<BaseIdentityDetail
					label="Role Valid Until"
					value={formatDate(postgresRole?.roleValidUntil)}
					span={1}
				/>
				<BaseIdentityDetail label="Superuser" span={1}>
					{postgresRole?.isRoleSuperuser ? 'TRUE' : 'FALSE'}
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Can Create Role" span={1}>
					{postgresRole?.canRoleCreateRole ? 'TRUE' : 'FALSE'}
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Can Login" span={1}>
					{postgresRole?.canRoleLogin ? 'TRUE' : 'FALSE'}
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Bypass RLS" span={1}>
					{postgresRole?.canRoleBypassRls ? 'TRUE' : 'FALSE'}
				</BaseIdentityDetail>

				<BaseIdentityDetail
					label="Connections Limit"
					value={
						postgresRole?.roleConnectionsLimit === -1
							? 'Unlimited'
							: postgresRole?.roleConnectionsLimit?.toString()
					}
					span={1}
				/>

				<BaseIdentityDetail label="Created At" value={formatDate(identity?.createdAt)} span={1} />
				<BaseIdentityDetail
					label="Role Last Activity"
					value={formatDate(postgresRole?.roleLastActivityDate)}
					span={1}
				/>
			</div>
			<div className="w-full">
				{contextAmount > 0 && (
					<div className="w-full">
						<Divider />
						<Row>
							<Col>
								<div className="text-gray-900">Context ({contextAmount})</div>
							</Col>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{postgresRole?.awsIamRolesXc && postgresRole?.awsIamRolesXc.length > 0 && (
									<AwsIamRoleContext awsIamRolesXc={postgresRole?.awsIamRolesXc} />
								)}
								{postgresRole?.awsEc2InstancesXc && postgresRole.awsEc2InstancesXc.length > 0 ? (
									<Row className="mt-2">
										<Space className="w-full" direction="vertical">
											<AwsEc2InstanceContext awsEc2InstancesXc={postgresRole.awsEc2InstancesXc} />
										</Space>
									</Row>
								) : null}
								<AwsIamUserChangeLogsContext
									environmentId={identity?.environmentId ?? ''}
									subEnvironmentId={identity?.awsIamUser?.awsAccount?.id ?? ''}
									identityArn={''}
									changeLogs={postgresRole?.changeLogs ?? []}
								/>
								{postgresRole?.kubernetesResourcesXc &&
									postgresRole?.kubernetesResourcesXc.length > 0 && (
										<KubernetesResourceContext
											kubernetesResourcesXc={postgresRole?.kubernetesResourcesXc}
										/>
									)}
							</Space>
						</Row>
					</div>
				)}
			</div>
		</>
	)
}
