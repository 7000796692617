import { Identity } from '../../../../../schemas/identity.ts'
import { Edge } from '@xyflow/react'
import { IdentityNodeType } from '../../common/IdentityNode.tsx'
import { getEdge } from '../../graphUtils/nodesAndEdges.ts'
import { IssueNodeType } from '../../common/IssueNode.tsx'
import { issuePrioritySorter } from '../../../../../utils/issueUtils.ts'
import { AzureKeyVaultNodeType } from './AzureKeyVaultNode.tsx'
import {
	AzureKeyVault,
	AzureKeyVaultAccessPolicy,
} from '../../../../../schemas/identities/azure/azureKeyVault/azureKeyVault.ts'
import { EntraIDUser, EntraIDUserNodeType } from '../../entraId/EntraIDUserNode.tsx'
import { EntraIDServicePrincipal, EntraIDServicePrincipalNodeType } from '../../entraId/EntraIDServicePrincipalNode.tsx'
import { BareNodesColumnsType, BareNodeType } from '../../identityGraphTypes.ts'
import { OwnershipNodeType } from '../../common/OwnershipNode.tsx'
import { EntraIdChangeLog } from '../../../../../schemas/identities/entraId/entraIdChangeLogSchema.ts'
import { aggregateEntraIdChangeLogs } from '../../../../../utils/entraIdentityUtils.ts'
import { getActorArnFriendlyName } from '../../../../../components/drawers/identities/utils.tsx'

const nodeLogicalTypeToColumnId = {
	generalIssue: 0,
	identity: 1,
	keyVault: 2,
	entraID: 3,
}

const ownerNodeRowIndex = 0
const ownerNodeId = `${nodeLogicalTypeToColumnId.identity}-${ownerNodeRowIndex}`
const identityNodeRowIndex = 1
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`

export const getAzureKVNodesAndEdges = (identity: Identity): [BareNodesColumnsType[], Edge[]] => {
	const edges: Edge[] = []
	const identityNodes: Array<BareNodeType<IdentityNodeType> | BareNodeType<OwnershipNodeType>> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	const changeLogs: EntraIdChangeLog[] =
		identity.azureKvCertificate?.changeLogs ||
		identity.azureKvKey?.changeLogs ||
		identity.azureKvSecret?.changeLogs ||
		[]

	if (changeLogs?.length) {
		const aggregatedChangeLogs = aggregateEntraIdChangeLogs(changeLogs)
		identityNodes.unshift({
			type: 'ownership',
			data: {
				owners: aggregatedChangeLogs.map((aggChangeLog) => ({
					id: aggChangeLog.actorPrincipalId,
					name: getActorArnFriendlyName(aggChangeLog.actorPrincipalName),
				})),
			},
			id: ownerNodeId,
		})

		edges.push(
			getEdge({
				source: ownerNodeId,
				target: identityNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []
	const keyVaultNodes: Array<BareNodeType<AzureKeyVaultNodeType>> = []

	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue, index) => {
		generalIssueNodes.push({
			type: 'issue',
			data: { issue },
			id: `${nodeLogicalTypeToColumnId.generalIssue}-${index}`,
		})
		edges.push(
			getEdge({
				source: `${nodeLogicalTypeToColumnId.generalIssue}-${index}`,
				target: identityNodeId,
			}),
		)
	})

	const baseIdentity = identity.azureKvSecret || identity.azureKvCertificate || identity.azureKvKey

	const users: EntraIDUser[] = []
	const servicePrincipals: EntraIDServicePrincipal[] = []
	const entraIDNodes: Array<BareNodeType<EntraIDUserNodeType> | BareNodeType<EntraIDServicePrincipalNodeType>> = []
	if (baseIdentity && baseIdentity.keyVault) {
		const keyVault: AzureKeyVault = baseIdentity.keyVault
		const keyVaultNodeId = `${nodeLogicalTypeToColumnId.keyVault}-0`
		keyVaultNodes.push({
			type: 'azureKeyVault',
			data: { keyVault },
			id: keyVaultNodeId,
		})
		edges.push(
			getEdge({
				source: identityNodeId,
				target: keyVaultNodeId,
			}),
		)

		// Process access policies
		keyVault.accessPolicies?.forEach((policy: AzureKeyVaultAccessPolicy) => {
			const isUser = policy.principalType === 'User'

			if (isUser) {
				users.push({
					principalName: policy.principalName,
					objectId: policy.objectId,
				})
			} else {
				servicePrincipals.push({
					principalName: policy.principalName,
					objectId: policy.objectId,
				})
			}
		})

		users.forEach((user) => {
			entraIDNodes.push({
				type: 'entraIDUser',
				data: { user },
				id: `${nodeLogicalTypeToColumnId.entraID}-${entraIDNodes.length}`,
			})
		})

		servicePrincipals.forEach((servicePrincipal) => {
			entraIDNodes.push({
				type: 'entraIDServicePrincipal',
				data: { servicePrincipal },
				id: `${nodeLogicalTypeToColumnId.entraID}-${entraIDNodes.length}`,
			})
		})

		entraIDNodes.forEach((_, index) => {
			edges.push(
				getEdge({
					source: keyVaultNodeId,
					target: `${nodeLogicalTypeToColumnId.entraID}-${index}`,
				}),
			)
		})
	}

	const nodes: BareNodesColumnsType[] = [
		{ yPosition: 'center', nodes: generalIssueNodes },
		{ yPosition: 'center', nodes: identityNodes },
		{ yPosition: 'center', nodes: keyVaultNodes },
		{
			yPosition: 'center',
			nodes: entraIDNodes,
		},
	]

	return [nodes, edges]
}
