import { Server } from 'miragejs/server'
import {
	IdentitiesPageSearchSchema,
	ServerIdentitiesTableRow,
	ServerIdentity,
	ServerIdentityRelatedIssues,
	ServerIdentityUsageLogs,
} from '../../schemas/identity.ts'
import { getIdentityWithIssues } from './utils.ts'
import { AppSchema } from '../schemas.ts'
import {
	getAllIdentitiesFromIssues,
	getAllIssues,
	getAllStandAloneIdentities,
	getIdentityById,
	getIdentityByIdFromIssues,
	getUsageLogsByIdentityId,
} from '../mirageORM.ts'
import { filterIdentities } from './identitiesUtils.ts'

export function mirageGetRelatedIssues(server: Server) {
	server.get('/identity/:id/related-issues', (schema: AppSchema, request): ServerIdentityRelatedIssues => {
		return getIdentityWithIssues(getAllIssues(schema), request.params.id)
	})
}

export function mirageIdentitiesSearch(server: Server) {
	server.post('/identity/search', (schema, request): ServerIdentitiesTableRow[] => {
		return filterIdentities(
			[...getAllIdentitiesFromIssues(schema), ...getAllStandAloneIdentities(schema)],
			IdentitiesPageSearchSchema.parse(JSON.parse(request.requestBody)),
		)
	})
}

export function mirageIdentity(server: Server) {
	server.get('/identity/:id', (schema, request): ServerIdentity => {
		return getIdentityById(schema, request.params.id) ?? getIdentityByIdFromIssues(schema, request.params.id)
	})
}

export function mirageUsageLogs(server: Server) {
	server.get('/identity/:id/usage-logs', (schema, request): ServerIdentityUsageLogs | null => {
		return getUsageLogsByIdentityId(schema, request.params.id)
	})
}
