import { z } from 'zod'
import { camelizeSchema } from '../../utils'

export const ServerDatabricksWorkspaceSchema = z
	.object({
		name: z.string(),
		status: z.string(),
		bucket_name: z.string(),
		credential_name: z.string(),
		metastore: z.string(),
	})
	.nullable()

export type ServerDatabricksWorkspace = z.infer<typeof ServerDatabricksWorkspaceSchema>

export const DatabricksWorkspaceSchema = camelizeSchema(ServerDatabricksWorkspaceSchema.transform((val) => val))
export type DatabricksWorkspace = z.infer<typeof DatabricksWorkspaceSchema>
