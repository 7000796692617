import { z } from 'zod'
import { camelizeSchema } from '../utils'
import { DateSchema } from '../common'

export const ServerGithubAppPermissionSchema = z.object({
	scope: z.string(),
	permission: z.string(),
})

export type ServerGithubAppPermission = z.infer<typeof ServerGithubAppPermissionSchema>

export const GithubAppPermissionSchema = camelizeSchema(ServerGithubAppPermissionSchema.transform((val) => val))
export type GithubAppPermission = z.infer<typeof GithubAppPermissionSchema>

export const ServerGithubAppInstallationSchema = z
	.object({
		id: z.string(),
		installation_id: z.number(),
		app_id: z.number(),
		permissions: z.array(ServerGithubAppPermissionSchema).nullish(),
		name: z.string().nullable(),
		is_public: z.boolean().nullable(),
		owner_id: z.number().nullable(),
		owner_name: z.string().nullable(),
		repositories: z.array(z.string()).nullable(),
		last_activity: DateSchema.nullish(),
		customer_id: z.string().uuid(),
		sub_env_id: z.string().nullable(),
		created_at: DateSchema,
		updated_at: DateSchema,
		environment_id: z.string(),
	})
	.nullable()

export type ServerGithubAppInstallation = z.infer<typeof ServerGithubAppInstallationSchema>

export const GithubAppInstallationSchema = camelizeSchema(ServerGithubAppInstallationSchema.transform((val) => val))
export type GithubAppInstallation = z.infer<typeof GithubAppInstallationSchema>
