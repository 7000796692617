import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Button, Modal } from 'antd'
import { getCsvFileName } from '../../../../utils.ts'
import { ColDef, SideBarDef } from '@ag-grid-community/core'
import ExportIcon from '../../../../assets/export_icon_20.svg?react'
import { AgGridReact } from '@ag-grid-community/react'
import { Identity } from '../../../../schemas/identity.ts'
import { AzureUsageLog } from '../../../../schemas/identities/entraId/azureUsageLogSchema.ts'
import { AzureUsageLogsAggregationsTable } from './AzureUsageLogsAggregationTable.tsx'

export const AzureUsageLogsContent = ({ usageLogs, identity }: { usageLogs: AzureUsageLog[]; identity: Identity }) => {
	const gridRef: React.RefObject<AgGridReact> = useRef<AgGridReact>(null)
	const sideBar: SideBarDef = useMemo(() => {
		return {
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
				},
			],
			position: 'left',
		}
	}, [])

	const [defaultColDef] = useState({
		sortable: true,
		resizable: true,
		filter: true,
		cellStyle: () => ({
			display: 'flex',
			alignItems: 'center',
		}),
		flex: 1,
	})
	const [isModalOpen, setIsModalOpen] = useState(false)
	const exportToCsv = useCallback(() => {
		gridRef?.current!.api.exportDataAsCsv({
			fileName: getCsvFileName(`usage-${identity.entraIdServicePrincipal?.displayName}`, false),
		})
	}, [])

	const [changeLogColumnDefs] = useState<ColDef<AzureUsageLog>[]>([
		{
			field: 'day',
			headerName: 'Event Time',
			sort: 'desc',
		},
		{
			field: 'association',
			headerName: 'Association',
		},
		{
			field: 'operations',
			headerName: 'Operations',
			valueFormatter: (params: { value: string[] | null | undefined }) => {
				return params.value?.join(', ') || ''
			},
		},
		{
			field: 'operationsCount',
			headerName: 'Count',
		},
		{
			field: 'ip',
			headerName: 'Source IP Addresses',
		},
	])
	return (
		<>
			{usageLogs.length > 0 ? (
				<>
					<div className="h-64 overflow-auto">
						<AzureUsageLogsAggregationsTable usageLogs={usageLogs} />
					</div>

					<div className="flex justify-between">
						<button
							className="mt-4 underline cursor-pointer text-blue-600"
							onClick={() => setIsModalOpen(true)}
						>
							See all events
						</button>
					</div>
				</>
			) : (
				<div className={'text-center'}>No Usage data available</div>
			)}
			<Modal
				centered={true}
				width={1024}
				title={'Usage'}
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				onOk={() => setIsModalOpen(false)}
				footer={[
					<div key="footer" className="flex justify-between items-center">
						<div className="flex">
							<Button
								icon={<ExportIcon />}
								onClick={exportToCsv}
								className="bg-white text-gray-500 px-2 flex items-start mr-2"
							>
								Export CSV
							</Button>
							<Button className="bg-black text-white" onClick={() => setIsModalOpen(false)}>
								Done
							</Button>
						</div>
					</div>,
				]}
			>
				<div className="flex overflow-y-auto h-[70vh]">
					<AgGridReact
						className={'ag-theme-alpine w-full'}
						rowData={usageLogs}
						rowHeight={54}
						columnDefs={changeLogColumnDefs}
						defaultColDef={defaultColDef}
						enableCellTextSelection
						sideBar={sideBar}
					/>
				</div>
			</Modal>
		</>
	)
}
