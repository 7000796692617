import { Identity } from '../../../schemas/identity.ts'
import { DatabricksWorkspaceTable } from '../../../tables/databricks/DataBricksWrokspaceTable.tsx'

export const SidePanelWorkspaces = ({ identity }: { identity: Identity }) => {
	const databricksWorkspaces =
		identity?.databricksUser?.workspaces || identity?.databricksServicePrincipal?.workspaces || []
	if (databricksWorkspaces?.length) {
		return <DatabricksWorkspaceTable data={databricksWorkspaces} />
	}

	return (
		<div className="flex gap-1">
			<span>No workspaces found for identity</span>
			<span className="font-semibold">{identity.literalFriendlyName || identity.literal}</span>
		</div>
	)
}
