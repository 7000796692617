import { Edge } from '@xyflow/react'
import { Identity, IdentitySource } from '../../../../schemas/identity.ts'
import { getAwsNodesAndEdges } from '../aws/awsGraphUtils.ts'
import { getGcpNodesAndEdges } from '../gcp/gcpGraphUtils.ts'
import { getGithubNodesAndEdges } from '../github/githubGraphUtils.ts'
import { getEntraIdNodesAndEdges } from '../entraId/entraIdGraphUtils.ts'
import { getDatabricksNodesAndEdges } from '../databricks/databricksGraphUtils.ts'
import { getAzureKVNodesAndEdges } from '../azure/azureKV/azureKVGraphUtils.ts'
import { getOktaNodesAndEdges } from '../okta/oktaGraphUtils.ts'
import { getSnowflakeNodesAndEdges } from '../snowflake/snowflakeGraphUtils.ts'
import { BareNodesColumnsType, IdentityGraphNodeType } from '../identityGraphTypes.ts'
import { groupNodes } from './nodeGroups.ts'
import { uniqWith } from 'lodash'
import { getJumpcloudNodesAndEdges } from '../jumpcloud/jumpcloudGraphUtils.ts'
import { getSalesforceNodesAndEdges } from '../salesforce/salesforceGraphUtils.ts'
import { getGoogleWorkspaceNodesAndEdges } from '../googleWorkspace/googleWorkspaceGraphUtils.ts'
import { getPostgresRoleNodesAndEdges } from '../postgresRole/postgresGraphUtils.ts'
import { getAzureDevOpsNodesAndEdges } from '../azure/azureDevops/azureDevopsGraphUtils.ts'

const yGap = 200
const xGap = 200

type GetEdgeArgs = {
	source: string
	target: string
	sourceHandle?: string
	targetHandle?: string
	animated?: boolean
}

export const getEdge = ({ source, target, sourceHandle, targetHandle, animated }: GetEdgeArgs): Edge => ({
	id: `edge_${source}_${target}`,
	source,
	sourceHandle: sourceHandle || 'right',
	targetHandle: targetHandle || 'left',
	target,
	animated,
})

export const getNodesAndEdges = (identity: Identity, isCompact: boolean): [IdentityGraphNodeType[], Edge[]] => {
	let [bareNodesColumns, edges]: [BareNodesColumnsType[], Edge[]] = [[], []]
	switch (identity?.source) {
		case IdentitySource.AWS_IAM_USER:
		case IdentitySource.AWS_IAM_ROLE:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getAwsNodesAndEdges(identity)
			break
		case IdentitySource.GCP_SERVICE_ACCOUNT:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getGcpNodesAndEdges(identity)
			break
		case IdentitySource.GITHUB_APP_INSTALLATION:
		case IdentitySource.GITHUB_USER:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getGithubNodesAndEdges(identity)
			break
		case IdentitySource.ENTRA_ID_USER:
		case IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getEntraIdNodesAndEdges(identity)
			break
		case IdentitySource.SNOWFLAKE_USER:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getSnowflakeNodesAndEdges(identity)
			break
		case IdentitySource.OKTA:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getOktaNodesAndEdges(identity)
			break
		case IdentitySource.DATABRICKS_USER:
		case IdentitySource.DATABRICKS_SERVICE_PRINCIPAL:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getDatabricksNodesAndEdges(identity)
			break
		case IdentitySource.AZURE_KV_CERTIFICATE:
		case IdentitySource.AZURE_KV_KEY:
		case IdentitySource.AZURE_KV_SECRET:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getAzureKVNodesAndEdges(identity)
			break
		case IdentitySource.JUMPCLOUD_USER:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getJumpcloudNodesAndEdges(identity)
			break
		case IdentitySource.SALESFORCE_USER:
		case IdentitySource.SALESFORCE_CONNECTED_APPLICATION:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getSalesforceNodesAndEdges(identity)
			break
		case IdentitySource.GOOGLE_WORKSPACE:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getGoogleWorkspaceNodesAndEdges(identity)
			break
		case IdentitySource.POSTGRES_ROLE:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getPostgresRoleNodesAndEdges(identity)
			break
		case IdentitySource.AZURE_DEVOPS_USER:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getAzureDevOpsNodesAndEdges(identity)
			break
	}

	if (isCompact) {
		// eslint-disable-next-line no-extra-semi
		;[bareNodesColumns, edges] = groupNodes(bareNodesColumns, edges)
	}

	edges = uniqWith(edges, (edgeA, edgeB) => edgeA.source === edgeB.source && edgeA.target === edgeB.target)

	const nodes: IdentityGraphNodeType[] = bareNodesColumns
		.filter((bareNodeColumn) => !!bareNodeColumn.nodes.length)
		.map((bareNodeColumn, columnIndex) => {
			const middleYPoint = (bareNodeColumn.nodes.length - 1) / 2
			const x = columnIndex * xGap
			return bareNodeColumn.nodes.toReversed().map((bareNode, rowIndex) => {
				const y =
					bareNodeColumn.yPosition === 'top' ? (0 - (rowIndex + 1)) * yGap : (middleYPoint - rowIndex) * yGap
				return { ...bareNode, position: { x, y }, selectable: true }
			})
		})
		.flat()

	return [nodes, edges]
}
