import { z } from 'zod'
import { DateSchema } from './common.ts'
import { camelizeSchema } from './utils.ts'
import { EnvironmentType } from './envType.ts'
import * as Sentry from '@sentry/react'
import { themeColors } from '../utils/colorUtils.ts'

export enum Tag {
	ADMIN = 'ADMIN',
	DATA_ACCESS = 'DATA_ACCESS',
	CREDENTIAL_EXPOSURE = 'CREDENTIAL_EXPOSURE',
	RESOURCE_EXPOSURE = 'RESOURCE_EXPOSURE',
	POSSIBLE_PRIVILEGE_ESCALATION = 'POSSIBLE_PRIVILEGE_ESCALATION',
}

export enum MockDemoTag {
	OVER_PRIVILEGED_IDENTITY = 'OVER_PRIVILEGED_IDENTITY',
	IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR = 'IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR',
}

export const TagsDisplayNameMap: Record<Tag, string> = {
	ADMIN: 'Admin',
	DATA_ACCESS: 'Data Access',
	CREDENTIAL_EXPOSURE: 'Credential Exposure',
	RESOURCE_EXPOSURE: 'Resource Exposure',
	POSSIBLE_PRIVILEGE_ESCALATION: 'Privilege Escalation',
}

export const TagsColorsMap: Record<Tag, string> = {
	ADMIN: themeColors.pink[400],
	DATA_ACCESS: themeColors.pink[600],
	CREDENTIAL_EXPOSURE: themeColors.pink[300],
	RESOURCE_EXPOSURE: themeColors.pink[200],
	POSSIBLE_PRIVILEGE_ESCALATION: themeColors.pink[800],
}

export const AWSTagsDescriptionMap: Record<Tag, string> = {
	ADMIN: 'The user has the AdministratorAccess policy attached to their IAM user, group, or role.',
	DATA_ACCESS:
		'A managed policy or managed policy action tag that indicates the presence of an action that could return data within data stores.',
	CREDENTIAL_EXPOSURE:
		'A managed policy or managed policy action tag that indicates the presence of an action that could produce a response that contains credentials.',
	RESOURCE_EXPOSURE:
		'A managed policy or managed policy action tag that indicates the presence of an action that could expose resources to the public.',
	POSSIBLE_PRIVILEGE_ESCALATION:
		'A managed policy or managed policy action tag that indicates the presence of an action that could potentially lead to a privilege escalation.',
}

export const AzureTagsDescriptionMap: Record<Tag, string> = {
	ADMIN: 'The identity has a role assigned to it that grants it admin access to the scope, making the identity highly privileged.',
	DATA_ACCESS:
		'A  built-in role tag that indicates the presence of an action that could return data within data stores.',
	CREDENTIAL_EXPOSURE:
		'A  built-in role tag that indicates the presence of an action that could produce a response that contains credentials.',
	RESOURCE_EXPOSURE:
		'A  built-in role tag that indicates the presence of an action that could expose resources to the public.',
	POSSIBLE_PRIVILEGE_ESCALATION:
		'A  built-in role tag that indicates the presence of an action that could potentially lead to a privilege escalation.',
}

export const EntraIDTagsDescriptionMap: Record<Tag, string> = {
	ADMIN: 'The identity has been assigned a privileged role in Entra ID, granting it administrative access across the tenant or specific resources.',
	DATA_ACCESS:
		'A built-in role tag indicating the ability to access or manage data within Entra ID, such as user data, logs, or audit trails.',
	CREDENTIAL_EXPOSURE:
		'A built-in role tag indicating that actions under this which allows access to manage encryption keys, exposing a risk of credential leakage.',
	RESOURCE_EXPOSURE:
		'A built-in role tag indicating the ability to access or manage cloud resources connected to Entra ID.',
	POSSIBLE_PRIVILEGE_ESCALATION:
		'A built-in role tag indicating that the assigned role has permissions that could be exploited to gain higher privileges within the tenant.',
}

export const SalesforceTagsDescriptionMap: Record<Tag, string> = {
	ADMIN: 'The user has permissions that grant extensive administrative access, including user management, role management, and system configuration capabilities.',
	DATA_ACCESS:
		'The user has permissions that allow broad access to view, edit, or export various types of data within the Salesforce organization.',
	CREDENTIAL_EXPOSURE:
		'The user has permissions that could potentially expose sensitive information or credentials, such as viewing encrypted data or accessing named credentials.',
	RESOURCE_EXPOSURE:
		'The user has permissions that allow them to access or modify resources that could potentially be exposed externally, such as public documents or reports.',
	POSSIBLE_PRIVILEGE_ESCALATION:
		'The user has permissions that could potentially be used to escalate privileges, such as assigning permission sets or managing custom permissions.',
}

export const GCPTagsDescriptionMap: Record<Tag, string> = {
	ADMIN: 'The user has the Owner role or a similar high-privilege role assigned to their IAM user, group, or service account.',
	DATA_ACCESS:
		'A predefined role or predefined role action tag that indicates the presence of an action that could return data within Google Cloud data stores.',
	CREDENTIAL_EXPOSURE:
		'A predefined role or predefined role action tag that indicates the presence of an action that could produce a response that contains credentials.',
	RESOURCE_EXPOSURE:
		'A predefined role or permission that allows resources to be made public or accessible outside the organization, potentially exposing them to external access.',
	POSSIBLE_PRIVILEGE_ESCALATION:
		'A predefined role or predefined role action tag that indicates the presence of an action that could potentially lead to a privilege escalation.',
}

export const getTagDescription = (tag: Tag, source: string | null | undefined) => {
	switch (source?.toUpperCase()) {
		case EnvironmentType.AZURE.toString():
			return AzureTagsDescriptionMap[tag]
		case EnvironmentType.SALESFORCE.toString():
			return SalesforceTagsDescriptionMap[tag]
		case EnvironmentType.AWS.toString():
			return AWSTagsDescriptionMap[tag]
		case EnvironmentType.ENTRA_ID.toString():
			return EntraIDTagsDescriptionMap[tag]
		case EnvironmentType.GCP.toString():
			return GCPTagsDescriptionMap[tag]
		default:
			Sentry.captureMessage(`getTagDescription - Unknown source: ${source}`)
			return AWSTagsDescriptionMap[tag]
	}
}

export enum ImpactType {
	LOW = 1,
	MEDIUM = 2,
	HIGH = 3,
	CRITICAL = 4,
}

export enum ProbabilityType {
	RARE = 1,
	UNLIKELY = 2,
	POSSIBLE = 3,
	LIKELY = 4,
	ALMOST_CERTAIN = 5,
}

export const TagSchema = z.object({
	id: z.string(),
	created_at: DateSchema.nullish(),
	updated_at: DateSchema.nullish(),
	source: z.string().nullish(),
	related_resource_type: z.string().nullish(),
	name: z.string(),
	impact: z.nativeEnum(ImpactType).nullish(),
	probability: z.nativeEnum(ProbabilityType).nullish(),
})

export type ServerTag = z.infer<typeof TagSchema>
export const TagSchemaCamel = camelizeSchema(TagSchema.transform((val) => val))
export type TagSchemaType = z.infer<typeof TagSchemaCamel>
