import { Node, NodeProps } from '@xyflow/react'
import { AppstoreOutlined } from '@ant-design/icons'
import { BaseIdentityNode } from '../common/baseNodes/BaseIdentityNode.tsx'

// Temporary interface to be replaced with the actual schema
export interface EntraIDServicePrincipal {
	principalName: string
	objectId: string
}

export type EntraIDServicePrincipalNodeType = Node<
	{ servicePrincipal: EntraIDServicePrincipal },
	'entraIDServicePrincipal'
>
type EntraIDServicePrincipalNodeProps = NodeProps<EntraIDServicePrincipalNodeType>

export const EntraIDServicePrincipalNode = ({ data: { servicePrincipal } }: EntraIDServicePrincipalNodeProps) => {
	return (
		<BaseIdentityNode
			name={{
				text: servicePrincipal.principalName || 'Unknown Service Principal',
				tooltipText: `ID: ${servicePrincipal.objectId}`,
			}}
			type="Entra ID Service Principal"
			Icon={AppstoreOutlined}
		/>
	)
}
