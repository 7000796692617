import { NodeProps, Node } from '@xyflow/react'
import { AwsIamUserAccessKey } from '../../../../schemas/identities/awsIamUserSchema.ts'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { BaseCredentialsNode } from '../common/baseNodes/BaseCredentialsNode.tsx'

export type AccessKeyNodeType = Node<{ accessKey: AwsIamUserAccessKey }, 'accessKey'>
type AccessKeyNodeProps = NodeProps<AccessKeyNodeType>

export const AccessKeyNode = ({ data: { accessKey } }: AccessKeyNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'accessKey' && highlightedNode.id === accessKey?.accessKeyId,
	)

	return (
		<BaseCredentialsNode
			isHighlighted={isHighlighted}
			type="Access Key"
			name={{ text: accessKey?.accessKeyId || 'Unknown access key' }}
		/>
	)
}
