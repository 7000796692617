import { Col, Divider, Row, Space, Tooltip } from 'antd'
import { formatDate, formatRelativeDateText } from '../../../utils'
import SuccessIcon from '../../../assets/success_icon_20.svg?react'
import WarningIcon from '../../../assets/warning_icon_20.svg?react'
import React, { useEffect, useState } from 'react'
import { AwsIamUser } from '../../../schemas/identities/awsIamUserSchema.ts'
import { BaseIdentityDetail } from './baseIdentities/BaseIdentityDetail'
import { IdentityTypeTag } from '../../../components/common/IdentityTypeTag'
import { AwsIamUserDetailAccessKey } from './iamUser/AwsIamUserDetailAccessKey'
import { IssueSourceIcon } from '../../../components/common/IssueSourceIcon'
import { Identity } from '../../../schemas/identity'
import { AwsIamRoleContext } from './AwsIamRoleContext'
import { AwsIamPermissionContext } from './AwsIamPermissionContext'
import { OktaUserContext } from './OktaUserContext.tsx'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { KubernetesResourceContext } from './kubernetes/KubernetesResourceContext.tsx'
import { AccountPriorityNameTag } from '../../common/AccountPriorityNameTag.tsx'
import { AwsIamUserChangeLogsContext } from './AwsIamUserChangeLogsContext.tsx'
import { AwsUsageLogsContext } from './AwsUsageLogsContext.tsx'
import { AwsEc2InstanceContext } from './AwsEc2InstanceContext.tsx'
import { EndpointAccessContext } from './EndpointAccessContext.tsx'

export const AwsIamUserIdentity: React.FunctionComponent<{
	identity?: Identity
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const awsIamUser: AwsIamUser | null = identity?.awsIamUser ?? null
	const [contextAmount, setContentAmount] = useState(0)
	useEffect(() => {
		calcAmountOfContext()
	})
	const accountPriority = awsIamUser?.awsAccount?.priority

	const calcAmountOfContext = () => {
		let amount = 0
		if (awsIamUser?.awsIamRolesXc?.length ?? 0 > 0) amount++
		if (awsIamUser?.awsEc2InstancesXc?.length ?? 0 > 0) amount++
		if (awsIamUser?.awsIamUserDetailsXc ?? 0 > 0) amount++
		if (awsIamUser?.oktaUserXc?.length ?? 0 > 0) amount++
		if (awsIamUser?.kubernetesResourcesXc?.length ?? 0 > 0) amount++
		if (awsIamUser?.changeLogs?.length ?? 0 > 0) amount++
		if (awsIamUser?.usageLogs?.length ?? 0 > 0) amount++
		if (awsIamUser?.endpointAccessXc?.length ?? 0 > 0) amount++
		setContentAmount(amount)
	}

	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail label="Name" value={awsIamUser?.name} span={1} />
				<BaseIdentityDetail label="Created At" value={formatDate(identity?.createdAt)} span={1} />
				<BaseIdentityDetail
					label="Last Activity"
					value={formatRelativeDateText(identity?.lastActivity, true)}
					tooltipTitle={formatDate(identity?.lastActivity)}
					span={1}
				/>
				<BaseIdentityDetail label="Updated At" value={formatDate(identity?.updatedAt)} span={1} />
				<BaseIdentityDetail label="Arn" value={identity?.literal} span={2} />
				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Account" span={1}>
					<div className="flex flex-row items-center space-x-2">
						<IssueSourceIcon source={envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
						<Tooltip placement="topLeft" title={identity?.accountLiteral}>
							<div className="font-normal text-gray-600 items-end truncate">
								{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
							</div>
						</Tooltip>
					</div>
				</BaseIdentityDetail>
				{accountPriority && (
					<BaseIdentityDetail label="Account Priority" span={1}>
						<AccountPriorityNameTag priority={accountPriority} />
					</BaseIdentityDetail>
				)}

				{awsIamUser?.passwordInfo?.enabled && (
					<BaseIdentityDetail label="Mfa" span={1}>
						{awsIamUser?.mfaActive && (
							<SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
						)}
						{!awsIamUser?.mfaActive && (
							<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
						)}
					</BaseIdentityDetail>
				)}
				{awsIamUser?.passwordInfo?.enabled && (
					<>
						<BaseIdentityDetail label="Password Enabled" value="Yes" span={1} />
						<BaseIdentityDetail
							label="Password Last changed"
							value={formatRelativeDateText(awsIamUser?.passwordInfo?.lastChanged, true)}
							tooltipTitle={formatDate(awsIamUser?.passwordInfo?.lastChanged)}
							span={1}
						/>
						<BaseIdentityDetail
							label="Password Last Rotated"
							value={formatRelativeDateText(awsIamUser?.passwordInfo?.lastRotated, true)}
							tooltipTitle={formatDate(awsIamUser?.passwordInfo?.lastRotated)}
							span={1}
						/>
						<BaseIdentityDetail
							label="Password Next Rotation"
							value={formatRelativeDateText(awsIamUser?.passwordInfo?.nextRotation, true)}
							tooltipTitle={formatDate(awsIamUser?.passwordInfo?.nextRotation)}
							span={1}
						/>
					</>
				)}
				{(awsIamUser?.awsIamAccessKeysXc?.[0] || awsIamUser?.accessKey1?.lastRotated) && (
					<BaseIdentityDetail label="Access Key">
						<AwsIamUserDetailAccessKey
							accessKey={awsIamUser?.awsIamAccessKeysXc?.[0] ?? awsIamUser?.accessKey1}
						/>
					</BaseIdentityDetail>
				)}
				{(awsIamUser?.awsIamAccessKeysXc?.[1] || awsIamUser?.accessKey2?.lastRotated) && (
					<BaseIdentityDetail label="Access Key">
						<AwsIamUserDetailAccessKey
							accessKey={awsIamUser?.awsIamAccessKeysXc?.[1] ?? awsIamUser?.accessKey2}
						/>
					</BaseIdentityDetail>
				)}
			</div>
			<div className="w-full">
				{contextAmount === 0 ? null : (
					<div className="w-full">
						<Divider />
						<Col className="gap-3 space-y-4">
							<Row className="text-gray-900">Context ({contextAmount})</Row>
							{awsIamUser?.awsIamUserDetailsXc && (
								<AwsIamPermissionContext awsIamPermissionsXc={awsIamUser?.awsIamUserDetailsXc} />
							)}
							{awsIamUser?.awsIamRolesXc && awsIamUser?.awsIamRolesXc.length > 0 && (
								<AwsIamRoleContext awsIamRolesXc={awsIamUser?.awsIamRolesXc} />
							)}
							{awsIamUser?.awsEc2InstancesXc && awsIamUser.awsEc2InstancesXc.length > 0 ? (
								<Row className="mt-2">
									<Space className="w-full" direction="vertical">
										<AwsEc2InstanceContext awsEc2InstancesXc={awsIamUser.awsEc2InstancesXc} />
									</Space>
								</Row>
							) : null}
							<AwsIamUserChangeLogsContext
								environmentId={identity?.environmentId ?? ''}
								subEnvironmentId={identity?.awsIamUser?.awsAccount?.id ?? ''}
								identityArn={identity?.awsIamUser?.arn ?? ''}
								changeLogs={awsIamUser?.changeLogs ?? []}
							/>

							<AwsUsageLogsContext
								environmentId={identity?.environmentId ?? ''}
								subEnvironmentId={awsIamUser?.awsAccount?.id ?? ''}
								identityArn={awsIamUser?.arn ?? ''}
								usageLogs={awsIamUser?.usageLogs ?? []}
							/>

							{awsIamUser?.oktaUserXc && awsIamUser?.oktaUserXc.length > 0 && (
								<OktaUserContext oktaUserXc={awsIamUser?.oktaUserXc} />
							)}
							{awsIamUser?.kubernetesResourcesXc && awsIamUser?.kubernetesResourcesXc.length > 0 && (
								<KubernetesResourceContext kubernetesResourcesXc={awsIamUser?.kubernetesResourcesXc} />
							)}
							{awsIamUser?.endpointAccessXc && awsIamUser?.endpointAccessXc.length > 0 && (
								<EndpointAccessContext endpointAccessXc={awsIamUser?.endpointAccessXc} />
							)}
						</Col>
					</div>
				)}
			</div>
		</>
	)
}
