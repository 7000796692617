import { PriorityBar } from '../../Findings/stats/PriorityBar.tsx'
import { useCallback, useMemo } from 'react'
import { PriorityType, PriorityTypeEnum, PriorityTypeNameMap } from '../../../schemas/issue.ts'
import { IdentitiesTableRow, IdentityFilter } from '../../../schemas/identity.ts'
import { FindingByPriority } from '../../../schemas/metrics/findingsByPriority.ts'
import { useSearch } from '@tanstack/react-router'
import { useIdentitiesContext } from '../context/IdentitiesProvider.tsx'
import { getIssuesMaxPriority } from '../../../utils/issueUtils.ts'
import { RoutePaths } from '../../RoutePaths.tsx'
import { Skeleton } from 'antd'
import { useSearchIdentities } from '../../../api/identities.ts'
import { formatNumber } from '../../../utils/numberUtils.ts'
import { TableSetFilterEntry } from '../../../schemas/tableFilter.ts'

export const IdentitiesStats = () => {
	const { gridRef, gridReady, statsRefreshHack } = useIdentitiesContext()
	const { lens: searchLens } = useSearch({ from: RoutePaths.Identities })
	const {
		isLoading: isIdentitiesLoading,
		data: identitiesData,
		isFetching: isIdentitiesFetching,
	} = useSearchIdentities(searchLens)

	const findingsByPriority = useMemo(() => {
		const acc: FindingByPriority[] = []
		if (!gridReady) return acc
		if (!gridRef?.current?.api) return acc
		for (let i = 0; i < gridRef?.current?.api?.getDisplayedRowCount(); i++) {
			const identityTableRow = gridRef?.current?.api?.getDisplayedRowAtIndex(i)?.data as
				| IdentitiesTableRow
				| undefined
			if (!identityTableRow) continue
			const priority = getIssuesMaxPriority(identityTableRow.issues)
			if (priority && priority !== PriorityType.NOT_CALCULATED) {
				const index = acc.findIndex((item) => item.priorityNumber === priority)
				if (index === -1) {
					acc.push({
						priorityNumber: priority,
						priority: PriorityType[priority] as PriorityTypeEnum,
						count: 1,
					})
				} else {
					acc[index].count++
				}
			}
		}
		return acc
	}, [gridReady, statsRefreshHack, identitiesData])

	const displayedRowCount = useMemo(() => {
		return findingsByPriority.reduce((acc, item) => acc + item.count, 0)
	}, [findingsByPriority])

	const filterByPostureScore = useCallback((priority: string) => {
		if (!gridRef.current || !gridRef.current.api) return

		const priorityString = PriorityTypeNameMap[priority as PriorityTypeEnum]
		const currentFilter = gridRef.current.api.getFilterModel() as NonNullable<IdentityFilter>
		const postureScores = (currentFilter.max_priority as TableSetFilterEntry | undefined)?.values || []

		if (postureScores.length === 1 && postureScores.includes(priorityString)) {
			// If the values array contains only this priority, delete max_priority key
			delete currentFilter.max_priority
		} else {
			// Set the filter to just the chosen priority (including when no filter at all)
			// eslint-disable-next-line camelcase
			currentFilter.max_priority = {
				filterType: 'set',
				values: [priorityString],
			}
		}

		gridRef.current.api.setFilterModel(currentFilter)
	}, [])

	const isLoading = isIdentitiesLoading || isIdentitiesFetching

	return (
		<div className="ml-2 flex-row flex">
			<div className="col-span-2 min-w-[360px]">
				<div className="m-4 text-base text-gray-400">Identity Posture</div>
				<div className="m-4">
					<Skeleton loading={isLoading} paragraph={false} active>
						{findingsByPriority.length > 0 ? (
							<PriorityBar
								findingsByPriority={findingsByPriority}
								onPriorityNameTagClick={filterByPostureScore}
							/>
						) : (
							<span className="text-base text-gray-400">N/A</span>
						)}
					</Skeleton>
				</div>
			</div>

			<div className="ml-5 col-span-2 text-base text-gray-400">
				<div className="my-4">Monitored Identities</div>
				<div className="my-4">
					<Skeleton className="pb-4" loading={isLoading} paragraph={false} active>
						{displayedRowCount > 0 ? (
							<span className="text-3xl text-black">{formatNumber(displayedRowCount)}</span>
						) : (
							<span>N/A</span>
						)}
					</Skeleton>
				</div>
			</div>
		</div>
	)
}
