import { AwsIamGroupDetails, AwsIamUserPermission } from '../schemas/identities/awsIamUserXcSchema.ts'
import { AwsIamRoleXc, CombinedAwsIamRolePolicy } from '../schemas/identities/awsIamRoleXcSchema.ts'
import { AwsIamChangeLog, AwsIamChangeLogsAggregated } from '../schemas/identities/awsIamChangeLogSchema.ts'
import groupBy from 'lodash/groupBy'
import { AwsIdentityPolicy } from '../schemas/identities/awsPoliciesSchema.ts'

export const getCombinedAwsIamRolePolicy = (
	awsIamPermissionsXc?: AwsIamUserPermission | null,
	awsIamRolesXc?: AwsIamRoleXc | null,
	awsIamRolesPermissionBoundary?: AwsIdentityPolicy | null,
	awsIamUserPermissionBoundary?: AwsIdentityPolicy | null,
): CombinedAwsIamRolePolicy[] => {
	const policyList: CombinedAwsIamRolePolicy[] = []
	// Add user policies
	awsIamPermissionsXc?.attachedManagedPolicies?.forEach((policy) => {
		policyList.push({ ...policy, isAttached: true })
	})
	awsIamPermissionsXc?.userPolicyList?.forEach((policy) => {
		policyList.push({ ...policy, isAttached: false })
	})

	awsIamPermissionsXc?.groupListPermissions?.forEach((groupPermission: AwsIamGroupDetails) => {
		groupPermission?.groupPolicyList.forEach((policy) => {
			policyList.push({ ...policy, isAttached: false, groupName: groupPermission.groupName })
		})
		groupPermission?.attachedManagedPolicies.forEach((policy) => {
			policyList.push({ ...policy, isAttached: true, groupName: groupPermission.groupName })
		})
	})

	// Add role policies
	awsIamRolesXc?.attachedManagedPolicies?.forEach((policy) => {
		policyList.push({ ...policy, isAttached: true })
	})

	awsIamRolesXc?.rolePolicyList?.forEach((policy) => {
		policyList.push({ ...policy, isAttached: false })
	})

	// Add permission boundary
	if (awsIamRolesPermissionBoundary) {
		policyList.push({ ...awsIamRolesPermissionBoundary, isPermissionBoundary: true })
	}
	if (awsIamUserPermissionBoundary) {
		policyList.push({ ...awsIamUserPermissionBoundary, isPermissionBoundary: true })
	}

	return policyList
}

export const getPolicyUniqueKey = (policy: CombinedAwsIamRolePolicy) =>
	`${policy.groupName}-${policy.isAttached}-${policy.policyName}-${policy.policyArn}-${policy.customerManaged}`

export const aggregateAwsIamChangeLogs = (changelog: AwsIamChangeLog[]): AwsIamChangeLogsAggregated[] => {
	const changeLogsGrouped = groupBy<AwsIamChangeLog>(changelog, 'actorArn')
	const changeLogsAggregated = Object.entries(changeLogsGrouped).map(([actorArn, logs]) => {
		const logsSorted = logs.sort((a, b) => new Date(a.eventTime).getTime() - new Date(b.eventTime).getTime())
		return {
			actorArn,
			firstEventTime: logsSorted[0].eventTime,
			lastEventTime: logsSorted[logsSorted.length - 1].eventTime,
			eventNames: [...new Set(logs.map((log) => log.eventName))],
		}
	})

	return changeLogsAggregated.toSorted(
		(agg1, agg2) => new Date(agg1.lastEventTime).getTime() - new Date(agg2.lastEventTime).getTime(),
	)
}
