import { Node, NodeProps } from '@xyflow/react'
import { TeamOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { DetailedEntraIdRoleAssignment } from '../../../../../schemas/identities/entraId/entraIdRoleAssignmentsSchemas.ts'
import { nodeColorClassNames } from '../../graphUtils/nodeColors.ts'
import { BaseNode } from '../../common/baseNodes/BaseNode.tsx'
import { NodeGroupBadge } from '../../common/NodeGroupBadge.tsx'

export type DetailedEntraIdRolesNodeType = Node<{ roles: DetailedEntraIdRoleAssignment[] }, 'detailedEntraIdRoles'>
type EntraIdRolesNodeProps = NodeProps<DetailedEntraIdRolesNodeType>

export const DetailedEntraIdRolesNode = ({ data: { roles } }: EntraIdRolesNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} ${nodeColorClassNames.permission.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
	)

	const badgeTooltipContent = roles.map((role) => (
		<span key={`${role.roleDefinition.id}${role.roleAssignment.id}`}>
			{role.roleDefinition.displayName || 'Unknown role'}
		</span>
	))

	return (
		<BaseNode type="Entra ID Roles">
			<NodeGroupBadge items={roles} nodeColorType="permission" tooltipContent={badgeTooltipContent} />
			<div className={iconWrapperClassName}>
				<TeamOutlined />
			</div>
		</BaseNode>
	)
}
