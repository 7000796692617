import { CombinedAwsIamRolePolicy } from '../../schemas/identities/awsIamRoleXcSchema.ts'
import { JsonViewer } from '../../components/common/JsonViewer.tsx'
import { ColDef } from '@ag-grid-community/core'
import { removeNulls } from '../../utils.ts'
import { Tag, TagSchemaType, TagsDisplayNameMap } from '../../schemas/tags.ts'
import { TagIcons } from '../../components/common/TagIcons.tsx'

export const combinedAwsIamRolePolicyColumnDefs: ColDef<CombinedAwsIamRolePolicy>[] = [
	{ field: 'policyName' },
	{
		headerName: 'Type',
		valueGetter: (params: { data?: CombinedAwsIamRolePolicy }) => {
			const group = `${params.data?.groupName ? ` (Group ${params.data?.groupName})` : ''}`
			if (params.data?.isAttached) {
				return `Attached${group}`
			} else if (params.data?.isPermissionBoundary) {
				return `Permission Boundary${group}`
			} else {
				return `Inline${group}`
			}
		},
	},
	{
		headerName: 'Risk',
		cellRenderer: (params: { data?: CombinedAwsIamRolePolicy }) => {
			return <TagIcons tags={params.data?.riskTags?.map((tag) => tag.name)} />
		},
		valueGetter: (params: { data?: CombinedAwsIamRolePolicy }) => {
			return params.data?.riskTags?.map((tag: TagSchemaType): string => TagsDisplayNameMap[tag.name as Tag])
		},
	},
	{
		resizable: false,
		headerName: 'Document',
		filter: false,
		sortable: false,
		cellRenderer: (params: { data?: CombinedAwsIamRolePolicy }) => {
			if (params.data?.policyInfo) {
				return (
					<JsonViewer
						data={removeNulls(params.data.policyInfo as Record<string, never>['policyVersionList'])}
						title={params.data.policyName!}
					/>
				)
			} else if (params.data?.policyDocument) {
				return (
					<JsonViewer data={removeNulls(params.data?.policyDocument)} title={params.data?.policyName ?? ''} />
				)
			} else {
				const href = params.data?.policyArn?.startsWith('arn:aws:iam::aws:policy/')
					? `https://docs.aws.amazon.com/aws-managed-policy/latest/reference/${params.data?.policyName}.html`
					: `https://console.aws.amazon.com/iam/home?region=us-east-1#/policies/arn:aws:iam::aws:policy/${params.data?.policyName}`
				return (
					<a className="underline cursor-pointer text-blue-600" target={'_blank'} href={href}>
						Open In AWS
					</a>
				)
			}
		},
	},
]
