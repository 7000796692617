import { useIdentitiesWithIssueEnvTypeDistribution } from '../../api/metrics.ts'
import { Skeleton } from 'antd'
import { IssueName } from '../../schemas/issue.ts'
import { formatNumber } from '../../utils/numberUtils.ts'
import { themeColors } from '../../utils/colorUtils.ts'
import { MetricsChartContainer } from './MetricsChartContainer.tsx'
import { IssueIcon } from '../../components/common/IssueIcon.tsx'
import { EnvTypeOrOther, OtherEnvironmentType } from '../../schemas/metrics/envTypeIdentitiesCount.ts'
import { StatItem } from '../../components/common/StatItem.tsx'
import { useCallback, useMemo, useState } from 'react'
import { EnvironmentTypeMap } from '../../schemas/envType.ts'
import { EnvironmentTypeColors } from '../../schemas/metrics/identitiesByEnv.ts'
import { DonutChartWithCustomLegend } from '../../components/common/DonutChartWithCustomLegend.tsx'

const legendTitleResolver = (envType: EnvTypeOrOther): string =>
	envType === OtherEnvironmentType.OTHER ? OtherEnvironmentType.OTHER : EnvironmentTypeMap[envType]

const seriesColorResolver = (envType: EnvTypeOrOther): string =>
	envType === OtherEnvironmentType.OTHER ? '#0000007D' : `${EnvironmentTypeColors[envType]}7D`

export const IdentitiesWithIssueEnvTypeDistribution = ({
	issueName,
	title,
}: {
	issueName: IssueName
	title: string
}) => {
	const { data: rawData, isLoading } = useIdentitiesWithIssueEnvTypeDistribution(issueName)
	const [enabledEnvTypes, setEnabledEnvTypes] = useState<EnvTypeOrOther[]>([])

	const formattedData = useMemo(() => {
		if (!rawData) {
			return []
		}

		// Zeroing out counts for environment types that are not enabled so that they won't show up in chart and counts
		return rawData.map((datum) => {
			if (enabledEnvTypes.length === 0 || enabledEnvTypes.includes(datum.envType)) {
				return { key: datum.envType, count: datum.totalCount, adminCount: datum.adminCount }
			}

			return { key: datum.envType, count: 0, adminCount: 0 }
		})
	}, [enabledEnvTypes, rawData])

	const legendData = useMemo(() => {
		if (!rawData) {
			return []
		}

		return rawData.map((datum) => ({ key: datum.envType, count: datum.totalCount, adminCount: datum.adminCount }))
	}, [rawData])

	const totalCount = useMemo(
		() => formattedData.reduce((count, datum) => count + datum.count, 0) || 0,
		[formattedData],
	)

	const adminCount = useMemo(
		() => formattedData.reduce((count, datum) => count + datum.adminCount, 0) || 0,
		[formattedData],
	)

	const seriesTooltipResolver = useCallback(
		(envType: EnvTypeOrOther) =>
			`${envType === OtherEnvironmentType.OTHER ? OtherEnvironmentType.OTHER : EnvironmentTypeMap[envType]} ${title}`,
		[title],
	)

	return (
		<MetricsChartContainer
			className="col-span-6"
			heightSize="small"
			header={
				<div className="flex gap-2">
					<IssueIcon name={issueName} />
					<span>{title}</span>
				</div>
			}
		>
			{isLoading ? (
				<Skeleton paragraph={{ rows: 2 }} active />
			) : (
				<div className="flex h-full">
					<DonutChartWithCustomLegend
						activeKeys={enabledEnvTypes}
						backgroundColor={themeColors.neutral[100]}
						totalCount={totalCount}
						data={formattedData}
						legendData={legendData}
						legendTitleResolver={legendTitleResolver}
						seriesColorResolver={seriesColorResolver}
						seriesTooltipTitleResolver={seriesTooltipResolver}
						setActiveKeys={setEnabledEnvTypes}
						dimensions={110}
					/>
					<StatItem className="ml-auto px-4" title="Admins">
						{formatNumber(adminCount)}
					</StatItem>
				</div>
			)}
		</MetricsChartContainer>
	)
}
