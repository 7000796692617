import React, { useCallback, useMemo, useRef, useState } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent } from '@ag-grid-community/core'
import { GcpPrincipalInfoXc } from '../../schemas/identities/gcp/gcpProjectXcSchema.ts'
import { GcpRolesTable } from './GcpRolesTable.tsx'
import { AwsIamRoleXc } from '../../schemas/identities/awsIamRoleXcSchema.ts'
import { AccountPriorityNameTag } from '../../components/common/AccountPriorityNameTag.tsx'
import { AccountPriorityEnum } from '../../schemas/environments/accountPriorityEnum.ts'

export const GcpProjectsTable: React.FunctionComponent<{
	gcpProjectsXc: GcpPrincipalInfoXc[]
}> = ({ gcpProjectsXc }) => {
	const gridRef = useRef<AgGridReact<GcpPrincipalInfoXc>>(null)
	const showAccountPriorityDrawer = gcpProjectsXc?.every((projectInfo) => projectInfo.accountPriority)
	const [rowData, setRowData] = useState<GcpPrincipalInfoXc[] | null>()
	const columnDefs = useMemo<ColDef<GcpPrincipalInfoXc>[]>(() => {
		return [
			{ field: 'project.displayName', headerName: 'Project', cellRenderer: 'agGroupCellRenderer' },
			{
				field: 'accountPriority',
				headerName: 'Project Priority',
				hide: !showAccountPriorityDrawer,
				cellRenderer: (params: { data?: AwsIamRoleXc }) => {
					if (params.data?.accountPriority) {
						return <AccountPriorityNameTag priority={params.data.accountPriority} />
					} else {
						return <AccountPriorityNameTag priority={AccountPriorityEnum.UNKNOWN} />
					}
				},
			},
			{ field: 'project.state' },
			{ field: 'project.createTime' },
			{ field: 'project.parent' },
		]
	}, [])

	const detailCellRenderer = useMemo(() => {
		return GcpRolesTable
	}, [])
	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback(
		(_params: GridReadyEvent<GcpPrincipalInfoXc>) => {
			setRowData(gcpProjectsXc)
			gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
		},
		[gcpProjectsXc],
	)

	return (
		<div className="w-full h-full iam-roles-table">
			<AgGridReact
				className={'ag-theme-alpine h-full w-full overflow-x-auto'}
				ref={gridRef}
				rowData={rowData}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				detailRowHeight={200}
				defaultColDef={defaultColDef}
				detailCellRenderer={detailCellRenderer}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
