import { z } from 'zod'
import { camelizeSchema } from '../../utils.ts'
import { DateSchema } from '../../common.ts'
import { ServerRawSalesforcePermissionSet } from './salesforcePermissionSetSchema.ts'
import { ServerRawSalesforceProfile } from './salesforceProfileSchema.ts'
import { ServerSalesforceIdentityOwnershipRecord } from './salesforceOwnershipRecordSchema.ts'
import { ServerOktaUserXcSchema } from '../oktaUserXcSchema.ts'
import { ServerEntraIdUserXcSchema } from '../entraId/entraIdUserXcSchema.ts'
import { ServerSalesforceSetupAuditTrailLogSchema } from './salesforceSetupAuditTrailLogSchema.ts'

export const ServerSalesforceUserSchema = z
	.object({
		user_id: z.string(),
		username: z.string(),
		email: z.string(),
		first_name: z.string().nullish(),
		last_name: z.string().nullish(),
		created_by_id: z.string().nullish(),
		last_login_date: DateSchema.nullish(),
		is_active: z.boolean(),
		user_type: z.string(),
		ownership_records: z.array(ServerSalesforceIdentityOwnershipRecord).nullish(),
		profile: ServerRawSalesforceProfile.nullish(),
		permission_sets: z.array(ServerRawSalesforcePermissionSet).nullish(),
		okta_user_xc: ServerOktaUserXcSchema.nullish(),
		entra_id_user_xc: ServerEntraIdUserXcSchema.nullish(),
		setup_audit_trail_logs: z.array(ServerSalesforceSetupAuditTrailLogSchema).nullish(),
	})
	.nullish()

export const SalesforceUserSchema = camelizeSchema(ServerSalesforceUserSchema.transform((val) => val))
export type SalesforceUser = z.infer<typeof SalesforceUserSchema>
