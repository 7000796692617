import React, { useMemo } from 'react'
import { Identity, IdentitySource } from '../../../schemas/identity.ts'
import { Tag, Tooltip } from 'antd'
import { AccountPriorityNameTag } from '../../../components/common/AccountPriorityNameTag.tsx'
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import SuccessIcon from '../../../assets/success_icon_20.svg?react'
import WarningIcon from '../../../assets/warning_icon_20.svg?react'
import PostgresIcon from '../../../assets/postgres_icon_20.svg?react'
import { formatDate, formatRelativeDateText } from '../../../utils.ts'
import { AwsIamUserDetailAccessKey } from '../../../components/drawers/identities/iamUser/AwsIamUserDetailAccessKey.tsx'
import { useHighlightedNodesContext } from '../HighlightedNodesContext.tsx'
import { IssueSourceIcon } from '../../../components/common/IssueSourceIcon.tsx'
import { GcpSaAccessKey } from '../../../components/drawers/identities/gcp/GcpSaAccessKey.tsx'
import { normalizeCredentials } from '../../../schemas/identities/entraId/entraIdApplicationXc.ts'
import { EntraIdSpAuthenticationCredential } from '../../../components/drawers/identities/entraId/EntraIdSpAuthenticationCredential.tsx'
import { Link } from '@tanstack/react-router'

type IdentityProperty = {
	title: string
	key: string
	shouldHide?: (identity: Identity) => boolean
	Component: React.ComponentType<{ identity: Identity }>
}

const AWS_COMMON_IDENTITY_PROPERTIES: IdentityProperty[] = [
	{
		title: 'ARN',
		key: 'arn',
		Component: ({ identity }) => identity.literal,
	},
	{
		title: 'Account',
		key: 'account',
		Component: ({ identity }) => (
			<Tooltip placement="topLeft" title={identity?.accountLiteral}>
				{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
			</Tooltip>
		),
	},
	{
		title: 'Account Priority',
		key: 'account-priority',
		shouldHide: (identity) =>
			!(identity.awsIamUser?.awsAccount?.priority || identity.awsIamRole?.awsAccount?.priority),
		Component: ({ identity }) => (
			<AccountPriorityNameTag
				priority={identity.awsIamUser?.awsAccount?.priority || identity.awsIamRole?.awsAccount?.priority}
			/>
		),
	},
]

const IDENTITY_SOURCE_TO_PROPERTIES: Partial<Record<IdentitySource, IdentityProperty[]>> = {
	[IdentitySource.AWS_IAM_USER]: [
		...AWS_COMMON_IDENTITY_PROPERTIES,
		{
			title: 'MFA',
			key: 'mfa',
			shouldHide: (identity) => !identity.awsIamUser?.passwordInfo?.enabled,
			Component: ({ identity }) =>
				identity.awsIamUser!.mfaActive ? (
					<CheckCircleOutlined className="text-green-700 text-lg" />
				) : (
					<ExclamationCircleOutlined className="text-orange-700 text-lg" />
				),
		},
		{
			title: 'Password Enabled',
			key: 'password-enabled',
			shouldHide: (identity) => !identity.awsIamUser?.passwordInfo?.enabled,
			Component: () => 'Yes',
		},
		{
			title: 'Password Last Changed',
			key: 'password-last-changed',
			shouldHide: (identity) => !identity.awsIamUser?.passwordInfo?.enabled,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.awsIamUser!.passwordInfo!.lastChanged)}>
					{formatRelativeDateText(identity.awsIamUser!.passwordInfo!.lastChanged, true)}
				</Tooltip>
			),
		},
		{
			title: 'Password Last Rotated',
			key: 'password-last-rotated',
			shouldHide: (identity) => !identity.awsIamUser?.passwordInfo?.enabled,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.awsIamUser!.passwordInfo!.lastRotated)}>
					{formatRelativeDateText(identity.awsIamUser!.passwordInfo!.lastRotated, true)}
				</Tooltip>
			),
		},
		{
			title: 'Password Next Rotation',
			key: 'password-next-rotation',
			shouldHide: (identity) => !identity.awsIamUser?.passwordInfo?.enabled,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.awsIamUser!.passwordInfo!.nextRotation)}>
					{formatRelativeDateText(identity.awsIamUser!.passwordInfo!.nextRotation, true)}
				</Tooltip>
			),
		},
		{
			title: 'Access Key',
			key: 'access-key-1',
			shouldHide: (identity) =>
				!(identity.awsIamUser?.awsIamAccessKeysXc?.[0] || identity.awsIamUser?.accessKey1?.lastRotated),
			Component: ({ identity }) => {
				const { setHighlightedNodes } = useHighlightedNodesContext()
				const accessKey = (identity.awsIamUser!.awsIamAccessKeysXc?.[0] ?? identity.awsIamUser!.accessKey1)!
				const onOpenChange = (visible: boolean) => {
					if (visible) {
						setHighlightedNodes([{ type: 'accessKey', id: accessKey.accessKeyId! }])
					} else {
						setHighlightedNodes([])
					}
				}
				return <AwsIamUserDetailAccessKey accessKey={accessKey} onOpenChange={onOpenChange} />
			},
		},
		{
			title: 'Access Key',
			key: 'access-key-2',
			shouldHide: (identity) =>
				!(identity.awsIamUser?.awsIamAccessKeysXc?.[1] || identity.awsIamUser?.accessKey2?.lastRotated),
			Component: ({ identity }) => {
				const { setHighlightedNodes } = useHighlightedNodesContext()
				const accessKey = (identity.awsIamUser!.awsIamAccessKeysXc?.[1] ?? identity.awsIamUser!.accessKey2)!
				const onOpenChange = (visible: boolean) => {
					if (visible) {
						setHighlightedNodes([{ type: 'accessKey', id: accessKey.accessKeyId! }])
					} else {
						setHighlightedNodes([])
					}
				}
				return <AwsIamUserDetailAccessKey accessKey={accessKey} onOpenChange={onOpenChange} />
			},
		},
	],
	[IdentitySource.AWS_IAM_ROLE]: [...AWS_COMMON_IDENTITY_PROPERTIES],
	[IdentitySource.GCP_SERVICE_ACCOUNT]: [
		{
			title: 'Email',
			key: 'email',
			Component: ({ identity }) => identity.gcpServiceAccount?.email,
		},
		{
			title: 'Status',
			key: 'status',
			shouldHide: (identity) => identity?.gcpServiceAccount?.disabled === undefined,
			Component: ({ identity }) => (identity?.gcpServiceAccount?.disabled ? 'Disabled' : 'Active'),
		},
		{
			title: 'Project',
			key: 'project',
			Component: ({ identity }) => (
				<Tooltip placement="topLeft" title={identity?.accountLiteral}>
					{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
				</Tooltip>
			),
		},
		{
			title: 'Project Priority',
			key: 'project-priority',
			Component: ({ identity }) => (
				<AccountPriorityNameTag priority={identity.gcpServiceAccount?.gcpProject?.priority} />
			),
		},
		...new Array(10).fill(null).map<IdentityProperty>((_, index) => ({
			title: `Key ${index + 1}`,
			key: `key-${index}`,
			shouldHide: (identity) => !identity.gcpServiceAccount?.serviceAccountKeysXc?.[index],
			Component: ({ identity }) =>
				identity.gcpServiceAccount?.serviceAccountKeysXc?.[index] ? (
					<GcpSaAccessKey accessKey={identity.gcpServiceAccount?.serviceAccountKeysXc[index]} />
				) : null,
		})),
	],
	[IdentitySource.GITHUB_APP_INSTALLATION]: [
		{
			title: 'ID',
			key: 'id',
			shouldHide: (identity) => !identity.githubAppInstallation?.appId,
			Component: ({ identity }) => identity.githubAppInstallation!.appId,
		},
		{
			title: 'Name',
			key: 'name',
			shouldHide: (identity) => !identity.githubAppInstallation?.name,
			Component: ({ identity }) => identity.githubAppInstallation!.name!,
		},
		{
			title: 'Owner',
			key: 'owner',
			shouldHide: (identity) => !identity.githubAppInstallation?.ownerName,
			Component: ({ identity }) => identity.githubAppInstallation!.ownerName!,
		},
		{
			title: 'Public',
			key: 'public',
			Component: ({ identity }) => {
				const isPublic = identity.githubAppInstallation?.isPublic
				return isPublic === true ? 'True' : isPublic === false ? 'False' : 'Unknown'
			},
		},
	],
	[IdentitySource.GITHUB_USER]: [
		{
			title: 'Login',
			key: 'login',
			shouldHide: (identity) => !identity.githubUser?.login,
			Component: ({ identity }) => identity.githubUser!.login,
		},
		{
			title: 'Name',
			key: 'name',
			shouldHide: (identity) => !identity.githubUser?.name,
			Component: ({ identity }) => identity.githubUser!.name!,
		},
		{
			title: 'Email',
			key: 'email',
			shouldHide: (identity) => !identity.githubUser?.email,
			Component: ({ identity }) => identity.githubUser!.email!,
		},
		{
			title: 'Role',
			key: 'role',
			shouldHide: (identity) => !identity.githubUser?.role,
			Component: ({ identity }) => <Tag>{identity.githubUser!.role}</Tag>,
		},
		{
			title: 'Number of Tokens',
			key: 'number of tokens',
			Component: ({ identity }) => <div>{identity.githubUser?.tokens?.length}</div>,
		},
	],
	[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
		{
			title: 'Service Principal ID',
			key: 'service-principal-id',
			Component: ({ identity }) => identity?.entraIdServicePrincipal?.servicePrincipalId,
		},
		{
			title: 'Service Principal Type',
			key: 'service-principal-type',
			Component: ({ identity }) => identity?.entraIdServicePrincipal?.servicePrincipalType,
		},

		{
			title: 'Environment',
			key: 'environment',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={identity?.envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<Tooltip placement="topLeft" title={identity?.accountLiteral}>
						<div className="font-normal text-gray-600 items-end truncate">
							{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Application ID',
			key: 'application-id',
			Component: ({ identity }) => identity?.entraIdServicePrincipal?.entraIdApplicationXc?.appId,
		},

		{
			title: 'Account Enabled',
			key: 'enabled',
			Component: ({ identity }) => (
				<span style={{ fontWeight: 'bold' }}>
					{identity.entraIdServicePrincipal?.accountEnabled ? 'True' : 'False'}
				</span>
			),
		},

		{
			title: 'Last Sign In Date',
			key: 'last-sign-in-date',
			shouldHide: (identity) => !identity.entraIdServicePrincipal?.lastSignInDateTime,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.entraIdServicePrincipal?.lastSignInDateTime)}>
					{formatRelativeDateText(identity.entraIdServicePrincipal?.lastSignInDateTime, true)}
				</Tooltip>
			),
		},
		...new Array(10).fill(null).map<IdentityProperty>((_, index) => ({
			title: `Authentication Credential ${index + 1}`,
			key: `credential-${index}`,
			shouldHide: (identity) => {
				const application = identity.entraIdServicePrincipal?.entraIdApplicationXc
				const keyCredentials = application?.keyCredentials || []
				const passwordCredentials = application?.passwordCredentials || []

				// Normalize all credentials once
				const normalizedCredentials = normalizeCredentials(keyCredentials, passwordCredentials)

				return !normalizedCredentials[index]
			},
			Component: ({ identity }) => {
				const application = identity.entraIdServicePrincipal?.entraIdApplicationXc
				const keyCredentials = application?.keyCredentials || []
				const passwordCredentials = application?.passwordCredentials || []

				// Normalize all credentials once
				const normalizedCredentials = useMemo(
					() => normalizeCredentials(keyCredentials, passwordCredentials),
					[keyCredentials, passwordCredentials],
				)

				const credential = normalizedCredentials[index]

				if (credential) {
					return <EntraIdSpAuthenticationCredential credential={credential} />
				} else {
					return null
				}
			},
		})),
	],
	[IdentitySource.ENTRA_ID_USER]: [
		{
			title: 'Email',
			key: 'email',
			shouldHide: (identity) => !identity.entraIdUser?.mail,
			Component: ({ identity }) => identity.entraIdUser?.mail,
		},
		{
			title: 'Entra User Id',
			key: 'entra-user-id',
			Component: ({ identity }) => identity.entraIdUser?.entraUserId,
		},

		{
			title: 'Environment',
			key: 'environment',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={identity.envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<Tooltip placement="topLeft" title={identity?.accountLiteral}>
						<div className="font-normal text-gray-600 items-end truncate">
							{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Name',
			key: 'name',
			shouldHide: (identity) => !identity.entraIdUser?.givenName,
			Component: ({ identity }) => identity.entraIdUser?.givenName,
		},
		{
			title: 'User Principal Name',
			key: 'user-principal-name',
			Component: ({ identity }) => identity.entraIdUser?.userPrincipalName,
		},
		{
			title: 'Job Title',
			key: 'job-title',
			shouldHide: (identity) => !identity.entraIdUser?.jobTitle,
			Component: ({ identity }) => identity.entraIdUser?.jobTitle,
		},

		{
			title: 'Last Login',
			key: 'last-login',
			shouldHide: (identity) => !identity.entraIdUser?.lastActivity,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.entraIdUser?.lastActivity)}>
					{formatRelativeDateText(identity.entraIdUser?.lastActivity, true)}
				</Tooltip>
			),
		},
		{
			title: 'Last Password Change',
			key: 'last-password-change',
			shouldHide: (identity) => !identity.entraIdUser?.passwordLastChanged,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.entraIdUser?.passwordLastChanged)}>
					{formatRelativeDateText(identity.entraIdUser?.passwordLastChanged, true)}
				</Tooltip>
			),
		},
		{
			title: 'Account Status',
			key: 'account-status',
			shouldHide: (identity) => identity.entraIdUser?.accountEnabled === null,
			Component: ({ identity }) => (
				<Tag>
					<div className="flex items-center">
						<div
							className={`${
								identity.entraIdUser?.accountEnabled ? 'text-green-700' : 'text-gray-700'
							} text font-medium`}
						>
							{identity.entraIdUser?.accountEnabled ? 'Enabled' : 'Disabled'}
						</div>
					</div>
				</Tag>
			),
		},
	],
	[IdentitySource.DATABRICKS_USER]: [
		{
			title: 'Email',
			key: 'email',
			Component: ({ identity }) => identity.databricksUser?.email,
		},
		{
			title: 'Has Personal Access Token',
			key: 'hasPersonalAccessToken',
			Component: ({ identity }) => {
				const hasPat = !!identity.databricksUser?.personalAccessTokens?.length
				return <Tag>{hasPat ? 'Yes' : 'No'}</Tag>
			},
		},
	],
	[IdentitySource.DATABRICKS_SERVICE_PRINCIPAL]: [
		{
			title: 'ID',
			key: 'id',
			Component: ({ identity }) => identity.databricksServicePrincipal?.spId,
		},
		{
			title: 'Name',
			key: 'name',
			Component: ({ identity }) => identity.databricksServicePrincipal?.spName,
		},
		{
			title: 'Has OAuth tokens',
			key: 'hasOAuthTokens',
			Component: ({ identity }) => {
				const hasTokens = !!identity.databricksServicePrincipal?.oauthTokens?.length
				return <Tag>{hasTokens ? 'Yes' : 'No'}</Tag>
			},
		},
	],

	[IdentitySource.AZURE_KV_SECRET]: [
		{
			title: 'Secret Name',
			key: 'secret-name',
			Component: ({ identity }) => identity?.azureKvSecret?.name,
		},
		{
			title: 'Secret ID',
			key: 'secret-id',
			Component: ({ identity }) => identity?.azureKvSecret?.secretId,
		},
		{
			title: 'Environment',
			key: 'environment',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={identity?.envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<Tooltip placement="topLeft" title={identity?.accountLiteral}>
						<div className="font-normal text-gray-600 items-end truncate">
							{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Vault Name',
			key: 'vault-name',
			Component: ({ identity }) => identity?.azureKvSecret?.vaultName,
		},
		{
			title: 'Vault ID',
			key: 'vault-id',
			Component: ({ identity }) => identity?.azureKvSecret?.vaultId,
		},
		{
			title: 'Subscription ID',
			key: 'subscription-id',
			Component: ({ identity }) => identity?.azureKvSecret?.subscriptionId,
		},
		{
			title: 'Status',
			key: 'status',
			Component: ({ identity }) => (
				<Tag color={identity.azureKvSecret?.isEnabled ? 'green' : 'red'}>
					{identity.azureKvSecret?.isEnabled ? 'Enabled' : 'Disabled'}
				</Tag>
			),
		},
		{
			title: 'Managed',
			key: 'managed',
			Component: ({ identity }) => (
				<Tag color={identity.azureKvSecret?.isManaged ? 'blue' : 'default'}>
					{identity.azureKvSecret?.isManaged ? 'Managed' : 'Not Managed'}
				</Tag>
			),
		},

		{
			title: 'Start Date',
			key: 'start-date',
			shouldHide: (identity) => !identity.azureKvSecret?.startDate,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.azureKvSecret?.startDate)}>
					{formatRelativeDateText(identity.azureKvSecret?.startDate, true)}
				</Tooltip>
			),
		},
		{
			title: 'Expiration Date',
			key: 'expiration-date',
			shouldHide: (identity) => !identity.azureKvSecret?.expirationDate,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.azureKvSecret?.expirationDate)}>
					{formatRelativeDateText(identity.azureKvSecret?.expirationDate, true)}
				</Tooltip>
			),
		},
	],
	[IdentitySource.AZURE_KV_KEY]: [
		{
			title: 'Key Name',
			key: 'key-name',
			Component: ({ identity }) => identity?.azureKvKey?.name,
		},
		{
			title: 'Key ID',
			key: 'key-id',
			Component: ({ identity }) => identity?.azureKvKey?.keyId,
		},
		{
			title: 'Environment',
			key: 'environment',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={identity?.envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<Tooltip placement="topLeft" title={identity?.accountLiteral}>
						<div className="font-normal text-gray-600 items-end truncate">
							{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Vault Name',
			key: 'vault-name',
			Component: ({ identity }) => identity?.azureKvKey?.vaultName,
		},
		{
			title: 'Vault ID',
			key: 'vault-id',
			Component: ({ identity }) => identity?.azureKvKey?.vaultId,
		},
		{
			title: 'Subscription ID',
			key: 'subscription-id',
			Component: ({ identity }) => identity?.azureKvKey?.subscriptionId,
		},
		{
			title: 'Status',
			key: 'status',
			Component: ({ identity }) => (
				<Tag color={identity.azureKvKey?.isEnabled ? 'green' : 'red'}>
					{identity.azureKvKey?.isEnabled ? 'Enabled' : 'Disabled'}
				</Tag>
			),
		},
		{
			title: 'Managed',
			key: 'managed',
			Component: ({ identity }) => (
				<Tag color={identity.azureKvKey?.isManaged ? 'blue' : 'default'}>
					{identity.azureKvKey?.isManaged ? 'Managed' : 'Not Managed'}
				</Tag>
			),
		},

		{
			title: 'Start Date',
			key: 'start-date',
			shouldHide: (identity) => !identity.azureKvKey?.startDate,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.azureKvKey?.startDate)}>
					{formatRelativeDateText(identity.azureKvKey?.startDate, true)}
				</Tooltip>
			),
		},
		{
			title: 'Expiration Date',
			key: 'expiration-date',
			shouldHide: (identity) => !identity.azureKvKey?.expirationDate,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.azureKvKey?.expirationDate)}>
					{formatRelativeDateText(identity.azureKvKey?.expirationDate, true)}
				</Tooltip>
			),
		},
	],
	[IdentitySource.AZURE_KV_CERTIFICATE]: [
		{
			title: 'Certificate Name',
			key: 'certificate-name',
			Component: ({ identity }) => identity?.azureKvCertificate?.name,
		},
		{
			title: 'Certificate ID',
			key: 'certificate-id',
			Component: ({ identity }) => identity?.azureKvCertificate?.certificateId,
		},
		{
			title: 'Environment',
			key: 'environment',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={identity?.envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<Tooltip placement="topLeft" title={identity?.accountLiteral}>
						<div className="font-normal text-gray-600 items-end truncate">
							{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Vault Name',
			key: 'vault-name',
			Component: ({ identity }) => identity?.azureKvCertificate?.vaultName,
		},
		{
			title: 'Vault ID',
			key: 'vault-id',
			Component: ({ identity }) => identity?.azureKvCertificate?.vaultId,
		},
		{
			title: 'Subscription ID',
			key: 'subscription-id',
			Component: ({ identity }) => identity?.azureKvCertificate?.subscriptionId,
		},
		{
			title: 'Status',
			key: 'status',
			Component: ({ identity }) => (
				<Tag color={identity.azureKvCertificate?.isEnabled ? 'green' : 'red'}>
					{identity.azureKvCertificate?.isEnabled ? 'Enabled' : 'Disabled'}
				</Tag>
			),
		},
		{
			title: 'Created At',
			key: 'created-at',
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.azureKvCertificate?.createdAt)}>
					{formatRelativeDateText(identity.azureKvCertificate?.createdAt, true)}
				</Tooltip>
			),
		},
		{
			title: 'Updated At',
			key: 'updated-at',
			shouldHide: (identity) => !identity.azureKvCertificate?.updatedAt,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.azureKvCertificate?.updatedAt)}>
					{formatRelativeDateText(identity.azureKvCertificate?.updatedAt, true)}
				</Tooltip>
			),
		},
		{
			title: 'Start Date',
			key: 'start-date',
			shouldHide: (identity) => !identity.azureKvCertificate?.startDate,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.azureKvCertificate?.startDate)}>
					{formatRelativeDateText(identity.azureKvCertificate?.startDate, true)}
				</Tooltip>
			),
		},
		{
			title: 'Expiration Date',
			key: 'expiration-date',
			shouldHide: (identity) => !identity.azureKvCertificate?.expirationDate,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.azureKvCertificate?.expirationDate)}>
					{formatRelativeDateText(identity.azureKvCertificate?.expirationDate, true)}
				</Tooltip>
			),
		},
	],
	[IdentitySource.OKTA]: [
		{
			title: 'Email',
			key: 'email',
			shouldHide: (identity) => !identity.oktaUser?.email,
			Component: ({ identity }) => identity.oktaUser?.email,
		},
		{
			title: 'Name',
			key: 'name',
			Component: ({ identity }) => identity.oktaUser?.name,
		},

		{
			title: 'Activated At',
			key: 'activated-at',
			Component: ({ identity }) => formatDate(identity.oktaUser?.activated),
		},
		{
			title: 'Password Changed',
			key: 'password-changed',
			shouldHide: (identity) => !identity.oktaUser?.passwordChanged,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.oktaUser?.passwordChanged)}>
					{formatRelativeDateText(identity.oktaUser?.passwordChanged, true)}
				</Tooltip>
			),
		},
		{
			title: 'Status',
			key: 'status',
			shouldHide: (identity) => !identity.oktaUser?.userStatus,
			Component: ({ identity }) => {
				const status = identity.oktaUser?.userStatus
				return <Tag color={status === 'ACTIVE' ? 'green' : 'red'}>{status}</Tag>
			},
		},
	],
	[IdentitySource.SNOWFLAKE_USER]: [
		{
			title: 'Name',
			key: 'name',
			Component: ({ identity }) => identity.snowflakeUser?.name,
		},
		{
			title: 'Snowflake User ID',
			key: 'snowflake-user-id',
			Component: ({ identity }) => identity.snowflakeUser?.userId,
		},
		{
			title: 'Environment',
			key: 'environment',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={identity?.envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<Tooltip placement="topLeft" title={identity?.accountLiteral}>
						<div className="font-normal text-gray-600 items-end truncate">
							{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Email',
			key: 'email',
			Component: ({ identity }) => identity.snowflakeUser?.email,
		},
		{
			title: 'Deleted On',
			key: 'deleted-on',
			shouldHide: (identity) => !identity.snowflakeUser?.deletedOn,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.snowflakeUser?.deletedOn)}>
					{formatRelativeDateText(identity.snowflakeUser?.deletedOn, true)}
				</Tooltip>
			),
		},
		{
			title: 'Disabled',
			key: 'disabled',
			Component: ({ identity }) => (identity.snowflakeUser?.disabled ? 'True' : 'False'),
		},
		{
			title: 'Password Enabled',
			key: 'password-enabled',
			Component: ({ identity }) => (identity.snowflakeUser?.hasPassword ? 'True' : 'False'),
		},
		{
			title: 'Last Password changed',
			key: 'last-password-changed',
			shouldHide: (identity) => !identity.snowflakeUser?.hasPassword,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.snowflakeUser?.passwordLastSetTime)}>
					{formatRelativeDateText(identity.snowflakeUser?.passwordLastSetTime, true)}
				</Tooltip>
			),
		},
		{
			title: 'MFA',
			key: 'mfa',
			shouldHide: (identity) => !identity.snowflakeUser?.hasPassword,
			Component: ({ identity }) =>
				identity.snowflakeUser?.extAuthnDuo ? (
					<SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
				) : (
					<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
				),
		},
		{
			title: 'RSA Public Key',
			key: 'rsa-public-key',
			Component: ({ identity }) => (identity.snowflakeUser?.hasRsaPublicKey ? 'True' : 'False'),
		},
	],
	[IdentitySource.JUMPCLOUD_USER]: [
		{
			title: 'Email',
			key: 'email',
			Component: ({ identity }) => identity.jumpcloudUser?.email,
		},
		{
			title: 'Environment',
			key: 'environment',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={identity?.envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<Tooltip placement="topLeft" title={identity?.accountLiteral}>
						<div className="font-normal text-gray-600 items-end truncate">
							{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Name',
			key: 'name',
			Component: ({ identity }) => identity.jumpcloudUser?.name,
		},
		{
			title: 'MFA',
			key: 'mfaActive',
			Component: ({ identity }) =>
				identity.jumpcloudUser?.mfaActive ? (
					<SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
				) : (
					<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
				),
		},
		{
			title: 'Password Last Rotated',
			key: 'passwordLastRotated',
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.jumpcloudUser?.passwordLastRotated)}>
					{formatRelativeDateText(identity.jumpcloudUser?.passwordLastRotated, true)}
				</Tooltip>
			),
		},
	],
	[IdentitySource.SALESFORCE_USER]: [
		{
			title: 'User ID',
			key: 'user-id',
			Component: ({ identity }) => identity.salesforceUser?.userId,
		},
		{
			title: 'Username',
			key: 'username',
			Component: ({ identity }) => identity.salesforceUser?.username,
		},
		{
			title: 'Email',
			key: 'email',
			Component: ({ identity }) => identity.salesforceUser?.email,
		},
		{
			title: 'Name',
			key: 'name',
			shouldHide: (identity) => !identity.salesforceUser?.firstName && !identity.salesforceUser?.lastName,
			Component: ({ identity }) =>
				`${identity.salesforceUser?.firstName || ''} ${identity.salesforceUser?.lastName || ''}`.trim(),
		},
		{
			title: 'Last Login',
			key: 'last-login',
			shouldHide: (identity) => !identity.salesforceUser?.lastLoginDate,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.salesforceUser?.lastLoginDate)}>
					{formatRelativeDateText(identity.salesforceUser?.lastLoginDate, true)}
				</Tooltip>
			),
		},
		{
			title: 'Status',
			key: 'status',
			Component: ({ identity }) => (
				<Tag color={identity.salesforceUser?.isActive ? 'green' : 'red'}>
					{identity.salesforceUser?.isActive ? 'Enabled' : 'Disabled'}
				</Tag>
			),
		},
		{
			title: 'User Type',
			key: 'user-type',
			Component: ({ identity }) => identity.salesforceUser?.userType,
		},
		{
			title: 'Environment',
			key: 'environment',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={identity.envType} marginBottom="-2px" marginLeft="-2px" size={14} />
					<Link
						to={`/environment/${identity?.id}`}
						className="font-normal text-blue-600 hover:text-blue-800 items-end truncate"
					>
						{identity?.accountLiteral}
					</Link>
				</div>
			),
		},
	],
	[IdentitySource.SALESFORCE_CONNECTED_APPLICATION]: [
		{
			title: 'Connected Application ID',
			key: 'connected-application-id',
			Component: ({ identity }) => identity.salesforceConnectedApplication?.connectedApplicationId,
		},
		{
			title: 'Name',
			key: 'name',
			Component: ({ identity }) => identity.salesforceConnectedApplication?.name,
		},
		{
			title: 'Contact Email',
			key: 'contact-email',
			shouldHide: (identity) => !identity.salesforceConnectedApplication?.appMetadata?.contactEmail,
			Component: ({ identity }) => identity.salesforceConnectedApplication?.appMetadata?.contactEmail,
		},
		{
			title: 'Allow Admin Approved Users Only',
			key: 'allow-admin-approved-users-only',
			Component: ({ identity }) => (
				<Tag
					color={
						identity.salesforceConnectedApplication?.optionsAllowAdminApprovedUsersOnly ? 'green' : 'red'
					}
				>
					{identity.salesforceConnectedApplication?.optionsAllowAdminApprovedUsersOnly ? 'Yes' : 'No'}
				</Tag>
			),
		},
		{
			title: 'Refresh Token Validity Metric',
			key: 'refresh-token-validity-metric',
			Component: ({ identity }) => (
				<Tag
					color={identity.salesforceConnectedApplication?.optionsRefreshTokenValidityMetric ? 'green' : 'red'}
				>
					{identity.salesforceConnectedApplication?.optionsRefreshTokenValidityMetric ? 'Yes' : 'No'}
				</Tag>
			),
		},
		{
			title: 'Has Session Level Policy',
			key: 'has-session-level-policy',
			Component: ({ identity }) => (
				<Tag color={identity.salesforceConnectedApplication?.optionsHasSessionLevelPolicy ? 'green' : 'red'}>
					{identity.salesforceConnectedApplication?.optionsHasSessionLevelPolicy ? 'Yes' : 'No'}
				</Tag>
			),
		},
		{
			title: 'Is Internal',
			key: 'is-internal',
			Component: ({ identity }) => (
				<Tag color={identity.salesforceConnectedApplication?.optionsIsInternal ? 'green' : 'red'}>
					{identity.salesforceConnectedApplication?.optionsIsInternal ? 'Yes' : 'No'}
				</Tag>
			),
		},
		{
			title: 'Full Content Push Notifications',
			key: 'full-content-push-notifications',
			Component: ({ identity }) => (
				<Tag
					color={
						identity.salesforceConnectedApplication?.optionsFullContentPushNotifications ? 'green' : 'red'
					}
				>
					{identity.salesforceConnectedApplication?.optionsFullContentPushNotifications ? 'Yes' : 'No'}
				</Tag>
			),
		},
		{
			title: 'Code Credential Guest Enabled',
			key: 'code-credential-guest-enabled',
			Component: ({ identity }) => (
				<Tag
					color={identity.salesforceConnectedApplication?.optionsCodeCredentialGuestEnabled ? 'green' : 'red'}
				>
					{identity.salesforceConnectedApplication?.optionsCodeCredentialGuestEnabled ? 'Yes' : 'No'}
				</Tag>
			),
		},
		{
			title: 'App Issue JWT Token Enabled',
			key: 'app-issue-jwt-token-enabled',
			Component: ({ identity }) => (
				<Tag color={identity.salesforceConnectedApplication?.optionsAppIssueJwtTokenEnabled ? 'green' : 'red'}>
					{identity.salesforceConnectedApplication?.optionsAppIssueJwtTokenEnabled ? 'Yes' : 'No'}
				</Tag>
			),
		},
		{
			title: 'Token Exchange Manage Bit Enabled',
			key: 'token-exchange-manage-bit-enabled',
			Component: ({ identity }) => (
				<Tag
					color={
						identity.salesforceConnectedApplication?.optionsTokenExchangeManageBitEnabled ? 'green' : 'red'
					}
				>
					{identity.salesforceConnectedApplication?.optionsTokenExchangeManageBitEnabled ? 'Yes' : 'No'}
				</Tag>
			),
		},
		{
			title: 'Start URL',
			key: 'start-url',
			shouldHide: (identity) => !identity.salesforceConnectedApplication?.startUrl,
			Component: ({ identity }) => identity.salesforceConnectedApplication?.startUrl,
		},
		{
			title: 'Refresh Token Validity Period',
			key: 'refresh-token-validity-period',
			shouldHide: (identity) => identity.salesforceConnectedApplication?.refreshTokenValidityPeriod === null,
			Component: ({ identity }) => `${identity.salesforceConnectedApplication?.refreshTokenValidityPeriod} days`,
		},
		{
			title: 'Description',
			key: 'description',
			shouldHide: (identity) => !identity.salesforceConnectedApplication?.appMetadata?.description,
			Component: ({ identity }) => identity.salesforceConnectedApplication?.appMetadata?.description,
		},
	],
	[IdentitySource.GOOGLE_WORKSPACE]: [
		{
			title: 'Primary Email',
			key: 'primary-email',
			Component: ({ identity }) => identity.googleWorkspaceUser?.primaryEmail,
		},
		{
			title: 'Recovery Email',
			key: 'recovery-email',
			Component: ({ identity }) => identity.googleWorkspaceUser?.recoveryEmail ?? 'N/A',
		},
		{
			title: 'Status',
			key: 'status',
			Component: ({ identity }) => (identity.googleWorkspaceUser?.suspended ? 'Suspended' : 'Active'),
		},
		{
			title: 'MFA',
			key: 'mfa',
			Component: ({ identity }) =>
				identity.googleWorkspaceUser?.mfaEnabled ? (
					<SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
				) : (
					<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
				),
		},
		{
			title: 'Last Activity',
			key: 'last-activity',
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.googleWorkspaceUser?.lastActivity)}>
					{formatRelativeDateText(identity.googleWorkspaceUser?.lastActivity, true)}
				</Tooltip>
			),
		},
	],

	[IdentitySource.POSTGRES_ROLE]: [
		{
			title: 'DB Name',
			key: 'db-name',
			Component: ({ identity }) => identity.postgresRole?.rdsInstance?.dbName,
		},
		{
			title: 'Instance ID',
			key: 'instance-id',
			Component: ({ identity }) => identity.postgresRole?.rdsInstance?.instanceId,
		},
		{
			title: 'DB Host',
			key: 'db-host',
			Component: ({ identity }) => identity.postgresRole?.rdsInstance?.host,
		},
		{
			title: 'Region',
			key: 'region',
			Component: ({ identity }) => identity.postgresRole?.rdsInstance?.region,
		},
		{
			title: 'Role Valid Until',
			key: 'role-valid-until',
			Component: ({ identity }) => formatDate(identity.postgresRole?.roleValidUntil),
		},
		{
			title: 'Superuser',
			key: 'superuser',
			Component: ({ identity }) => (identity.postgresRole?.isRoleSuperuser ? 'Yes' : 'No'),
		},
		{
			title: 'Can Create Role',
			key: 'can-create-role',
			Component: ({ identity }) => (identity.postgresRole?.canRoleCreateRole ? 'Yes' : 'No'),
		},
		{
			title: 'Can Login',
			key: 'can-login',
			Component: ({ identity }) => (identity.postgresRole?.canRoleLogin ? 'Yes' : 'No'),
		},
		{
			title: 'Bypass RLS',
			key: 'by-pass-rls',
			Component: ({ identity }) => (identity.postgresRole?.canRoleBypassRls ? 'Yes' : 'No'),
		},
		{
			title: 'Account',
			key: 'account',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<div className="flex -space-x-4 rtl:space-x-reverse">
						<IssueSourceIcon source={identity.envType} />
						<Tooltip title="Postgres">
							<span>
								<PostgresIcon />
							</span>
						</Tooltip>
					</div>
					<Tooltip placement="topLeft" title={identity?.accountLiteral}>
						<div className="font-normal text-gray-600 items-end truncate">
							{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
						</div>
					</Tooltip>
				</div>
			),
		},
	],

	[IdentitySource.AZURE_DEVOPS_USER]: [
		{
			title: 'Display Name',
			key: 'display-name',
			Component: ({ identity }) => identity.azureDevopsUser?.displayName,
		},
		{
			title: 'Principal Name',
			key: 'principal-name',
			Component: ({ identity }) => identity.azureDevopsUser?.principalName,
		},
		{
			title: 'Email',
			key: 'email',
			shouldHide: (identity) => !identity.azureDevopsUser?.mailAddress,
			Component: ({ identity }) => identity.azureDevopsUser?.mailAddress,
		},
		{
			title: 'Directory Alias',
			key: 'directory-alias',
			shouldHide: (identity) => !identity.azureDevopsUser?.directoryAlias,
			Component: ({ identity }) => identity.azureDevopsUser?.directoryAlias,
		},
		{
			title: 'Domain',
			key: 'domain',
			Component: ({ identity }) => identity.azureDevopsUser?.domain,
		},
		{
			title: 'Origin',
			key: 'origin',
			Component: ({ identity }) => identity.azureDevopsUser?.origin,
		},
		{
			title: 'Origin ID',
			key: 'origin-id',
			Component: ({ identity }) => identity.azureDevopsUser?.originId,
		},
		{
			title: 'Descriptor',
			key: 'descriptor',
			Component: ({ identity }) => identity.azureDevopsUser?.descriptor,
		},
	],
}

export const SidePanelProperties = ({ identity }: { identity: Identity }) => {
	if (!Object.keys(IDENTITY_SOURCE_TO_PROPERTIES).includes(identity.source)) {
		return 'No properties data'
	}

	return (
		<div className="bg-white rounded p-6 flex gap-8 flex-wrap">
			{IDENTITY_SOURCE_TO_PROPERTIES[identity.source]!.map(({ key, title, Component, shouldHide }) => {
				if (shouldHide && shouldHide(identity)) {
					return null
				}

				return (
					<div className="flex flex-col items-start gap-1" key={key}>
						<span className="text-neutral-500">{title}</span>
						<Component identity={identity} />
					</div>
				)
			})}
		</div>
	)
}
