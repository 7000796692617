export enum EnvironmentType {
	OKTA = 'OKTA',
	GOOGLE_WORKSPACE = 'GOOGLE_WORKSPACE',
	AWS = 'AWS',
	AZURE = 'AZURE',
	AZURE_DEVOPS = 'AZURE_DEVOPS',
	GCP = 'GCP',
	JUMPCLOUD = 'JUMPCLOUD',
	ENTRA_ID = 'ENTRA_ID',
	SNOWFLAKE = 'SNOWFLAKE',
	GITHUB = 'GITHUB',
	RDS_POSTGRES = 'RDS_POSTGRES',
	KUBERNETES = 'KUBERNETES',
	DATABRICKS = 'DATABRICKS',
	SALESFORCE = 'SALESFORCE',
}

export const EnvironmentTypeMap: Record<EnvironmentType, string> = {
	[EnvironmentType.OKTA]: 'Okta',
	[EnvironmentType.GOOGLE_WORKSPACE]: 'Google Workspace',
	[EnvironmentType.AWS]: 'AWS',
	[EnvironmentType.AZURE]: 'Azure',
	[EnvironmentType.AZURE_DEVOPS]: 'Azure DevOps',
	[EnvironmentType.GCP]: 'GCP',
	[EnvironmentType.JUMPCLOUD]: 'Jumpcloud',
	[EnvironmentType.ENTRA_ID]: 'EntraID',
	[EnvironmentType.SNOWFLAKE]: 'Snowflake',
	[EnvironmentType.GITHUB]: 'GitHub',
	[EnvironmentType.RDS_POSTGRES]: 'Postgres',
	[EnvironmentType.KUBERNETES]: 'Kubernetes',
	[EnvironmentType.DATABRICKS]: 'Databricks',
	[EnvironmentType.SALESFORCE]: 'Salesforce',
}
