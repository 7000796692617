import { z } from 'zod'
import { camelizeSchema } from '../utils'
import { DateSchema } from '../common.ts'

export const ServerAwsEc2InstanceXcSchema = z.object({
	InstanceId: z.string().nullish(),
	ImageId: z.string().nullish(),
	InstanceType: z.string().nullish(),
	InstanceLifecycle: z.string().nullish(),
	LaunchTime: DateSchema.nullish(),
	MetadataOptions: z.object({
		HttpEndpoint: z.string().nullish(),
		HttpProtocolIpv6: z.string().nullish(),
		HttpPutResponseHopLimit: z.number().nullish(),
		HttpTokens: z.string().nullish(),
		State: z.string().nullish(),
	}),
	PrivateIpAddress: z.string().nullish(),
	PublicIpAddress: z.string().nullish(),
	Tags: z
		.array(
			z.object({
				Key: z.string().nullish(),
				Value: z.string().nullish(),
			}),
		)
		.nullish(),
	State: z
		.object({
			Code: z.number().nullish(),
			Name: z.string().nullish(),
		})
		.nullish(),
})

export const AwsEc2InstanceXcSchema = camelizeSchema(ServerAwsEc2InstanceXcSchema.transform((val) => val))
export type AwsEc2InstanceXc = z.infer<typeof AwsEc2InstanceXcSchema>
