import { z } from 'zod'
import { camelizeSchema } from '../utils'
import { DateSchema } from '../common'
import { ServerAwsIamRoleXcSchema } from './awsIamRoleXcSchema.ts'
import { ServerAwsAccountSchema } from '../environments/awsAccountSchema.ts'

export const ServerAwsEc2InstanceSchema = z.object({
	name: z.string(),
	created_at: DateSchema,
	updated_at: DateSchema.nullish(),
	last_activity: DateSchema.nullish(),
	instance_id: z.string(),
	aws_iam_role_details_xc: ServerAwsIamRoleXcSchema.nullish(),
	aws_account: ServerAwsAccountSchema.nullish(),
	region: z.string(),
	state: z.string().nullish(),
	instance_type: z.string().nullish(),
})

export const AwsEc2Instance = camelizeSchema(ServerAwsEc2InstanceSchema.transform((val) => val))
export type AwsEc2Instance = z.infer<typeof AwsEc2Instance>
