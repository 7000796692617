import { z } from 'zod'
import { camelizeSchema } from '../../utils.ts'
import { DateSchema } from '../../common.ts'
import { ServerAwsIamRoleXcSchema } from '../awsIamRoleXcSchema.ts'
import { ServerGcpPrincipalInfoXc } from '../gcp/gcpProjectXcSchema.ts'

export const ServerJumpcloudUserSchema = z.object({
	account_locked: z.boolean().nullish(),
	suspended: z.boolean().nullish(),
	mfa_active: z.boolean().nullish(),
	mfa: z
		.object({
			configured: z.boolean().nullish(),
			exclusion: z.boolean().nullish(),
			exclusion_until: DateSchema.nullish(),
		})
		.nullish(),
	user_id: z.string().nullish(),
	name: z.string().nullish(),
	email: z.string().nullish(),
	first_name: z.string().nullish(),
	last_name: z.string().nullish(),
	created_at: DateSchema.nullish(),
	updated_at: DateSchema.nullish(),
	last_activity: DateSchema.nullish(),
	password_last_rotated: DateSchema.nullish(),
	aws_iam_roles_xc: z.array(ServerAwsIamRoleXcSchema).nullish(),
	gcp_projects_xc: z.array(ServerGcpPrincipalInfoXc).nullish(),
})

export const JumpcloudUser = camelizeSchema(ServerJumpcloudUserSchema.transform((val) => val))
export type JumpcloudUser = z.infer<typeof JumpcloudUser>
