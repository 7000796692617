import { Edge } from '@xyflow/react'
import { GcpProjectNodeType } from './GcpProjectNode.tsx'
import { GcpRoleNodeType } from './GcpRoleNode.tsx'
import { BareNodeType } from '../identityGraphTypes.ts'
import { getEdge } from '../graphUtils/nodesAndEdges.ts'
import { GcpPrincipalInfoXc } from '../../../../schemas/identities/gcp/gcpProjectXcSchema.ts'

export const processGcpProjects = (
	identityNodeId: string,
	gcpPrincipalInfoXc: GcpPrincipalInfoXc[] | null,
	nodeLogicalTypeToColumnId: { gcpProject: number; gcpRole: number },
	direction: 'leftToRight' | 'rightToLeft' = 'leftToRight',
) => {
	const gcpProjectNodes: BareNodeType<GcpProjectNodeType>[] = []
	const gcpRoleNodes: BareNodeType<GcpRoleNodeType>[] = []
	const gcpEdges: Edge[] = []

	gcpPrincipalInfoXc?.forEach((principalInfo, index) => {
		const projectNodeId = `${nodeLogicalTypeToColumnId.gcpProject}-${index}`
		gcpProjectNodes.push({
			type: 'gcpProject',
			data: { principalInfoXc: principalInfo },
			id: projectNodeId,
		})

		// Add edge between project and identity based on direction
		gcpEdges.push(
			getEdge(
				direction === 'leftToRight'
					? { source: projectNodeId, target: identityNodeId }
					: { source: identityNodeId, target: projectNodeId },
			),
		)

		principalInfo.roles.forEach((role) => {
			// Find index of role in gcpRoleNodes
			let indexRoleNode = gcpRoleNodes.findIndex((node) => node.data.role === role)
			if (indexRoleNode === -1) {
				indexRoleNode = gcpRoleNodes.length
				gcpRoleNodes.push({
					type: 'gcpRole',
					data: { role: role },
					id: `${nodeLogicalTypeToColumnId.gcpRole}-${indexRoleNode}`,
				})
			}
			const roleNodeId = `${nodeLogicalTypeToColumnId.gcpRole}-${indexRoleNode}`

			if (!gcpRoleNodes.find((node) => node.data.role === role)) {
				gcpRoleNodes.push({
					type: 'gcpRole',
					data: { role: role },
					id: roleNodeId,
				})
			}

			// Add edge between role and project based on direction
			gcpEdges.push(
				getEdge(
					direction === 'leftToRight'
						? { source: roleNodeId, target: projectNodeId }
						: { source: projectNodeId, target: roleNodeId },
				),
			)
		})
	})

	return { gcpProjectNodes, gcpRoleNodes, gcpEdges }
}
