/* eslint-disable camelcase */
import Schema from 'miragejs/orm/schema'
import { AppRegistry } from './schemas.ts'
import {
	IssueImpactProbabilityWithCount,
	IssueImpactProbabilityWithCountSchema,
	IssueStatus,
	ServerIssue,
	ServerIssueSchema,
} from '../schemas/issue.ts'
import {
	ServerIdentityUsageLogs,
	ServerIdentityUsageLogsSchema,
	ServerIdentitiesTableRow,
	ServerIdentitiesTableRowSchema,
	ServerIdentity,
	ServerIdentitySchema,
} from '../schemas/identity.ts'
import { EnvironmentType } from '../schemas/envType.ts'
import { ServerTag } from '../schemas/tags.ts'

export function getAllIssues(schema: Schema<AppRegistry>): ServerIssue[] {
	const rawIssues = schema.all('issue')
	const serverIssuesArray: ServerIssue[] = []
	rawIssues.models.forEach((issue) => {
		serverIssuesArray.push(ServerIssueSchema.parse(issue.attrs))
	})
	return serverIssuesArray
}

export function getAllIssueTypes(schema: Schema<AppRegistry>): IssueImpactProbabilityWithCount[] {
	const allIssues = getAllIssues(schema)
	return allIssues.reduce((issueTypes, issue, index) => {
		const currentIssueTypeIndex = issueTypes.findIndex(
			(issueType) => issueType.issue_source === issue.issue_source && issueType.issue_name === issue.issue_name,
		)

		if (currentIssueTypeIndex === -1) {
			return [
				...issueTypes,
				IssueImpactProbabilityWithCountSchema.parse({
					id: index.toString(),
					customer_id: '1',
					issue_name: issue.issue_name,
					issue_source: issue.issue_source,
					status_update_enabled: true,
					ignore_issue: false,
					issue_count: 1,
				}),
			]
		}

		issueTypes[currentIssueTypeIndex].issue_count++
		return issueTypes
	}, [] as IssueImpactProbabilityWithCount[])
}

function addAffectedEnvironmentToIdentity(identity: ServerIdentitiesTableRow) {
	if (!identity.affected_environments) {
		identity.affected_environments = {
			[identity.env_type as EnvironmentType]: [
				{
					account_db_id: identity.account_literal,
					account_id: identity.account_literal,
					account_name: identity.account_literal_friendly_name,
				},
			],
		}
	}
}

export function getAllStandAloneIdentities(schema: Schema<AppRegistry>): ServerIdentitiesTableRow[] {
	/*
	Note:
	As we built mirage on base of Issues, we use issues to get identities.
	But we also want to have identities without issues.
	To signal that an identity has no issues, we add an attribute to it; 'noIssueConnectedToIdentity'
	Then another attribute; 'identity', that will contain the identity object
	*/
	const rawIdentities = schema.all('identity')
	const serverIdentitiesMap: Map<string, ServerIdentitiesTableRow> = new Map()

	rawIdentities.models.forEach((identity) => {
		const identityObj = { ...identity.attrs }

		// @ts-expect-error: identity is not a property identityObj, as it can be structured differently

		if (!identityObj.noIssueConnectedToIdentity | identityObj.identity) return

		// @ts-expect-error: identity is not a property identityObj, as it can be structured differently
		const identityParsed = ServerIdentitiesTableRowSchema.parse(identityObj.identity)
		if (!identityParsed) return
		if (!serverIdentitiesMap.has(identityParsed.literal)) {
			serverIdentitiesMap.set(identityParsed.literal, identityParsed)
			addAffectedEnvironmentToIdentity(identityParsed)
		}
	})

	return [...serverIdentitiesMap.values()]
}

export function getAllIdentitiesFromIssues(schema: Schema<AppRegistry>): ServerIdentitiesTableRow[] {
	const rawIssues = schema.all('issue')
	const serverIdentitiesMap: Map<string, ServerIdentitiesTableRow> = new Map()

	rawIssues.models.forEach((issue) => {
		// @ts-expect-error: identity is not a property of issue
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const identityObj = { ...issue.attrs?.identity }

		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		identityObj.issues = []
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
		identityObj.tags = identityObj.tags?.map((tag: ServerTag) => tag.name)
		const identity = ServerIdentitiesTableRowSchema.parse(identityObj)
		const issueParsed = ServerIssueSchema.parse(issue.attrs)
		if (issueParsed.status !== IssueStatus.OPEN && issueParsed.status !== IssueStatus.IN_PROGRESS) return
		if (!identity) return
		if (!serverIdentitiesMap.has(identity.literal)) {
			serverIdentitiesMap.set(identity.literal, identity)
			addAffectedEnvironmentToIdentity(identity)
		}
		serverIdentitiesMap.get(identity.literal)?.issues.push(issueParsed)
	})

	return [...serverIdentitiesMap.values()]
}

export function getIssueById(schema: Schema<AppRegistry>, id: string): ServerIssue | undefined {
	const rawIssue = schema.findBy('issue', { id })
	if (!rawIssue) return
	return ServerIssueSchema.parse(rawIssue.attrs)
}

export function getIdentityById(schema: Schema<AppRegistry>, id: string): ServerIdentity {
	const rawIdentities = schema.all('identity')
	const serverIdentitiesMap: Map<string, ServerIdentity> = new Map()

	rawIdentities.models.forEach((identity) => {
		const identityObj = { ...identity.attrs }

		// @ts-expect-error: id is not a property identityObj, as it can be structured differently

		if (identityObj.id !== id) return
		// @ts-expect-error: identity is not a property identityObj, as it can be structured differently

		if (!identityObj.noIssueConnectedToIdentity && !identityObj.identity) return

		// @ts-expect-error: identity is not a property identityObj, as it can be structured differently
		const identityParsed = ServerIdentitySchema.parse(identityObj.identity)
		if (!identityParsed) return
		if (!serverIdentitiesMap.has(identityParsed.literal)) {
			serverIdentitiesMap.set(identityParsed.literal, identityParsed)
		}
	})
	return [...serverIdentitiesMap.values()][0]
}

export function getIdentityByIdFromIssues(schema: Schema<AppRegistry>, id: string): ServerIdentity {
	const rawIssues = schema.all('issue')
	const serverIdentitiesMap: Map<string, ServerIdentity> = new Map()

	rawIssues.models.forEach((issue) => {
		// @ts-expect-error: identity is not a property of issue
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const identityObj = issue.attrs?.identity
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		if (identityObj.id !== id) return

		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		identityObj.issues = []
		const identity = ServerIdentitySchema.parse(identityObj)
		const issueParsed = ServerIssueSchema.parse(issue.attrs)
		if (issueParsed.status !== IssueStatus.OPEN && issueParsed.status !== IssueStatus.IN_PROGRESS) return
		if (!identity) return
		if (!serverIdentitiesMap.has(identity.literal)) {
			serverIdentitiesMap.set(identity.literal, identity)
		}
		serverIdentitiesMap.get(identity.literal)?.issues!.push(issueParsed)
	})
	return [...serverIdentitiesMap.values()][0]
}

export function getUsageLogsByIdentityId(schema: Schema<AppRegistry>, id: string): ServerIdentityUsageLogs | null {
	const rawIdentity = schema.findBy('identity', { id })

	// Parse the data with partial schema - This allows us to create identities with missing keys (of logs)
	const partialData = ServerIdentityUsageLogsSchema.partial().parse(rawIdentity?.attrs)

	// Fill in any missing keys with empty arrays after parsing
	const usageLogs = Object.fromEntries(
		Object.keys(ServerIdentityUsageLogsSchema.shape).map((key) => [
			key,
			partialData[key as keyof ServerIdentityUsageLogs] ?? [],
		]),
	) as ServerIdentityUsageLogs

	return usageLogs ?? null
}
