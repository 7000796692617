import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent } from '@ag-grid-community/core'
import { GithubRepository } from '../../../../schemas/identities/githubUserSchema.ts'

export const GithubReposTable: React.FunctionComponent<{ githubRepos: GithubRepository[] }> = ({ githubRepos }) => {
	const gridRef = useRef<AgGridReact<GithubRepository>>(null)
	const columnDefs = useMemo<ColDef<GithubRepository>[]>(() => {
		const baseColumns: ColDef<GithubRepository>[] = [{ field: 'name', flex: 2 }]

		const hasOnlyName = githubRepos.every(
			(repo) => !repo.permission && (!repo.permissionSources || repo.permissionSources.length === 0),
		)

		if (!hasOnlyName) {
			baseColumns.push({ field: 'permission', flex: 1 })

			const hasPermissionSources = githubRepos.some(
				(repo) => repo.permissionSources && repo.permissionSources.length > 0,
			)

			if (hasPermissionSources) {
				baseColumns.push({
					headerName: 'Permission Sources',
					flex: 3,
					cellRenderer: (params: { data?: GithubRepository }) => {
						return (
							<div className="h-full flex justify-center flex-col text-xs">
								{params.data?.permissionSources?.map((permissionSource) => {
									const { roleName, permission, sourceType, sourceName } = permissionSource
									const roleText = roleName ? ` (role: ${roleName})` : ''
									return (
										<span
											key={`${roleName}${permission}${sourceType}${sourceName}`}
											className="block leading-tight"
										>
											{sourceName} ({sourceType}): {permission} {roleText}
										</span>
									)
								})}
							</div>
						)
					},
				})
			}
		}
		return baseColumns
	}, [githubRepos])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback((_params: GridReadyEvent<GithubRepository>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	return (
		<div className="h-80 overflow-auto">
			<AgGridReact
				className={'ag-theme-alpine h-full w-full overflow-x-auto'}
				ref={gridRef}
				rowData={githubRepos}
				rowHeight={54}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
