import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent } from '@ag-grid-community/core'
import { GithubTeam } from '../../../../schemas/identities/githubUserSchema.ts'

export const GithubTeamsTable: React.FunctionComponent<{ githubTeams: GithubTeam[] }> = ({ githubTeams }) => {
	const gridRef = useRef<AgGridReact<GithubTeam>>(null)
	const columnDefs = useMemo<ColDef<GithubTeam>[]>(() => {
		return [
			{ field: 'name', cellRenderer: 'agGroupCellRenderer' },
			{
				headerName: 'Repositories Access',
				cellRenderer: (params: { data?: GithubTeam }) => {
					return `${params?.data?.permissionsOnRepos?.length} repositories`
				},
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback((_params: GridReadyEvent<GithubTeam>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	const detailCellRendererParams = useMemo(() => {
		return {
			detailGridOptions: {
				columnDefs: [{ headerName: 'repository', field: 'sourceName' }, { field: 'permission' }],
				defaultColDef: { flex: 1 },
			},
			getDetailRowData(params: {
				successCallback: (data: GithubTeam['permissionsOnRepos']) => void
				data: GithubTeam
			}) {
				params.successCallback(params.data.permissionsOnRepos)
			},
		}
	}, [])

	return (
		<div className="h-80 overflow-auto">
			<AgGridReact
				className={'ag-theme-alpine h-full w-full overflow-x-auto'}
				ref={gridRef}
				rowData={githubTeams}
				rowHeight={54}
				detailCellRendererParams={detailCellRendererParams}
				masterDetail={true}
				isRowMaster={(dataItem: GithubTeam) =>
					!!(dataItem?.permissionsOnRepos && dataItem?.permissionsOnRepos.length > 0)
				}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
