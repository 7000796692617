import React, { useMemo, useState } from 'react'
import { Layout, message, Modal } from 'antd'
import { ApiOutlined } from '@ant-design/icons'
import { integrations, integrationsByKey } from './registry'

import { useCreateEnvironment, useEnvironments } from '../../api/environments'
import { useTestConnection } from '../../api/integrations'
import { NavigateToBase } from '../NavigateToBase'
import { useEntitlementToIntegrationsPage } from '../../services/auth/hooks'
import { IntegrationTestConnectionRequest } from '../../schemas/integrations/integrations'
import { IntegrationConfig, IntegrationFormData } from './types/integrationConfig.ts'
import { IntegrationsState, IntegrationStatus } from './types/integrationState.ts'
import { IntegrationsError, IntegrationsSkeleton } from './components/IntegrationStates/IntegrationStates.tsx'
import { IntegrationCard } from './components/IntegrationCard/IntegrationCard.tsx'
import { IntegrationForm } from './components/IntegrationForm/IntegrationForm.tsx'

const IntegrationsHeader: React.FC = () => {
	return (
		<div className="w-full h-12 bg-zinc-100 border border-zinc-200 rounded-lg flex items-center pl-5">
			<div className="flex items-center gap-2 text-zinc-700">
				<ApiOutlined className="text-base" />
				<span className="text-xl font-medium">Integrations</span>
			</div>
		</div>
	)
}

export const Integrations: React.FC = () => {
	const { isEntitled } = useEntitlementToIntegrationsPage()
	const { data: environments, isLoading, error } = useEnvironments()
	const { mutate: createEnvironment, isLoading: isCreatingEnvironment } = useCreateEnvironment()
	const testConnection = useTestConnection()
	const [selectedIntegration, setSelectedIntegration] = useState<IntegrationConfig | null>(null)
	const [isModalVisible, setIsModalVisible] = useState(false)

	const integrationsState: IntegrationsState = useMemo(() => {
		if (!environments) return {}

		return integrations.reduce((integrationsState, integration) => {
			const envs = environments[integration.key as keyof typeof environments] || []
			integrationsState[integration.key] = {
				status: envs.length > 0 ? IntegrationStatus.ACTIVE : IntegrationStatus.INACTIVE,
				environmentCount: envs.length,
			}
			return integrationsState
		}, {} as IntegrationsState)
	}, [environments])

	if (!isEntitled) {
		return <NavigateToBase />
	}

	const handleConnect = (integrationKey: string) => {
		const integration = integrationsByKey[integrationKey]
		if (integration) {
			setSelectedIntegration(integration)
			setIsModalVisible(true)
		}
	}

	const handleCloseModal = () => {
		setIsModalVisible(false)
		setSelectedIntegration(null)
	}

	const handleConnectSubmit = (formData: IntegrationFormData) => {
		if (!selectedIntegration) return

		const environmentData = selectedIntegration.createEnvironmentPayload(formData)
		createEnvironment(environmentData, {
			onSuccess: () => {
				message.success(`${selectedIntegration.name} environment created successfully`)
				handleCloseModal()
			},
			onError: (error) => {
				console.error('Error creating environment:', error)
				message.error(`Failed to create ${selectedIntegration.name} environment`)
			},
		})
	}

	const handleTestConnection = async (request: IntegrationTestConnectionRequest) => {
		return await testConnection.mutateAsync(request)
	}

	const handleEdit = () => {
		// Empty handler
	}

	return (
		<Layout className="h-full rounded-md">
			<Layout.Content className="px-6 pt-4">
				<div className="flex flex-col gap-5">
					<IntegrationsHeader />
					<div className="w-full bg-white border border-zinc-100 rounded-lg p-4">
						{isLoading ? (
							<IntegrationsSkeleton />
						) : error ? (
							<IntegrationsError
								error={error instanceof Error ? error : new Error('An unknown error occurred')}
							/>
						) : (
							<div className="flex flex-wrap gap-5">
								{integrations.map((integration) => (
									<IntegrationCard
										key={integration.key}
										logo={integration.logo}
										name={integration.name}
										description={integration.description}
										status={
											integrationsState[integration.key]?.status || IntegrationStatus.INACTIVE
										}
										environmentCount={integrationsState[integration.key]?.environmentCount || 0}
										onConnect={() => handleConnect(integration.key)}
										onEdit={handleEdit}
									/>
								))}
							</div>
						)}
					</div>
				</div>
			</Layout.Content>

			<Modal
				open={isModalVisible}
				onCancel={handleCloseModal}
				footer={null}
				width={530}
				className="integrationModal"
				destroyOnClose={true}
			>
				{selectedIntegration && (
					<IntegrationForm
						key={selectedIntegration.key}
						integration={selectedIntegration}
						onSubmit={handleConnectSubmit}
						onTestConnection={handleTestConnection}
						onClose={handleCloseModal}
						isConnecting={isCreatingEnvironment}
						isTestingConnection={testConnection.isLoading}
					/>
				)}
			</Modal>
		</Layout>
	)
}
