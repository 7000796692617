import AwsIcon from '../../assets/aws_logo_16.svg?react'
import OktaIcon from '../../assets/okta_logo_16.svg?react'
import GoogleWorkspaceIcon from '../../assets/google_workspace_logo_16.svg?react'
import HibpIcon from '../../assets/hibp_logo.svg?react'
import { ResourceType, ResourceTypeMap } from '../../schemas/issue'
import JumpcloudIcon from '../../assets/jumpcloud_icon_16.svg?react'
import EntraIdIcon from '../../assets/entra_id_icon_16.svg?react'
import GcpIcon from '../../assets/gcp_icon_20.svg?react'
import SnowflakeIcon from '../../assets/snowflake_icon_20.svg?react'
import GitHubIcon from '../../assets/github_icon_20.svg?react'
import AzureIcon from '../../assets/azure_icon.svg?react'
import DatabricksIcon from '../../assets/databricks_icon.svg?react'
import SalesforceIcon from '../../assets/salesforce_icon.svg?react'
import React from 'react'

const sourceMap: Partial<Record<ResourceType, React.FunctionComponent<React.SVGProps<SVGSVGElement>>>> = {
	[ResourceType.AWS]: AwsIcon,
	[ResourceType.OKTA]: OktaIcon,
	[ResourceType.GOOGLE_WORKSPACE]: GoogleWorkspaceIcon,
	[ResourceType.HIBP]: HibpIcon,
	[ResourceType.JUMPCLOUD]: JumpcloudIcon,
	[ResourceType.ENTRA_ID]: EntraIdIcon,
	[ResourceType.GCP]: GcpIcon,
	[ResourceType.SNOWFLAKE]: SnowflakeIcon,
	[ResourceType.GITHUB]: GitHubIcon,
	[ResourceType.AZURE]: AzureIcon,
	[ResourceType.AZURE_DEVOPS]: AzureIcon,
	[ResourceType.DATABRICKS]: DatabricksIcon,
	[ResourceType.SALESFORCE]: SalesforceIcon,
}

export const IssueSourceFilterIcon: React.FunctionComponent<{
	source?: string
}> = ({ source }) => {
	if (!source) {
		return null
	}
	const SourceIcon = sourceMap[source as ResourceType]
	const title = ResourceTypeMap[source as ResourceType] || source

	if (!SourceIcon) {
		return <div>{title}</div>
	}

	return (
		<div className="flex items-center gap-x-2">
			<div className="ml-1">
				<SourceIcon style={{ width: 16, height: 16 }} />
			</div>
			<div className="mr-2">{title}</div>
		</div>
	)
}
