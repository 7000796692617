import { camelizeSchema } from '../utils.ts'
import { z } from 'zod'
import { DateSchema } from '../common.ts'

export const ServerMetricsSummarySchema = z.object({
	total_identities: z.number(),
	total_environments: z.number(),
	last_scan: DateSchema.nullish(),
	time_to_resolve: z.string().nullish(),
})

export type ServerMetricsSummary = z.infer<typeof ServerMetricsSummarySchema>

export const MetricsSummarySchema = camelizeSchema(ServerMetricsSummarySchema.transform((val) => val))
export type MetricsSummary = z.infer<typeof MetricsSummarySchema>
