import { z } from 'zod'
import { camelizeSchema } from '../utils'
import { DateSchema } from '../common.ts'

export const ServerEndpointAccessXcSchema = z
	.object({
		device_name: z.string().nullish(),
		device_type: z.string().nullish(),
		endpoint_protection: z.boolean().nullish(),
		status: z.string().nullish(),
		ip_address: z.string().nullish(),
		first_seen: DateSchema.nullish(),
		last_seen: DateSchema.nullish(),
	})
	.nullish()

export const EndpointAccessXcSchema = camelizeSchema(ServerEndpointAccessXcSchema.transform((val) => val))
export type EndpointAccessXc = z.infer<typeof EndpointAccessXcSchema>
