import { AwsRoleNodeType } from './AwsRoleNode.tsx'
import { AwsAccountNodeType } from './AwsAccountNode.tsx'
import { BareNodeType } from '../identityGraphTypes.ts'
import { AwsPolicyNodeType } from './AwsPolicyNode.tsx'
import { AwsIamRoleXc } from '../../../../schemas/identities/awsIamRoleXcSchema.ts'
import { Edge } from '@xyflow/react'
import { getCombinedAwsIamRolePolicy, getPolicyUniqueKey } from '../../../../utils/awsIdentityUtils.ts'
import { getEdge } from '../graphUtils/nodesAndEdges.ts'

export const processAwsRoles = (
	identityNodeId: string,
	awsIamRolesXc: AwsIamRoleXc[] | null,
	nodeLogicalTypeToColumnId: { awsAccount: number; awsRoles: number; awsPolicy: number },
	direction: 'leftToRight' | 'rightToLeft' = 'rightToLeft',
) => {
	const awsRolesNodes: BareNodeType<AwsRoleNodeType>[] = []
	const awsAccountNodes: BareNodeType<AwsAccountNodeType>[] = []
	const policyNodes: BareNodeType<AwsPolicyNodeType>[] = []
	const awsEdges: Edge[] = []

	awsIamRolesXc?.forEach((role) => {
		// Check if the account is already in the list, push if it does not exist
		if (!awsAccountNodes.find((node) => node.data.awsAccount.name === role.accountName))
			awsAccountNodes.push({
				type: 'awsAccount',
				data: {
					awsAccount: { name: role.accountName, priority: role.accountPriority, accountId: role.accountId },
				},
				id: `${nodeLogicalTypeToColumnId.awsAccount}-${awsAccountNodes.length}`,
			})

		awsRolesNodes.push({
			type: 'awsIamRole',
			data: { awsIamRoleXc: role },
			id: `${nodeLogicalTypeToColumnId.awsRoles}-${awsRolesNodes.length}`,
		})

		// Create policy nodes and edges to the role
		const policies = getCombinedAwsIamRolePolicy(null, role)
		policies.forEach((policy) => {
			if (!policyNodes.find((node) => getPolicyUniqueKey(node.data.policy) === getPolicyUniqueKey(policy)))
				policyNodes.push({
					type: 'awsPolicy',
					data: { policy },
					id: `${nodeLogicalTypeToColumnId.awsPolicy}-${policyNodes.length}`,
				})
			const index = policyNodes.findIndex(
				(node) => getPolicyUniqueKey(node.data.policy) === getPolicyUniqueKey(policy),
			)
			// Add edge between role and policy based on direction
			awsEdges.push(
				getEdge(
					direction === 'rightToLeft'
						? {
								source: `${nodeLogicalTypeToColumnId.awsRoles}-${awsRolesNodes.length - 1}`,
								target: `${nodeLogicalTypeToColumnId.awsPolicy}-${index}`,
							}
						: {
								source: `${nodeLogicalTypeToColumnId.awsPolicy}-${index}`,
								target: `${nodeLogicalTypeToColumnId.awsRoles}-${awsRolesNodes.length - 1}`,
							},
				),
			)
		})
	})

	// Add edges between identity and AWS accounts based on direction
	awsAccountNodes.forEach((_, index) => {
		const accountNodeId = `${nodeLogicalTypeToColumnId.awsAccount}-${index}`
		awsEdges.push(
			getEdge(
				direction === 'rightToLeft'
					? { source: identityNodeId, target: accountNodeId }
					: { source: accountNodeId, target: identityNodeId },
			),
		)
	})

	// Add edges between AWS accounts and roles based on direction
	awsRolesNodes.forEach((roleNode, index) => {
		const indexAccount = awsAccountNodes.findIndex(
			(node) => node.data.awsAccount.accountId === roleNode.data.awsIamRoleXc.accountId,
		)
		const awsAccountId = `${nodeLogicalTypeToColumnId.awsAccount}-${indexAccount}`
		const awsRoleId = `${nodeLogicalTypeToColumnId.awsRoles}-${index}`
		awsEdges.push(
			getEdge(
				direction === 'rightToLeft'
					? { source: awsAccountId, target: awsRoleId }
					: { source: awsRoleId, target: awsAccountId },
			),
		)
	})

	return { awsRolesNodes, awsAccountNodes, policyNodes, awsEdges }
}
