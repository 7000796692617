import React, { useMemo } from 'react'
import { IdentitiesByEnvironmentChart } from '../../charts/IdentitiesByEnvironmentChart.tsx'
import { circleStyle, numberWithCommas } from './helpers.tsx'
import { IssueSourceIcon } from '../../components/common/IssueSourceIcon.tsx'
import { ResourceType } from '../../schemas/issue.ts'
import { EnvironmentsColors, IdentitiesByEnv, IdentityEnvSource } from '../../schemas/metrics/identitiesByEnv.ts'
import { useIdentitiesByEnv, useMetricsSummary } from '../../api/metrics.ts'
import { StatItem } from '../../components/common/StatItem.tsx'
import { MetricsChartContainer } from './MetricsChartContainer.tsx'
import { Skeleton } from 'antd'

export const MonitoringIdentities: React.FunctionComponent = () => {
	const { data: identitiesByEnv } = useIdentitiesByEnv()
	const { data: metricsSummary, isLoading } = useMetricsSummary()
	const latestData = useMemo<IdentitiesByEnv | undefined>(() => {
		return identitiesByEnv?.[identitiesByEnv.length - 1]
	}, [identitiesByEnv])

	return (
		<MetricsChartContainer className="col-span-6" header="Monitored Identities By Environment">
			{isLoading ? (
				<Skeleton active />
			) : (
				<>
					<div className="flex flex-row space-x-6">
						<StatItem title="Total Identities">
							<div className="h-full flex flex-row items-center space-x-2">
								<div>
									{metricsSummary?.totalIdentities ? metricsSummary.totalIdentities : 'Loading...'}
								</div>
							</div>
						</StatItem>

						<StatItem title="By Environment" className="overflow-x-auto">
							<div className="flex flex-row items-center space-x-2">
								<div className="flex space-x-4 font-normal text-xl">
									{latestData?.googleWorkspace && latestData?.googleWorkspace > 0 ? (
										<div className="flex flex-row items-center space-x-1">
											<div
												style={{
													...circleStyle,
													backgroundColor:
														EnvironmentsColors[IdentityEnvSource.GOOGLE_WORKSPACE],
												}}
											/>
											<IssueSourceIcon source={ResourceType.GOOGLE_WORKSPACE} size={16} />
											<div>{numberWithCommas(latestData?.googleWorkspace)}</div>
										</div>
									) : null}
									{latestData?.aws && latestData?.aws > 0 ? (
										<div className="flex flex-row items-center space-x-1">
											<div
												style={{
													...circleStyle,
													backgroundColor: EnvironmentsColors[IdentityEnvSource.AWS],
												}}
											/>
											<IssueSourceIcon source={ResourceType.AWS} size={16} />
											<div>{numberWithCommas(latestData?.aws)}</div>
										</div>
									) : null}
									{latestData?.okta && latestData?.okta > 0 ? (
										<div className="flex flex-row items-center space-x-1">
											<div
												style={{
													...circleStyle,
													backgroundColor: EnvironmentsColors[IdentityEnvSource.OKTA],
												}}
											/>
											<IssueSourceIcon source={ResourceType.OKTA} size={16} />
											<div>{numberWithCommas(latestData?.okta)}</div>
										</div>
									) : null}
									{latestData?.jumpcloud && latestData?.jumpcloud > 0 ? (
										<div className="flex flex-row items-center space-x-1">
											<div
												style={{
													...circleStyle,
													backgroundColor: EnvironmentsColors[IdentityEnvSource.JUMPCLOUD],
												}}
											/>
											<IssueSourceIcon source={ResourceType.JUMPCLOUD} size={16} />
											<div>{numberWithCommas(latestData?.jumpcloud)}</div>
										</div>
									) : null}
									{latestData?.entraId && latestData?.entraId > 0 ? (
										<div className="flex flex-row items-center space-x-1">
											<div
												style={{
													...circleStyle,
													backgroundColor: EnvironmentsColors[IdentityEnvSource.ENTRA_ID],
												}}
											/>
											<IssueSourceIcon source={ResourceType.ENTRA_ID} size={16} />
											<div>{numberWithCommas(latestData?.entraId)}</div>
										</div>
									) : null}
									{latestData?.gcp && latestData?.gcp > 0 ? (
										<div className="flex flex-row items-center space-x-1">
											<div
												style={{
													...circleStyle,
													backgroundColor: EnvironmentsColors[IdentityEnvSource.GCP],
												}}
											/>
											<IssueSourceIcon source={ResourceType.GCP} size={16} />
											<div>{numberWithCommas(latestData?.gcp)}</div>
										</div>
									) : null}
									{latestData?.snowflake && latestData?.snowflake > 0 ? (
										<div className="flex flex-row items-center space-x-1">
											<div
												style={{
													...circleStyle,
													backgroundColor: EnvironmentsColors[IdentityEnvSource.SNOWFLAKE],
												}}
											/>
											<IssueSourceIcon source={ResourceType.SNOWFLAKE} size={16} />
											<div>{numberWithCommas(latestData?.snowflake)}</div>
										</div>
									) : null}
									{latestData?.github && latestData?.github > 0 ? (
										<div className="flex flex-row items-center space-x-1">
											<div
												style={{
													...circleStyle,
													backgroundColor: EnvironmentsColors[IdentityEnvSource.GITHUB],
												}}
											/>
											<IssueSourceIcon source={ResourceType.GITHUB} size={16} />
											<div>{numberWithCommas(latestData?.github)}</div>
										</div>
									) : null}
									{latestData?.azure && latestData?.azure > 0 ? (
										<div className="flex flex-row items-center space-x-1">
											<div
												style={{
													...circleStyle,
													backgroundColor: EnvironmentsColors[IdentityEnvSource.AZURE],
												}}
											/>
											<IssueSourceIcon source={ResourceType.AZURE} size={16} />
											<div>{numberWithCommas(latestData?.azure)}</div>
										</div>
									) : null}
								</div>
							</div>
						</StatItem>
					</div>
					<IdentitiesByEnvironmentChart identitiesByEnv={identitiesByEnv} />
				</>
			)}
		</MetricsChartContainer>
	)
}
