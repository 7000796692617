import { z } from 'zod'
import { camelizeSchema } from '../../utils.ts'
import { DateSchema } from '../../common.ts'

export enum EntraIdCredentialType {
	ClientSecret = 'Client Secret',
	Certificate = 'Certificate',
}
export const ServerEntraIdSignInActivitySchema = z.object({
	signin_time: DateSchema.nullish(),
	ip_address: z.string().nullish(),
})

export const EntraIdSignInActivitySchema = camelizeSchema(ServerEntraIdSignInActivitySchema.transform((val) => val))
export type EntraIdSignInActivity = z.infer<typeof EntraIdSignInActivitySchema>

export const ServerEntraIdKeyCredentialSchema = z.object({
	custom_key_identifier: z.string().nullish(),
	display_name: z.string().nullish(),
	key: z.string().nullish(),
	key_id: z.string().nullish(),
	odata_type: z.string().nullish(),
	start_date_time: DateSchema.nullish(),
	end_date_time: DateSchema.nullish(),
	type: z.string().nullish(),
	usage: z.string().nullish(),
	last_sign_in: ServerEntraIdSignInActivitySchema.nullish(),
})

export const EntraIdKeyCredentialSchema = camelizeSchema(ServerEntraIdKeyCredentialSchema.transform((val) => val))
export type EntraIdKeyCredential = z.infer<typeof EntraIdKeyCredentialSchema>

export const ServerEntraIdPasswordCredentialSchema = z.object({
	customer_key_identifier: z.string().nullish(),
	display_name: z.string().nullish(),
	hint: z.string().nullish(),
	key_id: z.string().nullish(),
	odata_type: z.string().nullish(),
	secret_text: z.string().nullish(),
	end_date_time: DateSchema.nullish(),
	start_date_time: DateSchema.nullish(),
	last_sign_in: ServerEntraIdSignInActivitySchema.nullish(),
})

export const EntraIdPasswordCredentialSchema = camelizeSchema(
	ServerEntraIdPasswordCredentialSchema.transform((val) => val),
)
export type EntraIdPasswordCredential = z.infer<typeof EntraIdPasswordCredentialSchema>

// Common authentication credential schema
export const EntraIdCommonAuthenticationCredentialSchema = z.object({
	displayName: z.string(),
	keyId: z.string(),
	type: z.nativeEnum(EntraIdCredentialType),
	startDateTime: DateSchema,
	endDateTime: DateSchema,
	lastSignIn: DateSchema.nullish(),
})

export type EntraIdCommonAuthenticationCredential = z.infer<typeof EntraIdCommonAuthenticationCredentialSchema>

export const ServerEntraIdApplicationXcSchema = z
	.object({
		app_id: z.string().nullish(),
		created_date_time: DateSchema.nullish(),
		description: z.string().nullish(),
		display_name: z.string().nullish(),
		key_credentials: z.array(ServerEntraIdKeyCredentialSchema).nullish(),
		password_credentials: z.array(ServerEntraIdPasswordCredentialSchema).nullish(),
	})
	.nullish()

export const EntraIdApplicationXcSchema = camelizeSchema(ServerEntraIdApplicationXcSchema.transform((val) => val))
export type EntraIdUserXcSchema = z.infer<typeof EntraIdApplicationXcSchema>

export const normalizeCredentials = (
	keyCredentials: EntraIdKeyCredential[],
	passwordCredentials: EntraIdPasswordCredential[],
): EntraIdCommonAuthenticationCredential[] => {
	// Normalize key credentials
	const normalizedKeyCredentials = keyCredentials.map((credential) => ({
		displayName: credential.displayName || '',
		keyId: credential.keyId || '',
		type: EntraIdCredentialType.Certificate,
		startDateTime: credential.startDateTime || new Date(),
		endDateTime: credential.endDateTime || new Date(),
		lastSignIn: credential.lastSignIn?.signinTime,
	}))

	// Normalize password credentials
	const normalizedPasswordCredentials = passwordCredentials.map((credential) => ({
		displayName: credential.displayName || '',
		keyId: credential.keyId || '',
		type: EntraIdCredentialType.ClientSecret,
		startDateTime: credential.startDateTime || new Date(),
		endDateTime: credential.endDateTime || new Date(),
		lastSignIn: credential.lastSignIn?.signinTime,
	}))

	// Combine and return both normalized credential arrays
	return [...normalizedKeyCredentials, ...normalizedPasswordCredentials]
}
