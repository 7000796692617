import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent, IRowNode } from '@ag-grid-community/core'
import { EntraIdUserXcSchema } from '../schemas/identities/entraId/entraIdUserXcSchema.ts'

export const EntraIdUserXcTable: React.FunctionComponent<{ data: EntraIdUserXcSchema[] }> = ({ data }) => {
	const gridRef = useRef<AgGridReact<EntraIdUserXcSchema>>(null)
	const onGridReady = useCallback((_params: GridReadyEvent<EntraIdUserXcSchema>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	const columnDefs = useMemo<ColDef<EntraIdUserXcSchema>[]>(
		() => [
			{ field: 'mail' },
			{ field: 'userPrincipalName' },
			{ field: 'lastActivity' },
			{
				field: 'accountEnabled',
				cellRenderer: (params: { data?: EntraIdUserXcSchema; node: IRowNode }) => {
					return <div>{params.data?.accountEnabled ? 'Enabled' : 'Disabled'}</div>
				},
			},
			{ field: 'jobTitle' },
		],
		[],
	)

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				ref={gridRef}
				rowData={data}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				detailRowAutoHeight={true}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
