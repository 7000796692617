import { z } from 'zod'
import { camelizeSchema } from '../utils.ts'
import { ServerAbstractEnvironmentModelSchema } from './serverAbstractEnvironmentSchema.ts'

export const ServerEntraIdEnvironmentSchema = ServerAbstractEnvironmentModelSchema.extend({
	entra_id_tenant_id: z.string(),
	entra_id_app_id: z.string(),
})

export type ServerEntraIdEnvironment = z.infer<typeof ServerEntraIdEnvironmentSchema>

export const EntraIdEnvironmentSchema = camelizeSchema(ServerEntraIdEnvironmentSchema.transform((val) => val))
export type EntraIdEnvironment = z.infer<typeof EntraIdEnvironmentSchema>
