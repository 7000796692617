import { Issue, IssueStatus, IssueStatusMap, UserChangeableIssueStatuses } from '../../../schemas/issue.ts'
import { useEffect, useMemo, useState } from 'react'
import { useUpdateIssue } from '../../../api/issues.ts'
import { Input, Modal, Select } from 'antd'
import { IssueStatusTag } from '../../common/IssueStatusTag.tsx'
import cx from 'classnames'

const IssueStatusSelection = ({
	currentStatus,
	targetStatus,
	setStatus,
}: {
	currentStatus: IssueStatus
	targetStatus: IssueStatus
	setStatus: (targetStatus: IssueStatus) => void
}) => {
	const options = useMemo(
		() =>
			UserChangeableIssueStatuses.filter((issueStatus) => issueStatus !== currentStatus).map((issueStatus) => ({
				value: issueStatus,
				label: IssueStatusMap[issueStatus],
			})),
		[currentStatus],
	)

	return <Select size="small" className="w-[160px]" options={options} value={targetStatus} onChange={setStatus} />
}

const getTextAreaPropsByIssueStatus = (
	status: IssueStatus,
): { textAreaLabel: string; textAreaPlaceholder: string } | null => {
	if (status === IssueStatus.FALSE_POSITIVE) {
		return {
			textAreaLabel: 'Leave a comment on why this issue is a false-positive:',
			textAreaPlaceholder: 'This issue is a false-positive because...',
		}
	}

	if (status === IssueStatus.IGNORED) {
		return {
			textAreaLabel: 'Leave a comment on why this issue is ignored:',
			textAreaPlaceholder: 'This issue is ignored because...',
		}
	}

	return null
}

const IgnoreReasonInput = ({
	targetStatus,
	currentStatus,
	ignoreReason,
	setIgnoreReason,
	isUpdating,
}: {
	targetStatus: IssueStatus
	currentStatus: IssueStatus
	ignoreReason?: string
	setIgnoreReason: (newIgnoreReason: string) => void
	isUpdating: boolean
}) => {
	const propsByIssueStatus = getTextAreaPropsByIssueStatus(targetStatus)
	const containerClassName = cx('flex flex-col gap-2 transition-[height] duration-200', {
		'h-0': !propsByIssueStatus,
		'h-[100px]': !!propsByIssueStatus,
	})

	return (
		<div className={containerClassName}>
			{!!propsByIssueStatus && (
				<>
					<span>{propsByIssueStatus.textAreaLabel}</span>
					<Input.TextArea
						placeholder={propsByIssueStatus.textAreaPlaceholder}
						disabled={currentStatus === targetStatus || isUpdating}
						value={ignoreReason}
						onChange={(e) => setIgnoreReason(e.target.value)}
					/>
				</>
			)}
		</div>
	)
}
const getInitialTargetStatus = (currentStatus: IssueStatus): IssueStatus => {
	if (currentStatus === IssueStatus.OPEN) {
		return IssueStatus.IN_PROGRESS
	}

	return IssueStatus.OPEN
}

export const UpdateIssueStatusModal = ({
	isOpen,
	closeModal,
	issue,
}: {
	isOpen: boolean
	closeModal: () => void
	issue: Issue
}) => {
	const [targetStatus, setTargetStatus] = useState<IssueStatus>(() => getInitialTargetStatus(issue.status))
	const [ignoreReason, setIgnoreReason] = useState<string>('')
	const { mutate: updateIssue, isLoading: isIssueUpdating } = useUpdateIssue()

	useEffect(() => {
		setIgnoreReason('')
	}, [targetStatus])

	const onUpdateStatus = () => {
		updateIssue(
			{ issueId: issue.id, issueUpdate: { ignoreReason: ignoreReason || undefined, status: targetStatus } },
			{
				onSuccess: () => {
					closeModal()
					setTargetStatus(getInitialTargetStatus(targetStatus))
				},
			},
		)
	}

	return (
		<Modal
			open={isOpen}
			title="Update issue status"
			onCancel={() => {
				setTargetStatus(getInitialTargetStatus(issue.status))
				closeModal()
			}}
			confirmLoading={isIssueUpdating}
			onOk={onUpdateStatus}
			okText="Update"
			okType="primary"
			okButtonProps={{ className: 'bg-black' }}
		>
			<div className="flex flex-col gap-4 pt-2">
				<div className="flex items-center gap-1">
					<span>Change issue status from</span>
					<IssueStatusTag status={issue.status} />
					<span className="-ml-2 mr-1">to: </span>
					<IssueStatusSelection
						currentStatus={issue.status}
						targetStatus={targetStatus}
						setStatus={setTargetStatus}
					/>
				</div>
				<IgnoreReasonInput
					ignoreReason={ignoreReason}
					setIgnoreReason={setIgnoreReason}
					targetStatus={targetStatus}
					currentStatus={issue.status}
					isUpdating={isIssueUpdating}
				/>
			</div>
		</Modal>
	)
}
