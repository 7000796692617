import { z } from 'zod'
import { camelizeSchema } from '../utils.ts'
import { TagSchema } from '../tags.ts'

export const ServerAwsTag = z.object({
	Key: z.string(),
	Value: z.string(),
})

export const AwsTag = camelizeSchema(ServerAwsTag.transform((val) => val))
export type AwsTag = z.infer<typeof AwsTag>

export const ServerPolicyStatement = z.object({
	Sid: z.string().optional().nullish(),
	Effect: z.string(),
	Action: z
		.union([z.array(z.string()), z.string()])
		.optional()
		.nullish(),
	NotAction: z
		.union([z.array(z.string()), z.string()])
		.optional()
		.nullish(),
	Resource: z
		.union([z.string(), z.array(z.string())])
		.optional()
		.nullish(),
	NotResource: z
		.union([z.string(), z.array(z.string())])
		.optional()
		.nullish(),
	Condition: z.record(z.any()).optional().nullish(),
})
export const PolicyStatement = camelizeSchema(ServerPolicyStatement.transform((val) => val))
export type PolicyStatement = z.infer<typeof PolicyStatement>

export const ServerPolicyDocument = z.object({
	Version: z.string().optional().nullish(),
	Statement: z.union([z.array(ServerPolicyStatement), ServerPolicyStatement]),
})

export const PolicyDocument = camelizeSchema(ServerPolicyDocument.transform((val) => val))
export type PolicyDocument = z.infer<typeof PolicyDocument>

// Assuming PolicyDocument is defined somewhere else
export const ServerPolicyVersion = z.object({
	Document: ServerPolicyDocument,
	VersionId: z.string(),
	IsDefaultVersion: z.boolean(),
	CreateDate: z.string(),
})

export const PolicyVersion = camelizeSchema(ServerPolicyVersion.transform((val) => val))
export type PolicyVersion = z.infer<typeof PolicyVersion>

export const ServerAwsPolicy = z.object({
	PolicyName: z.string(),
	PolicyId: z.string(),
	Arn: z.string(),
	Path: z.string(),
	DefaultVersionId: z.string(),
	AttachmentCount: z.number(),
	PermissionsBoundaryUsageCount: z.number(),
	IsAttachable: z.boolean(),
	CreateDate: z.string(),
	UpdateDate: z.string(),
	PolicyVersionList: z.array(ServerPolicyVersion),
})

export const AwsPolicy = camelizeSchema(ServerAwsPolicy.transform((val) => val))
export type AwsPolicy = z.infer<typeof AwsPolicy>

export const ServerManagedPolicy = z.object({
	PolicyName: z.string(),
	PolicyArn: z.string(),
	PolicyInfo: ServerAwsPolicy.optional().nullish(),
	CustomerManaged: z.boolean().optional().default(false),
	RiskTags: TagSchema.array().nullish(),
})

export type ServerManagedPolicy = z.infer<typeof ServerManagedPolicy>
export const ManagedPolicy = camelizeSchema(ServerManagedPolicy.transform((val) => val))
export type ManagedPolicy = z.infer<typeof ManagedPolicy>

export const ServerAwsIdentityPolicy = z.object({
	PolicyName: z.string(),
	PolicyDocument: ServerPolicyDocument,
	RiskTags: TagSchema.array().nullish(),
})

export type ServerAwsIdentityPolicy = z.infer<typeof ServerAwsIdentityPolicy>
export const AwsIdentityPolicy = camelizeSchema(ServerAwsIdentityPolicy.transform((val) => val))
export type AwsIdentityPolicy = z.infer<typeof AwsIdentityPolicy>
