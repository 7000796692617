import { Edge } from '@xyflow/react'
import { IssueName } from '../../../../schemas/issue.ts'
import { IdentityNodeType } from '../common/IdentityNode.tsx'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { AccessKeyNodeType } from './AccessKeyNode.tsx'
import { AwsPolicyNodeType } from './AwsPolicyNode.tsx'
import { Identity } from '../../../../schemas/identity.ts'
import { aggregateAwsIamChangeLogs, getCombinedAwsIamRolePolicy } from '../../../../utils/awsIdentityUtils.ts'
import { Ec2InstancesNodeType } from './Ec2InstancesNode.tsx'
import { KubernetesResourcesNodeType } from '../common/KubernetesResourcesNode.tsx'
import { KubernetesResourceXc } from '../../../../schemas/identities/kubernetes/kubernetesResourceXcSchema.ts'
import { OktaUserNodeType } from '../okta/OktaUserNode.tsx'
import { getEdge } from '../graphUtils/nodesAndEdges.ts'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { AwsIamChangeLog } from '../../../../schemas/identities/awsIamChangeLogSchema.ts'
import { OwnershipNodeType } from '../common/OwnershipNode.tsx'
import { getActorArnFriendlyName } from '../../../../components/drawers/identities/utils.tsx'

const nodeLogicalTypeToColumnId = {
	ec2AndK8s: 0,
	generalIssue: 1,
	identity: 2,
	accessKey: 3,
	accessKeyIssue: 4,
	policy: 5,
}

const ownerNodeRowIndex = 0
const ownerNodeId = `${nodeLogicalTypeToColumnId.identity}-${ownerNodeRowIndex}`
const identityNodeRowIndex = 1
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`
const oktaUserNodeRowIndex = 2
const oktaUserNodeId = `${nodeLogicalTypeToColumnId.identity}-${oktaUserNodeRowIndex}`

const accessKeyIssueNames: IssueName[] = [
	IssueName.AccessKeyNotRotated,
	IssueName.InactiveAccessKey,
	IssueName.MultipleAccessKeys,
	IssueName.EKSAccessKeyReferencedInPod,
	IssueName.AccessKeyCreatedByOffboardedEmployee,
	IssueName.UserUsedFromAttackOrPentestingFramework,
	IssueName.ServiceAccountSuspiciousBehavior,
]

export const getAwsNodesAndEdges = (identity: Identity): [BareNodesColumnsType[], Edge[]] => {
	const edges: Edge[] = []
	const identityNodes: Array<
		BareNodeType<IdentityNodeType> | BareNodeType<OktaUserNodeType> | BareNodeType<OwnershipNodeType>
	> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	const changeLogs: AwsIamChangeLog[] = identity.awsIamUser?.changeLogs || identity.awsIamRole?.changeLogs || []
	if (changeLogs?.length) {
		const aggregatedChangeLogs = aggregateAwsIamChangeLogs(changeLogs)
		identityNodes.unshift({
			type: 'ownership',
			data: {
				owners: aggregatedChangeLogs.map((aggChangeLog) => ({
					id: aggChangeLog.actorArn,
					name: getActorArnFriendlyName(aggChangeLog.actorArn),
				})),
			},
			id: ownerNodeId,
		})

		edges.push(
			getEdge({
				source: ownerNodeId,
				target: identityNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	if (identity.awsIamUser?.oktaUserXc?.length) {
		// TODO: We currently use only the first okta user as a node, fix if need rises, requires proper UI design
		const details = identity.awsIamUser.oktaUserXc[0]
		const displayName = details.profile?.displayName || details.profile?.email
		identityNodes.push({
			type: 'oktaUser',
			data: { oktaUser: { type: 'Okta User', displayName } },
			id: oktaUserNodeId,
		})

		edges.push(
			getEdge({
				source: identityNodeId,
				target: oktaUserNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	const accessKeyIssueNodes: BareNodeType<IssueNodeType>[] = []
	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []
	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		if (issue.issueName && accessKeyIssueNames.includes(issue.issueName)) {
			accessKeyIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.accessKeyIssue}-${accessKeyIssueNodes.length}`,
			})
		} else {
			generalIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
			})
		}
	})

	const accessKeyNodes: BareNodeType<AccessKeyNodeType>[] =
		identity.awsIamUser?.awsIamAccessKeysXc?.map((accessKey, index) => ({
			type: 'accessKey',
			data: { accessKey },
			id: `${nodeLogicalTypeToColumnId.accessKey}-${index}`,
		})) || []

	const policies = getCombinedAwsIamRolePolicy(
		identity.awsIamUser?.awsIamUserDetailsXc,
		identity.awsIamRole?.awsIamRoleDetailsXc,
		identity.awsIamRole?.permissionBoundary,
		identity.awsIamUser?.permissionBoundary,
	)

	const policyNodes: BareNodeType<AwsPolicyNodeType>[] = policies.map((policy, index) => ({
		type: 'awsPolicy',
		data: { policy },
		id: `${nodeLogicalTypeToColumnId.policy}-${index}`,
	}))

	const ec2AndK8sNodes: Array<BareNodeType<Ec2InstancesNodeType> | BareNodeType<KubernetesResourcesNodeType>> = []
	if (identity.awsIamRole?.awsEc2InstancesXc?.length) {
		ec2AndK8sNodes.push({
			type: 'ec2Instances',
			data: { instances: identity.awsIamRole.awsEc2InstancesXc },
			id: `${nodeLogicalTypeToColumnId.ec2AndK8s}-${ec2AndK8sNodes.length}`,
		})
	}

	const kubernetesResources: KubernetesResourceXc[] =
		identity.awsIamUser?.kubernetesResourcesXc || identity.awsIamRole?.kubernetesResourcesXc || []
	if (kubernetesResources.length) {
		ec2AndK8sNodes.push({
			type: 'kubernetesResources',
			data: { resources: kubernetesResources },
			id: `${nodeLogicalTypeToColumnId.ec2AndK8s}-${ec2AndK8sNodes.length}`,
		})
	}

	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	accessKeyNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.accessKey}-${index}`
		edges.push(getEdge({ source: source, target: identityNodeId }))
	})

	accessKeyIssueNodes.forEach((issueNode, index) => {
		const source = `${nodeLogicalTypeToColumnId.accessKeyIssue}-${index}`
		if (issueNode.data.issue.issueName === IssueName.MultipleAccessKeys) {
			accessKeyNodes.forEach((_, accessKeyIndex) => {
				const target = `${nodeLogicalTypeToColumnId.accessKey}-${accessKeyIndex}`
				edges.push(getEdge({ source, target }))
			})
		} else {
			const target = `${nodeLogicalTypeToColumnId.accessKey}-${accessKeyNodes.findIndex(
				(accessKeyNode) =>
					!!accessKeyNode.data.accessKey?.accessKeyId &&
					issueNode.data.issue.description?.includes(accessKeyNode.data.accessKey.accessKeyId),
			)}`
			edges.push(getEdge({ source, target }))
		}
	})

	policyNodes.forEach((_, index) => {
		const target = `${nodeLogicalTypeToColumnId.policy}-${index}`
		edges.push(getEdge({ source: identityNodeId, target }))
	})

	ec2AndK8sNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.ec2AndK8s}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	// Each item represents a column in the graph. Order in the array will be the order in the graph (left->right)
	return [
		[
			{ yPosition: 'top', nodes: ec2AndK8sNodes },
			{ yPosition: 'center', nodes: [...accessKeyIssueNodes, ...generalIssueNodes] },
			{ yPosition: generalIssueNodes.length > 0 ? 'top' : 'center', nodes: accessKeyNodes },
			{ yPosition: 'center', nodes: identityNodes },
			{ yPosition: 'center', nodes: policyNodes },
		],
		edges,
	]
}
