import { z } from 'zod'
import { camelizeSchema } from '../utils'
import { ServerAwsIdentityPolicy, ServerAwsTag, ServerManagedPolicy } from './awsPoliciesSchema.ts'
import { DateSchema } from '../common.ts'
import { AccountPriorityEnum } from '../environments/accountPriorityEnum.ts'
import { TagSchemaType } from '../tags.ts'
import { ServerAwsUsageLogSchema } from './awsIamUserSchema.ts'

export const ServerAssumeRolePolicyDocumentSchema = z.object({
	Version: z.string(),
	Statement: z.array(
		z
			.object({
				Sid: z.string().optional(),
				Effect: z.string(),
				Principal: z.any(),
				Action: z.string().or(z.array(z.string())),
			})
			.passthrough(),
	),
})
export const AssumeRolePolicyDocumentSchema = camelizeSchema(
	ServerAssumeRolePolicyDocumentSchema.transform((val) => val),
)
export type AssumeRolePolicyDocument = z.infer<typeof AssumeRolePolicyDocumentSchema>
const ServerAwsRoleLastUsed = z.object({
	LastUsedDate: DateSchema.optional().nullish(),
	Region: z.string().optional().nullish(),
})

export const AwsRoleLastUsed = camelizeSchema(ServerAwsRoleLastUsed.transform((val) => val))
export type AwsRoleLastUsed = z.infer<typeof AwsIamRoleXcSchema>

export const ServerAwsIamRoleXcSchema = z.object({
	Path: z.string(),
	RoleName: z.string(),
	RoleId: z.string(),
	Arn: z.string(),
	AccountName: z.string().nullish(),
	CreateDate: z.string(),
	AssumeRolePolicyDocument: ServerAssumeRolePolicyDocumentSchema.nullish(),
	RolePolicyList: z.array(ServerAwsIdentityPolicy),
	AttachedManagedPolicies: z.array(ServerManagedPolicy),
	InstanceProfileList: z.array(z.record(z.any())),
	RoleLastUsed: AwsRoleLastUsed.nullish(),
	Tags: z.array(ServerAwsTag),
	PermissionsBoundary: z.record(z.any()).nullish(),
	AccountPriority: z.nativeEnum(AccountPriorityEnum).nullish(),
	AccountId: z.string(),
	usageLogs: z.array(ServerAwsUsageLogSchema).nullish(),
})

export type ServerAwsIamRole = z.infer<typeof ServerAwsIamRoleXcSchema>

export const AwsIamRoleXcSchema = camelizeSchema(ServerAwsIamRoleXcSchema.transform((val) => val))
export type AwsIamRoleXc = z.infer<typeof AwsIamRoleXcSchema>

export interface CombinedAwsIamRolePolicy {
	groupName?: string | null
	isAttached?: boolean | null
	isPermissionBoundary?: boolean | null
	policyName?: string | null
	policyArn?: string | null
	policyDocument?: object | null
	policyInfo?: object | null
	customerManaged?: boolean | null
	accountPriority?: AccountPriorityEnum | null
	riskTags?: TagSchemaType[] | null
}
