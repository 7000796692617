import { IssueStatusTag } from '../../common/IssueStatusTag.tsx'
import { useState } from 'react'
import { UpdateIssueStatusModal } from './UpdateIssueStatusModal.tsx'
import { useIssue } from '../../../api/issues.ts'
import { Tag } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export const IssueTitleStatus = ({ issueId }: { issueId: string }) => {
	const { data: issue, isLoading: isIssueLoading, isError: isIssueError } = useIssue(issueId)
	const [isModalOpen, setIsModalOpen] = useState(false)

	if (isIssueError) {
		return null
	}

	if (isIssueLoading) {
		return (
			<Tag className="text-sm px-6">
				<LoadingOutlined />
			</Tag>
		)
	}

	return (
		<div className="flex items-center">
			<IssueStatusTag status={issue.status} onEdit={() => setIsModalOpen(true)} />
			<UpdateIssueStatusModal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} issue={issue} />
		</div>
	)
}
