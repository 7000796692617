import { z } from 'zod'
import { camelizeSchema } from '../../utils.ts'
import { DateSchema } from '../../common.ts'

export const ServerEntraIdUserXcSchema = z
	.object({
		mail: z.string().nullish(),
		given_name: z.string().nullish(),
		job_title: z.string().nullish(),
		user_principal_name: z.string().nullish(),
		entra_user_id: z.string().nullish(),
		last_activity: DateSchema.nullish(),
		password_last_changed: z.string().nullish(),
		account_enabled: z.boolean().nullish(),
	})
	.nullish()

//export the access key schema for use in other schemas
export const EntraIdUserXcSchema = camelizeSchema(ServerEntraIdUserXcSchema.transform((val) => val))
export type EntraIdUserXcSchema = z.infer<typeof EntraIdUserXcSchema>
