import { z } from 'zod'
import { DateSchema } from '../../../common.ts'
import { ServerLifetimeActionSchema } from './commonSchemas.ts'
import { camelizeSchema } from '../../../utils.ts'
import { AzureKeyVaultSchema } from './azureKeyVault.ts'
import { ServerEntraIdChangeLogSchema } from '../../entraId/entraIdChangeLogSchema.ts'

export const ServerRotationPolicySchema = z.object({
	created_at: DateSchema.nullable(),
	updated_at: DateSchema.nullable(),
	expires_in: z.string().nullable(),
	rotation_policy_id: z.string().nullable(),
	lifetime_actions: z.array(ServerLifetimeActionSchema).nullable(),
})

export const ServerAzureKVKeySchema = z.object({
	name: z.string(),
	key_id: z.string(),
	created_at: DateSchema,
	updated_at: DateSchema.nullable(),
	expiration_date: DateSchema.nullable(),
	start_date: DateSchema.nullable(),
	is_enabled: z.boolean(),
	is_managed: z.boolean(),
	tags: z.record(z.string(), z.string()).nullable(),
	vault_name: z.string(),
	vault_id: z.string(),
	key_vault: AzureKeyVaultSchema.nullable(),
	subscription_id: z.string().uuid(),
	rotation_policy: ServerRotationPolicySchema.nullable(),
	change_logs: z.array(ServerEntraIdChangeLogSchema).nullish(),
})

export const AzureKVKeySchema = camelizeSchema(ServerAzureKVKeySchema.transform((val) => val))
export type AzureKVKey = z.infer<typeof AzureKVKeySchema>

export const RotationPolicySchema = camelizeSchema(ServerRotationPolicySchema.transform((val) => val))
export type RotationPolicy = z.infer<typeof RotationPolicySchema>
