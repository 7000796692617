import { EnvironmentType } from '../../../../schemas/envType'
import { Identity } from '../../../../schemas/identity'
import React, { useMemo } from 'react'
import { SnowflakeUser } from '../../../../schemas/identities/snowflake/snowflakeUserSchema.ts'
import { BaseIdentityDetail } from '../baseIdentities/BaseIdentityDetail.tsx'
import { IssueSourceIcon } from '../../../common/IssueSourceIcon.tsx'
import { IdentityTypeTag } from '../../../common/IdentityTypeTag.tsx'
import SuccessIcon from '../../../../assets/success_icon_20.svg?react'
import WarningIcon from '../../../../assets/warning_icon_20.svg?react'
import { formatDate, formatRelativeDateText } from '../../../../utils.ts'
import { Col, Divider, Row, Space, Tooltip } from 'antd'
import { SnowflakeRolesContext } from './SnowflakeRolesContext.tsx'
import { OktaUserContext } from '../OktaUserContext.tsx'
import { EntraIdUserContext } from '../entraId/EntraIdUserContext.tsx'

export const SnowflakeUserIdentity: React.FunctionComponent<{
	identity?: Identity
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const snowflakeUser: SnowflakeUser | null = identity?.snowflakeUser ?? null
	const contextAmount = useMemo<number>(() => {
		let count = 0
		if (snowflakeUser?.snowflakeRolesXc?.length ?? 0 > 0) count++
		if (snowflakeUser?.oktaUserXc) count++
		return count
	}, [snowflakeUser])

	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail label="Name" value={snowflakeUser?.name} span={1} />
				<BaseIdentityDetail label="Snowflake User ID" value={snowflakeUser?.userId} span={1} />
				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Environment" span={1}>
					<div className="flex flex-row items-center space-x-2">
						<IssueSourceIcon source={envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
						<Tooltip placement="topLeft" title={identity?.accountLiteral}>
							<div className="font-normal text-gray-600 items-end truncate">
								{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
							</div>
						</Tooltip>
					</div>
				</BaseIdentityDetail>
				{snowflakeUser?.email && <BaseIdentityDetail label="Email" value={snowflakeUser?.email} span={1} />}

				<BaseIdentityDetail label="Created At" value={formatDate(identity?.createdAt)} span={1} />
				<BaseIdentityDetail
					label="Last Activity"
					value={formatRelativeDateText(identity?.lastActivity, true)}
					tooltipTitle={formatDate(identity?.lastActivity)}
					span={1}
				/>
				{snowflakeUser?.deletedOn && (
					<BaseIdentityDetail label="Deleted On" value={formatDate(snowflakeUser?.deletedOn)} span={1} />
				)}
				<BaseIdentityDetail label="Disabled" span={1}>
					{snowflakeUser?.disabled ? 'True' : 'False'}
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Password Enabled" span={1}>
					{snowflakeUser?.hasPassword ? 'True' : 'False'}
				</BaseIdentityDetail>
				{snowflakeUser?.hasPassword && (
					<BaseIdentityDetail
						label="Last Password changed"
						value={formatRelativeDateText(snowflakeUser?.passwordLastSetTime, true)}
						tooltipTitle={formatDate(snowflakeUser?.passwordLastSetTime)}
						span={1}
					/>
				)}
				{snowflakeUser?.hasPassword && (
					<BaseIdentityDetail label="MFA" span={1}>
						{snowflakeUser?.extAuthnDuo ? (
							<SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
						) : (
							<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
						)}
					</BaseIdentityDetail>
				)}
				<BaseIdentityDetail label="RSA Public Key" span={1}>
					{snowflakeUser?.hasRsaPublicKey ? 'True' : 'False'}
				</BaseIdentityDetail>
				{snowflakeUser?.deletedOn && (
					<BaseIdentityDetail
						label="Deleted On"
						value={formatRelativeDateText(snowflakeUser?.deletedOn, true)}
						tooltipTitle={formatDate(snowflakeUser?.deletedOn)}
						span={1}
					/>
				)}
			</div>
			<div className="w-full">
				{contextAmount === 0 ? null : (
					<div className="w-full">
						<Divider />
						<Row>
							<Col>
								<div className="text-gray-900">Context ({contextAmount})</div>
							</Col>
						</Row>
						{snowflakeUser?.snowflakeRolesXc && (
							<Row className="mt-2">
								<Space className="w-full" direction="vertical">
									<SnowflakeRolesContext snowflakeRoleDetailsXc={snowflakeUser?.snowflakeRolesXc} />
								</Space>
							</Row>
						)}
						{snowflakeUser?.oktaUserXc && (
							<Row className="mt-2">
								<Space className="w-full" direction="vertical">
									<OktaUserContext oktaUserXc={[snowflakeUser?.oktaUserXc]} />
								</Space>
							</Row>
						)}
						{snowflakeUser?.entraIdUserXc && (
							<Row className="mt-2">
								<Space className="w-full" direction="vertical">
									<EntraIdUserContext entraIdUserXc={[snowflakeUser?.entraIdUserXc]} />
								</Space>
							</Row>
						)}
					</div>
				)}
			</div>
		</>
	)
}
