import { AgChartsReact } from 'ag-charts-react'
import { AgChartOptions, AgSeriesNodeClickEvent } from 'ag-charts-enterprise'
import { noDataOverlay, noDataOverlayComponent } from './helpers.tsx'
import { useCallback, useMemo } from 'react'
import { IssueNameMap, IssuesTabName, PriorityType, PriorityTypeMap } from '../schemas/issue.ts'
import { getPriorityColor, getPriorityColorWithOpacity } from '../tables/helpers.ts'
import { FindingDistName } from '../schemas/metrics/findingsDistByName.ts'
import { useNavigate } from '@tanstack/react-router'
import { useFindingsDistByName } from '../api/metrics.ts'
import { Skeleton } from 'antd'
import { themeColors } from '../utils/colorUtils.ts'
import { RoutePaths } from '../routes/RoutePaths.tsx'

export const FindingsDistributionTreeMapChart = () => {
	const navigate = useNavigate()
	const { data: findingsDistByName, isLoading } = useFindingsDistByName()
	const normalizedAmount = useCallback((amount: number) => {
		if (amount < 10) return 0.5
		return Math.min(10, Math.floor(amount / 10))
	}, [])

	const normalizedData = useMemo(() => {
		if (!findingsDistByName) return []
		const findings = findingsDistByName
			?.map((f) => ({
				...f,
				originalIssueName: f.issueName,
				originalAmount: f.amount,
				amount: normalizedAmount(f.amount),
				issueName: IssueNameMap[f.issueName],
				maxPriority: PriorityType[f.maxPriority],
			}))
			.filter((f) => (f.maxPriority as number) > 0)
		const findingsGroupedByPriority: {
			originalMaxPriority: number
			issueName: string
			children: {
				originalIssueName: string
				originalAmount: number
				issueName: string
				maxPriority: PriorityType
				amount: number
			}[]
		}[] = []
		for (const finding of findings) {
			const priority = finding.maxPriority
			const maxPriorityExists = findingsGroupedByPriority.find(
				(f) => f.originalMaxPriority === (finding.maxPriority as number),
			)
			if (!maxPriorityExists) {
				findingsGroupedByPriority.push({
					originalMaxPriority: finding.maxPriority,
					// its issueName because the treemap will use this key to display the label,
					// and it needs to be the max priority
					issueName: PriorityTypeMap[priority],
					children: [finding],
				})
			} else {
				maxPriorityExists.children.push(finding)
			}
		}
		return findingsGroupedByPriority
	}, [findingsDistByName])

	const colorRangeNormalized = useMemo(() => {
		if (!normalizedData) return []
		const colors: string[] = []
		const colorNums: number[] = []
		for (const f of normalizedData) {
			for (const c of f.children) {
				if ((c.maxPriority as number) === -1) continue
				const colorNumExists = colorNums.find((num) => num === (c.maxPriority as number))
				if (!colorNumExists) {
					colorNums.push(c.maxPriority)
				}
			}
		}
		for (const colorNum of colorNums.sort((a, b) => a - b)) {
			colors.push(getPriorityColorWithOpacity(colorNum))
		}
		return [...new Set(colors)]
	}, [normalizedData])

	if (isLoading) {
		return <Skeleton active />
	}

	const options: AgChartOptions = {
		data: normalizedData,
		background: {
			fill: themeColors.neutral[100],
		},
		listeners: {
			seriesNodeClick: (event: AgSeriesNodeClickEvent<{ originalIssueName: string }>) => {
				if (!event.datum.originalIssueName) return
				void navigate({
					to: RoutePaths.Findings,
					search: { issueName: event.datum.originalIssueName, tabName: IssuesTabName.OPEN },
				})
			},
		},
		series: [
			{
				type: 'treemap',
				labelKey: 'issueName',
				group: {
					label: {
						fontSize: 10,
					},
					padding: 4,
					gap: 2,
					fill: themeColors.neutral[100],
					stroke: themeColors.neutral[100],
				},
				tooltip: {
					renderer: (params) => {
						const datum = params.datum as FindingDistName
						if (!datum) {
							return { title: 'No data', content: 'No details available' }
						}

						// Check if maxPriorityKey is a valid key of PriorityTypeMap
						const priorityValue =
							typeof datum.maxPriority === 'number'
								? PriorityTypeMap[datum.maxPriority as PriorityType]
								: null
						if (!priorityValue) {
							return {}
						}

						return {
							title: datum.issueName,
							content: `Amount: ${datum.originalAmount}<br>Priority: ${priorityValue}`,
							backgroundColor: getPriorityColor(datum.maxPriority as unknown as PriorityType),
						}
					},
				},
				secondaryLabelKey: 'originalAmount',
				sizeName: 'Amount',
				sizeKey: 'amount',
				colorName: 'Priority',
				colorKey: 'maxPriority',
				colorRange: colorRangeNormalized,
				tile: {
					strokeWidth: 1,
					stroke: '#E5E7EB',
					gap: 4,
					label: {
						fontSize: 24,
					},
					secondaryLabel: {
						formatter: (params) => {
							const datum = params.datum as FindingDistName
							if (!datum || !datum.originalAmount) {
								return 'No data'
							}
							return `${datum?.originalAmount > 100 ? '100+' : datum.originalAmount}`
						},
						fontSize: 16,
					},
				},
			},
		],
		gradientLegend: {
			enabled: false,
		},
		overlays: {
			noData: {
				renderer: noDataOverlay,
			},
		},
	}

	return normalizedData ? (
		<AgChartsReact options={options} />
	) : (
		<div className="flex justify-center items-center h-full">{noDataOverlayComponent()}</div>
	)
}
