import { z } from 'zod'
import { camelizeSchema } from '../utils'
import { DateSchema } from '../common'
import { ServerAwsIamRoleXcSchema } from './awsIamRoleXcSchema.ts'
import { ServerGoogleWorkspaceUserXcSchema } from './googleWorkspaceUserXcSchema.ts'
import { ServerAwsIamUserPermissionXcSchema } from './awsIamUserXcSchema.ts'
import { ServerAwsUsageLogSchema } from './awsIamUserSchema.ts'

export const ServerOktaUserSchema = z.object({
	id: z.string(),
	email: z.string(),
	password_changed: DateSchema.nullish(),
	okta_user_id: z.string().nullish(),
	activated: DateSchema.nullish(),
	created_at: DateSchema.nullish(),
	updated_at: DateSchema.nullish(),
	last_activity: DateSchema.nullish(),
	name: z.string(),
	aws_iam_roles_xc: z.array(ServerAwsIamRoleXcSchema).nullish(),
	aws_iam_users_xc: z.array(ServerAwsIamUserPermissionXcSchema).nullish(),
	google_workspace_user_xc: ServerGoogleWorkspaceUserXcSchema.nullish(),
	linkedin_xc: z.any().nullish(),
	hibp_xc: z.any().nullish(),
	user_status: z.string().nullish(),
	demo_usage_logs: z.array(ServerAwsUsageLogSchema).nullish(),
})

export type ServerOktaUser = z.infer<typeof ServerOktaUserSchema>

export const OktaUserSchema = camelizeSchema(ServerOktaUserSchema.transform((val) => val))
export type OktaUserSchema = z.infer<typeof OktaUserSchema>
