import { Layout, Skeleton, Tooltip } from 'antd'
import { ResolvedVsUnresolvedFindingsChart } from '../../../charts/ResolvedVsUnresolvedFindingsChart.tsx'
import { PriorityBar } from '../../Findings/stats/PriorityBar.tsx'
import { formatDate, formatDaysAgo, formatDuration } from '../../../utils.ts'
import { MetricsDescription } from '../helpers.tsx'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useMemo } from 'react'
import { PriorityTypeEnum } from '../../../schemas/issue.ts'
import { useFindingsByPriority, useMetricsSummary, useMetricsTopBar } from '../../../api/metrics.ts'
import { StatItem } from '../../../components/common/StatItem.tsx'
import { useEntitlementToMetricsPageV2 } from '../../../services/auth/hooks.ts'
import { IdentityPosture } from './IdentityPosture.tsx'
import { TotalIdentitiesAtRisk } from './TotalIdentitiesAtRisk.tsx'
import { TotalAdminsAtRisk } from './TotalAdminsAtRisk.tsx'
import { formatNumber } from '../../../utils/numberUtils.ts'
import { useNavigate } from '@tanstack/react-router'
import { RoutePaths } from '../../RoutePaths.tsx'

const MetricsStatsHeaderContentV2 = () => {
	const { data: metricsTopBar } = useMetricsTopBar()
	const navigate = useNavigate({ from: RoutePaths.Metrics })
	if (!metricsTopBar) {
		return <Skeleton active />
	}
	return (
		<>
			<StatItem title="Identity Posture">
				<IdentityPosture postureScore={metricsTopBar.identityPostureScore} />
			</StatItem>
			<StatItem title="Total Identities">
				{
					<span
						className="cursor-pointer"
						onClick={() => {
							void navigate({ to: RoutePaths.Identities })
						}}
					>
						{formatNumber(metricsTopBar.totalIdentities)}
					</span>
				}
			</StatItem>
			<StatItem title="Total Identities at Risk">
				<TotalIdentitiesAtRisk metricsTopBar={metricsTopBar} />
			</StatItem>
			<StatItem title="Total Admins at Risk">
				<TotalAdminsAtRisk metricsTopBar={metricsTopBar} />
			</StatItem>
		</>
	)
}

const MetricsStatsHeaderContent = () => {
	const { data: metricsSummary } = useMetricsSummary()
	const { data: findingsByPriority } = useFindingsByPriority()

	const normalizedFindingsByPriority = useMemo(() => {
		return findingsByPriority?.filter((f) => f.priority !== PriorityTypeEnum.NOT_CALCULATED)
	}, [findingsByPriority])
	return (
		<>
			<StatItem title="Last Scan">
				<Tooltip title={formatDate(metricsSummary?.lastScan, false)}>
					{formatDaysAgo(metricsSummary?.lastScan)}
				</Tooltip>
			</StatItem>
			<StatItem title="Total Environments">
				<div>{metricsSummary?.totalEnvironments ?? 0}</div>
			</StatItem>
			<StatItem title="Total Identities">
				<div className="flex flex-row items-center space-x-2">
					<div>{metricsSummary?.totalIdentities ?? 0}</div>
				</div>
			</StatItem>
			<StatItem
				compact
				title={
					<Tooltip placement="bottom" title={MetricsDescription.ResolvedVsUnresolvedChart}>
						<div className="flex flex-row space-x-2 w-max">
							<div>Resolved Vs Unresolved</div>
							<QuestionCircleOutlined />
						</div>
					</Tooltip>
				}
			>
				<ResolvedVsUnresolvedFindingsChart />
			</StatItem>
			<StatItem title="Findings By Priority">
				<PriorityBar findingsByPriority={normalizedFindingsByPriority} />
			</StatItem>
			<StatItem title="Avg Time To Resolve">
				<div className="flex flex-row items-center space-x-2">
					<div>{formatDuration(metricsSummary?.timeToResolve)}</div>
				</div>
			</StatItem>
		</>
	)
}

export const MetricsStatsHeader = () => {
	const { isEntitled: isEntitledToMetricsPageV2 } = useEntitlementToMetricsPageV2()
	const headerStyle = {
		boxShadow: '0px 0px 20px 0px #8B90AC1A',
	}
	return (
		<Layout.Header
			style={headerStyle}
			className="bg-white border-y border-neutral-200 flex justify-between min-h-[130px] gap-2 max-w-full h-fit py-4 px-8 items-stretch"
		>
			{isEntitledToMetricsPageV2 ? <MetricsStatsHeaderContentV2 /> : <MetricsStatsHeaderContent />}
		</Layout.Header>
	)
}
