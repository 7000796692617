import { Collapse } from 'antd'
import { IssueSourceIcon } from '../../common/IssueSourceIcon'
import { ResourceType } from '../../../schemas/issue'
import React from 'react'
import { Ec2InstancesTable } from '../../../tables/Ec2InstancesTable.tsx'
import { AwsEc2InstanceXc } from '../../../schemas/identities/awsEc2InstanceXcSchema.ts'

export const AwsEc2InstanceContext: React.FunctionComponent<{
	awsEc2InstancesXc: AwsEc2InstanceXc[]
}> = ({ awsEc2InstancesXc }) => {
	return (
		<>
			<Collapse expandIconPosition={'end'}>
				<Collapse.Panel
					header={
						<div className="flex items-center">
							<IssueSourceIcon source={ResourceType.AWS} />
							<div>EC2 Instances</div>
						</div>
					}
					key="ec2Instances"
				>
					<Ec2InstancesTable awsEc2InstancesXc={awsEc2InstancesXc} />
				</Collapse.Panel>
			</Collapse>
		</>
	)
}
