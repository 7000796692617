import { EntraIdChangeLog, EntraIdChangeLogsAggregated } from '../schemas/identities/entraId/entraIdChangeLogSchema'

export const aggregateEntraIdChangeLogs = (changeLogs: EntraIdChangeLog[]): EntraIdChangeLogsAggregated[] => {
	const aggregatedMap = changeLogs.reduce(
		(acc, entry) => {
			const key = `${entry.actorPrincipalId}`
			const existing = acc[key]
			if (existing) {
				existing.firstEventTime =
					existing.firstEventTime < new Date(entry.eventTime)
						? existing.firstEventTime
						: new Date(entry.eventTime)
				existing.lastEventTime =
					existing.lastEventTime > new Date(entry.eventTime)
						? existing.lastEventTime
						: new Date(entry.eventTime)
				existing.operationCount += 1
				if (!existing.sourceIpAddresses.includes(entry.sourceIpAddress || '')) {
					existing.sourceIpAddresses.push(entry.sourceIpAddress || '')
				}
				if (!existing.operations.includes(entry.operationName)) {
					existing.operations.push(entry.operationName)
				}
			} else {
				acc[key] = {
					actorPrincipalId: key,
					actorPrincipalName: entry.actorPrincipalName || '',
					operations: [entry.operationName],
					sourceIpAddresses: [entry.sourceIpAddress || ''],
					firstEventTime: new Date(entry.eventTime),
					lastEventTime: new Date(entry.eventTime),
					operationCount: 1,
				}
			}
			return acc
		},
		{} as Record<string, EntraIdChangeLogsAggregated>,
	)

	return Object.values(aggregatedMap)
}
