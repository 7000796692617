import { issuePrioritySorter } from '../../../../../utils/issueUtils.ts'
import { IssueNodeType } from '../../common/IssueNode.tsx'
import { BareNodesColumnsType, BareNodeType } from '../../identityGraphTypes.ts'
import { IdentityNodeType } from '../../common/IdentityNode.tsx'
import { Identity } from '../../../../../schemas/identity.ts'
import { Edge } from '@xyflow/react'
import { getEdge } from '../../graphUtils/nodesAndEdges.ts'

const nodeLogicalTypeToColumnId = {
	generalIssue: 0,
	identity: 1,
}

const identityNodeRowIndex = 0
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`

export const getAzureDevOpsNodesAndEdges = (identity: Identity): [BareNodesColumnsType[], Edge[]] => {
	const edges: Edge[] = []
	const identityNodes: Array<BareNodeType<IdentityNodeType>> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []

	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		generalIssueNodes.push({
			type: 'issue',
			data: { issue },
			id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
		})
	})

	// Add edges for general issues
	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	const nodes: BareNodesColumnsType[] = [
		{ yPosition: 'top', nodes: generalIssueNodes },
		{ yPosition: 'center', nodes: identityNodes },
	]

	return [nodes, edges]
}
