import { Edge } from '@xyflow/react'
import { IdentityNodeType } from '../common/IdentityNode.tsx'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { Identity } from '../../../../schemas/identity.ts'
import { getEdge } from '../graphUtils/nodesAndEdges.ts'
import { GcpProjectNodeType } from './GcpProjectNode.tsx'
import { GcpRoleNodeType } from './GcpRoleNode.tsx'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { IssueName } from '../../../../schemas/issue.ts'
import { GcpAccessKeyNodeType } from './GcpSaAccessKeyNode.tsx'
import { KubernetesResourceXc } from '../../../../schemas/identities/kubernetes/kubernetesResourceXcSchema.ts'
import { KubernetesResourcesNodeType } from '../common/KubernetesResourcesNode.tsx'
import { BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { OwnershipNodeType } from '../common/OwnershipNode.tsx'
import { AwsIamChangeLog } from '../../../../schemas/identities/awsIamChangeLogSchema.ts'
import { aggregateAwsIamChangeLogs } from '../../../../utils/awsIdentityUtils.ts'
import { getActorArnFriendlyName } from '../../../../components/drawers/identities/utils.tsx'

const nodeLogicalTypeToColumnId = {
	vmsAndK8s: 0,
	generalIssue: 1,
	identity: 2,
	accessKey: 3,
	accessKeyIssue: 4,
	project: 5,
	role: 6,
}

const ownerNodeRowIndex = 0
const ownerNodeId = `${nodeLogicalTypeToColumnId.identity}-${ownerNodeRowIndex}`
const identityNodeRowIndex = 1
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`

const accessKeyIssueNames: IssueName[] = [
	IssueName.MultipleAccessKeys,
	IssueName.AccessKeyNotRotated,
	IssueName.ServiceAccountKeyReferencedInPod,
]

export const getGcpNodesAndEdges = (identity: Identity): [BareNodesColumnsType[], Edge[]] => {
	const edges: Edge[] = []
	const identityNodes: Array<BareNodeType<IdentityNodeType> | BareNodeType<OwnershipNodeType>> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	const changeLogs: AwsIamChangeLog[] = identity.gcpServiceAccount?.changeLogs || []
	if (changeLogs?.length) {
		const aggregatedChangeLogs = aggregateAwsIamChangeLogs(changeLogs)
		identityNodes.unshift({
			type: 'ownership',
			data: {
				owners: aggregatedChangeLogs.map((aggChangeLog) => ({
					id: aggChangeLog.actorArn,
					name: getActorArnFriendlyName(aggChangeLog.actorArn),
				})),
			},
			id: ownerNodeId,
		})

		edges.push(
			getEdge({
				source: ownerNodeId,
				target: identityNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	const accessKeyIssueNodes: BareNodeType<IssueNodeType>[] = []
	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []
	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		if (issue.issueName && accessKeyIssueNames.includes(issue.issueName)) {
			accessKeyIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.accessKeyIssue}-${accessKeyIssueNodes.length}`,
			})
		} else {
			generalIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
			})
		}
	})
	const vmsAndK8sNodes: Array<BareNodeType<KubernetesResourcesNodeType>> = []
	const kubernetesResources: KubernetesResourceXc[] = identity.gcpServiceAccount?.kubernetesResourcesXc || []
	if (kubernetesResources.length) {
		vmsAndK8sNodes.push({
			type: 'kubernetesResources',
			data: { resources: kubernetesResources },
			id: `${nodeLogicalTypeToColumnId.vmsAndK8s}-${vmsAndK8sNodes.length}`,
		})
	}
	const accessKeyNodes: BareNodeType<GcpAccessKeyNodeType>[] =
		identity.gcpServiceAccount?.serviceAccountKeysXc?.map((accessKey, index) => ({
			type: 'gcpAccessKey',
			data: { accessKey },
			id: `${nodeLogicalTypeToColumnId.accessKey}-${index}`,
		})) || []

	const gcpProjectNodes: Array<BareNodeType<GcpProjectNodeType>> = []
	const serviceAccountProjectsRolesXc = identity.gcpServiceAccount?.serviceAccountProjectsRolesXc
	serviceAccountProjectsRolesXc?.forEach((principalInfoXc, index) => {
		gcpProjectNodes.push({
			type: 'gcpProject',
			data: { principalInfoXc },
			id: `${nodeLogicalTypeToColumnId.project}-${index}`,
		})
	})

	const gcpRoleNodes: BareNodeType<GcpRoleNodeType>[] =
		identity.gcpServiceAccount?.serviceAccountProjectsRolesXc?.reduce(
			(acc, principalInfoXc) => {
				principalInfoXc.roles.forEach((role) => {
					if (!acc.seenRoles.has(role)) {
						acc.seenRoles.add(role)
						acc.gcpRoleNodes.push({
							type: 'gcpRole',
							data: { role: role },
							id: `${nodeLogicalTypeToColumnId.role}-${acc.gcpRoleNodes.length}`,
						})
					}
				})
				return acc
			},
			{
				gcpRoleNodes: [] as BareNodeType<GcpRoleNodeType>[],
				seenRoles: new Set<string>(),
			},
		).gcpRoleNodes || []

	vmsAndK8sNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.vmsAndK8s}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	accessKeyNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.accessKey}-${index}`
		edges.push(getEdge({ source: source, target: identityNodeId }))
	})

	gcpProjectNodes.forEach((_, index) => {
		const target = `${nodeLogicalTypeToColumnId.project}-${index}`
		edges.push(getEdge({ source: identityNodeId, target }))
	})

	gcpRoleNodes.forEach((_, index) => {
		const target = `${nodeLogicalTypeToColumnId.role}-${index}`
		gcpProjectNodes.forEach((gcpProjectNode) => {
			edges.push(getEdge({ source: gcpProjectNode.id, target }))
		})
	})

	accessKeyIssueNodes.forEach((issueNode, index) => {
		const source = `${nodeLogicalTypeToColumnId.accessKeyIssue}-${index}`
		if (issueNode.data.issue.issueName === IssueName.MultipleAccessKeys) {
			accessKeyNodes.forEach((_, accessKeyIndex) => {
				const target = `${nodeLogicalTypeToColumnId.accessKey}-${accessKeyIndex}`
				edges.push(getEdge({ source, target }))
			})
		} else {
			const target = `${nodeLogicalTypeToColumnId.accessKey}-${accessKeyNodes.findIndex(
				(accessKeyNode) =>
					!!accessKeyNode.data.accessKey?.accessKeyId &&
					issueNode.data.issue.description?.includes(accessKeyNode.data.accessKey?.accessKeyId),
			)}`
			edges.push(getEdge({ source, target }))
		}
	})
	const nodes: BareNodesColumnsType[] = [
		{ yPosition: 'top', nodes: vmsAndK8sNodes },
		{ yPosition: 'center', nodes: [...accessKeyIssueNodes, ...generalIssueNodes] },
		{ yPosition: generalIssueNodes.length > 0 ? 'top' : 'center', nodes: accessKeyNodes },
		{ yPosition: 'center', nodes: identityNodes },
		{ yPosition: 'center', nodes: gcpProjectNodes },
		{ yPosition: 'center', nodes: gcpRoleNodes },
	]
	return [nodes, edges]
}
