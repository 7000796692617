import { Collapse } from 'antd'

import { ContextPanelHeader } from '../ContextPanelHeader.tsx'
import { IdentitySource } from '../../../../schemas/identity.ts'
import { EntraIdUserXcSchema } from '../../../../schemas/identities/entraId/entraIdUserXcSchema.ts'
import { EntraIdUserXcTable } from '../../../../tables/EntraIdUserXcTable.tsx'

export const EntraIdUserContext: React.FunctionComponent<{
	entraIdUserXc?: EntraIdUserXcSchema[] | null
}> = ({ entraIdUserXc }) => {
	return (
		<>
			<Collapse expandIconPosition={'end'}>
				<Collapse.Panel
					header={<ContextPanelHeader source={IdentitySource.ENTRA_ID_USER} />}
					key={IdentitySource.ENTRA_ID_USER}
				>
					<EntraIdUserXcTable data={entraIdUserXc!} />
				</Collapse.Panel>
			</Collapse>
		</>
	)
}
