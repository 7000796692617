import { Node, NodeProps } from '@xyflow/react'
import { FileTextOutlined } from '@ant-design/icons'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import { CombinedAwsIamRolePolicy } from '../../../../schemas/identities/awsIamRoleXcSchema.ts'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import cx from 'classnames'
import { RiskNodeTagIcons } from '../common/RiskNodeTagIcons.tsx'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { NodeGroupBadge } from '../common/NodeGroupBadge.tsx'
import { TagSchemaType } from '../../../../schemas/tags.ts'
import { getPolicyUniqueKey } from '../../../../utils/awsIdentityUtils.ts'

export type AwsPoliciesNodeType = Node<{ policies: CombinedAwsIamRolePolicy[] }, 'awsPolicies'>
type PoliciesNodeProps = NodeProps<AwsPoliciesNodeType>

export const AwsPoliciesNode = ({ data: { policies } }: PoliciesNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'awsPolicy')

	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
		{
			[nodeColorClassNames.permission.bg]: !isHighlighted,
			[nodeColorClassNames.permission.bgHighlighted]: isHighlighted,
		},
	)

	const badgeTooltipContent = policies.map((policy) => (
		<span key={getPolicyUniqueKey(policy)}>{policy.policyName || 'Unknown policy'}</span>
	))

	const uniqueRiskTags = policies.reduce((tags, policy) => {
		policy.riskTags?.forEach((riskTag) => {
			if (tags.find((tag) => tag.name === riskTag.name)) {
				return
			}

			tags.push(riskTag)
		})

		return tags
	}, [] as TagSchemaType[])

	return (
		<BaseNode type="AWS Policies">
			<NodeGroupBadge
				items={policies}
				nodeColorType="permission"
				tooltipContent={badgeTooltipContent}
				positionOnLeft
			/>
			<RiskNodeTagIcons tags={uniqueRiskTags} />
			<div className={iconWrapperClassName}>
				<FileTextOutlined />
			</div>
		</BaseNode>
	)
}
