import { api } from './api.ts'
import { ResolvedVsUnresolvedSchema, ServerResolvedVsUnresolved } from '../schemas/metrics/resolvedVsUnresolved.ts'
import { FindingByPrioritySchema, ServerFindingByPriority } from '../schemas/metrics/findingsByPriority.ts'
import { FindingDistNameSchema, ServerFindingDistName } from '../schemas/metrics/findingsDistByName.ts'
import { FindingDistEnv, FindingDistEnvSchema, ServerFindingDistEnv } from '../schemas/metrics/findingsDistByEnv.ts'
import { IdentitiesByEnv, IdentitiesByEnvSchema, ServerIdentitiesByEnv } from '../schemas/metrics/identitiesByEnv.ts'
import {
	IdentitiesDistByPriorityAndDate,
	IdentitiesDistByPriorityAndDateSchema,
	IdentitiesDistByPrioritySchema,
	ServerIdentitiesDistByPriority,
	ServerIdentitiesDistByPriorityAndDate,
} from '../schemas/metrics/identitiesDistByPriority.ts'
import {
	ServerTopIssuesAndInsights,
	TopIssuesAndInsights,
	TopIssuesAndInsightsSchema,
} from '../schemas/metrics/topIssuesAndInsights.ts'
import { MetricsSummarySchema, ServerMetricsSummary } from '../schemas/metrics/metricsSummary.ts'
import {
	MostWantedIdentities,
	MostWantedIdentitiesSchema,
	ServerMostWantedIdentities,
} from '../schemas/metrics/mostWantedIdentities.ts'
import { useQuery } from '@tanstack/react-query'
import {
	DistributionData,
	DistributionDataSchema,
	ServerDistributionData,
} from '../schemas/metrics/distributionData.ts'
import { MetricsTopBarSchema, ServerMetricsTopBar } from '../schemas/metrics/metricsTopBar.ts'
import {
	EnvTypeOrOtherIdentitiesCount,
	EnvTypeOrOther,
	ServerEnvTypeIdentitiesCount,
	EnvTypeIdentitiesCountSchema,
	EnvTypeIdentitiesCount,
	OtherEnvironmentType,
} from '../schemas/metrics/envTypeIdentitiesCount.ts'
import { IssueName } from '../schemas/issue.ts'
import { EnvironmentType } from '../schemas/envType.ts'

const metricsApi = {
	async resolvedVsUnresolvedFindings() {
		const { data: resolvedVsUnResolved } = await api.get<ServerResolvedVsUnresolved[]>(
			`/api/metrics/resolved-vs-unresolved-findings`,
		)
		return resolvedVsUnResolved.map((item) => ResolvedVsUnresolvedSchema.parse(item))
	},
	async findingsByPriority() {
		const { data: findingsByPriority } = await api.get<ServerFindingByPriority[]>(
			`/api/metrics/findings-by-priority`,
		)
		return findingsByPriority.map((item) => FindingByPrioritySchema.parse(item))
	},
	async findingsDistByName() {
		const { data: findingsDistByName } = await api.get<ServerFindingDistName[]>(
			`/api/metrics/findings-dist-by-name`,
		)
		return findingsDistByName.map((item) => FindingDistNameSchema.parse(item))
	},

	async findingsDistByEnv(): Promise<FindingDistEnv[]> {
		const { data: findingsDistByName } = await api.get<ServerFindingDistEnv[]>(`/api/metrics/findings-dist-by-env`)
		return findingsDistByName.map((item) => FindingDistEnvSchema.parse(item))
	},

	async monitoredIdentitiesByEnv(): Promise<IdentitiesByEnv[]> {
		const { data: identitiesByEnv } = await api.get<ServerIdentitiesByEnv[]>(
			`/api/metrics/monitored-identities-by-env`,
		)
		return identitiesByEnv.map((item) => IdentitiesByEnvSchema.parse(item))
	},

	async identitiesDistByPriority() {
		const { data: identitiesDistByPriority } = await api.get<ServerIdentitiesDistByPriority[]>(
			`/api/metrics/identities-dist-by-priority`,
		)
		return identitiesDistByPriority.map((item) => IdentitiesDistByPrioritySchema.parse(item))
	},
	async topIssuesAndInsights(): Promise<TopIssuesAndInsights | undefined> {
		try {
			const { data: top } = await api.get<ServerTopIssuesAndInsights>(`/api/metrics/top-issues-and-insights`)

			return TopIssuesAndInsightsSchema.parse(top)
		} catch (error) {
			console.error(error)
			return
		}
	},
	async mostWantedIdentities(limit: number): Promise<MostWantedIdentities> {
		const { data: top } = await api.get<ServerMostWantedIdentities>(
			`/api/metrics/most-wanted-identities?limit=${limit}`,
		)
		return MostWantedIdentitiesSchema.parse(top)
	},
	async summary() {
		try {
			const { data: summary } = await api.get<ServerMetricsSummary>(`/api/metrics/summary`)

			return MetricsSummarySchema.parse(summary)
		} catch (error) {
			console.error(error)
			return
		}
	},
	async topBar() {
		try {
			const { data: summary } = await api.get<ServerMetricsTopBar>(`/api/metrics/top-bar`)

			return MetricsTopBarSchema.parse(summary)
		} catch (error) {
			console.error(error)
			return
		}
	},
	async keyRotation(): Promise<DistributionData> {
		const { data: keyRotation } = await api.get<ServerDistributionData>(`/api/metrics/key-rotation`)
		return DistributionDataSchema.parse(keyRotation)
	},
	async identitiesLifetimeDistribution(): Promise<DistributionData> {
		const { data: lifecycleDistribution } = await api.get<ServerDistributionData>(
			`/api/metrics/identities-lifetime-distribution`,
		)
		return DistributionDataSchema.parse(lifecycleDistribution)
	},
	async identitiesDistByPriorityAndDate(): Promise<IdentitiesDistByPriorityAndDate[]> {
		const { data: identitiesDistByPriorityAndDate } = await api.get<ServerIdentitiesDistByPriorityAndDate[]>(
			`/api/metrics/identities-dist-by-priority-and-date`,
		)
		return identitiesDistByPriorityAndDate.map((item) => IdentitiesDistByPriorityAndDateSchema.parse(item))
	},
	async identitiesWithIssueEnvTypeDistribution(issueName: IssueName): Promise<EnvTypeIdentitiesCount[]> {
		const { data } = await api.get<ServerEnvTypeIdentitiesCount[]>(
			`/api/metrics/identities-with-issue-env-type-distribution?issue_name=${issueName}`,
		)
		return data.map((datum) => EnvTypeIdentitiesCountSchema.parse(datum))
	},
}

export function useResolvedVsUnresolvedMetrics() {
	return useQuery({
		queryKey: ['resolvedVsUnresolvedMetrics'],
		queryFn: () => metricsApi.resolvedVsUnresolvedFindings(),
	})
}

export function useFindingsByPriority() {
	return useQuery({
		queryKey: ['findingsByPriority'],
		queryFn: () => metricsApi.findingsByPriority(),
	})
}

export function useFindingsDistByName() {
	return useQuery({
		queryKey: ['findingsDistByName'],
		queryFn: () => metricsApi.findingsDistByName(),
	})
}

export function useFindingsDistByEnv() {
	return useQuery({
		queryKey: ['findingsDistByEnv'],
		queryFn: () => metricsApi.findingsDistByEnv(),
	})
}

export function useIdentitiesByEnv() {
	return useQuery({
		queryKey: ['identitiesByEnv'],
		queryFn: () => metricsApi.monitoredIdentitiesByEnv(),
	})
}

export function useIdentitiesDistByPriority() {
	return useQuery({
		queryKey: ['identitiesDistByPriority'],
		queryFn: () => metricsApi.identitiesDistByPriority(),
	})
}

export function useTopIssuesAndInsights() {
	return useQuery({
		queryKey: ['topIssuesAndInsights'],
		queryFn: () => metricsApi.topIssuesAndInsights(),
	})
}

export function useMostWantedIdentities(limit = 10) {
	return useQuery({
		queryKey: ['mostWantedIdentities', limit],
		queryFn: () => metricsApi.mostWantedIdentities(limit),
	})
}

export function useMetricsSummary() {
	return useQuery({
		queryKey: ['metricsSummary'],
		queryFn: () => metricsApi.summary(),
	})
}

export function useMetricsTopBar() {
	return useQuery({
		queryKey: ['metricsTopBar'],
		queryFn: () => metricsApi.topBar(),
	})
}

export function useKeyRotation() {
	return useQuery({
		queryKey: ['keyRotation'],
		queryFn: () => metricsApi.keyRotation(),
	})
}

export function useIdentitiesLifetimeDistribution() {
	return useQuery({
		queryKey: ['identitiesLifetimeDistribution'],
		queryFn: () => metricsApi.identitiesLifetimeDistribution(),
	})
}

export function useIdentitiesDistByPriorityAndDate() {
	return useQuery({
		queryKey: ['identitiesDistByPriorityAndDate'],
		queryFn: () => metricsApi.identitiesDistByPriorityAndDate(),
	})
}

export function useIdentitiesWithIssueEnvTypeDistribution(issueName: IssueName) {
	return useQuery({
		queryKey: ['identitiesWithIssueEnvTypeDistribution', issueName],
		queryFn: () => metricsApi.identitiesWithIssueEnvTypeDistribution(issueName),
		select: (data): EnvTypeOrOtherIdentitiesCount[] => {
			if (data.length <= 4) {
				return data
			}

			// We compress the data from N environment types to 3 environment types with the most identities and an
			// "Other" environment type that aggregates the rest of the environment types
			const topEnvTypes: EnvironmentType[] = data
				.sort((a, b) => b.totalCount - a.totalCount)
				.slice(0, 3)
				.map((datum) => datum.envType)

			const selectedData: Partial<Record<EnvTypeOrOther, EnvTypeOrOtherIdentitiesCount>> = data.reduce(
				(acc, datum) => {
					if (topEnvTypes.includes(datum.envType)) {
						return { ...acc, [datum.envType]: datum }
					}

					const totalCount = (acc[OtherEnvironmentType.OTHER]?.totalCount ?? 0) + datum.totalCount
					const adminCount = (acc[OtherEnvironmentType.OTHER]?.adminCount ?? 0) + datum.adminCount
					return {
						...acc,
						[OtherEnvironmentType.OTHER]: { envType: OtherEnvironmentType.OTHER, totalCount, adminCount },
					}
				},
				{} as Partial<Record<EnvTypeOrOther, EnvTypeOrOtherIdentitiesCount>>,
			)

			return Object.values(selectedData)
		},
	})
}
