import { EnvironmentType } from '../../../../schemas/envType'
import { Identity } from '../../../../schemas/identity'
import SuccessIcon from '../../../../assets/success_icon_20.svg?react'
import WarningIcon from '../../../../assets/warning_icon_20.svg?react'
import React, { useMemo } from 'react'

import { BaseIdentityDetail } from '../baseIdentities/BaseIdentityDetail.tsx'
import { IssueSourceIcon } from '../../../common/IssueSourceIcon.tsx'
import { IdentityTypeTag } from '../../../common/IdentityTypeTag.tsx'
import { formatDate, formatRelativeDateText } from '../../../../utils.ts'
import { DatabricksUser } from '../../../../schemas/identities/databricks/databricksUserSchema.ts'
import { Col, Collapse, Divider, Row, Space } from 'antd'
import { DatabricksPATsContext } from './DatabricksPATsContext.tsx'
import { DatabricksWorkspaceContext } from './DatabricksWorkspaceContext.tsx'
import { DatabricksRolesContext } from './DatabricksRolesContext.tsx'
import { GithubReposTable } from '../github/GithubReposTable.tsx'
import { ResourceType } from '../../../../schemas/issue.ts'

export const DatabricksUserIdentity: React.FunctionComponent<{
	identity?: Identity
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const databricksUser: DatabricksUser | null = identity?.databricksUser ?? null
	const contextAmount = useMemo<number>(() => {
		let count = 0
		if (databricksUser?.workspaces?.length ?? 0 > 0) count++
		if (databricksUser?.roles?.length ?? 0 > 0) count++
		return count
	}, [databricksUser])
	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail label="Name" value={databricksUser?.username} span={1} />

				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Environment" span={1}>
					<IssueSourceIcon source={envType} />
				</BaseIdentityDetail>
				{databricksUser?.email && <BaseIdentityDetail label="Email" value={databricksUser?.email} span={1} />}

				<BaseIdentityDetail label="Created At" value={formatDate(identity?.createdAt)} span={1} />
				<BaseIdentityDetail label="Admin" span={1}>
					{databricksUser?.admin ? (
						<SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
					) : (
						<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
					)}
				</BaseIdentityDetail>
				<BaseIdentityDetail
					label="Last Activity"
					value={formatRelativeDateText(identity?.lastActivity, true)}
					tooltipTitle={formatDate(identity?.lastActivity)}
					span={1}
				/>
			</div>
			<div className="w-full">
				{contextAmount === 0 ? null : (
					<div className="w-full">
						<Divider />
						<Row>
							<Col>
								<div className="text-gray-900">Context ({contextAmount})</div>
							</Col>
						</Row>
						{databricksUser?.workspaces && (
							<Row className="mt-2">
								<Space className="w-full" direction="vertical">
									<DatabricksWorkspaceContext databricksWorkspaces={databricksUser?.workspaces} />
								</Space>
							</Row>
						)}

						{databricksUser?.roles && (
							<Row className="mt-2">
								<Space className="w-full" direction="vertical">
									<DatabricksRolesContext databricksRoles={databricksUser?.roles} />
								</Space>
							</Row>
						)}

						{databricksUser?.personalAccessTokens && (
							<Row className="mt-2">
								<Space className="w-full" direction="vertical">
									<DatabricksPATsContext databricksPat={databricksUser?.personalAccessTokens} />
								</Space>
							</Row>
						)}

						{databricksUser?.githubReposXc?.length ? (
							<Row className="mt-2">
								<Space className="w-full" direction="vertical">
									<Collapse
										expandIconPosition={'end'}
										items={[
											{
												key: 'github-repositories',
												label: (
													<div className="flex items-center">
														<IssueSourceIcon source={ResourceType.GITHUB} />
														<span className="align-middle font-medium">
															Github Repositories
														</span>
													</div>
												),
												children: (
													<GithubReposTable githubRepos={databricksUser.githubReposXc} />
												),
											},
										]}
									/>
								</Space>
							</Row>
						) : null}
					</div>
				)}
			</div>
		</>
	)
}
