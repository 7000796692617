import { z } from 'zod'
import { camelizeSchema } from './utils'
import { DateSchema } from './common'
import { ServerOktaUserSchema } from './identities/oktaUserSchema.ts'
import { ServerGoogleWorkspaceUserSchema } from './identities/googleWorkspaceUserSchema.ts'
import { ServerAwsIamUserSchema, ServerAwsUsageLogSchema } from './identities/awsIamUserSchema.ts'
import { ServerAwsIamGroupSchema } from './identities/awsIamGroupSchema.ts'
import { ServerAwsIamRoleSchema } from './identities/awsIamRoleSchema.ts'
import { BaseServerIssueSchema, PriorityType, ServerIssueSchema } from './issue.ts'
import { ServerAwsEc2InstanceSchema } from './identities/awsEc2InstanceSchema.ts'
import { ServerJumpcloudUserSchema } from './identities/jumpcloud/jumpcloudUserSchema.ts'
import { ServerEntraIdUserSchema } from './identities/entraId/entraIdUserSchema.ts'
import { EnvironmentType } from './envType.ts'
import { ServerKubernetesResourceSchema } from './identities/kubernetesResourceSchema.ts'
import { ServerGcpServiceAccountSchema } from './identities/gcp/gcpServiceAccountSchema.ts'
import { ServerPostgresRoleSchema } from './identities/postgresRoleSchema.ts'
import { MockDemoTag, Tag, TagSchema } from './tags.ts'
import { ServerSnowflakeUserSchema } from './identities/snowflake/snowflakeUserSchema.ts'
import { ServerGithubUserLogSchema, ServerGithubUserSchema } from './identities/githubUserSchema.ts'
import { ServerAwsKeyPairSchema } from './identities/awsKeyPairSchema.ts'
import { ServerEntraIdServicePrincipalSchema } from './identities/entraId/entraIdServicePrincipalSchema.ts'
import { ServerDatabricksUserSchema } from './identities/databricks/databricksUserSchema.ts'
import { ServerDatabricksServicePrincipalSchema } from './identities/databricks/ServerDatabricksServicePrincipalSchema.ts'
import { ServerAzureKVSecretSchema } from './identities/azure/azureKeyVault/azureKVSecret.ts'
import { ServerAzureKVKeySchema } from './identities/azure/azureKeyVault/azureKvKey.ts'
import { ServerAzureKVCertificateSchema } from './identities/azure/azureKeyVault/azureKvCertificate.ts'
import { ServerGithubAppInstallationSchema } from './identities/githubAppInstallationSchema.ts'
import { ServerSalesforceUserSchema } from './identities/salesforce/salesforceUserSchema.ts'
import { ServerAzureUsageLogSchema } from './identities/entraId/azureUsageLogSchema.ts'
import { ServerSalesforceConnectedApplicationSchema } from './identities/salesforce/salesforceConnectedApplicationSchema.ts'
import {
	ServerSalesforceSetupAuditTrailAggregatedLogSchema,
	ServerSalesforceSetupAuditTrailLogSchema,
} from './identities/salesforce/salesforceSetupAuditTrailLogSchema.ts'
import {
	ServerSalesforceApiTotalUsageAggregatedLogReadModelSchema,
	ServerSalesforceApiTotalUsageLogSchema,
} from './identities/salesforce/salesforceApiTotalUsageLogSchema.ts'
import { ServerAzureDevopsUserSchema } from './identities/azure/azureDevops/azureDevopsUserSchema.ts'
import { themeColors } from '../utils/colorUtils.ts'
import { UserOutlined } from '@ant-design/icons'
import MachineIcon from '../assets/machine_icon_20.svg?react'
import CrossEnvironmentIcon from '../assets/cross_environment_icon_20.svg?react'
import WarningGrayIcon from '../assets/warning_gray_icon_20.svg?react'
import { TableFilterEntrySchema } from './tableFilter.ts'

export enum IdentityType {
	Human = 'HUMAN',
	Machine = 'MACHINE',
	Hybrid = 'HYBRID',
	Unknown = 'UNKNOWN',
}

export const IdentityTypeMap: Record<IdentityType, string> = {
	[IdentityType.Human]: 'Human',
	[IdentityType.Machine]: 'Machine',
	[IdentityType.Hybrid]: 'Hybrid',
	[IdentityType.Unknown]: 'Unknown',
}

export const IdentityTypeColorMap: Record<IdentityType, string> = {
	[IdentityType.Human]: themeColors.blue[300],
	[IdentityType.Machine]: themeColors.blue[500],
	[IdentityType.Hybrid]: themeColors.blue[700],
	[IdentityType.Unknown]: themeColors.blue[900],
}

export const IdentityTypeIconMap: Record<IdentityType, React.ComponentType<{ className?: string }>> = {
	[IdentityType.Human]: UserOutlined,
	[IdentityType.Machine]: MachineIcon,
	[IdentityType.Hybrid]: CrossEnvironmentIcon,
	[IdentityType.Unknown]: WarningGrayIcon,
}

export const isIdentityType = (type?: string | null): type is IdentityType =>
	!!type && Object.values(IdentityType).includes(type as IdentityType)

export enum IdentitySource {
	OKTA = 'OKTA',
	GOOGLE_WORKSPACE = 'GOOGLE_WORKSPACE',
	AWS_IAM_ROLE = 'AWS_IAM_ROLE',
	AWS_IAM_USER = 'AWS_IAM_USER',
	AWS_IAM_GROUP = 'AWS_IAM_GROUP',
	AWS_EC2_INSTANCE = 'AWS_EC2_INSTANCE',
	AWS_KEY_PAIR = 'AWS_KEY_PAIR',
	JUMPCLOUD_USER = 'JUMPCLOUD_USER',
	ENTRA_ID_USER = 'ENTRA_ID_USER',
	ENTRA_ID_SERVICE_PRINCIPAL = 'ENTRA_ID_SERVICE_PRINCIPAL',
	KUBERNETES_RESOURCE = 'KUBERNETES_RESOURCE',
	GCP_SERVICE_ACCOUNT = 'GCP_SERVICE_ACCOUNT',
	POSTGRES_ROLE = 'POSTGRES_ROLE',
	SNOWFLAKE_USER = 'SNOWFLAKE_USER',
	GITHUB_APP_INSTALLATION = 'GITHUB_APP_INSTALLATION',
	GITHUB_USER = 'GITHUB_USER',
	DATABRICKS_USER = 'DATABRICKS_USER', // Demo
	DATABRICKS_SERVICE_PRINCIPAL = 'DATABRICKS_SERVICE_PRINCIPAL', // Demo
	AZURE_POSTGRES_ROLE = 'AZURE_POSTGRES_ROLE', // Demo
	AZURE_KV_SECRET = 'AZURE_KV_SECRET',
	AZURE_KV_KEY = 'AZURE_KV_KEY',
	AZURE_KV_CERTIFICATE = 'AZURE_KV_CERTIFICATE',
	SALESFORCE_USER = 'SALESFORCE_USER',
	SALESFORCE_CONNECTED_APPLICATION = 'SALESFORCE_CONNECTED_APPLICATION',
	AZURE_DEVOPS_USER = 'AZURE_DEVOPS_USER',
}

export const isIdentitySource = (type?: string | null): type is IdentitySource =>
	!!type && Object.values(IdentitySource).includes(type as IdentitySource)

export const DemoIdentitySources = [
	IdentitySource.DATABRICKS_USER,
	IdentitySource.DATABRICKS_SERVICE_PRINCIPAL,
	IdentitySource.AZURE_POSTGRES_ROLE,
]

export const SUPPORTED_IDENTITY_PAGE_SOURCE_TYPES = [
	IdentitySource.AWS_IAM_USER,
	IdentitySource.AWS_IAM_ROLE,
	IdentitySource.GCP_SERVICE_ACCOUNT,
	IdentitySource.GITHUB_APP_INSTALLATION,
	IdentitySource.GITHUB_USER,
	IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL,
	IdentitySource.ENTRA_ID_USER,
	IdentitySource.DATABRICKS_USER,
	IdentitySource.DATABRICKS_SERVICE_PRINCIPAL,
	IdentitySource.AZURE_KV_SECRET,
	IdentitySource.AZURE_KV_KEY,
	IdentitySource.AZURE_KV_CERTIFICATE,
	IdentitySource.OKTA,
	IdentitySource.SNOWFLAKE_USER,
	IdentitySource.JUMPCLOUD_USER,
	IdentitySource.SALESFORCE_USER,
	IdentitySource.SALESFORCE_CONNECTED_APPLICATION,
	IdentitySource.GOOGLE_WORKSPACE,
	IdentitySource.POSTGRES_ROLE,
	IdentitySource.AZURE_DEVOPS_USER,
]

export enum IdentityActivityStatus {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	DELETED = 'DELETED',
}

export const IdentitySourceMap: Record<IdentitySource, string> = {
	[IdentitySource.OKTA]: 'OKTA',
	[IdentitySource.GOOGLE_WORKSPACE]: 'Google Workspace',
	[IdentitySource.AWS_IAM_ROLE]: 'IAM Role',
	[IdentitySource.AWS_IAM_USER]: 'IAM User',
	[IdentitySource.AWS_IAM_GROUP]: 'IAM Group',
	[IdentitySource.AWS_EC2_INSTANCE]: 'EC2 Instance',
	[IdentitySource.AWS_KEY_PAIR]: 'AWS Key Pair',
	[IdentitySource.JUMPCLOUD_USER]: 'Jumpcloud User',
	[IdentitySource.ENTRA_ID_USER]: 'Entra Id User',
	[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: 'Entra Id Service Principal',
	[IdentitySource.KUBERNETES_RESOURCE]: 'Kubernetes Resource',
	[IdentitySource.GCP_SERVICE_ACCOUNT]: 'GCP Service Account',
	[IdentitySource.POSTGRES_ROLE]: 'Postgres Role',
	[IdentitySource.SNOWFLAKE_USER]: 'Snowflake User',
	[IdentitySource.GITHUB_APP_INSTALLATION]: 'GitHub App',
	[IdentitySource.GITHUB_USER]: 'GitHub User',
	[IdentitySource.DATABRICKS_USER]: 'Databricks User',
	[IdentitySource.DATABRICKS_SERVICE_PRINCIPAL]: 'Databricks Service Principal',
	[IdentitySource.AZURE_POSTGRES_ROLE]: 'Azure Postgres Role',
	[IdentitySource.AZURE_KV_SECRET]: 'Azure Key Vault Secret',
	[IdentitySource.AZURE_KV_KEY]: 'Azure Key Vault Key',
	[IdentitySource.AZURE_KV_CERTIFICATE]: 'Azure Key Vault Certificate',
	[IdentitySource.SALESFORCE_USER]: 'Salesforce User',
	[IdentitySource.SALESFORCE_CONNECTED_APPLICATION]: 'Salesforce Connected Application',
	[IdentitySource.AZURE_DEVOPS_USER]: 'Azure DevOps User',
}

export const ServerIdentitySchema = z.object({
	id: z.string(),
	literal: z.string(),
	literal_friendly_name: z.string().nullish(),
	last_activity: DateSchema.nullish(),
	created_at: DateSchema.nullish(),
	updated_at: DateSchema.nullish(),
	system_update_at: DateSchema.nullish(),
	type: z.nativeEnum(IdentityType).nullish(),
	tags: TagSchema.array().nullish(),
	activity_status: z.nativeEnum(IdentityActivityStatus).nullish(),
	source: z.nativeEnum(IdentitySource),
	env_type: z.nativeEnum(EnvironmentType).nullish(),
	environment_id: z.string().nullish(),
	okta_user: ServerOktaUserSchema.nullish(),
	google_workspace_user: ServerGoogleWorkspaceUserSchema.nullish(),
	account_literal: z.string(),
	account_literal_friendly_name: z.string().nullish(),
	aws_iam_user: ServerAwsIamUserSchema.nullish(),
	aws_iam_role: ServerAwsIamRoleSchema.nullish(),
	aws_iam_group: ServerAwsIamGroupSchema.nullish(),
	aws_ec2_instance: ServerAwsEc2InstanceSchema.nullish(),
	aws_key_pair: ServerAwsKeyPairSchema.nullish(),
	jumpcloud_user: ServerJumpcloudUserSchema.nullish(),
	entra_id_user: ServerEntraIdUserSchema.nullish(),
	entra_id_service_principal: ServerEntraIdServicePrincipalSchema.nullish(),
	kubernetes_resource: ServerKubernetesResourceSchema.nullish(),
	gcp_service_account: ServerGcpServiceAccountSchema.nullish(),
	postgres_role: ServerPostgresRoleSchema.nullish(),
	snowflake_user: ServerSnowflakeUserSchema.nullish(),
	github_app_installation: ServerGithubAppInstallationSchema.nullish(),
	github_user: ServerGithubUserSchema.nullish(),
	azure_kv_secret: ServerAzureKVSecretSchema.nullish(),
	azure_kv_key: ServerAzureKVKeySchema.nullish(),
	azure_kv_certificate: ServerAzureKVCertificateSchema.nullish(),
	databricks_user: ServerDatabricksUserSchema.nullish(),
	databricks_service_principal: ServerDatabricksServicePrincipalSchema.nullish(),
	salesforce_user: ServerSalesforceUserSchema.nullish(),
	salesforce_connected_application: ServerSalesforceConnectedApplicationSchema.nullish(),
	azure_devops_user: ServerAzureDevopsUserSchema.nullish(),
	issues: z.lazy(() => BaseServerIssueSchema.array().nullish()),
})

export type ServerIdentity = z.infer<typeof ServerIdentitySchema>

export const IdentitySchema = camelizeSchema(ServerIdentitySchema.transform((val) => val))
export type Identity = z.infer<typeof IdentitySchema>

export const ServerAffectedEnvironmentSchema = z.object({
	account_db_id: z.string().nullish(),
	account_id: z.string().nullish(),
	account_name: z.string().nullish(),
})

export const AffectedEnvironmentWithEnvSchema = ServerAffectedEnvironmentSchema.extend({
	env_type: z.nativeEnum(EnvironmentType),
})

export type AffectedEnvironmentWithEnv = z.infer<typeof AffectedEnvironmentWithEnvSchema>

export const ServerAffectedEnvironmentsSchema = z
	.record(z.nativeEnum(EnvironmentType), ServerAffectedEnvironmentSchema.array())
	.optional()

export const ServerIdentitiesTableRowSchema = z.object({
	id: z.string(),
	literal: z.string(),
	literal_friendly_name: z.string().nullish(),
	created_at: DateSchema.nullish(),
	updated_at: DateSchema.nullish(),
	system_update_at: DateSchema.nullish(),
	last_activity: DateSchema.nullish(),
	env_type: z.nativeEnum(EnvironmentType).nullish(),
	type: z.nativeEnum(IdentityType).nullish(),
	source: z.nativeEnum(IdentitySource),
	affected_environments: ServerAffectedEnvironmentsSchema.nullish(),
	account_literal: z.string(),
	account_literal_friendly_name: z.string().nullish(),
	issues: z.lazy(() => ServerIssueSchema.array()),
	tags: z.array(z.string()).nullish(),
	tags_with_env: z.array(z.string()).nullish(),
	activity_status: z.nativeEnum(IdentityActivityStatus).nullish(),
	max_priority: z.lazy(() => z.nativeEnum(PriorityType).nullish()),
	issue_count: z.number().nullish(),
	mock_demo_tags: z.array(z.nativeEnum(MockDemoTag)).nullish(), // DEMO SPECIFIC
})

export type ServerIdentitiesTableRow = z.infer<typeof ServerIdentitiesTableRowSchema>
export const IdentitiesTableRowSchema = camelizeSchema(ServerIdentitiesTableRowSchema.transform((val) => val))
export type IdentitiesTableRow = z.infer<typeof IdentitiesTableRowSchema>

export const ServerIdentitiesQueryResponseSchema = z.object({
	identities: z.array(ServerIdentitiesTableRowSchema),
	total_count: z.number(),
})

export type ServerIdentitiesQueryResponse = z.infer<typeof ServerIdentitiesQueryResponseSchema>

export const ServerIdentitiesStatsSchema = z.object({
	total_count: z.number(),
	count_by_priority: z.record(z.coerce.number(), z.number()),
	risky_count: z.number(),
	count_by_risk: z.record(z.nativeEnum(Tag), z.number()),
	count_by_type: z.record(z.nativeEnum(IdentityType), z.number()),
})

export type ServerIdentitiesStats = z.infer<typeof ServerIdentitiesStatsSchema>

export const inventoryUrlQuickSearchFilterParameter = 'quick_search'

export const IdentityFilterSchema = z
	.record(
		z.union([
			ServerIdentitiesTableRowSchema.keyof(),
			z.literal(inventoryUrlQuickSearchFilterParameter),
			z.string(), // TODO: Remove when non-paginated table is removed
		]),
		TableFilterEntrySchema.optional(),
	)
	.optional()

export type IdentityFilter = z.infer<typeof IdentityFilterSchema>

export enum IdentitiesPageLens {
	ALL = 'ALL',
	HUMAN = 'HUMAN',
	MACHINE = 'MACHINE',
	HYBRID = 'HYBRID',
	UNKNOWN = 'UNKNOWN',
	ADMIN = 'ADMIN',
	DATA_ACCESS = 'DATA_ACCESS',
	CREDENTIAL_EXPOSURE = 'CREDENTIAL_EXPOSURE',
	RESOURCE_EXPOSURE = 'RESOURCE_EXPOSURE',
	PRIVILEGE_ESCALATION = 'POSSIBLE_PRIVILEGE_ESCALATION',
	AWS = 'AWS',
	GCP = 'GCP',
	AZURE = 'AZURE',
	OKTA = 'OKTA',
	JUMPCLOUD = 'JUMPCLOUD',
	ENTRA_ID = 'ENTRA_ID',
	GOOGLE_WORKSPACE = 'GOOGLE_WORKSPACE',
	POSTGRES = 'POSTGRES',
	SNOWFLAKE = 'SNOWFLAKE',
	KUBERNETES = 'KUBERNETES',
	OFF_BOARDED_EMPLOYEES = 'offBoardedEmployees',
	MULTIPLE_ACCESS_KEYS = 'MultipleAccessKeys',
	UNFEDERATED_IDENTITIES = 'UnFederatedIdentity',
	RECENTLY_CREATED = 'recentlyCreated',
	MOST_WANTED = 'mostWanted',
	DEMO_DATABRICKS = 'DATABRICKS',
	DEMO_OVER_PRIVILEGED_IDENTITY = 'OVER_PRIVILEGED_IDENTITY',
	DEMO_IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR = 'IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR',
}

const IdentitiesPaginationSchema = z
	.object({
		page: z.number().nullish(),
		pageSize: z.number(),
	})
	.optional()

export type IdentitiesPagination = z.infer<typeof IdentitiesPaginationSchema>

export const IdentitiesPageSearchSchema = z.object({
	issueId: z.string().uuid().catch('').optional(),
	identityFilter: IdentityFilterSchema,
	pagination: IdentitiesPaginationSchema,
	lens: z.nativeEnum(IdentitiesPageLens).catch(IdentitiesPageLens.ALL).optional(),
})

export type IdentitiesPageSearch = z.infer<typeof IdentitiesPageSearchSchema>

export const ServerIdentityRelatedIssuesSchema = z.lazy(() =>
	z.object({
		id: z.string(),
		issues: z.array(ServerIssueSchema),
	}),
)

export type ServerIdentityRelatedIssues = z.infer<typeof ServerIdentityRelatedIssuesSchema>

export const IdentityRelatedIssuesSchema = camelizeSchema(ServerIdentityRelatedIssuesSchema.transform((val) => val))
export type IdentityRelatedIssues = z.infer<typeof IdentityRelatedIssuesSchema>

export const IdentityPageSearchSchema = z.object({
	issueId: z.string().uuid().catch('').optional(),
	identitiesPage: IdentitiesPageSearchSchema.nullish(),
})

export const ServerIdentityUsageLogsSchema = z.object({
	aws_iam_user_usage_logs: z.array(ServerAwsUsageLogSchema),
	aws_iam_role_usage_logs: z.array(ServerAwsUsageLogSchema),
	entra_id_service_principal_usage_logs: z.array(ServerAzureUsageLogSchema),
	entra_id_user_usage_logs: z.array(ServerAzureUsageLogSchema),
	salesforce_user_setup_audit_trail_logs: z.array(ServerSalesforceSetupAuditTrailLogSchema),
	salesforce_user_setup_audit_trail_logs_aggregated: z.array(ServerSalesforceSetupAuditTrailAggregatedLogSchema),
	salesforce_connected_app_api_total_usage_logs: z.array(ServerSalesforceApiTotalUsageLogSchema),
	salesforce_connected_app_api_total_usage_logs_aggregated: z.array(
		ServerSalesforceApiTotalUsageAggregatedLogReadModelSchema,
	),
	github_user_audit_logs: z.array(ServerGithubUserLogSchema),
})

export type ServerIdentityUsageLogs = z.infer<typeof ServerIdentityUsageLogsSchema>
export const IdentityUsageLogsSchema = camelizeSchema(ServerIdentityUsageLogsSchema.transform((val) => val))
export type IdentityUsageLogs = z.infer<typeof IdentityUsageLogsSchema>

export const ServerIdentityEnvironmentSchema = z.object({
	env_type: z.nativeEnum(EnvironmentType).nullish(),
	account_literal: z.string(),
	account_literal_friendly_name: z.string().nullish(),
})

export type ServerIdentityEnvironment = z.infer<typeof ServerIdentityEnvironmentSchema>
