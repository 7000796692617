import { KubernetesResourceAccessKeys } from '../../../../schemas/identities/kubernetes/kubernetesResourceAccessKeysSchema.ts'

export const AccessKeyTooltip = (props: { accessKey: KubernetesResourceAccessKeys }) => {
	return (
		<div className="flex flex-col">
			<span className="flex text-sm text-gray-300">Pod Name: {props.accessKey?.podName}</span>
			<span className="flex text-sm text-gray-300">Container Name: {props.accessKey?.containerName}</span>
			<span className="flex text-sm text-gray-300">Secret Key: {props.accessKey?.secretKey}</span>
			<span className="flex text-sm text-gray-300">Secret Name: {props.accessKey?.secretName}</span>
		</div>
	)
}
