import { Identity, IdentitySource } from '../../../schemas/identity'
import { GoogleWorkspaceUserIdentity } from './GoogleWorkspaceUser'
import { OktaUserIdentity } from './OktaUser'
import { AwsIamUserIdentity } from './AwsIamUser'
import { AwsIamRoleIdentity } from './AwsIamRole'
import { AwsIamGroupIdentity } from './AwsIamGroup'
import { AwsEc2InstanceIdentity } from './AwsEc2Instance'
import { JumpcloudUserIdentity } from './JumpcloudUser.tsx'
import { EntraIdUserIdentity } from './entraId/EntraIdUser.tsx'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { KubernetesResourceIdentity } from './KubernetesResource.tsx'
import { GcpServiceAccountIdentity } from './GcpServiceAccount.tsx'
import { PostgresRoleIdentity } from './PostgresRole.tsx'
import { SnowflakeUserIdentity } from './snowflake/SnowflakeUser.tsx'
import React from 'react'
import { GithubUserIdentity } from './github/GithubUser.tsx'
import { AwsKeyPairIdentity } from './AwsKeyPair.tsx'
import { EntraIdServicePrincipalIdentity } from './entraId/EntraIdServicePrincipal.tsx'
import { DatabricksUserIdentity } from './databricks/DatabricksUser.tsx'
import { DatabricksServicePrincipalIdentity } from './databricks/DatabricksServicePrincipal.tsx'
import { DemoAzurePostgresRoleIdentity } from './azure/demo/DemoAzurePostgresSqlDatabase.tsx'
import { AzureKVSecretIdentity } from './azure/azureKV/AzureKVSecretIdentity.tsx'
import { AzureKVCertificateIdentity } from './azure/azureKV/AzureKVCertificateIdentity.tsx'
import { AzureKVKeyIdentity } from './azure/azureKV/AzureKVKeyIdentity.tsx'

export const BaseIdentity: React.FunctionComponent<{
	identity?: Identity | null
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	switch (identity?.source) {
		case IdentitySource.OKTA:
			return <OktaUserIdentity identity={identity} envType={envType} />
		case IdentitySource.GOOGLE_WORKSPACE:
			return <GoogleWorkspaceUserIdentity identity={identity} envType={envType} />
		case IdentitySource.AWS_IAM_USER:
			return <AwsIamUserIdentity identity={identity} envType={envType} />
		case IdentitySource.AWS_IAM_ROLE:
			return <AwsIamRoleIdentity identity={identity} envType={envType} />
		case IdentitySource.AWS_IAM_GROUP:
			return <AwsIamGroupIdentity identity={identity} envType={envType} />
		case IdentitySource.AWS_EC2_INSTANCE:
			return <AwsEc2InstanceIdentity identity={identity} envType={envType} />
		case IdentitySource.AWS_KEY_PAIR:
			return <AwsKeyPairIdentity identity={identity} envType={envType} />
		case IdentitySource.JUMPCLOUD_USER:
			return <JumpcloudUserIdentity identity={identity} envType={envType} />
		case IdentitySource.ENTRA_ID_USER:
			return <EntraIdUserIdentity identity={identity} envType={envType} />
		case IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL:
			return <EntraIdServicePrincipalIdentity identity={identity} envType={envType} />
		case IdentitySource.KUBERNETES_RESOURCE:
			return <KubernetesResourceIdentity identity={identity} envType={envType} />
		case IdentitySource.GCP_SERVICE_ACCOUNT:
			return <GcpServiceAccountIdentity identity={identity} envType={envType} />
		case IdentitySource.POSTGRES_ROLE:
			return <PostgresRoleIdentity identity={identity} envType={envType} />
		case IdentitySource.SNOWFLAKE_USER:
			return <SnowflakeUserIdentity identity={identity} envType={envType} />
		case IdentitySource.GITHUB_USER:
			return <GithubUserIdentity identity={identity} envType={envType} />
		case IdentitySource.DATABRICKS_USER:
			return <DatabricksUserIdentity identity={identity} envType={envType} />
		case IdentitySource.DATABRICKS_SERVICE_PRINCIPAL:
			return <DatabricksServicePrincipalIdentity identity={identity} envType={envType} />
		case IdentitySource.AZURE_POSTGRES_ROLE:
			return <DemoAzurePostgresRoleIdentity identity={identity} envType={envType} />
		case IdentitySource.AZURE_KV_SECRET:
			return <AzureKVSecretIdentity identity={identity} envType={envType} />
		case IdentitySource.AZURE_KV_CERTIFICATE:
			return <AzureKVCertificateIdentity identity={identity} envType={envType} />
		case IdentitySource.AZURE_KV_KEY:
			return <AzureKVKeyIdentity identity={identity} envType={envType} />
	}
}
