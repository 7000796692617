import { Edge } from '@xyflow/react'
import { IdentityNodeType } from '../common/IdentityNode.tsx'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { Identity } from '../../../../schemas/identity.ts'
import { getEdge } from '../graphUtils/nodesAndEdges.ts'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { EntraIdSpAuthCredentialNodeType } from './EntraIdSpAuthCredentialNode.tsx'
import {
	EntraIdKeyCredential,
	EntraIdPasswordCredential,
	normalizeCredentials,
} from '../../../../schemas/identities/entraId/entraIdApplicationXc.ts'
import { IssueName } from '../../../../schemas/issue.ts'
import {
	AzureManagementGroup,
	AzureSubscription,
} from '../../../../schemas/identities/entraId/azureRoleAssignmentsXc.ts'
import { AzureSubscriptionNodeType } from '../azure/azureResource/AzureSubscriptionNode.tsx'
import { AzureManagementGroupNodeType } from '../azure/azureResource/AzureManagementGroupNode.tsx'
import { EnrichedAzureRoleNodeType } from '../azure/azureRBAC/EnrichedAzureRoleNode.tsx'
import { DetailedEntraIdRoleNodeType } from './entraIdRbac/DetailedEntraIdRoleNode.tsx'
import { BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { KubernetesResourcesNodeType } from '../common/KubernetesResourcesNode.tsx'
import { DemoAzureVirtualMachinesNodeType } from '../azure/demo/DemoAzureVirtualMachinesNode.tsx'
import { KubernetesResourceXc } from '../../../../schemas/identities/kubernetes/kubernetesResourceXcSchema.ts'
import { processAwsRoles } from '../aws/crossAwsGraphUtils.ts'
import { getActorArnFriendlyName } from '../../../../components/drawers/identities/utils.tsx'
import { EntraIdChangeLog } from '../../../../schemas/identities/entraId/entraIdChangeLogSchema.ts'
import { aggregateEntraIdChangeLogs } from '../../../../utils/entraIdentityUtils.ts'
import { OwnershipNodeType } from '../common/OwnershipNode.tsx'

const nodeLogicalTypeToColumnId = {
	generalIssue: 0,
	identity: 1,
	credential: 2,
	credentialIssue: 3,
	detailedEntraIdRole: 4,
	enrichedAzureRole: 5,
	azureSubscription: 6,
	azureManagementGroup: 7,
	vmsAndK8s: 8,
	awsAccount: 9,
	awsRoles: 10,
	awsPolicy: 11,
}

const ownerNodeRowIndex = 0
const ownerNodeId = `${nodeLogicalTypeToColumnId.identity}-${ownerNodeRowIndex}`
const identityNodeRowIndex = 1
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`

const credentialIssueNames: IssueName[] = [
	IssueName.IdentityUsingWeakAuthentication,
	IssueName.AccessKeyNotRotated,
	IssueName.MultipleAccessKeys,
	IssueName.OverextendedSecret,
	IssueName.InactiveCredential,
	IssueName.ClientSecretReferencedInPod,
	IssueName.ClientSecretUsedFromEndpoint,
]

export const getEntraIdNodesAndEdges = (identity: Identity): [BareNodesColumnsType[], Edge[]] => {
	const edges: Edge[] = []
	const identityNodes: Array<BareNodeType<IdentityNodeType> | BareNodeType<OwnershipNodeType>> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	const changeLogs: EntraIdChangeLog[] =
		identity.entraIdServicePrincipal?.changeLogs || identity.entraIdUser?.changeLogs || []
	if (changeLogs?.length) {
		const aggregatedChangeLogs = aggregateEntraIdChangeLogs(changeLogs)
		identityNodes.unshift({
			type: 'ownership',
			data: {
				owners: aggregatedChangeLogs.map((aggChangeLog) => ({
					id: aggChangeLog.actorPrincipalId,
					name: getActorArnFriendlyName(aggChangeLog.actorPrincipalName),
				})),
			},
			id: ownerNodeId,
		})

		edges.push(
			getEdge({
				source: ownerNodeId,
				target: identityNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	const credentialIssueNodes: BareNodeType<IssueNodeType>[] = []
	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []

	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		if (issue.issueName && credentialIssueNames.includes(issue.issueName)) {
			credentialIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.credentialIssue}-${credentialIssueNodes.length}`,
			})
		} else {
			generalIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
			})
		}
	})

	const application = identity.entraIdServicePrincipal?.entraIdApplicationXc

	const keyCredentials: EntraIdKeyCredential[] = application?.keyCredentials || []
	const passwordCredentials: EntraIdPasswordCredential[] = application?.passwordCredentials || []

	// Normalize all credentials
	const normalizedCredentials = normalizeCredentials(keyCredentials, passwordCredentials)

	const credentialNodes: BareNodeType<EntraIdSpAuthCredentialNodeType>[] =
		normalizedCredentials.map((credential, index) => ({
			type: 'entraIdCredential',
			data: { credential },
			id: `${nodeLogicalTypeToColumnId.credential}-${index}`,
		})) || []

	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	credentialNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.credential}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	credentialNodes.forEach((credentialNode, credentialIndex) => {
		const target = `${nodeLogicalTypeToColumnId.credential}-${credentialIndex}`
		credentialIssueNodes.forEach((issueNode, issueIndex) => {
			if (issueNode.data.issue.issueName === IssueName.MultipleAccessKeys) {
				const source = `${nodeLogicalTypeToColumnId.credentialIssue}-${issueIndex}`
				edges.push(getEdge({ source, target }))
			} else if (issueNode.data.issue.description?.includes(credentialNode.data.credential?.keyId)) {
				const source = `${nodeLogicalTypeToColumnId.credentialIssue}-${issueIndex}`
				edges.push(getEdge({ source, target }))
			}
		})
	})
	const { awsRolesNodes, awsAccountNodes, policyNodes, awsEdges } = processAwsRoles(
		identityNodeId,
		identity.entraIdUser?.awsIamRolesXc ?? null,
		nodeLogicalTypeToColumnId,
	)
	edges.push(...awsEdges)

	// Create Entra ID role nodes
	const entraIdRoleNodes: Array<BareNodeType<DetailedEntraIdRoleNodeType>> = []
	const entraIdRoles =
		identity.entraIdUser?.entraIdRoleAssignments || identity.entraIdServicePrincipal?.entraIdRoleAssignments || []

	entraIdRoles?.forEach((role, index) => {
		entraIdRoleNodes.push({
			type: 'detailedEntraIdRole',
			data: { role },
			id: `${nodeLogicalTypeToColumnId.detailedEntraIdRole}-${index}`,
		})
	})

	// Connect Entra ID role nodes to the identity node
	entraIdRoleNodes.forEach((_node, index) => {
		const target = `${nodeLogicalTypeToColumnId.detailedEntraIdRole}-${index}`
		edges.push(getEdge({ source: identityNodeId, target }))
	})

	const azureRoleNodes: Array<BareNodeType<EnrichedAzureRoleNodeType>> = []
	const azureRoles =
		identity.entraIdUser?.azureRoleAssignmentsXc || identity.entraIdServicePrincipal?.azureRoleAssignmentsXc || []
	azureRoles?.forEach((role, index) => {
		azureRoleNodes.push({
			type: 'enrichedAzureRole',
			data: { role },
			id: `${nodeLogicalTypeToColumnId.enrichedAzureRole}-${index}`,
		})
	})

	// Create Maps to store unique subscriptions and management groups
	const subscriptionsMap = new Map<string, AzureSubscription>()
	const managementGroupsMap = new Map<string, AzureManagementGroup>()

	// Collect unique subscriptions and management groups from role assignments
	azureRoles.forEach((role) => {
		if (role.scopeType === 'Subscription') {
			const subscription = role.subscription as AzureSubscription
			if (subscription && subscription.subscriptionId) {
				subscriptionsMap.set(subscription.subscriptionId, subscription)
			}
		} else if (role.scopeType === 'ManagementGroup') {
			const managementGroup = role.managementGroup as AzureManagementGroup
			if (managementGroup && managementGroup.id) {
				managementGroupsMap.set(managementGroup.id, managementGroup)
				// Add subscriptions from management group to subscriptionsMap
				managementGroup.subscriptions?.forEach((subscription) => {
					if (subscription.subscriptionId) {
						subscriptionsMap.set(subscription.subscriptionId, subscription)
					}
				})
			}
		}
	})

	// Create subscription nodes
	const subscriptionNodes: Array<BareNodeType<AzureSubscriptionNodeType>> = []
	const subscriptions = Array.from(subscriptionsMap.values())
	subscriptions.forEach((subscription, subscriptionIndex) => {
		subscriptionNodes.push({
			type: 'azureSubscription',
			data: { subscription },
			id: `${nodeLogicalTypeToColumnId.azureSubscription}-${subscriptionIndex}`,
		})
	})

	// Create management group nodes
	const managementGroupNodes: Array<BareNodeType<AzureManagementGroupNodeType>> = []
	const managementGroups = Array.from(managementGroupsMap.values())
	managementGroups.forEach((managementGroup, managementGroupIndex) => {
		managementGroupNodes.push({
			type: 'azureManagementGroup',
			data: { managementGroup },
			id: `${nodeLogicalTypeToColumnId.azureManagementGroup}-${managementGroupIndex}`,
		})
	})

	azureRoleNodes.forEach((_, index) => {
		const target = `${nodeLogicalTypeToColumnId.enrichedAzureRole}-${index}`
		edges.push(getEdge({ source: identityNodeId, target }))
	})

	// Create edges between role nodes and subscription/management group nodes
	azureRoleNodes.forEach((roleNode, roleIndex) => {
		const role = roleNode.data.role
		if (role.scopeType === 'Subscription' && role.subscription) {
			const subscriptionId = role.subscription?.subscriptionId
			if (subscriptionId) {
				const subscriptionNodeIndex = subscriptionNodes.findIndex(
					(node) => node.data.subscription.subscriptionId === subscriptionId,
				)
				if (subscriptionNodeIndex !== -1) {
					edges.push(
						getEdge({
							source: `${nodeLogicalTypeToColumnId.enrichedAzureRole}-${roleIndex}`,
							target: `${nodeLogicalTypeToColumnId.azureSubscription}-${subscriptionNodeIndex}`,
						}),
					)
				}
			}
		} else if (role.scopeType === 'ManagementGroup' && role.managementGroup) {
			const managementGroupId = role.managementGroup?.id
			if (managementGroupId) {
				const managementGroupNodeIndex = managementGroupNodes.findIndex(
					(node) => node.data.managementGroup.id === managementGroupId,
				)
				if (managementGroupNodeIndex !== -1) {
					edges.push(
						getEdge({
							source: `${nodeLogicalTypeToColumnId.enrichedAzureRole}-${roleIndex}`,
							target: `${nodeLogicalTypeToColumnId.azureManagementGroup}-${managementGroupNodeIndex}`,
						}),
					)
				}
			}
		} else if (role.roleAssignment.scope === '/') {
			// Link to all subscriptions and management groups if scope is "/"
			subscriptionNodes.forEach((_, subscriptionNodeIndex) => {
				edges.push(
					getEdge({
						source: `${nodeLogicalTypeToColumnId.enrichedAzureRole}-${roleIndex}`,
						target: `${nodeLogicalTypeToColumnId.azureSubscription}-${subscriptionNodeIndex}`,
					}),
				)
			})
			managementGroupNodes.forEach((_, managementGroupNodeIndex) => {
				edges.push(
					getEdge({
						source: `${nodeLogicalTypeToColumnId.enrichedAzureRole}-${roleIndex}`,
						target: `${nodeLogicalTypeToColumnId.azureManagementGroup}-${managementGroupNodeIndex}`,
					}),
				)
			})
		}
	})

	// Create edges between management groups and their subscriptions
	managementGroupNodes.forEach((mgNode, mgNodeIndex) => {
		const managementGroup = mgNode.data.managementGroup
		const mgSubscriptions = managementGroup.subscriptions || []
		mgSubscriptions.forEach((subscription) => {
			if (subscription.subscriptionId) {
				const subscriptionNodeIndex = subscriptionNodes.findIndex(
					(node) => node.data.subscription.subscriptionId === subscription.subscriptionId,
				)
				if (subscriptionNodeIndex !== -1) {
					edges.push(
						getEdge({
							source: `${nodeLogicalTypeToColumnId.azureManagementGroup}-${mgNodeIndex}`,
							target: `${nodeLogicalTypeToColumnId.azureSubscription}-${subscriptionNodeIndex}`,
						}),
					)
				}
			}
		})
	})

	const vmsAndK8sNodes: Array<
		BareNodeType<KubernetesResourcesNodeType> | BareNodeType<DemoAzureVirtualMachinesNodeType>
	> = []
	if (identity.entraIdServicePrincipal?.virtualMachines?.length) {
		vmsAndK8sNodes.push({
			type: 'azureVirtualMachines',
			data: { vms: identity.entraIdServicePrincipal?.virtualMachines },
			id: `${nodeLogicalTypeToColumnId.vmsAndK8s}-${vmsAndK8sNodes.length}`,
		})
	}
	const kubernetesResources: KubernetesResourceXc[] = identity.entraIdServicePrincipal?.kubernetesResourcesXc || []
	if (kubernetesResources.length) {
		vmsAndK8sNodes.push({
			type: 'kubernetesResources',
			data: { resources: kubernetesResources },
			id: `${nodeLogicalTypeToColumnId.vmsAndK8s}-${vmsAndK8sNodes.length}`,
		})
	}

	vmsAndK8sNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.vmsAndK8s}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})
	const nodes: BareNodesColumnsType[] = [
		{ yPosition: 'top', nodes: vmsAndK8sNodes },
		{ yPosition: 'center', nodes: [...credentialIssueNodes, ...generalIssueNodes] },
		{ yPosition: generalIssueNodes.length > 0 ? 'top' : 'center', nodes: credentialNodes },
		{ yPosition: 'center', nodes: identityNodes },
		{ yPosition: 'top', nodes: [...entraIdRoleNodes, ...awsAccountNodes] },
		{ yPosition: 'center', nodes: [...azureRoleNodes, ...awsRolesNodes] },
		{ yPosition: 'center', nodes: managementGroupNodes },
		{ yPosition: 'center', nodes: [...subscriptionNodes, ...policyNodes] },
	]
	return [nodes, edges]
}
