import { camelizeSchema } from '../utils.ts'
import { z } from 'zod'
import { IssueName, PriorityTypeEnum } from '../issue.ts'

export const ServerFindingDistNameSchema = z.object({
	issue_name: z.nativeEnum(IssueName),
	amount: z.number(),
	max_priority: z.nativeEnum(PriorityTypeEnum),
	original_issue_name: z.nativeEnum(IssueName).nullish(),
	original_amount: z.number().nullish(),
})

export type ServerFindingDistName = z.infer<typeof ServerFindingDistNameSchema>

export const FindingDistNameSchema = camelizeSchema(ServerFindingDistNameSchema.transform((val) => val))
export type FindingDistName = z.infer<typeof FindingDistNameSchema>
