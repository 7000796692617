import { Col, Divider, Row, Space, Tooltip } from 'antd'
import { formatDate, formatRelativeDateText } from '../../../utils'
import React, { useMemo } from 'react'
import { BaseIdentityDetail } from './baseIdentities/BaseIdentityDetail'
import { IdentityTypeTag } from '../../common/IdentityTypeTag'
import { IssueSourceIcon } from '../../common/IssueSourceIcon'
import { Identity } from '../../../schemas/identity'
import { AwsIamRole } from '../../../schemas/identities/awsIamRoleSchema.ts'
import { AwsIamPermissionContext } from './AwsIamPermissionContext.tsx'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { AwsEc2InstanceContext } from './AwsEc2InstanceContext.tsx'
import { AwsIamRoleContext } from './AwsIamRoleContext.tsx'
import AwsIcon from '../../../assets/aws_border_icon.svg?react'
import { AwsIamTrustRelationshipsContext } from './AwsIamTrustRelationshipsContext.tsx'
import { AccountPriorityNameTag } from '../../common/AccountPriorityNameTag.tsx'
import { KubernetesResourceContext } from './kubernetes/KubernetesResourceContext.tsx'
import { AwsIamUserChangeLogsContext } from './AwsIamUserChangeLogsContext.tsx'

export const AwsIamRoleIdentity: React.FunctionComponent<{
	identity?: Identity
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const awsIamRole: AwsIamRole | null = identity?.awsIamRole ?? null
	const accountPriority = awsIamRole?.awsAccount?.priority
	const contextAmount = useMemo<number>(() => {
		let count = 0
		if (awsIamRole?.awsIamRoleDetailsXc) count++
		if (awsIamRole?.awsEc2InstancesXc && awsIamRole.awsEc2InstancesXc.length > 0) count++
		if (awsIamRole?.awsIamCrossAccountRolesXc && awsIamRole.awsIamCrossAccountRolesXc.length > 0) count++
		if (awsIamRole?.awsIamRoleDetailsXc?.assumeRolePolicyDocument) count++
		if (awsIamRole?.kubernetesResourcesXc?.length ?? 0 > 0) count++
		if (awsIamRole?.changeLogs?.length ?? 0 > 0) count++
		return count
	}, [awsIamRole])
	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail label="Arn" value={identity?.literal} span={2} />
				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Account" span={1}>
					<div className="flex flex-row items-center space-x-2">
						<IssueSourceIcon source={envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
						<Tooltip placement="topLeft" title={identity?.accountLiteral}>
							<div className="font-normal text-gray-600 items-end truncate">
								{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
							</div>
						</Tooltip>
					</div>
				</BaseIdentityDetail>
				{accountPriority && (
					<BaseIdentityDetail label="Account Priority" span={1}>
						<AccountPriorityNameTag priority={accountPriority} />
					</BaseIdentityDetail>
				)}
				<BaseIdentityDetail label="Name" value={identity?.literal?.split('/')[1]} span={1} />
				<BaseIdentityDetail label="Created At" value={formatDate(identity?.createdAt)} span={1} />
				<BaseIdentityDetail label="Updated At" value={formatDate(identity?.updatedAt)} span={1} />
				<BaseIdentityDetail
					label="Last Activity"
					value={formatRelativeDateText(identity?.lastActivity, true)}
					tooltipTitle={formatDate(identity?.lastActivity)}
					span={1}
				/>
			</div>
			<div className="w-full">
				{contextAmount === 0 ? null : (
					<div className="w-full">
						<Divider />
						<Row>
							<Col>
								<div className="text-gray-900">Context ({contextAmount})</div>
							</Col>
						</Row>
						{awsIamRole?.awsIamRoleDetailsXc && (
							<Row className="mt-2">
								<Space className="w-full" direction="vertical">
									<AwsIamPermissionContext awsIamRolesXc={awsIamRole?.awsIamRoleDetailsXc} />
								</Space>
							</Row>
						)}
						{awsIamRole?.changeLogs && awsIamRole?.changeLogs?.length > 0 && (
							<Row className="mt-2">
								<Space className="w-full" direction="vertical">
									<AwsIamUserChangeLogsContext
										environmentId={identity?.environmentId ?? ''}
										subEnvironmentId={awsIamRole?.awsAccount?.id ?? ''}
										identityArn={awsIamRole?.arn ?? ''}
										changeLogs={awsIamRole?.changeLogs ?? []}
									/>
								</Space>
							</Row>
						)}

						{awsIamRole?.awsEc2InstancesXc && awsIamRole.awsEc2InstancesXc.length > 0 ? (
							<Row className="mt-2">
								<Space className="w-full" direction="vertical">
									<AwsEc2InstanceContext awsEc2InstancesXc={awsIamRole.awsEc2InstancesXc} />
								</Space>
							</Row>
						) : null}
						{awsIamRole?.awsIamRoleDetailsXc?.assumeRolePolicyDocument?.statement?.length &&
							awsIamRole?.awsIamRoleDetailsXc?.assumeRolePolicyDocument?.statement?.length > 0 && (
								<Row className="mt-2">
									<Space className="w-full" direction="vertical">
										<AwsIamTrustRelationshipsContext
											trustRelationships={
												awsIamRole?.awsIamRoleDetailsXc?.assumeRolePolicyDocument
											}
										/>
									</Space>
								</Row>
							)}
						{awsIamRole?.awsIamCrossAccountRolesXc && awsIamRole.awsIamCrossAccountRolesXc.length > 0 ? (
							<Row className="mt-2">
								<Space className="w-full" direction="vertical">
									<AwsIamRoleContext
										awsIamRolesXc={awsIamRole?.awsIamCrossAccountRolesXc}
										header={
											<div className="flex items-center">
												<Tooltip placement="topLeft" title="AWS">
													<AwsIcon style={{ minWidth: 40, minHeight: 40 }} />
												</Tooltip>
												<div className="">Trusted Principals</div>
											</div>
										}
									/>
								</Space>
							</Row>
						) : null}
						{awsIamRole?.kubernetesResourcesXc && awsIamRole?.kubernetesResourcesXc.length > 0 && (
							<Row className="mt-2">
								<Space className="w-full" direction="vertical">
									<KubernetesResourceContext
										kubernetesResourcesXc={awsIamRole?.kubernetesResourcesXc}
									/>
								</Space>
							</Row>
						)}
					</div>
				)}
			</div>
		</>
	)
}
