import { camelizeSchema } from '../utils.ts'
import { z } from 'zod'
import { IdentitySource } from '../identity.ts'
import { PriorityTypeEnum } from '../issue.ts'
import { EnvironmentType } from '../envType.ts'

export const ServerFindingDistEnvSchema = z.object({
	env_type: z.nativeEnum(EnvironmentType).nullish(),
	originalIdentitySource: z.nativeEnum(IdentitySource).nullish(),
	amount: z.number(),
	max_priority: z.nativeEnum(PriorityTypeEnum),
})

export type ServerFindingDistEnv = z.infer<typeof ServerFindingDistEnvSchema>

export const FindingDistEnvSchema = camelizeSchema(ServerFindingDistEnvSchema.transform((val) => val))
export type FindingDistEnv = z.infer<typeof FindingDistEnvSchema>
