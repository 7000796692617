import { Identity } from '../../../schemas/identity.ts'
import { AwsIamUserChangeLogsContent } from '../../../components/drawers/identities/AwsIamUserChangeLogsContent.tsx'
import { EntraIdChangeLogsContent } from '../../../components/drawers/identities/entraId/EntraIdChangeLogsContent.tsx'
import { useHighlightedNodesContext } from '../HighlightedNodesContext.tsx'
import { SalesforceOwnershipTable } from '../../../tables/salesforce/SalesforceOwnershipTable.tsx'
import { GithubOwnershipTable } from '../../../tables/github/GithubOwnershipTable.tsx'

export const SidePanelOwnership = ({ identity }: { identity: Identity }) => {
	const { setHighlightedNodes } = useHighlightedNodesContext()
	return (
		<div
			onMouseEnter={() => {
				setHighlightedNodes([{ type: 'ownership', id: '' }])
			}}
			onMouseLeave={() => {
				setHighlightedNodes([])
			}}
		>
			{(identity.awsIamUser || identity.awsIamRole) && (
				<AwsIamUserChangeLogsContent
					environmentId={identity.environmentId ?? ''}
					subEnvironmentId={
						(identity.awsIamUser?.awsAccount?.id || identity.awsIamRole?.awsAccount?.id) ?? ''
					}
					identityArn={(identity.awsIamUser?.arn || identity.awsIamRole?.arn) ?? ''}
					changeLogs={(identity.awsIamUser?.changeLogs || identity.awsIamRole?.changeLogs) ?? []}
				/>
			)}
			{identity.databricksServicePrincipal && (
				<AwsIamUserChangeLogsContent
					environmentId={''}
					subEnvironmentId={''}
					identityArn={''}
					changeLogs={identity.databricksServicePrincipal?.changeLogs ?? []}
				/>
			)}
			{identity.gcpServiceAccount && (
				<AwsIamUserChangeLogsContent
					environmentId={identity.environmentId ?? ''}
					subEnvironmentId={''}
					identityArn={''}
					changeLogs={identity.gcpServiceAccount.changeLogs ?? []}
				/>
			)}
			{identity.entraIdServicePrincipal && (
				<EntraIdChangeLogsContent
					changeLogs={identity.entraIdServicePrincipal.changeLogs}
					identity={identity}
				/>
			)}
			{identity.entraIdUser?.changeLogs && (
				<EntraIdChangeLogsContent changeLogs={identity.entraIdUser.changeLogs} identity={identity} />
			)}
			{identity.azureKvCertificate?.changeLogs && (
				<EntraIdChangeLogsContent changeLogs={identity.azureKvCertificate.changeLogs} identity={identity} />
			)}
			{identity.azureKvKey?.changeLogs && (
				<EntraIdChangeLogsContent changeLogs={identity.azureKvKey.changeLogs} identity={identity} />
			)}
			{identity.azureKvSecret?.changeLogs && (
				<EntraIdChangeLogsContent changeLogs={identity.azureKvSecret.changeLogs} identity={identity} />
			)}{' '}
			{identity.salesforceUser?.ownershipRecords && (
				<SalesforceOwnershipTable data={identity.salesforceUser.ownershipRecords} />
			)}
			{identity.salesforceConnectedApplication?.ownershipRecords && (
				<SalesforceOwnershipTable data={identity.salesforceConnectedApplication.ownershipRecords} />
			)}
			{identity.githubAppInstallation?.ownerId && (
				<GithubOwnershipTable data={[identity.githubAppInstallation]} />
			)}
		</div>
	)
}
