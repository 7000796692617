import React from 'react'
import { IssueName, IssueNameMap, IssueStats } from '../../schemas/issue.ts'
import { IssueIcon } from '../../components/common/IssueIcon.tsx'
import { Tooltip } from 'antd'

export const FindingMenuItemLabel: React.FC<{
	item: IssueStats
}> = ({ item }) => {
	const name = IssueNameMap[item.issueName as IssueName]
	const nameDiv = <div className="ml-2 truncate">{name}</div>
	return (
		<div className="flex justify-between">
			<div className="flex content-start items-center truncate">
				<IssueIcon name={item.issueName} />
				{/*the if is because of issue sidebar width, it doesn't truncate if length too long*/}
				{name?.length >= 30 ? <Tooltip title={name}>{nameDiv}</Tooltip> : nameDiv}
			</div>
			<div>({item.count})</div>
		</div>
	)
}
