import React, { ChangeEvent, useCallback, useRef, useState } from 'react'
import { FilterChangedEvent, RowClickedEvent } from '@ag-grid-community/core'
import { Button, Col, Input, Layout, Row, Skeleton, Tabs, Tooltip } from 'antd'
import { Issue, IssuesTabName, IssueStatGroup, IssueStatus } from '../../schemas/issue'
import FindingsIcon from '../../assets/findings_not_clicked_icon_20.svg?react'
import { IssueDrawer } from '../../components/drawers/issueDrawer'
import { FindingsSideBarMenu } from './FindingsSideBar'
import { MenuInfo } from 'rc-menu/lib/interface'
import SearchIcon from '../../assets/search_icon_20.svg?react'
import ClearFilterIcon from '../../assets/clear_filter_icon_20.svg?react'
import ExportIcon from '../../assets/export_icon_20.svg?react'
import { AgGridReact } from '@ag-grid-community/react'
import { FindingsStats } from './stats/FindingsStats'
import { LogRocketTrackEvent, trackEvent } from '../../services/logrocket/logrocket'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { sideBarFolder } from '../../schemas/sideBarMenu.ts'
import { getCsvFileName } from '../../utils.ts'
import { useEntitlementToIdentityPage, useFronteggUsers } from '../../services/auth'
import { useIssueSearch } from '../../api/issues.ts'
import { SUPPORTED_IDENTITY_PAGE_SOURCE_TYPES } from '../../schemas/identity.ts'
import { RoutePaths } from '../RoutePaths.tsx'
import { isDemo } from '../../utils/demoUtils.ts'
import { FindingsTablePaginated } from '../../tables/findings/FindingsTablePaginated.tsx'
import { useEntitlementToPaginatedFindings } from '../../services/auth/hooks.ts'
import { FindingsTable } from '../../tables/findings/FindingsTable.tsx'

const TabToStatuses: Record<IssuesTabName, IssueStatus[]> = {
	[IssuesTabName.OPEN]: [IssueStatus.OPEN, IssueStatus.IN_PROGRESS],
	[IssuesTabName.RESOLVED]: [IssueStatus.RESOLVED],
	[IssuesTabName.SUPPRESSED]: [IssueStatus.IGNORED, IssueStatus.FALSE_POSITIVE],
	[IssuesTabName.ALL]: [
		IssueStatus.OPEN,
		IssueStatus.IN_PROGRESS,
		IssueStatus.RESOLVED,
		IssueStatus.IGNORED,
		IssueStatus.FALSE_POSITIVE,
	],
}

const TabToTooltip: Partial<Record<IssuesTabName, string>> = {
	[IssuesTabName.OPEN]: 'Open and in-progress issues',
	[IssuesTabName.SUPPRESSED]: 'Ignored and false-positive issues',
}

const { Content, Sider } = Layout
export const Findings = () => {
	const navigate = useNavigate({ from: RoutePaths.Findings })
	const {
		issueName: searchIssueName,
		tabName: searchTabName,
		mostWanted: searchMostWanted,
		issueFilter: searchIssueFilter,
		nonHuman: searchNonHuman,
		human: searchHuman,
		issueTag: searchIssueTag,
		cloudProvider: searchCloudProvider,
		identityProvider: searchIdentityProvider,
		environment: searchEnvironment,
		myIssues: searchMyIssues,
		relatedTickets: searchRelatedTickets,
	} = useSearch({ from: RoutePaths.Findings })
	const { isEntitled: isEntitledToPaginatedTable } = useEntitlementToPaginatedFindings()

	const gridRef: React.RefObject<AgGridReact | null> = useRef(null)
	const [searchText, setSearchText] = useState('')
	const [gridReady, setGridReady] = useState(false)
	const [filterChanged, setFilterChanged] = useState({ value: false })
	const [tabItems] = useState(() =>
		Object.values(IssuesTabName).map((tabName) => ({
			key: tabName,
			label: TabToTooltip[tabName] ? <Tooltip title={TabToTooltip[tabName]}>{tabName}</Tooltip> : tabName,
			children: null,
		})),
	)

	const { data: issuesData, isLoading: isIssuesLoading } = useIssueSearch({
		issueName: searchIssueName,
		issueStatuses: TabToStatuses[searchTabName || IssuesTabName.OPEN],
		mostWanted: searchMostWanted,
		nonHuman: searchNonHuman,
		issueTag: searchIssueTag,
		human: searchHuman,
		cloudProvider: searchCloudProvider,
		identityProvider: searchIdentityProvider,
		myIssues: searchMyIssues,
		relatedTickets: searchRelatedTickets,
	})

	const { data: fronteggUsersData, isLoading: isFronteggUsersLoading } = useFronteggUsers()
	const { isEntitled: isEntitledToIdentityPage } = useEntitlementToIdentityPage()

	const onTabChange = (activeKey: string) => {
		gridRef?.current?.api?.setFilterModel(null)
		void navigate({
			to: RoutePaths.Findings,
			search: (prev) => ({
				...prev,
				tabName: activeKey as IssuesTabName,
				issueFilter: undefined,
				environment: undefined,
			}),
		})
	}
	const onFindingsSideBarMenuClick = (e: MenuInfo) => {
		const sideBarFolderItem = JSON.parse(e.key) as sideBarFolder
		switch (sideBarFolderItem.folder) {
			case IssueStatGroup.mostWanted: {
				navigate({
					search: () => ({
						tabName: IssuesTabName.OPEN,
						mostWanted: true,
					}),
				})

				break
			}
			case IssueStatGroup.nonHuman: {
				navigate({
					search: () => ({
						tabName: IssuesTabName.OPEN,
						issueName: sideBarFolderItem.file,
						nonHuman: true,
					}),
				})
				break
			}
			case IssueStatGroup.AllCount: {
				navigate({
					search: () => ({
						tabName: IssuesTabName.OPEN,
						issueName: 'All',
					}),
				})
				break
			}
			case IssueStatGroup.Findings: {
				trackEvent(LogRocketTrackEvent.IssueNameInSideBarClicked, { IssueName: sideBarFolderItem.file })
				navigate({
					search: () => ({
						tabName: IssuesTabName.OPEN,
						issueName: sideBarFolderItem.file,
					}),
				})
				break
			}
			case IssueStatGroup.human: {
				navigate({
					search: () => ({
						tabName: IssuesTabName.OPEN,
						issueName: sideBarFolderItem.file,
						human: true,
					}),
				})
				break
			}
			case IssueStatGroup.tag: {
				navigate({
					search: () => ({
						tabName: IssuesTabName.OPEN,
						issueTag: sideBarFolderItem.file,
					}),
				})
				break
			}
			case IssueStatGroup.cloudProvider: {
				navigate({
					search: () => ({
						tabName: IssuesTabName.OPEN,
						cloudProvider: sideBarFolderItem.file,
					}),
				})
				break
			}
			case IssueStatGroup.identityProvider: {
				navigate({
					search: () => ({
						tabName: IssuesTabName.OPEN,
						identityProvider: sideBarFolderItem.file,
					}),
				})
				break
			}
			case IssueStatGroup.MyIssues: {
				navigate({
					search: () => ({
						tabName: IssuesTabName.OPEN,
						myIssues: true,
					}),
				})
				break
			}
			case IssueStatGroup.RelatedTickets: {
				navigate({
					search: () => ({
						tabName: IssuesTabName.OPEN,
						relatedTickets: true,
					}),
				})
				break
			}
			default: {
				console.error('Unknown sideBarFolderItem.folder:', sideBarFolderItem.folder)
			}
		}
		gridRef?.current?.api?.setFilterModel(null) // clear the filter when the sidebar is clicked
	}

	const onIssueClicked = (row: RowClickedEvent<Issue>) => {
		if (row.node.group) return

		if (
			isEntitledToIdentityPage &&
			row.data?.identity?.source &&
			SUPPORTED_IDENTITY_PAGE_SOURCE_TYPES.includes(row.data.identity.source)
		) {
			void navigate({
				to: RoutePaths.Identity,
				params: { identityId: row.data.identity.id },
				search: () => ({ issueId: row.data?.id }),
			})
			return
		}

		void navigate({
			search: (prev) => ({ ...prev, issueId: row.data?.id ?? '' }),
		})
	}

	// this if for the drawer to be inside the layout
	const containerStyle: React.CSSProperties = {
		position: 'relative',
		overflow: 'hidden',
	}
	const siderStyle: React.CSSProperties = {
		lineHeight: '120px',
		backgroundColor: '#353535',
		boxShadow: '0px 0px 10px 0px #0000001A',
	}

	const onFilterTextBoxChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		trackEvent(LogRocketTrackEvent.IssuesTextSearchChanged, {})
		setSearchText(e.currentTarget.value)
	}, [])

	const onIssueTableFilterChanged = useCallback(
		(_event: FilterChangedEvent<Issue>) => {
			const issueFilter = gridRef?.current?.api?.getFilterModel()
			if (!issueFilter) return // if the filter is empty, do nothing
			// check if searchEnvironment need to be updated
			const environments = new Set<string>()

			if (issueFilter['identity.accountLiteral']) {
				const { values } = issueFilter['identity.accountLiteral'] as { values?: string[] }
				values?.forEach((env: string) => {
					if (env) {
						environments.add(env.split(' - ')[0])
					}
				})
			}
			let newSearchEnvironment: string | undefined = undefined
			if (environments.size === 1) {
				// if there is only one environment, update the searchEnvironment
				// check if the environment is the same as the current searchEnvironment
				if (searchEnvironment === environments.values().next().value) {
					// if it is not the same, then update the searchEnvironment
					newSearchEnvironment = searchEnvironment
				}
			}
			navigate({
				search: (prev) => ({
					...prev,
					issueFilter,
					environment: newSearchEnvironment,
				}),
			})
			setFilterChanged({ value: true })
		},
		[searchIssueFilter],
	)
	const clearFilters = useCallback(() => {
		gridRef?.current?.api?.setFilterModel(null)
		setSearchText('')
		navigate({
			search: (prev) => ({ ...prev, environment: undefined }),
		})
		// no need to set filterChanged here, because the filterChanged will be set in the onIssueTableFilterChanged
	}, [])

	const exportToCsv = useCallback(() => {
		gridRef?.current?.api?.exportDataAsCsv({ fileName: getCsvFileName('issues') })
	}, [])

	return (
		<Layout className="h-full rounded-md">
			<Sider className="overflow-y-auto" width="15%" style={siderStyle}>
				<FindingsSideBarMenu onFindingsSideBarMenuClick={onFindingsSideBarMenuClick} />
			</Sider>
			<Content className="overflow-y-clip">
				<div className="h-full flex flex-col" style={containerStyle}>
					<div
						style={{
							backgroundColor: '#FFFFFF',
							border: '2px solid #FFFFFF',
							boxShadow: '0px 0px 10px 0px #0000001A',
						}}
						className="mb-2 rounded"
					>
						<div
							style={{
								border: '2px solid #FFFFFF',
								boxShadow: '0px 0px 10px 0px #0000001A',
							}}
							className="flex flexrow"
						>
							<div className="flex flex-row items-center p-2">
								<FindingsIcon />
								<span className="text-2xl font-semibold pl-1 whitespace-nowrap">{`${isDemo ? 'Security Posture' : 'Findings'}`}</span>
							</div>
							<div className="flex w-5/6 justify-center items-center content-center">
								<Tabs
									className="full-width-tabs"
									defaultActiveKey="Open"
									onChange={onTabChange}
									activeKey={searchTabName}
									size="large"
									tabBarStyle={{
										marginBottom: '0px',
									}}
									items={tabItems}
								/>
							</div>
						</div>
						{isIssuesLoading ? (
							<Skeleton className="m-2 max-w-lg" loading={isIssuesLoading} active />
						) : (
							<Row>
								<FindingsStats
									issuesData={issuesData}
									gridRef={gridRef}
									filterChanged={filterChanged}
									gridReady={gridReady}
								/>
							</Row>
						)}
					</div>
					{isIssuesLoading || isFronteggUsersLoading ? (
						<Skeleton className="m-2" loading={isIssuesLoading || isFronteggUsersLoading} active />
					) : (
						<>
							<Row className="px-2 items-center justify-between space-x-2">
								<Col className="flex flex-row items-center space-x-2">
									<Input
										value={searchText}
										size="middle"
										onChange={onFilterTextBoxChanged}
										allowClear={true}
										placeholder="Search"
										prefix={<SearchIcon />}
									/>
									<Tooltip placement="topLeft" title="Clear filters">
										<Button
											icon={<ClearFilterIcon />}
											className="bg-white text-gray-500 !p-1"
											onClick={clearFilters}
										/>
									</Tooltip>
								</Col>
								{!isEntitledToPaginatedTable && (
									<Col>
										<Button
											icon={<ExportIcon className="inline-block" />}
											className="bg-white text-gray-500 px-2 flex items-start"
											onClick={exportToCsv}
										>
											Export CSV
										</Button>
									</Col>
								)}
							</Row>
							<Row className="h-full w-full flex">
								{isEntitledToPaginatedTable ? (
									<FindingsTablePaginated
										fronteggUsers={fronteggUsersData!}
										setGridReady={setGridReady}
										gridReady={gridReady}
										setFilterChanged={setFilterChanged}
										issuesTableData={issuesData}
										onIssueClicked={onIssueClicked}
										searchText={searchText}
										gridRef={gridRef}
										onIssueTableFilterChanged={onIssueTableFilterChanged}
										searchMostWanted={searchMostWanted}
									/>
								) : (
									<FindingsTable
										fronteggUsers={fronteggUsersData!}
										setGridReady={setGridReady}
										gridReady={gridReady}
										setFilterChanged={setFilterChanged}
										issuesTableData={issuesData}
										onIssueClicked={onIssueClicked}
										searchText={searchText}
										gridRef={gridRef}
										onIssueTableFilterChanged={onIssueTableFilterChanged}
										searchMostWanted={searchMostWanted}
									/>
								)}
							</Row>
						</>
					)}
					<IssueDrawer route={RoutePaths.Findings} />
				</div>
			</Content>
		</Layout>
	)
}
