import React, { useMemo } from 'react'
import { AgChartsReact } from 'ag-charts-react'
import {
	AgAreaSeriesOptions,
	AgChartOptions,
	AgLineSeriesTooltipRendererParams,
	AgTooltipRendererResult,
} from 'ag-charts-enterprise'
import { noDataOverlay } from './helpers.tsx'
import { formatDate } from '../utils.ts'
import { PriorityType, PriorityTypeEnum, PriorityTypeMap, PriorityTypeNameToNumber } from '../schemas/issue.ts'
import { getPriorityColor, getPriorityColorWithOpacity } from '../tables/helpers.ts'
import { useIdentitiesDistByPriorityAndDate } from '../api/metrics.ts'
import { IdentitiesDistByPriorityAndDate } from '../schemas/metrics/identitiesDistByPriority.ts'
import { Skeleton } from 'antd'
import { themeColors } from '../utils/colorUtils.ts'

const chartRenderer =
	(title: string, source: PriorityTypeEnum) =>
	(params: AgLineSeriesTooltipRendererParams): string | AgTooltipRendererResult => {
		const data = params.datum as { date: Date } & Record<PriorityTypeEnum, string>
		if (!data) return { title: '', content: '' }
		return {
			title: `${title} Risk Identities`,
			content: `${formatDate(data.date, false)} - ${data[source]}`,
			backgroundColor: getPriorityColor(PriorityTypeNameToNumber[source]),
		}
	}

const getChartSeries = (yKey: PriorityTypeEnum): AgAreaSeriesOptions => {
	const priorityNumber = PriorityTypeNameToNumber[yKey]
	const yName = PriorityTypeMap[priorityNumber]
	return {
		type: 'area',
		xKey: 'date',
		yKey: yKey,
		yName: yName,
		stroke: getPriorityColor(priorityNumber),
		strokeWidth: 1,
		stacked: true,
		tooltip: {
			renderer: chartRenderer(yName, yKey),
		},
	}
}

const formatData = (data: IdentitiesDistByPriorityAndDate[]) =>
	data
		.map((item) => ({
			date: new Date(item.date),
			[PriorityTypeEnum.LOW]: item.low,
			[PriorityTypeEnum.MEDIUM]: item.medium,
			[PriorityTypeEnum.HIGH]: item.high,
			[PriorityTypeEnum.CRITICAL]: item.critical,
		}))
		.toSorted((a, b) => a.date.getTime() - b.date.getTime())

export const IdentitiesByRiskChart: React.FunctionComponent = () => {
	const { data: rawData, isLoading } = useIdentitiesDistByPriorityAndDate()
	const formattedData = useMemo(() => {
		if (rawData) return formatData(rawData)
		return []
	}, [rawData])

	if (isLoading) {
		return <Skeleton active />
	}

	const options: AgChartOptions = {
		overlays: {
			noData: {
				renderer: noDataOverlay,
			},
		},
		data: formattedData,
		background: {
			fill: themeColors.neutral[100],
		},
		theme: {
			palette: {
				fills: [
					getPriorityColorWithOpacity(PriorityType.LOW),
					getPriorityColorWithOpacity(PriorityType.MEDIUM),
					getPriorityColorWithOpacity(PriorityType.HIGH),
					getPriorityColorWithOpacity(PriorityType.CRITICAL),
				],
			},
		},
		autoSize: true,
		axes: [
			{
				type: 'time',
				position: 'bottom',
				label: {
					enabled: true,
					format: '%d-%m-%Y',
				},
				nice: false,
			},
			{
				type: 'number',
				position: 'left',
			},
		],
		series: [
			getChartSeries(PriorityTypeEnum.LOW),
			getChartSeries(PriorityTypeEnum.MEDIUM),
			getChartSeries(PriorityTypeEnum.HIGH),
			getChartSeries(PriorityTypeEnum.CRITICAL),
		],
	}

	return <AgChartsReact options={options} />
}
