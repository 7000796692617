import { getColorFromTailwindColorClassName } from '../../../../utils/colorUtils.ts'
import { isDev } from '../../../../utils/devUtils.ts'
import { IdentityGraphNodeType } from '../identityGraphTypes.ts'

type ColorClassNames = {
	bg: string
	bgHighlighted: string
	bgHover: string
	badgeBg: string
	badgeBorder: string
	badgeText: string
}

/**
 * Mapping of kinds of nodes to their respective color. Using a constant for the class names instead of constructing
 * them dynamically to allow tailwind to pick up these class names and generate them.
 */
export const nodeColorClassNames = {
	credentials: {
		bg: 'bg-fuchsia-800',
		bgHighlighted: 'bg-fuchsia-600',
		bgHover: 'hover:bg-fuchsia-900',
		badgeBg: 'bg-fuchsia-100',
		badgeBorder: 'border-fuchsia-800',
		badgeText: 'text-fuchsia-800',
	},
	dependencies: {
		bg: 'bg-cyan-700',
		bgHighlighted: 'bg-cyan-500',
		bgHover: 'hover:bg-cyan-800',
		badgeBg: 'bg-cyan-100',
		badgeBorder: 'border-cyan-700',
		badgeText: 'text-cyan-700',
	},
	permission: {
		bg: 'bg-blue-800',
		bgHighlighted: 'bg-blue-500',
		bgHover: 'hover:bg-blue-900',
		badgeBg: 'bg-blue-100',
		badgeBorder: 'border-blue-800',
		badgeText: 'text-blue-800',
	},
	environment: {
		bg: 'bg-purple-800',
		bgHighlighted: 'bg-purple-500',
		bgHover: 'hover:bg-purple-900',
		badgeBg: 'bg-purple-100',
		badgeBorder: 'border-purple-800',
		badgeText: 'text-purple-800',
	},
	identity: {
		bg: 'bg-green-800',
		bgHighlighted: 'bg-green-600',
		bgHover: 'hover:bg-green-900',
		badgeBg: 'bg-green-100',
		badgeBorder: 'border-green-800',
		badgeText: 'text-green-800',
	},
	issue: {
		bg: 'bg-red-700',
		bgHighlighted: 'bg-red-400',
		bgHover: 'hover:bg-red-800',
		badgeBg: 'bg-red-100',
		badgeBorder: 'border-red-700',
		badgeText: 'text-red-700',
	},
} satisfies Record<string, ColorClassNames>

export type NodeColorTypes = keyof typeof nodeColorClassNames

/** Turning the node colors class names into actual colors, keeping the same structure. */
const nodeColors = Object.entries(nodeColorClassNames).reduce(
	(colorsAcc, colorsValue) => {
		return {
			...colorsAcc,
			[colorsValue[0]]: Object.entries(colorsValue[1]).reduce((acc, value) => {
				return { ...acc, [value[0]]: getColorFromTailwindColorClassName(value[1]) }
			}, {} as ColorClassNames),
		}
	},
	{} as Record<NodeColorTypes, ColorClassNames>,
)

export function getMinimapNodeColor(node: IdentityGraphNodeType): string {
	switch (node.type) {
		case 'accessKey':
		case 'oauthToken':
		case 'pat':
		case 'entraIdCredential':
		case 'gcpAccessKey':
		case 'githubUserToken':
		case 'salesforceConsumerKey':
			return nodeColors.credentials.bg
		case 'ec2Instances':
		case 'kubernetesResources':
		case 'azureVirtualMachines':
		case 'azureKeyVault':
			return nodeColors.dependencies.bg
		case 'awsPolicy':
		case 'awsPolicies':
		case 'azureManagementGroup':
		case 'azureManagementGroups':
		case 'enrichedAzureRole':
		case 'enrichedAzureRoles':
		case 'detailedEntraIdRole':
		case 'detailedEntraIdRoles':
		case 'databricksRole':
		case 'gcpRole':
		case 'gcpRoles':
		case 'awsIamRole':
		case 'awsIamRoles':
		case 'snowflakeRole':
		case 'snowflakeRoles':
		case 'githubAppPermission':
		case 'salesforceProfile':
		case 'salesforcePermissionSet':
		case 'salesforcePermissionSets':
		case 'salesforceConnectedAppMetadata':
			return nodeColors.permission.bg
		case 'azureSubscription':
		case 'azureSubscriptions':
		case 'awsAccount':
		case 'gcpProject':
		case 'gcpProjects':
		case 'githubRepository':
		case 'githubRepositories':
			return nodeColors.environment.bg
		case 'identity':
		case 'oktaUser':
		case 'entraIDUser':
		case 'jumpcloudUser':
		case 'entraIDUsers':
		case 'entraIDServicePrincipal':
		case 'entraIDServicePrincipals':
		case 'ownership':
			return nodeColors.identity.bg
		case 'issue':
			return nodeColors.issue.bg
	}

	if (isDev) {
		throw new Error(`Node type ${node.type} does not have a minimap color`)
	}

	return '#2e2e2e'
}
