import { Dispatch, memo, ReactNode, SetStateAction, useCallback } from 'react'
import { Collapse, CollapseProps } from 'antd'
import {
	BarChartOutlined,
	BuildOutlined,
	DatabaseOutlined,
	FileTextOutlined,
	MinusOutlined,
	PartitionOutlined,
	PlusOutlined,
	SwitcherOutlined,
	UserOutlined,
	WarningOutlined,
} from '@ant-design/icons'
import { Identity, IdentitySource } from '../../../schemas/identity.ts'
import { SidePanelIssues } from './SidePanelIssues.tsx'
import { PermissionsCollapseItemKeys, SidePanelPermissions } from './SidePanelPermissions.tsx'
import { SidePanelOwnership } from './SidePanelOwnership.tsx'
import { SidePanelProperties } from './SidePanelProperties.tsx'
import { SidePanelRisks } from './SidePanelRisks.tsx'
import { DependenciesCollapseItemKeys, SidePanelDependencies } from './SidePanelDependencies.tsx'
import { CrossContextCollapseItemKeys, SidePanelCrossContext } from './SidePanelCrossContext.tsx'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { omit } from 'lodash'
import { SidePanelUsage } from './SidePanelUsage.tsx'
import { SidePanelWorkspaces } from './SidePanelWorkspaces.tsx'
import { LastSelectedNode } from '../IdentityPageBody.tsx'
import { isDemo } from '../../../utils/demoUtils.ts'
import { useActiveSidePanelKeysContext } from '../ActiveSidePanelKeysContext.tsx'
import { SidePanelTags } from './SidePanelTags.tsx'
import IdentityTagIcon from '../../../assets/identityTagIcons/identity_tag_icon_16.svg?react'

const CollapseLabel = ({ icon, title }: { icon: ReactNode; title: string }) => (
	<span className="flex gap-2 items-center">
		{icon}
		{title}
	</span>
)

export type SidePanelKey =
	| 'Issues'
	| 'Properties'
	| 'Permissions'
	| 'Risks'
	| 'Ownership'
	| 'Dependencies'
	| 'Usage'
	| 'Cross-Context'
	| 'Workspaces'
	| 'Tags'

type CollapseItem = Required<CollapseProps>['items'][number] & {
	key: SidePanelKey
	hide: boolean
}

type IdentitySidePanelProps = {
	identity: Identity
	lastSelectedNode: LastSelectedNode | null
	activeDependenciesKeys: DependenciesCollapseItemKeys[]
	setActiveDependenciesKeys: Dispatch<SetStateAction<DependenciesCollapseItemKeys[]>>
	activeCrossContextKeys: CrossContextCollapseItemKeys[]
	setActiveCrossContextKeys: Dispatch<SetStateAction<CrossContextCollapseItemKeys[]>>
	activePermissionsKeys: PermissionsCollapseItemKeys[]
	setActivePermissionsKeys: Dispatch<SetStateAction<PermissionsCollapseItemKeys[]>>
}

export const IdentitySidePanel = memo(
	({
		identity,
		lastSelectedNode,
		activeDependenciesKeys,
		setActiveDependenciesKeys,
		activeCrossContextKeys,
		setActiveCrossContextKeys,
		activePermissionsKeys,
		setActivePermissionsKeys,
	}: IdentitySidePanelProps) => {
		const { activeSidePanelKeys, setActiveSidePanelKeys } = useActiveSidePanelKeysContext()
		const onCollapseChange = useCallback(
			(key: string | string[]) => {
				const keyList = typeof key === 'string' ? [key as SidePanelKey] : (key as SidePanelKey[])
				setActiveSidePanelKeys(keyList)
			},
			[setActiveSidePanelKeys],
		)

		const collapseItemClassName = 'mb-2 !rounded bg-zinc-100 border border-zinc-200'
		// Override antd styles
		const collapseItemStyle = { borderBottom: '1px solid #E4E4E7' }
		const collapseItems: CollapseItem[] = [
			{
				key: 'Issues',
				label: <CollapseLabel icon={<WarningOutlined />} title="Issues" />,
				children: <SidePanelIssues identity={identity} />,
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide: false,
			},
			{
				key: 'Properties',
				label: <CollapseLabel icon={<DatabaseOutlined />} title="Properties" />,
				children: <SidePanelProperties identity={identity} />,
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide: false,
			},
			{
				key: 'Permissions',
				label: <CollapseLabel icon={<FileTextOutlined />} title="Permissions" />,
				children: (
					<SidePanelPermissions
						identity={identity}
						lastSelectedNode={lastSelectedNode}
						activePermissionsKeys={activePermissionsKeys}
						setActivePermissionsKeys={setActivePermissionsKeys}
					/>
				),
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide:
					identity.source === IdentitySource.POSTGRES_ROLE ||
					(identity.envType !== EnvironmentType.AWS &&
						identity.envType !== EnvironmentType.GCP &&
						identity.envType !== EnvironmentType.AZURE &&
						identity.envType !== EnvironmentType.ENTRA_ID &&
						identity.envType !== EnvironmentType.DATABRICKS &&
						identity.envType !== EnvironmentType.SNOWFLAKE &&
						identity.envType !== EnvironmentType.GITHUB &&
						identity.envType !== EnvironmentType.SALESFORCE),
			},
			{
				key: 'Workspaces',
				label: <CollapseLabel icon={<SwitcherOutlined />} title="Workspaces" />,
				children: <SidePanelWorkspaces identity={identity} />,
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide: identity.envType !== EnvironmentType.DATABRICKS,
			},
			{
				key: 'Risks',
				label: <CollapseLabel icon={<WarningOutlined />} title="Risks" />,
				children: <SidePanelRisks identity={identity} />,
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide: false,
			},
			{
				key: 'Ownership',
				label: <CollapseLabel icon={<UserOutlined />} title="Ownership" />,
				children: <SidePanelOwnership identity={identity} />,
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide:
					identity.source === IdentitySource.POSTGRES_ROLE ||
					(identity.envType !== EnvironmentType.AWS &&
						identity.envType !== EnvironmentType.AZURE &&
						!(identity.envType === EnvironmentType.GCP && isDemo) &&
						identity.envType !== EnvironmentType.ENTRA_ID &&
						identity.source !== IdentitySource.DATABRICKS_SERVICE_PRINCIPAL &&
						identity.source !== IdentitySource.SALESFORCE_USER &&
						identity.source !== IdentitySource.SALESFORCE_CONNECTED_APPLICATION &&
						identity.source !== IdentitySource.GITHUB_APP_INSTALLATION),
			},
			{
				key: 'Dependencies',
				label: <CollapseLabel icon={<BuildOutlined />} title="Dependencies" />,
				children: (
					<SidePanelDependencies
						identity={identity}
						activeDependenciesKeys={activeDependenciesKeys}
						setActiveDependenciesKeys={setActiveDependenciesKeys}
					/>
				),
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide:
					identity.envType !== EnvironmentType.AWS &&
					identity.envType !== EnvironmentType.AZURE &&
					identity.envType !== EnvironmentType.GITHUB &&
					identity.source !== IdentitySource.GCP_SERVICE_ACCOUNT &&
					identity.source !== IdentitySource.DATABRICKS_SERVICE_PRINCIPAL &&
					!(identity.source === IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL && isDemo),
			},
			{
				key: 'Usage',
				label: <CollapseLabel icon={<BarChartOutlined />} title="Usage" />,
				children: <SidePanelUsage identity={identity} />,
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide:
					identity.source === IdentitySource.POSTGRES_ROLE ||
					(identity.envType !== EnvironmentType.AWS &&
						identity.envType !== EnvironmentType.AZURE &&
						identity.source !== IdentitySource.GCP_SERVICE_ACCOUNT &&
						identity.source !== IdentitySource.ENTRA_ID_USER &&
						identity.source !== IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL &&
						identity.envType !== EnvironmentType.GITHUB &&
						identity.envType !== EnvironmentType.SALESFORCE &&
						identity.source !== IdentitySource.OKTA),
			},
			{
				key: 'Cross-Context',
				label: <CollapseLabel icon={<PartitionOutlined />} title="Cross-Context" />,
				children: (
					<SidePanelCrossContext
						identity={identity}
						activeCrossContextKeys={activeCrossContextKeys}
						setActiveCrossContextKeys={setActiveCrossContextKeys}
						lastSelectedNode={lastSelectedNode}
					/>
				),
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide: identity.envType
					? ![
							EnvironmentType.AWS,
							EnvironmentType.GITHUB,
							EnvironmentType.ENTRA_ID,
							EnvironmentType.OKTA,
							EnvironmentType.SNOWFLAKE,
							EnvironmentType.JUMPCLOUD,
							EnvironmentType.SALESFORCE,
						].includes(identity.envType)
					: true,
			},
			{
				key: 'Tags',
				label: <CollapseLabel icon={<IdentityTagIcon />} title="Tags" />,
				children: <SidePanelTags identity={identity} />,
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide:
					identity.source !== IdentitySource.AWS_IAM_USER && identity.source !== IdentitySource.AWS_IAM_ROLE,
			},
		]

		return (
			<div className="p-2 overflow-y-auto h-full">
				<Collapse
					ghost
					onChange={onCollapseChange}
					activeKey={activeSidePanelKeys}
					items={collapseItems.filter((item) => !item.hide).map((item) => omit(item, 'hide'))}
					expandIconPosition="end"
					className="bg-inherit"
					expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
				/>
			</div>
		)
	},
)
