import { camelizeSchema } from '../utils.ts'
import { z } from 'zod'
import { EnvironmentType } from '../envType.ts'

export const ServerEnvTypeIdentitiesCountSchema = z.object({
	total_count: z.number(),
	admin_count: z.number(),
	env_type: z.nativeEnum(EnvironmentType),
})

export type ServerEnvTypeIdentitiesCount = z.infer<typeof ServerEnvTypeIdentitiesCountSchema>

export const EnvTypeIdentitiesCountSchema = camelizeSchema(ServerEnvTypeIdentitiesCountSchema.transform((val) => val))
export type EnvTypeIdentitiesCount = z.infer<typeof EnvTypeIdentitiesCountSchema>

export enum OtherEnvironmentType {
	OTHER = 'Other',
}

export type EnvTypeOrOther = EnvironmentType | OtherEnvironmentType
export type EnvTypeOrOtherIdentitiesCount = Omit<EnvTypeIdentitiesCount, 'envType'> & { envType: EnvTypeOrOther }
