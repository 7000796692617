import { z } from 'zod'
import { DateSchema } from '../common'
import { camelizeSchema } from '../utils.ts'
import { ServerAbstractEnvironmentModelSchema } from './serverAbstractEnvironmentSchema.ts'

export const ServerAwsAccountCloudtrailSchema = ServerAbstractEnvironmentModelSchema.extend({
	trail_arn: z.string(),
	aws_account_id: z.string(),
	cloudtrail_region: z.string().nullish(),
	is_organization_trail: z.boolean().nullish(),
	first_log_date: DateSchema.nullish(),
})

export type ServerAwsAccountCloudtrail = z.infer<typeof ServerAwsAccountCloudtrailSchema>

export const AwsAccountCloudtrailSchema = camelizeSchema(ServerAwsAccountCloudtrailSchema.transform((val) => val))
export type AwsAccountCloudtrail = z.infer<typeof AwsAccountCloudtrailSchema>
