import { z } from 'zod'
import { camelizeSchema } from '../utils.ts'
import { ServerAbstractEnvironmentModelSchema } from './serverAbstractEnvironmentSchema.ts'

export const ServerJumpcloudEnvironmentSchema = ServerAbstractEnvironmentModelSchema.extend({
	org_id: z.string(),
})

export type ServerJumpcloudEnvironment = z.infer<typeof ServerJumpcloudEnvironmentSchema>

export const JumpcloudEnvironmentSchema = camelizeSchema(ServerJumpcloudEnvironmentSchema.transform((val) => val))
export type JumpcloudEnvironment = z.infer<typeof JumpcloudEnvironmentSchema>
