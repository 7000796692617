import { NodeProps, Node } from '@xyflow/react'
import { KubernetesOutlined } from '@ant-design/icons'
import { KubernetesResourceXc } from '../../../../schemas/identities/kubernetes/kubernetesResourceXcSchema.ts'
import { BaseDependenciesNode } from './baseNodes/BaseDependenciesNode.tsx'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'

export type KubernetesResourcesNodeType = Node<{ resources: KubernetesResourceXc[] }, 'kubernetesResources'>
type KubernetesResourcesNodeProps = NodeProps<KubernetesResourcesNodeType>

export const KubernetesResourcesNode = ({ data: { resources } }: KubernetesResourcesNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'kubernetesResources')
	return (
		<BaseDependenciesNode
			resources={resources}
			isHighlighted={isHighlighted}
			nodeTypename="Kubernetes Resources"
			tooltipTitle={`Identity is used in ${resources.length} Kubernetes ${
				resources.length === 1 ? 'resource' : 'resources'
			}`}
			Icon={KubernetesOutlined}
		/>
	)
}
