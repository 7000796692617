import { useMemo } from 'react'
import { AgChartsReact } from 'ag-charts-react'
import { ResolvedVsUnresolved } from '../schemas/metrics/resolvedVsUnresolved.ts'
import { noDataOverlay } from './helpers.tsx'
import { formatDate, formatDuration } from '../utils.ts'
import { AgChartOptions } from 'ag-charts-enterprise'
import { useMetricsSummary, useResolvedVsUnresolvedMetrics } from '../api/metrics.ts'
import { Skeleton } from 'antd'
import { themeColors } from '../utils/colorUtils.ts'
import { MetricsChartContainer } from '../routes/Metrics/MetricsChartContainer.tsx'

const resolvedColor = '#a0a3e9'
const unresolvedColor = '#f488b0'
const resolvedColorWithOpacity = `${resolvedColor}4d`
const unresolvedColorWithOpacity = `${unresolvedColor}4d`

export const ResolvedVsUnresolvedFindingsChartV2 = ({ size }: { size: 'small' | 'large' }) => {
	const { data: metricsSummary } = useMetricsSummary()
	const { data: resolvedVsUnresolvedMetrics, isLoading: isLoadingResolvedVsUnresolvedMetrics } =
		useResolvedVsUnresolvedMetrics()
	const normalizedData = useMemo(() => {
		return resolvedVsUnresolvedMetrics?.toSorted((a, b) => a.date.getTime() - b.date.getTime())
	}, [resolvedVsUnresolvedMetrics])

	const options: AgChartOptions = {
		overlays: {
			noData: {
				renderer: noDataOverlay,
			},
		},
		data: normalizedData,
		background: {
			fill: themeColors.neutral[100],
		},
		autoSize: true,
		axes: [
			{
				type: 'time',
				position: 'bottom',
				label: {
					enabled: true,
					format: '%d-%m-%Y',
				},
				nice: false,
			},
			{
				type: 'number',
				position: 'left',
			},
		],
		theme: {
			palette: {
				fills: [resolvedColorWithOpacity, unresolvedColorWithOpacity],
			},
		},
		series: [
			{
				type: 'area',
				stacked: true,
				xKey: 'date',
				yKey: 'resolved',
				yName: 'Resolved',
				stroke: resolvedColor,
				strokeWidth: 2,
				tooltip: {
					renderer: ({ datum }: { datum: ResolvedVsUnresolved }) => {
						return {
							title: formatDate(datum.date, false),
							content: `Resolved Findings: ${datum.resolved}`,
							backgroundColor: resolvedColor,
						}
					},
				},
			},
			{
				type: 'area',
				stacked: true,
				xKey: 'date',
				yKey: 'unresolved',
				yName: 'Unresolved',
				stroke: unresolvedColor,
				strokeWidth: 2,
				tooltip: {
					renderer: ({ datum }: { datum: ResolvedVsUnresolved }) => {
						return {
							title: formatDate(datum.date, false),
							content: `Unresolved Findings: ${datum.unresolved}`,
							backgroundColor: unresolvedColor,
						}
					},
				},
			},
		],
	}
	return (
		<MetricsChartContainer
			className={size === 'small' ? 'col-span-3' : 'col-span-6'}
			header="Resolved vs Unresolved Findings"
		>
			<div className="flex gap-1">
				<span>Average time to resolution:</span>
				<span className="font-semibold text-neutral-600">
					{metricsSummary?.timeToResolve ? formatDuration(metricsSummary.timeToResolve) : 'Loading ...'}
				</span>
			</div>
			{isLoadingResolvedVsUnresolvedMetrics ? <Skeleton active /> : <AgChartsReact options={options} />}
		</MetricsChartContainer>
	)
}
