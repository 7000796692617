export enum FeatureFlags {
	IDENTITY_PAGE = 'identity-page',
	SHOW_JIRA_BUTTON = 'show-jira-button',
	NEW_USAGE_TABLE = 'new-usage-table',
	SETTINGS_PAGE = 'settings-page',
	METRICS_PAGE_V2 = 'metrics_page_v2',
	PAGINATED_INVENTORY = 'paginated-inventory',
	INTEGRATIONS_PAGE = 'integrations-page',
	PAGINATED_FINDINGS = 'paginated-findings',
	SHOW_SWAGGER_API = 'show_swagger_api',
}
