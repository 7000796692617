import { z } from 'zod'
import { camelizeSchema } from '../../utils'
import { DateSchema } from '../../common'
import { ServerDatabricksPermissionSchema } from './ServerDatabricksPermissionSchema.ts'
import { TagSchema } from '../../tags.ts'

export const ServerDatabricksRoleSchema = z
	.object({
		role_id: z.string(),
		role_name: z.string(),
		description: z.string(),
		created_at: DateSchema,
		updated_at: DateSchema,
		permissions: z.array(ServerDatabricksPermissionSchema).nullable(),
		tags: TagSchema.array().nullish(),
	})
	.nullable()

export type ServerDatabricksRole = z.infer<typeof ServerDatabricksRoleSchema>

export const DatabricksRoleSchema = camelizeSchema(ServerDatabricksRoleSchema.transform((val) => val))
export type DatabricksRole = z.infer<typeof DatabricksRoleSchema>
