import { NodeProps, Node } from '@xyflow/react'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { BaseIdentityNode } from '../common/baseNodes/BaseIdentityNode.tsx'

type OktaUser = {
	displayName?: string | null
	type: string
}

export type OktaUserNodeType = Node<{ oktaUser: OktaUser }, 'oktaUser'>
type OktaUserNodeProps = NodeProps<OktaUserNodeType>

export const OktaUserNode = ({ data: { oktaUser } }: OktaUserNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'oktaUser')

	return (
		<BaseIdentityNode
			name={oktaUser.displayName ? { text: oktaUser.displayName } : null}
			type={oktaUser.type}
			isHighlighted={isHighlighted}
		/>
	)
}
