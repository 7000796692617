import React, { useCallback, useMemo, useRef, useState } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, SideBarDef } from '@ag-grid-community/core'
import { formatDate, getCsvFileName } from '../../utils'
import {
	SalesforceSetupAuditTrailAggregatedLog,
	SalesforceSetupAuditTrailLog,
} from '../../schemas/identities/salesforce/salesforceSetupAuditTrailLogSchema'
import { Button, Modal, Skeleton } from 'antd'
import ExportIcon from '../../assets/export_icon_20.svg?react'
import { Identity } from '../../schemas/identity'
import { useIdentityUsageLogs } from '../../api/identities'

type Props = {
	identity: Identity
}

export const SalesforceSetupAuditTrailLogsTable: React.FC<Props> = ({ identity }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [hasStartedLoadingDetails, setHasStartedLoadingDetails] = useState(false)

	const aggregatedGridRef = useRef<AgGridReact<SalesforceSetupAuditTrailAggregatedLog>>(null)
	const detailedGridRef = useRef<AgGridReact<SalesforceSetupAuditTrailLog>>(null)

	const { data: aggregatedData, isLoading: isLoadingAggregated } = useIdentityUsageLogs(identity.id, true)
	const {
		data: detailedData,
		isLoading: isLoadingDetailed,
		isFetching: isFetchingDetailed,
	} = useIdentityUsageLogs(identity.id, false, {
		enabled: hasStartedLoadingDetails,
		staleTime: 5 * 60 * 1000,
		refetchOnWindowFocus: false,
	})

	const handleOpenModal = useCallback(() => {
		setHasStartedLoadingDetails(true)
		setIsModalOpen(true)
	}, [])

	const sideBar: SideBarDef = useMemo(
		() => ({
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
				},
			],
			position: 'left',
		}),
		[],
	)

	const aggregatedColumnDefs = useMemo<ColDef<SalesforceSetupAuditTrailAggregatedLog>[]>(
		() => [
			{
				field: 'day',
				headerName: 'Day',
				flex: 1,
			},
			{
				field: 'sections',
				headerName: 'Sections',
				flex: 1,
				cellRenderer: (params: { value: string[] }) => params.value.join(', '),
			},
			{ field: 'actionsCount', headerName: 'Total Actions', flex: 1 },
		],
		[],
	)

	const detailedColumnDefs = useMemo<ColDef<SalesforceSetupAuditTrailLog>[]>(
		() => [
			{
				field: 'timestamp',
				headerName: 'Timestamp',
				flex: 1,
				cellRenderer: (params: { value: string }) => formatDate(params.value),
			},
			{ field: 'action', headerName: 'Action', flex: 1 },
			{ field: 'section', headerName: 'Section', flex: 1 },
			{
				field: 'display',
				headerName: 'Display',
				flex: 1,
				tooltipField: 'display',
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
				},
			},
		],
		[],
	)

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}),
		[],
	)

	const exportToCsv = useCallback(() => {
		if (isModalOpen) {
			detailedGridRef?.current!.api.exportDataAsCsv({
				fileName: getCsvFileName(`setup-audit-trail-logs-${identity.salesforceUser?.username}`, false),
			})
		} else {
			aggregatedGridRef?.current!.api.exportDataAsCsv({
				fileName: getCsvFileName(
					`setup-audit-trail-logs-aggregated-${identity.salesforceUser?.username}`,
					false,
				),
			})
		}
	}, [identity, isModalOpen])

	if (isLoadingAggregated) {
		return <Skeleton active />
	}

	const usageLogsAggregated = aggregatedData?.salesforceUserSetupAuditTrailLogsAggregated ?? []

	if (usageLogsAggregated.length === 0 && !isModalOpen) {
		return <div className="text-center">No Setup Audit Trail Logs available</div>
	}

	return (
		<>
			<div className="h-64 overflow-auto">
				<AgGridReact<SalesforceSetupAuditTrailAggregatedLog>
					rowData={usageLogsAggregated}
					columnDefs={aggregatedColumnDefs}
					defaultColDef={defaultColDef}
					ref={aggregatedGridRef}
					enableCellTextSelection
					className="ag-theme-alpine w-full h-full overflow-x-auto"
					rowHeight={54}
					enableBrowserTooltips
				/>
			</div>
			<div className="flex justify-between">
				<button className="mt-4 underline cursor-pointer text-blue-600" onClick={handleOpenModal}>
					See all events
				</button>
			</div>
			<Modal
				centered
				width={1024}
				title="Setup Audit Trail Logs"
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				footer={[
					<div key="footer" className="flex justify-between items-center">
						<div className="flex">
							<Button
								icon={<ExportIcon />}
								onClick={exportToCsv}
								className="bg-white text-gray-500 px-2 flex items-start mr-2"
								disabled={isLoadingDetailed || isFetchingDetailed}
							>
								Export CSV
							</Button>
							<Button className="bg-black text-white" onClick={() => setIsModalOpen(false)}>
								Done
							</Button>
						</div>
					</div>,
				]}
			>
				<div className="flex overflow-y-auto h-[70vh]">
					{isLoadingDetailed || isFetchingDetailed ? (
						<div className="w-full flex items-center justify-center">
							<Skeleton active />
						</div>
					) : !detailedData?.salesforceUserSetupAuditTrailLogs?.length ? (
						<div className="w-full flex items-center justify-center text-gray-500">
							No detailed logs available
						</div>
					) : (
						<AgGridReact<SalesforceSetupAuditTrailLog>
							className="ag-theme-alpine w-full"
							rowData={detailedData.salesforceUserSetupAuditTrailLogs}
							rowHeight={54}
							columnDefs={detailedColumnDefs}
							defaultColDef={defaultColDef}
							enableCellTextSelection
							sideBar={sideBar}
							ref={detailedGridRef}
							enableBrowserTooltips
						/>
					)}
				</div>
			</Modal>
		</>
	)
}
