import { camelizeSchema } from './utils.ts'
import { z } from 'zod'
import { ResourceType } from './issue.ts'

export enum InsightName {
	MissingSecurityHub = 'MissingSecurityHub',
	MissingSecurityAlternateContact = 'MissingSecurityAlternateContact',
	PasswordPolicy = 'PasswordPolicy',
	CrossAccountAccess = 'CrossAccountAccess',
	GuestUserAccess = 'GuestUserAccess',
	MFAEnforcement = 'MFAEnforcement',
	ConditionalAccess = 'ConditionalAccess',
}

export const ServerInsightSchema = z.object({
	id: z.string(),
	insight_name: z.nativeEnum(InsightName),
	insight_source: z.nativeEnum(ResourceType),
	description: z.string(),
	account_literal: z.string(),
	account_literal_friendly_name: z.string().nullable(),
})

export type ServerInsight = z.infer<typeof ServerInsightSchema>

export const InsightSchema = camelizeSchema(ServerInsightSchema.transform((val) => val))
export type Insight = z.infer<typeof InsightSchema>

export enum InsightNameMap {
	MissingSecurityHub = 'Missing Security Hub',
	MissingSecurityAlternateContact = 'Missing Security Alternate Contact',
	PasswordPolicy = 'Password Policy',
	CrossAccountAccess = 'Cross Account Access',
	GuestUserAccess = 'Guest User Access',
	MFAEnforcement = 'MFA Enforcement',
	ConditionalAccess = 'Conditional Access',
}
