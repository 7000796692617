import React, { useEffect, useState } from 'react'
import { Identity } from '../../../../schemas/identity.ts'
import { BaseIdentityDetail } from '../baseIdentities/BaseIdentityDetail.tsx'
import { IdentityTypeTag } from '../../../common/IdentityTypeTag.tsx'
import { IssueSourceIcon } from '../../../common/IssueSourceIcon.tsx'
import { formatDate } from '../../../../utils.ts'
import { Col, Divider, Row, Space, Tag, Tooltip } from 'antd'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import { EntraIdServicePrincipal } from '../../../../schemas/identities/entraId/entraIdServicePrincipalSchema.ts'
import { EntraIdSpAuthenticationCredential } from './EntraIdSpAuthenticationCredential.tsx'
import { normalizeCredentials } from '../../../../schemas/identities/entraId/entraIdApplicationXc.ts'
import { AzureRoleAssignmentsContext } from '../azure/azureRBAC/AzureRoleAssignmentsContext.tsx'
import { EntraIdChangeLogsContext } from './EntraIdChangeLogsContext.tsx'
import { AzureUsageLogsContent } from '../azure/AzureUsageLogsContent.tsx'

export const EntraIdServicePrincipalIdentity: React.FunctionComponent<{
	identity?: Identity | null
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const [contextAmount, setContextAmount] = useState(0)
	const entraIdServicePrincipal: EntraIdServicePrincipal | null | undefined = identity?.entraIdServicePrincipal

	useEffect(() => {
		calcAmountOfContext()
	}, [entraIdServicePrincipal])

	const calcAmountOfContext = () => {
		let amount = 0
		if (entraIdServicePrincipal?.azureRoleAssignmentsXc?.length) amount++
		if (entraIdServicePrincipal?.changeLogs?.length) amount++
		if (entraIdServicePrincipal?.usageLogs?.length) amount++
		// Add other cross-context checks here if needed in the future
		setContextAmount(amount)
	}

	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail
					label="Service Principal Id"
					value={entraIdServicePrincipal?.servicePrincipalId}
					span={1}
				/>
				<BaseIdentityDetail
					label="Service Principal Type"
					value={entraIdServicePrincipal?.servicePrincipalType}
					span={1}
				/>
				{entraIdServicePrincipal?.displayName ? (
					<BaseIdentityDetail label="Name" value={entraIdServicePrincipal?.displayName} span={1} />
				) : null}
				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Environment" span={1}>
					<div className="flex flex-row items-center space-x-2">
						<IssueSourceIcon source={envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
						<Tooltip placement="topLeft" title={identity?.accountLiteral}>
							<div className="font-normal text-gray-600 items-end truncate">
								{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
							</div>
						</Tooltip>
					</div>
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Application Id" value={entraIdServicePrincipal?.appId} span={1} />
				{entraIdServicePrincipal?.accountEnabled !== null ? (
					<BaseIdentityDetail label="Account status" span={1}>
						<Tag>
							<div className="flex items-center">
								<div
									className={`${entraIdServicePrincipal?.accountEnabled ? 'text-green-700' : 'text-gray-700'} text font-medium`}
								>
									{entraIdServicePrincipal?.accountEnabled ? 'Enabled' : 'Disabled'}
								</div>
							</div>
						</Tag>
					</BaseIdentityDetail>
				) : null}

				<BaseIdentityDetail label="Created At" value={formatDate(identity?.createdAt)} span={1} />
				{normalizeCredentials(
					entraIdServicePrincipal?.entraIdApplicationXc?.keyCredentials || [],
					entraIdServicePrincipal?.entraIdApplicationXc?.passwordCredentials || [],
				).map((credential, index) => (
					<BaseIdentityDetail key={credential.keyId} label={`Credential ${index + 1}`} span={1}>
						<EntraIdSpAuthenticationCredential credential={credential} />
					</BaseIdentityDetail>
				))}
			</div>
			<div className="w-full">
				{contextAmount > 0 && (
					<div className="w-full">
						<Divider />
						<Row>
							<Col>
								<div className="text-gray-900">Context ({contextAmount})</div>
							</Col>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{entraIdServicePrincipal?.azureRoleAssignmentsXc &&
									entraIdServicePrincipal?.azureRoleAssignmentsXc?.length > 0 && (
										<AzureRoleAssignmentsContext
											azureRoleAssignmentsXc={entraIdServicePrincipal.azureRoleAssignmentsXc}
										/>
									)}
							</Space>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{identity &&
									entraIdServicePrincipal?.changeLogs &&
									entraIdServicePrincipal?.changeLogs?.length > 0 && (
										<EntraIdChangeLogsContext
											changeLogs={entraIdServicePrincipal?.changeLogs || []}
											identity={identity}
										/>
									)}
							</Space>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{entraIdServicePrincipal?.usageLogs &&
									entraIdServicePrincipal?.usageLogs?.length > 0 && (
										<AzureUsageLogsContent
											usageLogs={entraIdServicePrincipal?.usageLogs}
											identity={identity as Identity}
										/>
									)}
							</Space>
						</Row>
					</div>
				)}
			</div>
		</>
	)
}
