import { IssueUpdate, ServerIssueUpdateSchema } from './../schemas/issues/issueUpdate'
import {
	ClientIssueSearch,
	Issue,
	IssueImpactProbabilityWithCount,
	IssueImpactProbabilityWithCountSchema,
	IssueSchema,
	IssueStats,
	IssueStatsSchema,
	ServerIssue,
	ServerIssueStats,
} from '../schemas/issue'
import { api } from './api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

const issuesApi = {
	async search(search?: ClientIssueSearch): Promise<Issue[]> {
		try {
			const { data: issues } = await api.post<ServerIssue[]>(`/api/issues/search`, search)
			return issues.map((issue) => IssueSchema.parse(issue))
		} catch (error) {
			console.error(error)
			return []
		}
	},
	async stats(): Promise<IssueStats[]> {
		try {
			const { data: issuesStats } = await api.get<ServerIssueStats[]>('/api/issues/stats')
			return issuesStats.map((issue) => IssueStatsSchema.parse(issue))
		} catch (error) {
			console.error(error)
			return []
		}
	},
	async byId(issueId: string): Promise<Issue> {
		const { data: issue } = await api.get<ServerIssue>(`/api/issues/${issueId}`)
		try {
			return IssueSchema.parse(issue)
		} catch (e) {
			console.dir(e)
			throw e
		}
	},
	async update(issueId: string, issueUpdate: IssueUpdate): Promise<Issue> {
		const { data: issue } = await api.put<ServerIssue>(
			`/api/issues/${issueId}`,
			ServerIssueUpdateSchema.parse({
				...issueUpdate,
				ignore_reason: issueUpdate.ignoreReason, // eslint-disable-line camelcase
			}),
		)

		return IssueSchema.parse(issue)
	},
	async types(): Promise<IssueImpactProbabilityWithCount[]> {
		const { data: issueTypes } = await api.get<IssueImpactProbabilityWithCount[]>(`/api/issues/types`)
		return issueTypes.map((type) => IssueImpactProbabilityWithCountSchema.parse(type))
	},
	async updateIssueTypes(issueTypes: IssueImpactProbabilityWithCount[]): Promise<void> {
		await api.put(`/api/issues/types`, issueTypes)
	},
}

const issueKeys = {
	issueSearches: () => ['issueSearch'] as const,
	issueSearch: (search?: ClientIssueSearch) => [...issueKeys.issueSearches(), search] as const,
	issueStats: () => ['issueStats'] as const,
	issue: (issueId: string) => ['issue', issueId] as const,
	updateIssue: () => ['updateIssue'] as const,
	issueTypes: () => ['types'] as const,
	updateIssueTypes: () => ['updateIssueTypes'] as const,
}

export function useIssueSearch(search?: ClientIssueSearch) {
	if (['ALL'].includes(search?.issueName?.toUpperCase() as string)) {
		delete search?.issueName
	}

	return useQuery({
		queryKey: issueKeys.issueSearch(search),
		queryFn: () => issuesApi.search(search),
		cacheTime: 60 * 60 * 1000, // 1 Hour
	})
}

export function useIssuesStats() {
	return useQuery({
		queryKey: issueKeys.issueStats(),
		queryFn: () => issuesApi.stats(),
		cacheTime: 60 * 60 * 1000, // 1 Hour
	})
}

export function useIssue(issueId: string) {
	return useQuery({
		queryKey: issueKeys.issue(issueId),
		queryFn: () => issuesApi.byId(issueId),
		enabled: !!issueId,
	})
}

export function useUpdateIssue() {
	const queryClient = useQueryClient()

	return useMutation({
		mutationKey: issueKeys.updateIssue(),
		mutationFn: ({ issueId, issueUpdate }: { issueId: string; issueUpdate: IssueUpdate }) =>
			issuesApi.update(issueId, issueUpdate),
		onSuccess: (data) => {
			queryClient.setQueryData(issueKeys.issue(data.id), data)
			void queryClient.invalidateQueries({ queryKey: issueKeys.issueSearches(), refetchType: 'active' })
			void queryClient.invalidateQueries({ queryKey: issueKeys.issueStats(), refetchType: 'active' })
		},
	})
}

export function useIssueTypes() {
	return useQuery({
		queryKey: issueKeys.issueTypes(),
		queryFn: () => issuesApi.types(),
		staleTime: Infinity,
		cacheTime: Infinity,
	})
}

export function useUpdateIssueTypes() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: issueKeys.updateIssueTypes(),
		mutationFn: async (issueTypes: IssueImpactProbabilityWithCount[]) =>
			await issuesApi.updateIssueTypes(issueTypes),
		onSuccess: () => {
			void queryClient.invalidateQueries({ queryKey: issueKeys.issueTypes(), refetchType: 'active' })
		},
	})
}
