import { Collapse } from 'antd'
import { ContextPanelHeader } from './ContextPanelHeader'
import { IdentitySource } from '../../../schemas/identity'
import { GoogleWorkspaceUserXcTable } from '../../../tables/GoogleWorkspaceUsersTable.tsx'
import { GoogleWorkspaceUserXcSchema } from '../../../schemas/identities/googleWorkspaceUserXcSchema.ts'

export const GoogleWorkspaceUserContext: React.FunctionComponent<{
	googleWorkspaceUserXc: GoogleWorkspaceUserXcSchema[]
}> = ({ googleWorkspaceUserXc }) => {
	if (!googleWorkspaceUserXc) {
		return null
	}
	return (
		<Collapse expandIconPosition={'end'}>
			<Collapse.Panel
				header={<ContextPanelHeader source={IdentitySource.GOOGLE_WORKSPACE} />}
				key={IdentitySource.GOOGLE_WORKSPACE}
			>
				<GoogleWorkspaceUserXcTable googleWorkspaceUserXc={googleWorkspaceUserXc} />
			</Collapse.Panel>
		</Collapse>
	)
}
