import { z } from 'zod'
import { camelizeSchema } from '../utils'
import { DateSchema } from '../common'
import { ServerAssumeRolePolicyDocumentSchema, ServerAwsIamRoleXcSchema } from './awsIamRoleXcSchema.ts'
import { ServerAwsEc2InstanceXcSchema } from './awsEc2InstanceXcSchema.ts'
import { ServerAwsAccountSchema } from '../environments/awsAccountSchema.ts'
import { ServerKubernetesResourceXcSchema } from './kubernetes/kubernetesResourceXcSchema.ts'
import { ServerAwsIamChangeLogSchema } from './awsIamChangeLogSchema.ts'
import { ServerAwsUsageLogSchema } from './awsIamUserSchema.ts'
import { ServerAwsIdentityPolicy } from './awsPoliciesSchema.ts'

export const ServerAwsIamRoleSchema = z.object({
	arn: z.string(),
	role_id: z.string(),
	last_used_region: z.string().nullish(),
	created_at: DateSchema,
	updated_at: DateSchema.nullish(),
	last_activity: DateSchema.nullish(),
	name: z.string().nullish(),
	aws_iam_role_details_xc: ServerAwsIamRoleXcSchema.nullish(),
	aws_ec2_instances_xc: z.array(ServerAwsEc2InstanceXcSchema).nullish(),
	aws_iam_cross_account_roles_xc: z.array(ServerAwsIamRoleXcSchema).nullish(),
	AssumeRolePolicyDocument: ServerAssumeRolePolicyDocumentSchema.nullish(),
	aws_account: ServerAwsAccountSchema.nullish(),
	change_logs: z.array(ServerAwsIamChangeLogSchema).nullish(),
	kubernetes_resources_xc: z.array(ServerKubernetesResourceXcSchema).nullish(),
	usage_logs: z.array(ServerAwsUsageLogSchema).nullish(),
	permission_boundary: ServerAwsIdentityPolicy.nullish(),
})

export type ServerAwsIamRole = z.infer<typeof ServerAwsIamRoleSchema>

export const AwsIamRoleSchema = camelizeSchema(ServerAwsIamRoleSchema.transform((val) => val))
export type AwsIamRole = z.infer<typeof AwsIamRoleSchema>
