/* eslint-disable camelcase */

import AzureIcon from '../../../assets/azure_icon.svg?react'
import AzureGuide from '../../../assets/integrationGuides/azure-devops-integration-guide.pdf'
import { EnvironmentCreateRequest } from '../../../schemas/environments/environments'
import { EnvironmentType } from '../../../schemas/envType'
import { IntegrationTestConnectionRequest } from '../../../schemas/integrations/integrations'
import { IntegrationConfig, IntegrationFormData } from '../types/integrationConfig'

export const azureDevopsConfig: IntegrationConfig = {
	key: 'azure_devops',
	name: 'Azure DevOps',
	description: 'Link Token Security to your Azure DevOps account to map cloud identities within the Azure framework.',
	logo: AzureIcon,
	category: 'Cloud',
	guideFileName: AzureGuide,
	formFields: [
		{ name: 'organizationName', label: 'Organization Name', type: 'text', required: true },
		{ name: 'personalAccessToken', label: 'Personal Access Token', type: 'password', required: true },
		{ name: 'logAnalyticsWorkspaceId', label: 'Log Analytics Workspace ID', type: 'text', required: true },
	],
	createTestConnectionPayload: (formData: IntegrationFormData): IntegrationTestConnectionRequest => ({
		environment_type: EnvironmentType.AZURE_DEVOPS,
		config: {
			organization_name: formData.organizationName as string,
			log_analytics_workspace_id: formData.logAnalyticsWorkspaceId as string,
			personal_access_token: formData.personalAccessToken as string,
		},
	}),
	createEnvironmentPayload: (formData: IntegrationFormData): EnvironmentCreateRequest => ({
		name: formData.name as string,
		environment_type: EnvironmentType.AZURE_DEVOPS,
		config: {
			organization_name: formData.organizationName as string,
			log_analytics_workspace_id: formData.logAnalyticsWorkspaceId as string,
			personal_access_token: formData.personalAccessToken as string,
		},
	}),
}
