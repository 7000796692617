import { z } from 'zod'
import { camelizeSchema } from '../utils'
import { ServerAwsIdentityPolicy, ServerAwsTag, ServerManagedPolicy } from './awsPoliciesSchema.ts'

export const ServerAwsIamGroupDetails = z.object({
	Path: z.string(),
	GroupName: z.string(),
	GroupId: z.string(),
	Arn: z.string(),
	CreateDate: z.string(),
	GroupPolicyList: z.array(ServerAwsIdentityPolicy),
	AttachedManagedPolicies: z.array(ServerManagedPolicy),
	CustomerManaged: z.boolean().optional().nullish(),
})
export const AwsIamGroupDetails = camelizeSchema(ServerAwsIamGroupDetails.transform((val) => val))
export type AwsIamGroupDetails = z.infer<typeof AwsIamGroupDetails>

export const ServerAwsIamUserPermissionXcSchema = z.object({
	Path: z.string().nullish(),
	UserName: z.string().nullish(),
	UserId: z.string().nullish(),
	Arn: z.string().nullish(),
	CreateDate: z.string().nullish(),
	UserPolicyList: z.array(ServerAwsIdentityPolicy).optional().default([]),
	GroupList: z.array(z.string()).optional().default([]),
	AttachedManagedPolicies: z.array(ServerManagedPolicy).optional().default([]),
	Tags: z.array(ServerAwsTag).optional().default([]),
	GroupListPermissions: z.array(ServerAwsIamGroupDetails).optional().default([]),
})

export type ServerAwsIamUserPermission = z.infer<typeof ServerAwsIamUserPermissionXcSchema>

export const AwsIamUserPermission = camelizeSchema(ServerAwsIamUserPermissionXcSchema.transform((val) => val))
export type AwsIamUserPermission = z.infer<typeof AwsIamUserPermission>
