import { IssueStatus } from '../issue.ts'
import { camelizeSchema } from '../utils.ts'
import { z } from 'zod'

export const ServerIssueUpdateSchema = z.object({
	status: z.nativeEnum(IssueStatus).optional(),
	ignore_reason: z.string().optional(),
	assignee: z.string().nullish().optional(),
})
export type ServerIssueUpdate = z.infer<typeof ServerIssueUpdateSchema>

export const IssueUpdateSchema = camelizeSchema(ServerIssueUpdateSchema.transform((val) => val))
export type IssueUpdate = z.infer<typeof IssueUpdateSchema>
