import {
	Button,
	Dropdown,
	Form,
	Input,
	MenuProps,
	Modal,
	notification,
	Select,
	Spin,
	Tabs,
	Tag,
	Typography,
} from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { Issue, IssueName, IssueNameMap } from '../../schemas/issue.ts'
import JiraIcon from '../assets/jira_logo.svg?react'
import { DynamicJiraFormItems } from './DynamicJiraFormItems.tsx'
import {
	useCreateIntegrationTicketInTokenDb,
	useFetchIssueStatuses,
	useGetTickets,
	useJiraProxyCreateTicket,
	useJiraProxyFetchFields,
	useJiraProxyFetchIssueTypes,
	useJiraProxyFetchLabels,
	useJiraProxyFetchProjects,
	useJiraProxyFetchUsers,
} from '../api/jira.ts'
import { generateJiraDescription } from '../jiraUtils.ts'
import { ServerIntegration, ServerIntegrationTicketInTokenDb, JiraFormItem } from '../schemas/common.ts'
import {
	FastApiJiraHttpExceptionSchema,
	JiraCreateTicket,
	JiraCreateTicketFieldsBase,
	JiraField,
	jiraFieldTypeToComponent,
	JiraIssueType,
	JiraProject,
	JiraTicketSchema,
} from '../schemas/jiraSchemas.ts'
import axios from 'axios'
import { DebounceSelectJiraIssues, JiraIssueOptionType } from './DebounceSelectJiraIssues.tsx'

const { Text } = Typography
const newJiraTicketMenuItemKey = 'New'
const issueSelectorFormItemKey = 'Issue Selector'

const getJiraDescription = (issue: Issue): string => {
	const description = issue.description?.startsWith('\n') ? issue.description.slice(1) : issue.description
	const tagCount = issue.identity?.tags?.length || 0
	return `${description}

Identity
* Name: ${issue.identity?.literal}
* Account: ${issue.identity?.accountLiteral} ${
		issue.identity?.accountLiteralFriendlyName ? `(${issue.identity?.accountLiteralFriendlyName})` : ''
	}

Tags
${tagCount > 0 ? '* ' : ''}${issue.identity?.tags?.map((tag) => tag.name).join('\n* ') || 'No tags'}
`
}

const JiraIntegration: React.FC<{
	integration: ServerIntegration
	issue: Issue
}> = ({ integration, issue }) => {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
	const [currentProjectId, setCurrentProjectId] = useState<string | undefined>(undefined)
	const [currentIssueTypeId, setCurrentIssueTypeId] = useState<string | undefined>(undefined)
	const [selectedTab, setSelectedTab] = useState<string>('Create')
	const [selectedLinkIssue, setSelectedLinkIssue] = useState<JiraIssueOptionType | null>(null)

	const {
		data: relatedTickets,
		isLoading: isRelatedTicketsLoading,
		isError: isRelatedTicketsError,
	} = useGetTickets(integration.id, issue.id)

	const { data: issueStatuses, isInitialLoading: isIssueStatusesLoading } = useFetchIssueStatuses(
		integration,
		relatedTickets || [],
	)

	const { data: projects, isLoading: isProjectsLoading } = useJiraProxyFetchProjects(integration)
	const { data: labels, isLoading: isLabelsLoading } = useJiraProxyFetchLabels(integration)
	const { data: users, isLoading: isUsersLoading } = useJiraProxyFetchUsers(integration, currentProjectId) // It is better to switch to getting assignee from the autoCompleteUrl of the user field
	const { data: issueTypes, isLoading: isIssueTypesLoading } = useJiraProxyFetchIssueTypes(
		integration,
		currentProjectId,
	)
	const { data: jiraFields, isLoading: isJiraFieldsLoading } = useJiraProxyFetchFields(
		integration,
		currentProjectId,
		currentIssueTypeId,
	)

	const currentIssueType: JiraIssueType | undefined = useMemo(
		() => issueTypes?.find((issueType) => issueType.id === currentIssueTypeId),
		[issueTypes, currentIssueTypeId],
	)
	const currentProject: JiraProject | undefined = useMemo(
		() => projects?.find((project) => project.id === currentProjectId),
		[projects, currentProjectId],
	)

	const [form] = Form.useForm()

	const { mutate: createTicketInJira, isLoading: isCreateTicketLoading } = useJiraProxyCreateTicket(
		integration.id,
		issue.id,
	)
	const { mutate: linkTicketInTokenDb } = useCreateIntegrationTicketInTokenDb(integration, issue.id)

	useEffect(() => {
		if (!isModalVisible) {
			setCurrentProjectId(undefined)
		}
	}, [isModalVisible])

	useEffect(() => {
		setCurrentIssueTypeId(undefined)
		setSelectedLinkIssue(null)
		form.resetFields()
		form.setFieldValue('project', currentProjectId)
	}, [currentProjectId])

	useEffect(() => {
		const filteredJiraFields = filterJiraFields(jiraFields)
		const filteredJiraFieldsWithoutSupportedDynamicFields = filteredJiraFields.filter(
			(field) => !Object.keys(jiraFieldTypeToComponent).includes(field.schema.type),
		)
		if (filteredJiraFieldsWithoutSupportedDynamicFields.length > 0) {
			setCurrentIssueTypeId(undefined)
			notification.error({
				message: `Error`,
				description: `Issue type ${currentIssueType?.name} contains unsupported fields: ${filteredJiraFields
					.map((field) => field.name)
					.join(', ')}. Please select different issue type.`,
				duration: 0,
			})
			form.resetFields(['issuetype'])
		}
	}, [jiraFields])

	useEffect(() => {
		if (isRelatedTicketsError) {
			notification.error({
				message: `Error`,
				description: `Failed to fetch related Jira tickets`,
			})
		}
	}, [isRelatedTicketsError])

	const defaultFormItems: { [key: string]: JiraFormItem } = {
		project: {
			label: 'Project',
			rules: [{ required: true, message: 'Please select a project!' }],
			component: (
				<Select
					placeholder="Select a project"
					onChange={setCurrentProjectId}
					loading={isProjectsLoading}
					options={projects?.map((project) => {
						return {
							label: `[${project.key}] - ${project.name}`,
							value: project.id,
						}
					})}
					filterOption={(inputValue, option) =>
						(option?.label as string).toLowerCase().includes(inputValue.toLowerCase())
					}
					showSearch
				/>
			),
		},
		issuetype: {
			label: 'Issue Type',
			rules: [{ required: true, message: 'Please select an issue type!' }],
			component: (
				<Select
					placeholder="Select an issue type"
					onChange={setCurrentIssueTypeId}
					disabled={!currentProjectId}
					loading={
						(!!currentProjectId && isIssueTypesLoading) || (!!currentIssueTypeId && isJiraFieldsLoading)
					}
					options={issueTypes?.map((issueType) => {
						return {
							label: issueType.name ? issueType.name : issueType.value,
							value: issueType.id,
						}
					})}
					filterOption={(inputValue, option) =>
						(option?.label as string).toLowerCase().includes(inputValue.toLowerCase())
					}
					showSearch
				/>
			),
		},
		assignee: {
			label: 'Assignee',
			rules: [{ required: true, message: 'Please select an assignee!' }],
			component: (
				<Select
					placeholder="Select an assignee"
					disabled={!currentIssueTypeId}
					loading={!!currentProjectId && isUsersLoading}
					options={users?.map((user) => {
						return {
							label: user.displayName,
							value: user.accountId,
						}
					})}
					showSearch
					filterOption={(inputValue, option) =>
						(option?.label as string).toLowerCase().includes(inputValue.toLowerCase())
					}
				/>
			),
		},
		watchers: {
			label: 'Watchers',
			rules: [{ required: false, message: 'optional: add watchers to the ticket' }],
			component: (
				<Select
					mode="multiple"
					placeholder="Select watchers"
					disabled={!currentIssueTypeId}
					loading={!!currentProjectId && isUsersLoading}
					options={users?.map((user) => {
						return {
							label: user.displayName,
							value: user.accountId,
						}
					})}
					showSearch
					filterOption={(inputValue, option) =>
						(option?.label as string).toLowerCase().includes(inputValue.toLowerCase())
					}
				/>
			),
		},
		labels: {
			label: 'Labels',
			rules: [{ required: true, message: 'Please provide labels!' }],
			component: (
				<Select
					mode="tags"
					placeholder="Enter labels"
					disabled={!currentIssueTypeId}
					loading={isLabelsLoading}
					options={labels?.map((jiraLabel) => {
						return {
							label: jiraLabel,
							value: jiraLabel,
						}
					})}
					filterOption={(inputValue, option) =>
						(option?.label as string).toLowerCase().includes(inputValue.toLowerCase())
					}
					showSearch
				/>
			),
		},
		summary: {
			label: 'Summary',
			rules: [{ required: true, message: 'Please provide a summary!' }],
			component: <Input disabled={!currentIssueTypeId} placeholder="Enter summary" />,
			initialValue: `${IssueNameMap[issue.issueName as IssueName]} for ${issue.identity?.literalFriendlyName}`,
		},
		description: {
			label: 'Description',
			rules: [{ required: true, message: 'Please provide a description!' }],
			component: <Input.TextArea disabled={!currentIssueTypeId} autoSize={{ minRows: 3, maxRows: 7 }} />,
			initialValue: getJiraDescription(issue),
		},
	}

	const defaultJiraFormItems = () => {
		return Object.keys(defaultFormItems).map((key) => (
			<Form.Item
				key={key}
				name={key}
				label={defaultFormItems[key].label}
				rules={defaultFormItems[key].rules}
				initialValue={defaultFormItems[key].initialValue}
			>
				{defaultFormItems[key].component}
			</Form.Item>
		))
	}

	const filterJiraFields = (fields?: JiraField[]): JiraField[] => {
		if (!fields) {
			return []
		}
		return fields
			.filter((jiraField) => jiraField.required)
			.filter((jiraField) => !Object.keys(defaultFormItems).includes(jiraField.key))
			.filter((jiraField) => !(jiraField.schema.type === 'user' && jiraField.schema.system === 'reporter'))
	}

	const handleCreateInJira = async () => {
		const values: Record<string, unknown> = (await form.getFieldsValue()) as Record<string, unknown>
		const issuePayload: JiraCreateTicket = {
			fields: {
				description: '',
				summary: '',
				project: { key: '' },
				issuetype: { id: '' },
			} as JiraCreateTicketFieldsBase,
		}

		const nonIdFieldTypes = ['string', 'text', 'number', 'date', 'datetime', 'array']

		// Generate payload for issue
		Object.keys(values).forEach((key) => {
			const field = jiraFields?.find((f) => f.key === key)
			if (key === 'watchers') {
				issuePayload.watchers = values[key] as string[]
			} else if (key === 'description') {
				issuePayload.fields[key] = generateJiraDescription(values[key] as string)
			} else if (field && nonIdFieldTypes.includes(field.schema?.type ?? '')) {
				if (field.schema.type === 'number') {
					issuePayload.fields[key] = Number(values[key])
				} else {
					issuePayload.fields[key] = values[key]
				}
			} else {
				if (values[key]) {
					issuePayload.fields[key] = { id: values[key] }
				}
			}
		})
		issuePayload.fields.summary =
			(values.summary as string) ||
			IssueNameMap[issue?.issueName as keyof typeof IssueNameMap] ||
			'Default Summary'

		createTicketInJira(
			{ issuePayload: issuePayload },
			{
				onSuccess: (event: ServerIntegrationTicketInTokenDb) => {
					const jiraTicketLink = `${event.data.self.split('/rest')[0]}/browse/${event.data.key}`
					notification.success({
						message: `Success`,
						description: (
							<div>
								Jira issue {event.data.key} was created successfully
								<br />
								<a
									href={jiraTicketLink}
									target="_blank"
									rel="noopener noreferre"
									className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
								>
									Open In Jira
								</a>
							</div>
						),
						duration: 0,
					})
					setIsModalVisible(false)
				},
				onError: (e) => {
					if (axios.isAxiosError(e)) {
						if (e.response?.data) {
							const parsedRes = FastApiJiraHttpExceptionSchema.safeParse(e.response?.data)
							if (parsedRes.success) {
								const errorDetail = Object.keys(parsedRes.data.detail.errors).map(
									(key) => `${key} : ${parsedRes.data.detail.errors[key]}`,
								)
								notification.error({
									message: `Error`,
									description: `Failed to create Jira issue. Error details - ${errorDetail.join(', ')}`,
									duration: 0,
								})
								return
							} else {
								notification.error({
									message: `Error`,
									description: `Failed to create Jira issue. Error details - ${JSON.stringify(e.response?.data)}`,
									duration: 0,
								})
							}
						}
					}
					notification.error({
						message: `Error`,
						description: `Failed to create Jira issue`,
						duration: 0,
					})
				},
			},
		)
	}

	const handleMenuClick: MenuProps['onClick'] = (e) => {
		if (e.key === newJiraTicketMenuItemKey) {
			setIsModalVisible(true)
		}
	}

	const items: MenuProps['items'] = useMemo(() => {
		const createNewJiraTicketMenuItem = {
			label: 'Create or link a ticket',
			key: newJiraTicketMenuItemKey,
			icon: <JiraIcon className="w-[15px] h-[15px]" />,
		}

		if (!relatedTickets || !issueStatuses) {
			return [createNewJiraTicketMenuItem]
		}

		const jiraTicketsMenuItems = relatedTickets.map((ticket) => {
			const jiraTicket = JiraTicketSchema.parse(ticket.data)
			const baseUrl = jiraTicket.self.split('/rest')[0]
			return {
				key: ticket.id,
				label: (
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`${baseUrl}/browse/${jiraTicket.key}`}
						className="flex items-center justify-between gap-2"
					>
						{jiraTicket.key}
						<Tag>{issueStatuses[jiraTicket.key]}</Tag>
					</a>
				),
			}
		})
		return jiraTicketsMenuItems.length
			? [...jiraTicketsMenuItems, { type: 'divider' }, createNewJiraTicketMenuItem]
			: [createNewJiraTicketMenuItem]
	}, [relatedTickets, issueStatuses])

	const menuProps = {
		items,
		onClick: handleMenuClick,
	}

	const tabsItems = [
		{
			label: 'Create',
			key: 'Create',
			children: (
				<Spin spinning={isProjectsLoading || isCreateTicketLoading}>
					<Form form={form} layout="vertical" className="max-h-[50vh] overflow-y-auto">
						{defaultJiraFormItems()}
						<DynamicJiraFormItems jiraFields={filterJiraFields(jiraFields)} />
					</Form>
				</Spin>
			),
		},
		{
			label: 'Link',
			key: 'Link',
			children: (
				<Spin spinning={isProjectsLoading || isCreateTicketLoading}>
					<Form form={form} layout="vertical" className="max-h-[50vh] overflow-y-auto">
						<Form.Item
							key={'project'}
							name={'project'}
							label={'Project'}
							rules={[{ required: true, message: 'Please select a project!' }]}
						>
							<Select
								placeholder="Select a project"
								onChange={setCurrentProjectId}
								loading={isProjectsLoading}
								options={projects?.map((project) => {
									return {
										label: `[${project.key}] - ${project.name}`,
										value: project.id,
									}
								})}
								filterOption={(inputValue, option) =>
									(option?.label as string).toLowerCase().includes(inputValue.toLowerCase())
								}
								showSearch
							/>
						</Form.Item>
						{currentProjectId ? (
							<Form.Item key={issueSelectorFormItemKey} name={issueSelectorFormItemKey} label={'Issue'}>
								<DebounceSelectJiraIssues
									integration={integration}
									project={currentProject}
									value={selectedLinkIssue}
									onChange={setSelectedLinkIssue}
								/>
							</Form.Item>
						) : null}
						{relatedTickets
							?.map((ticket) => ticket.data.key)
							.includes(selectedLinkIssue?.key ? selectedLinkIssue.key : '') && (
							<div
								className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
								role="alert"
							>
								<strong className="font-bold">Issue already linked: </strong>
								<span className="block sm:inline">Please select another issue.</span>
							</div>
						)}
					</Form>
				</Spin>
			),
		},
	]

	const handleTabChange = (key: string) => {
		form.resetFields()
		setCurrentProjectId(undefined)
		setSelectedTab(key)
	}

	const handleLinkIssueToJira = async () => {
		const values: Record<string, unknown> = (await form.getFieldsValue()) as Record<string, unknown>
		const selectedIssue = values[issueSelectorFormItemKey] as JiraIssueOptionType
		linkTicketInTokenDb(
			{ id: selectedIssue.value, key: selectedIssue.key },
			{
				onSuccess: (event: ServerIntegrationTicketInTokenDb) => {
					setIsModalVisible(false)
					const jiraTicketLink = `${event.data.self}/browse/${event.data.key}`
					notification.success({
						message: `Success`,
						description: (
							<div>
								Jira issue {event.data.key} was linked successfully
								<br />
								<a
									href={jiraTicketLink}
									target="_blank"
									rel="noopener noreferre"
									className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
								>
									Open In Jira
								</a>
							</div>
						),
						duration: 0,
					})
				},
			},
		)
	}

	const handleModalOk = async () => {
		if (selectedTab === 'Create') {
			await handleCreateInJira()
		} else if (selectedTab === 'Link') {
			await handleLinkIssueToJira()
		}
	}

	return (
		<>
			<Dropdown menu={menuProps} overlayClassName="max-h-[400px] overflow-y-auto">
				<Button onClick={() => setIsModalVisible(true)} icon={<JiraIcon className="w-[18px] h-[18px]" />}>
					{isRelatedTicketsLoading || isIssueStatusesLoading ? (
						<Spin size="small" />
					) : (
						!isRelatedTicketsError &&
						relatedTickets?.length > 0 && (
							<Text className="text-zinc-600 mb-[1px]">({relatedTickets.length})</Text>
						)
					)}
				</Button>
			</Dropdown>

			<Modal
				title="Jira Issue"
				open={isModalVisible}
				onCancel={() => {
					setIsModalVisible(false)
				}}
				destroyOnClose={true}
				onOk={() => void handleModalOk()}
				okText={selectedTab === 'Create' ? 'Create' : 'Link'}
				okType="primary"
				okButtonProps={{
					className: 'bg-black',
					disabled:
						selectedTab === 'Create'
							? !currentProjectId || !currentIssueTypeId || !jiraFields
							: !currentProjectId ||
								!selectedLinkIssue ||
								relatedTickets?.map((ticket) => ticket.data.key).includes(selectedLinkIssue.key),
				}}
				forceRender
			>
				<Tabs
					className="full-width-tabs"
					activeKey={selectedTab}
					items={tabsItems}
					onChange={handleTabChange}
				/>
			</Modal>
		</>
	)
}

export default JiraIntegration
