import { Collapse, CollapseProps } from 'antd'
import React from 'react'
import KubernetesIcon from '../../../../assets/kubernetes_icon_20.svg?react'
import { KubernetesRole, KubernetesRoleBinding } from '../../../../schemas/identities/kubernetesResourceSchema.ts'
import { KubernetesRoleBindingsTable } from '../../../../tables/KubernetesRoleBindingsTable.tsx'

export const KubernetesRoleBindingsContext: React.FunctionComponent<{
	roleBindings: KubernetesRoleBinding[]
	roles: KubernetesRole[]
}> = ({ roleBindings, roles }) => {
	const items: CollapseProps['items'] = [
		{
			key: 'kubernetesResources',
			label: (
				<div className="flex items-center space-x-2">
					<KubernetesIcon style={{ width: '35px', height: '35px' }} />
					<div>Kubernetes Role Bindings</div>
				</div>
			),
			children: <KubernetesRoleBindingsTable roleBindings={roleBindings} roles={roles} />,
		},
	]

	return <Collapse items={items} expandIconPosition={'end'} />
}
