import { Node, NodeProps } from '@xyflow/react'
import { Identity, IdentitySourceMap } from '../../../../schemas/identity'
import { BaseIdentityNode } from './baseNodes/BaseIdentityNode'
import { TagsNodeIndicator } from './TagsNodeIndicator'

export type IdentityNodeType = Node<{ identity: Identity }, 'identity'>
type IdentityNodeProps = NodeProps<IdentityNodeType>

export const IdentityNode = ({ data: { identity } }: IdentityNodeProps) => {
	return (
		<BaseIdentityNode
			name={{
				text: identity.literalFriendlyName || identity.literal || 'Unknown identity',
				tooltipText: identity.literal,
			}}
			type={IdentitySourceMap[identity.source]}
			isMainIdentity
		>
			<TagsNodeIndicator identity={identity} />
		</BaseIdentityNode>
	)
}
