import {
	IdentitiesPageLens,
	IdentitiesPageSearch,
	IdentityActivityStatus,
	IdentitySource,
	IdentityType,
	ServerIdentitiesTableRow,
} from '../../schemas/identity.ts'
import { mostWantedIdentitiesData } from '../data/metricsData.ts'
import { MockDemoTag } from '../../schemas/tags.ts'
import { EnvironmentType } from '../../schemas/envType.ts'
import { IssueName } from '../../schemas/issue.ts'

export function filterIdentities(
	identities: ServerIdentitiesTableRow[],
	postParams: IdentitiesPageSearch,
): ServerIdentitiesTableRow[] {
	if (identities.length === 0) return []
	let filteredIdentities = identities
	const { lens } = postParams

	switch (lens) {
		case IdentitiesPageLens.AWS:
		case IdentitiesPageLens.GCP:
		case IdentitiesPageLens.AZURE:
		case IdentitiesPageLens.OKTA:
		case IdentitiesPageLens.JUMPCLOUD:
		case IdentitiesPageLens.ENTRA_ID:
		case IdentitiesPageLens.GOOGLE_WORKSPACE: {
			filteredIdentities = filteredIdentities.filter((identity) => {
				return identity.env_type === (lens as unknown as EnvironmentType)
			})
			break
		}
		case IdentitiesPageLens.HUMAN:
		case IdentitiesPageLens.MACHINE:
		case IdentitiesPageLens.HYBRID:
		case IdentitiesPageLens.UNKNOWN: {
			filteredIdentities = filteredIdentities.filter((identity) => {
				return identity.type === (lens as unknown as IdentityType)
			})
			break
		}
		case IdentitiesPageLens.ADMIN:
		case IdentitiesPageLens.DATA_ACCESS:
		case IdentitiesPageLens.CREDENTIAL_EXPOSURE:
		case IdentitiesPageLens.RESOURCE_EXPOSURE:
		case IdentitiesPageLens.PRIVILEGE_ESCALATION: {
			filteredIdentities = filteredIdentities.filter((identity) => {
				const tags: string[] = []
				identity.tags?.forEach((tag) => {
					tags.push(tag)
				})
				return tags.includes(lens)
			})
			break
		}
		case IdentitiesPageLens.DEMO_OVER_PRIVILEGED_IDENTITY:
		case IdentitiesPageLens.DEMO_IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR: {
			filteredIdentities = filteredIdentities.filter((identity) => {
				return identity.mock_demo_tags?.includes(lens as unknown as MockDemoTag)
			})
			break
		}
		case IdentitiesPageLens.OFF_BOARDED_EMPLOYEES: {
			filteredIdentities = filteredIdentities.filter((identity) => {
				const validSources = [
					IdentitySource.OKTA,
					IdentitySource.GOOGLE_WORKSPACE,
					IdentitySource.JUMPCLOUD_USER,
					IdentitySource.ENTRA_ID_USER,
				]
				return (
					identity.type === IdentityType.Human &&
					identity.activity_status === IdentityActivityStatus.INACTIVE &&
					validSources.includes(identity.source)
				)
			})
			break
		}
		case IdentitiesPageLens.MULTIPLE_ACCESS_KEYS:
		case IdentitiesPageLens.UNFEDERATED_IDENTITIES: {
			filteredIdentities = filteredIdentities.filter((identity) => {
				return (
					identity.issues.filter((issue) => {
						return issue.issue_name === (lens as unknown as IssueName)
					}).length > 0
				)
			})
			break
		}
		case IdentitiesPageLens.RECENTLY_CREATED: {
			filteredIdentities = filteredIdentities.filter((identity) => {
				return identity.created_at && new Date(identity.created_at) > new Date(Date.now() - 24 * 60 * 60 * 1000)
			})
			break
		}
		case IdentitiesPageLens.MOST_WANTED: {
			const mostWantedIdentitiesIds = mostWantedIdentitiesData.map((identity) => identity.id)

			// Filter identities that are in mostWantedIdentities
			filteredIdentities = filteredIdentities.filter((identity) => {
				return mostWantedIdentitiesIds.includes(identity.id)
			})

			// Sort filteredIdentities to match the order of mostWantedIdentities
			filteredIdentities.sort((a, b) => {
				return mostWantedIdentitiesIds.indexOf(a.id) - mostWantedIdentitiesIds.indexOf(b.id)
			})
			break
		}
		case IdentitiesPageLens.KUBERNETES:
		case IdentitiesPageLens.POSTGRES:
		case IdentitiesPageLens.SNOWFLAKE:
		case IdentitiesPageLens.DEMO_DATABRICKS: {
			filteredIdentities = filteredIdentities.filter((identity) => {
				return identity.source.toLowerCase().includes(lens.toLowerCase())
			})
		}
	}

	return filteredIdentities
}
