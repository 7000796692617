import { Issue, PriorityType, ServerIssue } from '../schemas/issue.ts'

export function getIssuesMaxPriority(issues?: Issue[] | ServerIssue[] | null): PriorityType {
	if (!issues || issues.length === 0) {
		return PriorityType.LOW
	}

	let maxPriority = PriorityType.NOT_CALCULATED
	issues.forEach((issue) => {
		if (issue.priority) {
			if (issue.priority > maxPriority) {
				maxPriority = issue.priority
			}
		}
	})

	return maxPriority
}

/** A sorter function that can be passed to `Array.sort`, sorts issues from high->low priority */
export function issuePrioritySorter(issueA: Issue, issueB: Issue): number {
	if (issueA.priority && issueB.priority) {
		return issueB.priority - issueA.priority
	} else if (!issueA.priority && issueB.priority) {
		return 1
	} else if (issueA.priority && !issueB.priority) {
		return -1
	} else {
		return 0
	}
}
