import { NodeProps, Node } from '@xyflow/react'
import { CloudServerOutlined } from '@ant-design/icons'
import { BaseDependenciesNode } from '../../common/baseNodes/BaseDependenciesNode.tsx'
import { AzureVirtualMachine } from '../../../../../schemas/identities/azure/demoAzureVirtualMachine.ts'
import { useHighlightedNodesContext } from '../../../HighlightedNodesContext.tsx'

export type DemoAzureVirtualMachinesNodeType = Node<{ vms: AzureVirtualMachine[] }, 'azureVirtualMachines'>
type DemoAzureVirtualMachinesNodeProps = NodeProps<DemoAzureVirtualMachinesNodeType>

export const DemoAzureVirtualMachinesNode = ({ data: { vms } }: DemoAzureVirtualMachinesNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'azureVirtualMachines')
	return (
		<BaseDependenciesNode
			resources={vms}
			isHighlighted={isHighlighted}
			nodeTypename="Virtual Machines"
			tooltipTitle={`Identity is used in ${vms.length} ${vms.length === 1 ? 'virtual machine' : 'virtual machines'}`}
			Icon={CloudServerOutlined}
		/>
	)
}
