import { Dispatch, memo, SetStateAction, useCallback, useEffect, useState } from 'react'
import {
	Background,
	Controls,
	Edge,
	FitViewOptions,
	MiniMap,
	NodeMouseHandler,
	ReactFlow,
	useEdgesState,
	useNodesState,
} from '@xyflow/react'
import { useNavigate } from '@tanstack/react-router'
import { Identity } from '../../../schemas/identity.ts'
import { IdentityNode } from './common/IdentityNode.tsx'
import { IssueNode } from './common/IssueNode.tsx'
import { AccessKeyNode } from './aws/AccessKeyNode.tsx'
import { AwsPolicyNode } from './aws/AwsPolicyNode.tsx'
import { getNodesAndEdges } from './graphUtils/nodesAndEdges.ts'
import { Ec2InstancesNode } from './aws/Ec2InstancesNode.tsx'
import { KubernetesResourcesNode } from './common/KubernetesResourcesNode.tsx'
import { getPolicyUniqueKey } from '../../../utils/awsIdentityUtils.ts'
import { DependenciesCollapseItemKeys } from '../IdentitySidePanel/SidePanelDependencies.tsx'
import { CrossContextCollapseItemKeys } from '../IdentitySidePanel/SidePanelCrossContext.tsx'
import { GcpProjectNode } from './gcp/GcpProjectNode.tsx'
import { GcpRoleNode } from './gcp/GcpRoleNode.tsx'
import { GithubRepositoryNode } from './github/GithubRepositoryNode.tsx'
import { GithubUserTokenNode } from './github/GithubUserTokenNode.tsx'
import { GcpSaAccessKeyNode } from './gcp/GcpSaAccessKeyNode.tsx'
import { EntraIdSpAuthCredentialNode } from './entraId/EntraIdSpAuthCredentialNode.tsx'
import { DatabricksOAuthNode } from './databricks/DatabricksOAuthNode.tsx'
import { DatabricksPATNode } from './databricks/DatabricksPATNode.tsx'
import { DatabricksRoleNode } from './databricks/DatabricksRoleNode.tsx'
import { DemoAzureVirtualMachinesNode } from './azure/demo/DemoAzureVirtualMachinesNode.tsx'
import { getMinimapNodeColor } from './graphUtils/nodeColors.ts'
import { MiniMapNode } from './MiniMapNode.tsx'
import { GithubRepositoriesNode } from './github/GithubRepositoriesNode.tsx'
import { GcpProjectsNode } from './gcp/GcpProjectsNode.tsx'
import { CompactModeControlButton } from './CompactModeControlButton.tsx'
import { EnrichedAzureRoleNode } from './azure/azureRBAC/EnrichedAzureRoleNode.tsx'
import { EnrichedAzureRolesNode } from './azure/azureRBAC/EnrichedAzureRolesNode.tsx'
import { AzureSubscriptionNode } from './azure/azureResource/AzureSubscriptionNode.tsx'
import { AzureSubscriptionsNode } from './azure/azureResource/AzureSubscriptionsNode.tsx'
import { AzureManagementGroupNode } from './azure/azureResource/AzureManagementGroupNode.tsx'
import { AzureManagementGroupsNode } from './azure/azureResource/AzureManagementGroupsNode.tsx'
import { AzureKeyVaultNode } from './azure/azureKV/AzureKeyVaultNode.tsx'
import { EntraIDUserNode } from './entraId/EntraIDUserNode.tsx'
import { EntraIDServicePrincipalNode } from './entraId/EntraIDServicePrincipalNode.tsx'
import { LastSelectedNode } from '../IdentityPageBody.tsx'
import { EntraIDUsersNode } from './entraId/EntraIDUsersNode.tsx'
import { EntraIDServicePrincipalsNode } from './entraId/EntraIDServicePrincipalsNode.tsx'
import { RoutePaths } from '../../RoutePaths.tsx'

import '@xyflow/react/dist/style.css'
import './IdentityGraph.css'
import { DetailedEntraIdRoleNode } from './entraId/entraIdRbac/DetailedEntraIdRoleNode.tsx'
import { DetailedEntraIdRolesNode } from './entraId/entraIdRbac/DetailedEntraIdRolesNode.tsx'
import { AwsRoleNode } from './aws/AwsRoleNode.tsx'
import { AwsAccountNode } from './aws/AwsAccountNode.tsx'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { SnowflakeRoleNode } from './snowflake/SnowflakeRoleNode.tsx'
import { SnowflakeRolesNode } from './snowflake/SnowflakeRolesNode.tsx'
import { IdentityGraphNodeType } from './identityGraphTypes.ts'
import { AwsRolesNode } from './aws/AwsRolesNode.tsx'
import { OwnershipNode } from './common/OwnershipNode.tsx'
import { GithubAppPermissionsNode } from './github/GithubAppPermissionNode.tsx'
import { OktaUserNode } from './okta/OktaUserNode.tsx'
import { SalesforcePermissionSetNode } from './salesforce/SalesforcePermissionSetNode.tsx'
import { SalesforceProfileNode } from './salesforce/SalesforceProfileNode.tsx'
import { PermissionsCollapseItemKeys } from '../IdentitySidePanel/SidePanelPermissions.tsx'
import { AwsPoliciesNode } from './aws/AwsPoliciesNode.tsx'
import { GcpRolesNode } from './gcp/GcpRolesNode.tsx'
import { SalesforcePermissionSetsNode } from './salesforce/SalesforcePermissionSetsNode.tsx'
import { SalesforceConnectedAppMetadataNode } from './salesforce/SalesforceConnectedAppOAuthNode.tsx'
import { JumpcloudUserNode } from './jumpcloud/JumpcloudUserNode.tsx'
import { SalesforceConsumerKeyNode } from './salesforce/SalesforceCosumerKeyNode.tsx'
import { useActiveSidePanelKeysContext } from '../ActiveSidePanelKeysContext.tsx'

const nodeTypes = {
	identity: IdentityNode,
	issue: IssueNode,
	accessKey: AccessKeyNode,
	awsIamRole: AwsRoleNode,
	awsIamRoles: AwsRolesNode,
	awsAccount: AwsAccountNode,
	gcpAccessKey: GcpSaAccessKeyNode,
	entraIdCredential: EntraIdSpAuthCredentialNode,
	awsPolicy: AwsPolicyNode,
	awsPolicies: AwsPoliciesNode,
	ec2Instances: Ec2InstancesNode,
	kubernetesResources: KubernetesResourcesNode,
	oktaUser: OktaUserNode,
	gcpProject: GcpProjectNode,
	gcpProjects: GcpProjectsNode,
	gcpRole: GcpRoleNode,
	gcpRoles: GcpRolesNode,
	githubAppPermission: GithubAppPermissionsNode,
	githubRepository: GithubRepositoryNode,
	githubRepositories: GithubRepositoriesNode,
	githubUserToken: GithubUserTokenNode,
	oauthToken: DatabricksOAuthNode,
	pat: DatabricksPATNode,
	databricksRole: DatabricksRoleNode,
	enrichedAzureRole: EnrichedAzureRoleNode,
	enrichedAzureRoles: EnrichedAzureRolesNode,
	azureVirtualMachines: DemoAzureVirtualMachinesNode,
	azureSubscription: AzureSubscriptionNode,
	azureSubscriptions: AzureSubscriptionsNode,
	azureManagementGroup: AzureManagementGroupNode,
	azureManagementGroups: AzureManagementGroupsNode,
	azureKeyVault: AzureKeyVaultNode,
	entraIDUser: EntraIDUserNode,
	entraIDUsers: EntraIDUsersNode,
	entraIDServicePrincipal: EntraIDServicePrincipalNode,
	entraIDServicePrincipals: EntraIDServicePrincipalsNode,
	detailedEntraIdRole: DetailedEntraIdRoleNode,
	detailedEntraIdRoles: DetailedEntraIdRolesNode,
	snowflakeRole: SnowflakeRoleNode,
	snowflakeRoles: SnowflakeRolesNode,
	ownership: OwnershipNode,
	salesforceProfile: SalesforceProfileNode,
	salesforcePermissionSet: SalesforcePermissionSetNode,
	salesforcePermissionSets: SalesforcePermissionSetsNode,
	salesforceConnectedAppMetadata: SalesforceConnectedAppMetadataNode,
	salesforceConsumerKey: SalesforceConsumerKeyNode,
	jumpcloudUser: JumpcloudUserNode,
}

const fitViewOptions: FitViewOptions = { maxZoom: 1 }

interface IdentityGraphProps {
	identity: Identity
	setLastSelectedNode: Dispatch<SetStateAction<LastSelectedNode | null>>
	openSidePanel: () => void
	setActiveDependenciesKeys: Dispatch<SetStateAction<DependenciesCollapseItemKeys[]>>
	setActiveCrossContextKeys: Dispatch<SetStateAction<CrossContextCollapseItemKeys[]>>
	setActivePermissionsKeys: Dispatch<SetStateAction<PermissionsCollapseItemKeys[]>>
}

export const IdentityGraph = memo(
	({
		identity,
		setLastSelectedNode,
		openSidePanel,
		setActiveDependenciesKeys,
		setActiveCrossContextKeys,
		setActivePermissionsKeys,
	}: IdentityGraphProps) => {
		const navigate = useNavigate({ from: RoutePaths.Identity })
		const [nodes, setNodes, onNodesChange] = useNodesState<IdentityGraphNodeType>([])
		const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([])
		const [isCompact, setIsCompact] = useState(true)
		const { setActiveSidePanelKeys } = useActiveSidePanelKeysContext()

		useEffect(() => {
			const [newNodes, newEdges] = getNodesAndEdges(identity, isCompact)
			setNodes(newNodes)
			setEdges(newEdges)
		}, [identity, isCompact])

		const onNodeClick: NodeMouseHandler<IdentityGraphNodeType> = useCallback(
			(_, node) => {
				switch (node.type) {
					case 'issue':
						setActiveSidePanelKeys(['Issues'])
						openSidePanel()
						void navigate({ search: (prev) => ({ ...prev, issueId: node.data.issue.id }) })
						break
					case 'awsPolicy':
					case 'awsPolicies':
						if (
							identity.envType === EnvironmentType.OKTA ||
							identity.envType === EnvironmentType.ENTRA_ID ||
							identity.envType === EnvironmentType.JUMPCLOUD
						) {
							setActiveSidePanelKeys(['Cross-Context'])
							setActiveCrossContextKeys(['aws-roles'])
						} else {
							setActiveSidePanelKeys(['Permissions'])
							setLastSelectedNode({
								type: node.type,
								id: node.type === 'awsPolicy' ? getPolicyUniqueKey(node.data.policy) : '',
							})
						}

						openSidePanel()
						break
					case 'identity':
						setActiveSidePanelKeys(['Properties'])
						openSidePanel()
						break
					case 'entraIdCredential':
					case 'accessKey':
					case 'gcpAccessKey':
					case 'pat':
					case 'oauthToken':
						setActiveSidePanelKeys(['Properties'])
						openSidePanel()
						break
					case 'ec2Instances':
					case 'kubernetesResources':
						setActiveSidePanelKeys(['Dependencies'])
						openSidePanel()
						setActiveDependenciesKeys([node.type === 'ec2Instances' ? 'ec2' : 'kubernetes'])
						break
					case 'oktaUser':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['okta'])
						break
					case 'gcpProject':
					case 'gcpProjects':
						if (identity.envType === EnvironmentType.JUMPCLOUD) {
							setActiveSidePanelKeys(['Cross-Context'])
							setActiveCrossContextKeys(['gcp-projects'])
						} else {
							setActiveSidePanelKeys(['Permissions'])
						}
						openSidePanel()
						break
					case 'gcpRole':
					case 'gcpRoles':
					case 'databricksRole':
					case 'githubAppPermission':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						break
					case 'githubUserToken':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['github-tokens'])
						break
					case 'githubRepository':
					case 'githubRepositories':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['github-repos'])
						break
					case 'awsIamRole':
					case 'awsAccount':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['trusted-principals'])
						setActiveCrossContextKeys(['aws-roles'])

						break
					case 'entraIDUser':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['entraIDUser'])
						break
					case 'jumpcloudUser':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['jumpcloudUser'])
						break
					case 'snowflakeRole':
						setActiveSidePanelKeys(['Permissions'])
						setLastSelectedNode({ type: node.type, id: node.data.role.name })
						openSidePanel()
						break
					case 'snowflakeRoles':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						break
					case 'azureVirtualMachines':
						openSidePanel()
						setActiveSidePanelKeys(['Dependencies'])
						setActiveDependenciesKeys(['azureVms'])
						break
					case 'azureSubscription':
					case 'azureSubscriptions':
					case 'enrichedAzureRoles':
					case 'azureManagementGroup':
					case 'azureManagementGroups':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['azure-roles'])
						break
					case 'enrichedAzureRole':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['azure-roles'])
						setLastSelectedNode({ type: node.type, id: node.data.role.roleAssignment.id })
						break
					case 'detailedEntraIdRole':
						setActiveSidePanelKeys(['Permissions'])
						setLastSelectedNode({ type: node.type, id: node.data.role.roleAssignment.id })
						break
					case 'detailedEntraIdRoles':
						setActiveSidePanelKeys(['Permissions'])
						break
					case 'salesforceConsumerKey':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						break
					case 'salesforceProfile':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						setActivePermissionsKeys(['salesforceProfile'])
						setLastSelectedNode({ type: node.type, id: node.data.profile.id })
						break
					case 'salesforcePermissionSet':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						setActivePermissionsKeys(['salesforcePermissionSet'])
						setLastSelectedNode({ type: node.type, id: node.data.permissionSet.id })
						break
					case 'salesforcePermissionSets':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						setActivePermissionsKeys(['salesforcePermissionSet'])
						break
					case 'salesforceConnectedAppMetadata':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						break
					case 'ownership':
						openSidePanel()
						setActiveSidePanelKeys(['Ownership'])
						break
				}
			},
			[identity, navigate, setActiveSidePanelKeys, setLastSelectedNode],
		)

		return (
			<ReactFlow
				nodes={nodes}
				edges={edges}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				nodeTypes={nodeTypes}
				fitView={true}
				fitViewOptions={fitViewOptions}
				onNodeClick={onNodeClick}
				nodesDraggable={false}
				nodesConnectable={false}
				edgesFocusable={false}
				selectNodesOnDrag={false}
				deleteKeyCode={null}
				selectionKeyCode={null}
				multiSelectionKeyCode={null}
				zoomActivationKeyCode={null}
				panActivationKeyCode={null}
				proOptions={{ hideAttribution: true }}
			>
				<MiniMap nodeColor={getMinimapNodeColor} pannable nodeComponent={MiniMapNode} />
				<Background />
				<Controls showInteractive={false} position="top-right" fitViewOptions={fitViewOptions}>
					<CompactModeControlButton isCompact={isCompact} setIsCompact={setIsCompact} />
				</Controls>
			</ReactFlow>
		)
	},
)
