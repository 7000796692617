import React from 'react'
import { Identity } from '../../../../../schemas/identity.ts'
import { EnvironmentType } from '../../../../../schemas/envType.ts'
import { BaseIdentityDetail } from '../../baseIdentities/BaseIdentityDetail.tsx'
import { IdentityTypeTag } from '../../../../common/IdentityTypeTag.tsx'
import { IssueSourceIcon } from '../../../../common/IssueSourceIcon.tsx'
import { Tag, Tooltip } from 'antd'
import { formatDate } from '../../../../../utils.ts'
import { AzureKVKey } from '../../../../../schemas/identities/azure/azureKeyVault/azureKvKey.ts'

export const AzureKVKeyIdentity: React.FunctionComponent<{
	identity?: Identity | null
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const azureKVKey: AzureKVKey | null | undefined = identity?.azureKvKey

	return (
		<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
			<BaseIdentityDetail label="Key Name" value={azureKVKey?.name} span={1} />
			<BaseIdentityDetail label="Key ID" value={azureKVKey?.keyId} span={1} />
			<BaseIdentityDetail label="Identity Type" span={1}>
				<IdentityTypeTag type={identity?.type} />
			</BaseIdentityDetail>
			<BaseIdentityDetail label="Environment" span={1}>
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<Tooltip placement="topLeft" title={identity?.accountLiteral}>
						<div className="font-normal text-gray-600 items-end truncate">
							{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
						</div>
					</Tooltip>
				</div>
			</BaseIdentityDetail>
			<BaseIdentityDetail label="Vault Name" value={azureKVKey?.vaultName} span={1} />
			<BaseIdentityDetail label="Vault ID" value={azureKVKey?.vaultId} span={1} />
			<BaseIdentityDetail label="Subscription ID" value={azureKVKey?.subscriptionId} span={1} />

			<BaseIdentityDetail label="Status" span={1}>
				<Tag color={azureKVKey?.isEnabled ? 'green' : 'red'}>
					{azureKVKey?.isEnabled ? 'Enabled' : 'Disabled'}
				</Tag>
			</BaseIdentityDetail>
			<BaseIdentityDetail label="Managed" span={1}>
				<Tag color={azureKVKey?.isManaged ? 'blue' : 'default'}>
					{azureKVKey?.isManaged ? 'Managed' : 'Not Managed'}
				</Tag>
			</BaseIdentityDetail>
			<BaseIdentityDetail label="Created At" value={formatDate(azureKVKey?.createdAt)} span={1} />
			<BaseIdentityDetail label="Updated At" value={formatDate(azureKVKey?.updatedAt)} span={1} />
			<BaseIdentityDetail label="Start Date" value={formatDate(azureKVKey?.startDate)} span={1} />
			<BaseIdentityDetail label="Expiration Date" value={formatDate(azureKVKey?.expirationDate)} span={1} />
		</div>
	)
}
