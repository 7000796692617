import { Identity } from '../../../schemas/identity.ts'
import { KubernetesResourceXc } from '../../../schemas/identities/kubernetes/kubernetesResourceXcSchema.ts'
import { AwsEc2InstanceXc } from '../../../schemas/identities/awsEc2InstanceXcSchema.ts'
import { useHighlightedNodesContext } from '../HighlightedNodesContext.tsx'
import { KubernetesResourcesTable } from '../../../tables/KubernetesResourcesTable.tsx'
import { Collapse, CollapseProps } from 'antd'
import { Ec2InstancesTable } from '../../../tables/Ec2InstancesTable.tsx'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import KubernetesIcon from '../../../assets/kubernetes_icon_20.svg?react'
import MachineIcon from '../../../assets/machine_icon_20.svg?react'
import AwsIcon from '../../../assets/aws_icon_16.svg?react'
import AzureVmIcon from '../../../assets/azure_virtual_machine.svg?react'
import { DemoAzureVirtualMachineTable } from '../../../components/drawers/identities/azure/demo/DemoAzureVirtualMachineInstance.tsx'
import { EndpointAccessXc } from '../../../schemas/identities/endpointAccessXcSchema.ts'
import { EndpointAccessContextTable } from '../../../tables/EndpointAccessTable.tsx'
import { CollapsibleItemLabel } from '../../../components/common/CollaplsibleItemLabel.tsx'

export type DependenciesCollapseItemKeys = 'kubernetes' | 'ec2' | 'azureVms' | 'endpointAccess'
type DependenciesCollapseItem = Required<CollapseProps>['items'][number] & {
	key: DependenciesCollapseItemKeys
}

type SidePanelDependenciesProps = {
	identity: Identity
	activeDependenciesKeys: DependenciesCollapseItemKeys[]
	setActiveDependenciesKeys: Dispatch<SetStateAction<DependenciesCollapseItemKeys[]>>
}

export const SidePanelDependencies = ({
	identity,
	activeDependenciesKeys,
	setActiveDependenciesKeys,
}: SidePanelDependenciesProps) => {
	const { setHighlightedNodes } = useHighlightedNodesContext()
	const onCollapseChange = useCallback(
		(key: string | string[]) => {
			const keyList =
				typeof key === 'string'
					? [key as DependenciesCollapseItemKeys]
					: (key as DependenciesCollapseItemKeys[])
			setActiveDependenciesKeys(keyList)
		},
		[setActiveDependenciesKeys],
	)

	const kubernetesResourcesXc: KubernetesResourceXc[] =
		identity.awsIamUser?.kubernetesResourcesXc ||
		identity.awsIamRole?.kubernetesResourcesXc ||
		identity.databricksServicePrincipal?.kubernetesResourcesXc ||
		identity.entraIdServicePrincipal?.kubernetesResourcesXc ||
		identity.gcpServiceAccount?.kubernetesResourcesXc ||
		identity.postgresRole?.kubernetesResourcesXc ||
		[]
	const awsEc2InstancesXc: AwsEc2InstanceXc[] =
		identity.awsIamRole?.awsEc2InstancesXc ||
		identity.githubUser?.awsEc2InstancesXc ||
		identity.postgresRole?.awsEc2InstancesXc ||
		[]
	const endpointAccessXc: EndpointAccessXc[] =
		identity.gcpServiceAccount?.endpointAccessXc || identity.githubUser?.endpointAccessXc || []
	const azureVms = identity.entraIdServicePrincipal?.virtualMachines || []
	const items: DependenciesCollapseItem[] = useMemo(() => {
		const ret: DependenciesCollapseItem[] = []
		if (kubernetesResourcesXc.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Kubernetes'} icon={KubernetesIcon} />,
				headerClass: '!items-center',
				key: 'kubernetes',
				children: <KubernetesResourcesTable kubernetesResourcesXc={kubernetesResourcesXc} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'kubernetesResources', id: '' }])
				},
			})
		}

		if (awsEc2InstancesXc.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'EC2 Instances'} icon={AwsIcon} />,
				key: 'ec2',
				headerClass: '!items-center',
				children: <Ec2InstancesTable awsEc2InstancesXc={awsEc2InstancesXc} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'ec2Instances', id: '' }])
				},
			})
		}

		if (endpointAccessXc.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Endpoint Access'} icon={MachineIcon} />,
				key: 'endpointAccess',
				headerClass: '!items-center',
				children: <EndpointAccessContextTable endpointAccessXc={endpointAccessXc} />,
			})
		}

		if (azureVms.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Virtual Machines'} icon={AzureVmIcon} />,
				key: 'azureVms',
				headerClass: '!items-center',
				children: <DemoAzureVirtualMachineTable azureVirtualMachines={azureVms} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'azureVirtualMachines', id: '' }])
				},
			})
		}

		return ret
	}, [identity])

	if (!kubernetesResourcesXc.length && !awsEc2InstancesXc.length && !azureVms.length && !endpointAccessXc.length) {
		return 'No dependencies data'
	}

	return (
		<div
			onMouseLeave={() => {
				setHighlightedNodes([])
			}}
		>
			<Collapse
				onChange={onCollapseChange}
				activeKey={activeDependenciesKeys}
				style={{ backgroundColor: 'white' }}
				expandIconPosition={'end'}
				items={items}
			/>
		</div>
	)
}
