import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent, IRowNode } from '@ag-grid-community/core'
import { AwsEc2InstanceXc } from '../schemas/identities/awsEc2InstanceXcSchema.ts'
import SuccessIcon from '../assets/success_icon_20.svg?react'
import WarningIcon from '../assets/warning_icon_20.svg?react'
import { Tooltip } from 'antd'
import { capitalizeFirstLetter, formatDate, removeNulls } from '../utils.ts'
import { JsonViewer } from '../components/common/JsonViewer.tsx'

export const Ec2InstancesTable: React.FunctionComponent<{
	awsEc2InstancesXc: AwsEc2InstanceXc[] | null
}> = ({ awsEc2InstancesXc }) => {
	const gridRef = useRef<AgGridReact<AwsEc2InstanceXc>>(null)
	const columnDefs = useMemo<ColDef<AwsEc2InstanceXc>[]>(() => {
		return [
			{
				field: 'state.name',
				headerName: 'State',
				flex: 0.75,
				cellRenderer: (params: { data?: AwsEc2InstanceXc; node: IRowNode }) => {
					return <div>{capitalizeFirstLetter(params?.data?.state?.name)}</div>
				},
			},
			{
				headerName: 'IMDS',
				flex: 0.75,
				cellRenderer: (params: { data?: AwsEc2InstanceXc; node: IRowNode }) => {
					if (params.data?.metadataOptions?.httpEndpoint === 'disabled') {
						return <div>Disabled</div>
					}
					if (
						params.data?.metadataOptions?.httpEndpoint === 'enabled' &&
						params.data?.metadataOptions?.httpTokens === 'optional'
					) {
						return (
							<Tooltip title="IMDSv2 is optional">
								<span>
									<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
								</span>
							</Tooltip>
						)
					}
					return (
						<Tooltip title="IMDSv2 is required">
							<span>
								<SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
							</span>
						</Tooltip>
					)
				},
			},
			{ field: 'instanceId' },
			{ field: 'publicIpAddress', headerName: 'Public IP' },
			{ field: 'privateIpAddress', headerName: 'Private IP' },
			{ field: 'instanceLifecycle', headerName: 'Lifecycle' },
			{
				field: 'launchTime',
				cellRenderer: (params: { data?: AwsEc2InstanceXc; node: IRowNode }) => {
					if (params.node.group) return
					return <div className="text-gray-400">{formatDate(params.data?.launchTime)}</div>
				},
			},
			{
				field: 'tags',
				headerName: 'Tags',
				valueFormatter: (params) => JSON.stringify(params.data?.tags),
				cellRenderer: (params: { data?: AwsEc2InstanceXc; node: IRowNode }) => {
					return <JsonViewer data={params.data?.tags ? removeNulls(params.data.tags) : {}} title="Tags" />
				},
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback((_params: GridReadyEvent<AwsEc2InstanceXc>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine overflow-x-auto'}
				ref={gridRef}
				rowData={awsEc2InstancesXc}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				detailRowHeight={200}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
