import { z } from 'zod'
import { camelizeSchema } from '../utils.ts'
import { ServerAbstractEnvironmentModelSchema } from './serverAbstractEnvironmentSchema.ts'
import { ServerAwsAccountSchema } from './awsAccountSchema.ts'

export const ServerAwsEnvironmentSchema = ServerAbstractEnvironmentModelSchema.extend({
	org_id: z.string().nullish(),
	external_id: z.string().uuid(),
	aws_accounts: z.array(ServerAwsAccountSchema),
})

export type ServerAwsEnvironment = z.infer<typeof ServerAwsEnvironmentSchema>

export const AwsEnvironmentSchema = camelizeSchema(ServerAwsEnvironmentSchema.transform((val) => val))
export type AwsEnvironment = z.infer<typeof AwsEnvironmentSchema>
