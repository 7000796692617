import React, { useMemo } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import {
	ColDef,
	FilterChangedEvent,
	FirstDataRenderedEvent,
	GridPreDestroyedEvent,
	GridReadyEvent,
	IRowNode,
	RowClickedEvent,
	SideBarDef,
	StatusPanelDef,
} from '@ag-grid-community/core'
import { Tag as AntdTag, Tooltip } from 'antd'
import { PriorityType, PriorityTypeMap } from '../../schemas/issue'
import {
	IdentitiesTableRow,
	IdentitySource,
	IdentitySourceMap,
	inventoryUrlQuickSearchFilterParameter,
} from '../../schemas/identity'
import { formatDate, formatRelativeDateText } from '../../utils'
import { IdentityTypeTag } from '../../components/common/IdentityTypeTag'
import { PriorityNameTag } from '../../components/common/PriorityNameTag'
import { IdentityTypeWithIcon } from '../../components/common/IdentityTypeWithIcon.tsx'
import { IssueSourceFilterIcon } from '../../components/common/IssueSourceIconFilter.tsx'
import { IssueSourceIcon } from '../../components/common/IssueSourceIcon.tsx'
import KubernetesIcon from '../../assets/kubernetes_icon_20.svg?react'
import PostgresIcon from '../../assets/postgres_icon_20.svg?react'
import { getLatestIssuesLastSeen } from './helpers'
import { getIdentitiesTableRowStyle } from '../helpers.ts'
import { IdentityTagFilterIcon } from '../../components/common/IdentityTag/IdentityTagFilterIcon.tsx'
import { Tag, TagsDisplayNameMap } from '../../schemas/tags.ts'
import { camelCaseToUnderscoreSpace } from '../../utils.ts'
import { useIdentitiesContext } from '../../routes/Identities/context/IdentitiesProvider.tsx'
import { IdentitiesGroupCellRender } from './IdentitiesGroupCellRenderer.tsx'
import { TagIcons } from '../../components/common/TagIcons.tsx'
import { getIssuesMaxPriority } from '../../utils/issueUtils.ts'

export const IdentitiesTable: React.FC<{
	identitiesTableData: IdentitiesTableRow[] | undefined
	onIdentityClicked: (row: RowClickedEvent<IdentitiesTableRow>) => void
	onIdentityTableFilterChanged: (event: FilterChangedEvent<IdentitiesTableRow>) => void
	onGridReady: (event: GridReadyEvent<IdentitiesTableRow>) => void
	onGridPreDestroyed: (event: GridPreDestroyedEvent<IdentitiesTableRow>) => void
	onFirstDataRendered: (event: FirstDataRenderedEvent<IdentitiesTableRow>) => void
}> = ({
	identitiesTableData,
	onIdentityClicked,
	onIdentityTableFilterChanged,
	onGridReady,
	onGridPreDestroyed,
	onFirstDataRendered,
}) => {
	const { gridRef, searchText } = useIdentitiesContext()
	const columnDefs: ColDef<IdentitiesTableRow>[] = useMemo(() => {
		return [
			{
				headerName: 'Identity Type',
				enableRowGroup: true,
				field: 'type',
				hide: false,
				minWidth: 150,
				flex: 1,
				cellRenderer: (params: { data?: IdentitiesTableRow; node: IRowNode }) => {
					if (params.node.group) return
					return <IdentityTypeTag type={params.data?.type} />
				},
				filterParams: {
					cellRenderer: (params: { value: string }) => {
						return <IdentityTypeWithIcon type={params.value} />
					},
				},
				valueGetter: (params: { data?: IdentitiesTableRow; node: IRowNode | null }) =>
					params.node?.group ? null : params.data?.type,
			},
			{
				headerName: 'Identity Name',
				enableRowGroup: true,
				field: 'literal',
				hide: false,
				filter: 'agTextColumnFilter',
				colId: 'literal',
				filterValueGetter: (params: { data?: IdentitiesTableRow }) =>
					params.data?.literalFriendlyName ?? params.data?.literal ?? '',
				filterParams: {},
				minWidth: 260,
				flex: 2,
				cellRenderer: (params: { data?: IdentitiesTableRow; node: IRowNode }) => {
					if (params.node.group) return
					return (
						<Tooltip placement="bottomLeft" title={params.data?.literal}>
							{params.data?.literalFriendlyName ?? params.data?.literal}
						</Tooltip>
					)
				},

				comparator: (
					_valueA: string,
					_valueB: string,
					nodeA: IRowNode<IdentitiesTableRow>,
					nodeB: IRowNode<IdentitiesTableRow>,
				) => {
					const valA = nodeA?.data?.literalFriendlyName ?? nodeA?.data?.literal ?? ''
					const valB = nodeB?.data?.literalFriendlyName ?? nodeB?.data?.literal ?? ''
					if (typeof valA === 'string' && typeof valB === 'string') {
						return valA.localeCompare(valB)
					}
					return 0
				},
			},
			{
				headerName: 'Identity Source',
				field: 'source',
				flex: 1,
				filter: 'agSetColumnFilter',
				minWidth: 180,
				valueGetter: (params: { data?: IdentitiesTableRow; node: IRowNode | null }) => {
					return params.data?.source ? IdentitySourceMap[params.data?.source] : 'N/A'
				},
				cellRenderer: (params: { data?: IdentitiesTableRow; node: IRowNode }) => {
					if (params.node.group) return
					return (
						<AntdTag>
							<div className="flex items-center">
								<div className="pl-1 text-gray-800 text text-sm">
									{params.data?.source ? IdentitySourceMap[params.data?.source] : 'N/A'}
								</div>
							</div>
						</AntdTag>
					)
				},
				filterParams: {
					cellRenderer: (params: { value: string }) => {
						return params.value
					},
				},
			},
			{
				headerName: 'Posture Score',
				colId: 'max_priority',
				enableRowGroup: true,
				hide: false,
				filter: 'agSetColumnFilter',
				flex: 0.9,
				minWidth: 150,
				filterParams: {
					comparator: (a: string, b: string) => {
						const numA = Object.entries(PriorityTypeMap).find((p) => p[1] === a)![0]
						const numB = Object.entries(PriorityTypeMap)?.find((p) => p[1] === b)![0]
						const valA = parseInt(numA)
						const valB = parseInt(numB)
						if (valA === valB) return 0
						return valA < valB ? 1 : -1
					},
				},
				cellRenderer: (params: { data?: IdentitiesTableRow; node: IRowNode }) => {
					if (params.node.group) return
					const issues = params.data?.issues
					const maxPriority = getIssuesMaxPriority(issues)

					return <PriorityNameTag priority={maxPriority} />
				},
				valueGetter: (params: { data?: IdentitiesTableRow }) => {
					const maxPriority = getIssuesMaxPriority(params.data?.issues)

					return PriorityTypeMap[maxPriority]
				},
				comparator: (valueA: string, valueB: string) => {
					if (typeof valueA === 'string' && typeof valueB === 'string') {
						const priorityA = PriorityType[valueA.toUpperCase() as keyof typeof PriorityType]
						const priorityB = PriorityType[valueB.toUpperCase() as keyof typeof PriorityType]
						if (priorityA !== undefined && priorityB !== undefined) {
							return priorityA - priorityB
						}
					}
					return 0
				},
				sort: 'desc',
				sortIndex: 0,
			},
			{
				headerName: 'Source Environment',
				enableRowGroup: true,
				field: 'accountLiteral',
				hide: false,
				filter: 'agSetColumnFilter',
				flex: 1.2,
				minWidth: 200,
				filterParams: {
					treeList: true,
					treeListPathGetter: (params: string) => {
						const [key, value] = params.split(' - ')
						return [key, value]
					},
					cellRenderer: (params: { value: string }) => {
						return <IssueSourceFilterIcon source={params.value} />
					},
				},
				valueGetter: (params: { data?: IdentitiesTableRow }) => {
					if (params.data?.accountLiteralFriendlyName) {
						return `${params.data?.envType} -  ${params.data?.accountLiteralFriendlyName} (${params.data?.accountLiteral})`
					} else {
						// Concatenate the issueSource and accountLiteral to get the full value
						return `${params.data?.envType} - ${params.data?.accountLiteral}`
					}
				},

				cellRenderer: (params: { data?: IdentitiesTableRow; node: IRowNode }) => {
					if (params.node.group) return
					return (
						<>
							{params.data?.source === IdentitySource.KUBERNETES_RESOURCE ? (
								<div className="flex -space-x-4 rtl:space-x-reverse">
									<IssueSourceIcon source={params.data?.envType} />
									<Tooltip title="Kubernetes">
										<span>
											<KubernetesIcon />
										</span>
									</Tooltip>
								</div>
							) : params.data?.source === IdentitySource.POSTGRES_ROLE ||
							  params.data?.source === IdentitySource.AZURE_POSTGRES_ROLE ? (
								<div className="flex -space-x-4 rtl:space-x-reverse">
									<IssueSourceIcon source={params.data?.envType} />
									<Tooltip title="Postgres">
										<span>
											<PostgresIcon />
										</span>
									</Tooltip>
								</div>
							) : (
								<IssueSourceIcon source={params.data?.envType} />
							)}

							<Tooltip
								placement="bottomLeft"
								title={
									params.data?.accountLiteralFriendlyName
										? `${params.data?.accountLiteralFriendlyName} - (${params.data?.accountLiteral})`
										: params.data?.accountLiteral
								}
							>
								<div className="ml-2 truncate">
									{params.data?.accountLiteralFriendlyName ?? params.data?.accountLiteral}
								</div>
							</Tooltip>
						</>
					)
				},
			},
			{
				headerName: 'Affected Environments',
				enableRowGroup: true,
				field: 'affectedEnvironments',
				hide: false,
				filter: 'agSetColumnFilter',
				flex: 1.2,
				minWidth: 200,
				filterParams: {
					treeList: true,
					treeListPathGetter: (params: string) => {
						if (!params) return ['(Blanks)']
						const [key, value] = params.split(' - ')
						return [key, value]
					},
					cellRenderer: (params: { value: string }) => {
						return <IssueSourceFilterIcon source={params.value} />
					},
				},
				valueGetter: (params: { data?: IdentitiesTableRow }) => {
					const valueSet = new Set()
					if (params.data?.affectedEnvironments) {
						Object.entries(params.data.affectedEnvironments).forEach(([envType, affectedEnvs]) => {
							affectedEnvs.forEach((env) => {
								if (env.accountName) {
									valueSet.add(
										`${camelCaseToUnderscoreSpace(envType).toUpperCase()} - ${env.accountName} (${
											env.accountId
										})`,
									)
								} else {
									valueSet.add(
										`${camelCaseToUnderscoreSpace(envType).toUpperCase()} - ${env.accountId}`,
									)
								}
							})
						})
					}
					return Array.from(valueSet)
				},

				cellRenderer: (params: { data?: IdentitiesTableRow; node: IRowNode }) => {
					if (params.node.group) return
					return (
						<>
							{params.data?.affectedEnvironments &&
								Object.entries(params.data.affectedEnvironments).map(([envType, _environments]) => (
									<div className="flex items-center space-x-2" key={envType}>
										<IssueSourceIcon source={camelCaseToUnderscoreSpace(envType).toUpperCase()} />
									</div>
								))}
						</>
					)
				},
			},
			{
				headerName: 'Risk',
				enableRowGroup: true,
				field: 'tags',
				hide: false,
				filter: 'agSetColumnFilter',
				flex: 1.2,
				minWidth: 200,
				filterValueGetter: (params: { data?: IdentitiesTableRow }) => {
					if (!params.data?.tags || params.data.tags.length === 0) return null
					return params.data.tags
				},
				filterParams: {
					cellRenderer: (params: { value: string }) => {
						if (!params.value) return <>(Blanks)</>
						return <IdentityTagFilterIcon name={params.value as Tag} />
					},
				},
				valueGetter: (params: { data?: IdentitiesTableRow }): string[] | null => {
					if (params.data?.tags && params.data.tags.length > 0) {
						return params.data.tags.map((tag) => TagsDisplayNameMap[tag as Tag] || tag).sort()
					} else {
						return null
					}
				},

				cellRenderer: (params: { data?: IdentitiesTableRow; node: IRowNode }) => {
					if (params.node.group) return
					return <TagIcons tags={params.data?.tags} />
				},
				comparator: (valueA: string[] | null | undefined, valueB: string[] | null | undefined) => {
					if (!valueA && !valueB) {
						return 0
					}
					// First, compare by the number of tags
					if (!valueA) return 1
					if (!valueB) return -1

					const tagCountDiff = valueB.length - valueA.length
					if (tagCountDiff !== 0) {
						return tagCountDiff
					}
					// If the tag count is the same, compare by the sorted tag names
					const sortedTagsA = valueA.slice().sort((a, b) => a.localeCompare(b))
					const sortedTagsB = valueB.slice().sort((a, b) => a.localeCompare(b))

					for (let i = 0; i < sortedTagsA.length && i < sortedTagsB.length; i++) {
						const tagNameDiff = sortedTagsA[i].localeCompare(sortedTagsB[i])
						if (tagNameDiff !== 0) {
							return tagNameDiff
						}
					}

					// If the tag names are the same, consider them equal
					return 0
				},
			},
			{
				headerName: 'Open Issues Count',
				enableRowGroup: true,
				colId: 'issueCount',
				hide: false,
				filter: 'agNumberColumnFilter',
				flex: 1,
				minWidth: 200,
				valueGetter: (params: { data?: IdentitiesTableRow; node: IRowNode | null }) => {
					if (params.node?.group) return
					return params.data?.issues.length
				},
				cellRenderer: (params: { data?: IdentitiesTableRow; node: IRowNode }) => {
					if (params.node.group) return

					return <div className="flex justify-center items-center w-full">{params.data?.issues.length}</div>
				},
				sort: 'desc',
				sortIndex: 1,
			},
			{
				headerName: 'Identity Last Activity',
				field: 'lastActivity',
				hide: false,
				filter: 'agDateColumnFilter',
				flex: 1,
				minWidth: 180,
				cellRenderer: (params: {
					data?: IdentitiesTableRow
					node: IRowNode
					value: Date | undefined | null
				}) => {
					if (params.node.group) return
					return (
						<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
							<div className="text-gray-400">{formatRelativeDateText(params.value, true)}</div>
						</Tooltip>
					)
				},
			},
			{
				headerName: 'Issue last seen',
				colId: 'issueLastSeen',
				hide: false,
				filter: 'agDateColumnFilter',
				flex: 1,
				minWidth: 180,
				cellRenderer: (params: { data?: IdentitiesTableRow; node: IRowNode; value: Date | undefined }) => {
					if (params.node.group) return
					return (
						<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
							<div className="text-gray-400">{formatRelativeDateText(params.value, true)}</div>
						</Tooltip>
					)
				},
				valueGetter: (params: { data?: IdentitiesTableRow; node: IRowNode | null }) =>
					params.node?.group ? null : getLatestIssuesLastSeen(params.data?.issues),
			},
			{
				colId: inventoryUrlQuickSearchFilterParameter,
				hide: true,
				suppressColumnsToolPanel: true,
				filter: 'agTextColumnFilter',
			},
		]
	}, [])
	const defaultColDef = useMemo<ColDef<IdentitiesTableRow>>(
		() => ({
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
				maxNumConditions: 1,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}),
		[],
	)

	const statusBar = useMemo<{
		statusPanels: StatusPanelDef[]
	}>(() => {
		return {
			statusPanels: [{ statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' }],
		}
	}, [])

	const autoGroupColumnDef: ColDef = {
		cellRenderer: IdentitiesGroupCellRender,
		width: 200,
		minWidth: 150,
	}

	const sideBar = useMemo<SideBarDef>(
		() => ({
			position: 'left',
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					minWidth: 225,
					maxWidth: 225,
					width: 225,
					toolPanelParams: {
						suppressPivotMode: true,
					},
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
					minWidth: 300,
					maxWidth: 400,
					width: 300,
				},
			],
		}),
		[],
	)

	return (
		<AgGridReact
			onFilterChanged={onIdentityTableFilterChanged}
			autoGroupColumnDef={autoGroupColumnDef}
			getRowStyle={getIdentitiesTableRowStyle}
			ref={gridRef}
			className={'ag-theme-alpine h-full w-full overflow-x-auto'}
			rowHeight={54}
			onGridReady={onGridReady}
			onGridPreDestroyed={onGridPreDestroyed}
			rowData={identitiesTableData}
			columnDefs={columnDefs}
			defaultColDef={defaultColDef}
			groupDefaultExpanded={1}
			onRowClicked={onIdentityClicked}
			overlayLoadingTemplate={'Loading...'}
			suppressDragLeaveHidesColumns={true}
			overlayNoRowsTemplate={'No data'}
			rowSelection={'single'}
			quickFilterText={searchText}
			statusBar={statusBar}
			suppressHorizontalScroll={false}
			sideBar={sideBar}
			rowGroupPanelShow="never"
			enableCellTextSelection
			onFirstDataRendered={onFirstDataRendered}
		/>
	)
}
