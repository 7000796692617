import { Node, NodeProps } from '@xyflow/react'
import { AppstoreOutlined } from '@ant-design/icons'
import { EntraIDServicePrincipal } from './EntraIDServicePrincipalNode.tsx'
import { BaseIdentityNode } from '../common/baseNodes/BaseIdentityNode.tsx'
import { NodeGroupBadge } from '../common/NodeGroupBadge.tsx'

export type EntraIDServicePrincipalsNodeType = Node<
	{ servicePrincipals: EntraIDServicePrincipal[] },
	'entraIDServicePrincipals'
>
type EntraIDServicePrincipalsNodeProps = NodeProps<EntraIDServicePrincipalsNodeType>

export const EntraIDServicePrincipalsNode = ({ data: { servicePrincipals } }: EntraIDServicePrincipalsNodeProps) => {
	const badgeTooltipContent = servicePrincipals.map((servicePrincipal) => (
		<span key={servicePrincipal.objectId}>{servicePrincipal.principalName}</span>
	))

	return (
		<BaseIdentityNode type="Entra ID Service Principals" Icon={AppstoreOutlined}>
			<NodeGroupBadge items={servicePrincipals} nodeColorType="identity" tooltipContent={badgeTooltipContent} />
		</BaseIdentityNode>
	)
}
