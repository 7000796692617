import { Col, Divider, Row, Space } from 'antd'
import { formatDate, formatRelativeDateText } from '../../../utils'
import { AwsIamRoleContext } from './AwsIamRoleContext'
import { BaseIdentityDetail } from './baseIdentities/BaseIdentityDetail'
import React, { useEffect, useState } from 'react'
import { IdentityTypeTag } from '../../common/IdentityTypeTag'
import { IssueSourceIcon } from '../../common/IssueSourceIcon'
import { Identity } from '../../../schemas/identity'
import { JumpcloudUser } from '../../../schemas/identities/jumpcloud/jumpcloudUserSchema.ts'
import SuccessIcon from '../../../assets/success_icon_20.svg?react'
import WarningIcon from '../../../assets/warning_icon_20.svg?react'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { GcpProjectsContext } from './gcp/GcpProjectsContext.tsx'

export const JumpcloudUserIdentity: React.FunctionComponent<{
	identity?: Identity | null
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const [contextAmount, setContentAmount] = useState(0)
	const jumpcloudUser: JumpcloudUser | null | undefined = identity?.jumpcloudUser
	useEffect(() => {
		calcAmountOfContext()
	})

	const calcAmountOfContext = () => {
		let amount = 0
		if (jumpcloudUser?.awsIamRolesXc?.length) amount++
		if (jumpcloudUser?.gcpProjectsXc?.length) amount++
		setContentAmount(amount)
	}

	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail label="Email" value={jumpcloudUser?.email} span={1} />

				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Environment" span={1}>
					<IssueSourceIcon source={envType} />
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Name" value={jumpcloudUser?.name} span={1} />
				<BaseIdentityDetail label="MFA" span={1}>
					{jumpcloudUser?.mfaActive ? (
						<SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
					) : (
						<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
					)}
				</BaseIdentityDetail>
				{identity?.jumpcloudUser?.passwordLastRotated ? (
					<BaseIdentityDetail
						label="Password Last changed"
						value={formatRelativeDateText(identity?.jumpcloudUser?.passwordLastRotated, true)}
						tooltipTitle={formatDate(identity?.jumpcloudUser?.passwordLastRotated)}
						span={1}
					/>
				) : null}
				<BaseIdentityDetail label="Created At" value={formatDate(identity?.createdAt)} span={1} />
			</div>
			<div className="w-full">
				{contextAmount > 0 && (
					<div className="w-full">
						<Divider />
						<Row>
							<Col>
								<div className="text-gray-900">Context ({contextAmount})</div>
							</Col>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{jumpcloudUser?.awsIamRolesXc && jumpcloudUser?.awsIamRolesXc.length > 0 && (
									<AwsIamRoleContext awsIamRolesXc={jumpcloudUser?.awsIamRolesXc} />
								)}
							</Space>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{jumpcloudUser?.gcpProjectsXc && jumpcloudUser?.gcpProjectsXc?.length > 0 && (
									<GcpProjectsContext gcpProjectsXc={jumpcloudUser?.gcpProjectsXc} />
								)}
							</Space>
						</Row>
					</div>
				)}
			</div>
		</>
	)
}
