import React, { useEffect, useState } from 'react'
import { formatDate, formatRelativeDateText } from '../../../utils'
import { GoogleWorkspaceUserSchema } from '../../../schemas/identities/googleWorkspaceUserSchema.ts'
import { BaseIdentityDetail } from './baseIdentities/BaseIdentityDetail'
import SuccessIcon from '../../../assets/success_icon_20.svg?react'
import WarningIcon from '../../../assets/warning_icon_20.svg?react'
import { AwsIamRoleContext } from './AwsIamRoleContext'
import { Col, Divider, Row } from 'antd'
import { Identity } from '../../../schemas/identity'
import { IdentityTypeTag } from '../../common/IdentityTypeTag'
import { IssueSourceIcon } from '../../common/IssueSourceIcon'
import { OktaUserContext } from './OktaUserContext.tsx'
import { EnvironmentType } from '../../../schemas/envType.ts'

export const GoogleWorkspaceUserIdentity: React.FunctionComponent<{
	identity?: Identity | null
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const [contextAmount, setContentAmount] = useState(0)
	useEffect(() => {
		calcAmountOfContext()
	})
	const googleWorkspaceUser: GoogleWorkspaceUserSchema | undefined | null = identity?.googleWorkspaceUser
	const calcAmountOfContext = () => {
		let amount = 0
		if (googleWorkspaceUser?.awsIamRolesXc?.length ?? 0 > 0) amount++
		if (googleWorkspaceUser?.oktaUserXc?.length ?? 0 > 0) amount++
		setContentAmount(amount)
	}

	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail
					label="Primary Email"
					value={googleWorkspaceUser?.primaryEmail || undefined}
					span={1}
				/>

				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Environment" span={1}>
					<IssueSourceIcon source={envType} />
				</BaseIdentityDetail>
				<BaseIdentityDetail
					label="Recovery Email"
					value={googleWorkspaceUser?.recoveryEmail || 'N/A'}
					span={1}
				/>
				<BaseIdentityDetail
					label="Status"
					value={googleWorkspaceUser?.suspended ? 'Suspended' : 'Active'}
					span={1}
				/>
				<BaseIdentityDetail label="MFA" span={1}>
					{googleWorkspaceUser?.mfaEnabled ? (
						<SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
					) : (
						<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
					)}
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Created At" value={formatDate(identity?.createdAt)} span={1} />
				<BaseIdentityDetail
					label="Last Activity"
					value={formatRelativeDateText(identity?.lastActivity, true)}
					tooltipTitle={formatDate(identity?.lastActivity)}
					span={1}
				/>
				{googleWorkspaceUser?.deletedTime && (
					<BaseIdentityDetail
						label="Deleted At"
						value={formatDate(googleWorkspaceUser?.deletedTime)}
						span={1}
					/>
				)}
			</div>
			<div className="w-full">
				{contextAmount === 0 ? null : (
					<div className="w-full">
						<Divider />
						<Col className="gap-3 space-y-4">
							<Row className="text-gray-900">Context ({contextAmount})</Row>
							{googleWorkspaceUser?.awsIamRolesXc && googleWorkspaceUser?.awsIamRolesXc.length > 0 && (
								<AwsIamRoleContext awsIamRolesXc={googleWorkspaceUser?.awsIamRolesXc} />
							)}
							{googleWorkspaceUser?.oktaUserXc && (
								<OktaUserContext oktaUserXc={googleWorkspaceUser?.oktaUserXc} />
							)}
						</Col>
					</div>
				)}
			</div>
		</>
	)
}
