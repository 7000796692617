import { Node, NodeProps } from '@xyflow/react'
import { FileTextOutlined, MinusOutlined } from '@ant-design/icons'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import { CombinedAwsIamRolePolicy } from '../../../../schemas/identities/awsIamRoleXcSchema.ts'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { getPolicyUniqueKey } from '../../../../utils/awsIdentityUtils.ts'
import cx from 'classnames'
import { RiskNodeTagIcons } from '../common/RiskNodeTagIcons.tsx'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { NodeBadge } from '../common/NodeBadge.tsx'

export type AwsPolicyNodeType = Node<{ policy: CombinedAwsIamRolePolicy }, 'awsPolicy'>
type AwsPolicyNodeProps = NodeProps<AwsPolicyNodeType>

export const AwsPolicyNode = ({ data: { policy } }: AwsPolicyNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'awsPolicy' && highlightedNode.id === getPolicyUniqueKey(policy),
	)
	const badgeClassName = 'bg-blue-100 border-blue-600 text-blue-600'

	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
		{
			[nodeColorClassNames.permission.bg]: !isHighlighted,
			[nodeColorClassNames.permission.bgHighlighted]: isHighlighted,
		},
	)

	return (
		<BaseNode
			type={policy.isPermissionBoundary ? 'Permissions Boundary' : 'AWS Policy'}
			name={{ text: policy.policyName || 'Unknown policy', tooltipText: policy.policyArn || undefined }}
		>
			{policy.isPermissionBoundary ? (
				<NodeBadge className={badgeClassName}>
					<MinusOutlined />
				</NodeBadge>
			) : (
				<RiskNodeTagIcons tags={policy.riskTags} />
			)}

			<div className={iconWrapperClassName}>
				<FileTextOutlined />
			</div>
		</BaseNode>
	)
}
