import React from 'react'
import { Collapse } from 'antd'
import { EnvironmentType } from '../../../../../schemas/envType.ts'
import { IssueSourceIcon } from '../../../../common/IssueSourceIcon.tsx'
import { AzureRoleAssignmentsTable } from '../../../../../tables/Azure/AzureRoleAssignmentsTable.tsx'
import { DetailedAzureRoleAssignment } from '../../../../../schemas/identities/entraId/azureRoleAssignmentsXc.ts'

export const AzureRoleAssignmentsContext: React.FunctionComponent<{
	azureRoleAssignmentsXc?: DetailedAzureRoleAssignment[] | null
}> = ({ azureRoleAssignmentsXc }) => {
	if (!azureRoleAssignmentsXc?.length) {
		return null
	}
	return (
		<Collapse expandIconPosition={'end'}>
			<Collapse.Panel
				header={
					<div className="flex items-center">
						<IssueSourceIcon source={EnvironmentType.AZURE} />
						<div>Azure Role Assignments</div>
					</div>
				}
				key="AzureRoleAssignments"
			>
				<AzureRoleAssignmentsTable data={azureRoleAssignmentsXc} />
			</Collapse.Panel>
		</Collapse>
	)
}
