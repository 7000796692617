import { Collapse } from 'antd'
import React from 'react'
import { IssueSourceIcon } from '../../../common/IssueSourceIcon.tsx'
import { ResourceType } from '../../../../schemas/issue.ts'
import { DatabricksPersonalAccessToken } from '../../../../schemas/identities/databricks/databricksUserSchema.ts'
import { DatabricksPATsTable } from '../../../../tables/DatabricksPATsTable.tsx'

export const DatabricksPATsContext: React.FunctionComponent<{
	databricksPat?: DatabricksPersonalAccessToken[] | null
}> = ({ databricksPat }) => {
	return (
		<Collapse
			expandIconPosition={'end'}
			items={[
				{
					key: 'PATs',
					label: (
						<div className="flex items-center">
							<IssueSourceIcon source={ResourceType.DATABRICKS} />
							<div>Personal Access Tokens</div>
						</div>
					),
					children: <DatabricksPATsTable data={databricksPat ?? []} />,
				},
			]}
		/>
	)
}
