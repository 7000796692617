import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, IRowNode } from '@ag-grid-community/core'
import { AwsIamUserPermission } from '../schemas/identities/awsIamUserXcSchema.ts'
import { formatDate } from '../utils.ts'
import { IamUsersPoliciesTable } from './IamUsersPoliciesTable.tsx'

export const IamUsersTable: React.FunctionComponent<{
	awsIamUsersXc?: AwsIamUserPermission[] | null
}> = ({ awsIamUsersXc }) => {
	const gridRef = useRef<AgGridReact<AwsIamUserPermission>>(null)
	const columnDefs = useMemo<ColDef<AwsIamUserPermission>[]>(() => {
		return [
			{ field: 'userName', cellRenderer: 'agGroupCellRenderer' },
			{ field: 'arn' },
			{
				field: 'createDate',
				cellRenderer: (params: { data?: AwsIamUserPermission; node: IRowNode }) => {
					if (params.node.group) return
					return <div className="text-gray-400">{formatDate(params.data?.createDate)}</div>
				},
			},
		]
	}, [])

	const detailCellRenderer = useMemo(() => {
		return IamUsersPoliciesTable
	}, [])
	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback(() => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	return (
		<div className="w-full h-full iam-roles-table">
			<AgGridReact
				className={'ag-theme-alpine h-full w-full overflow-x-auto'}
				ref={gridRef}
				rowData={awsIamUsersXc}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				detailRowHeight={200}
				defaultColDef={defaultColDef}
				detailCellRenderer={detailCellRenderer}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
