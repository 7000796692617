import { Collapse } from 'antd'
import { IssueSourceIcon } from '../../common/IssueSourceIcon'
import { ResourceType } from '../../../schemas/issue'
import React from 'react'
import { IamTrustRelationshipTable } from '../../../tables/iamTrustRelationshipTable.tsx'
import { AssumeRolePolicyDocument } from '../../../schemas/identities/awsIamRoleXcSchema.ts'

export const AwsIamTrustRelationshipsContext: React.FunctionComponent<{
	trustRelationships: AssumeRolePolicyDocument
}> = ({ trustRelationships }) => {
	return (
		<Collapse expandIconPosition={'end'}>
			<Collapse.Panel
				header={
					<div className="flex items-center">
						<IssueSourceIcon source={ResourceType.AWS} />
						<div>Trust Relationships</div>
					</div>
				}
				key="trust-relationships"
			>
				<IamTrustRelationshipTable data={trustRelationships} />
			</Collapse.Panel>
		</Collapse>
	)
}
