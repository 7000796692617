import { IdentitiesStatsMonitoredIdentities } from './IdentitiesStatsMonitoredIdentities.tsx'
import { IdentitiesStatsRisks } from './IdentitiesStatsRisks.tsx'
import { IdentitiesStatsPosture } from './IdentitiesStatsPosture.tsx'
import { RoutePaths } from '../../RoutePaths.tsx'
import { getQueryFilterFromTableFilterModel } from '../paginatedInventoryUtils.ts'
import { useIdentityStats } from '../../../api/identities.ts'
import { useSearch } from '@tanstack/react-router'
import { useMemo } from 'react'
import { TableFilterEntry } from '../../../schemas/tableFilter.ts'

export const IdentitiesStatsPaginated = () => {
	const { identityFilter, lens } = useSearch({ from: RoutePaths.Identities })
	const query = useMemo(
		() => ({
			filter: getQueryFilterFromTableFilterModel((identityFilter || {}) as Record<string, TableFilterEntry>),
		}),
		[identityFilter],
	)

	const statsQuery = useIdentityStats(query, lens, false, true)

	return (
		<div className="py-4 px-6 flex flex-row gap-2 justify-between min-h-36">
			<IdentitiesStatsMonitoredIdentities statsQuery={statsQuery} />
			<IdentitiesStatsRisks statsQuery={statsQuery} />
			<IdentitiesStatsPosture statsQuery={statsQuery} />
		</div>
	)
}
