import { z } from 'zod'
import { camelizeSchema } from '../../utils.ts'
import { DateSchema } from '../../common.ts'
import { AccountPriorityEnum } from '../../environments/accountPriorityEnum.ts'

export enum GcpResourceState {
	ACTIVE = 'ACTIVE',
	DELETE_REQUESTED = 'DELETE_REQUESTED',
	DELETING = 'DELETING',
}
export const ServerGcpProjectSchemaXc = z.object({
	name: z.string(),
	parent: z.string().nullable(),
	projectId: z.string(),
	displayName: z.string().nullable(),
	createTime: DateSchema.nullable(),
	updateTime: DateSchema.nullable(),
	labels: z.record(z.string()).nullable(),
	tags: z.record(z.string()).nullable(),
	state: z.nativeEnum(GcpResourceState),
})

export const ServerGcpPrincipalInfoXcSchema = z.object({
	roles: z.array(z.string()),
	project: ServerGcpProjectSchemaXc,
	account_priority: z.nativeEnum(AccountPriorityEnum).nullish(),
})

export type ServerGcpPrincipalInfoXc = z.infer<typeof ServerGcpPrincipalInfoXcSchema>

export const ServerGcpPrincipalInfoXc = camelizeSchema(ServerGcpPrincipalInfoXcSchema.transform((val) => val))
export type GcpPrincipalInfoXc = z.infer<typeof ServerGcpPrincipalInfoXc>
