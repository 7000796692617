import { EnvironmentType } from '../../../../schemas/envType'
import { Identity } from '../../../../schemas/identity'
import React from 'react'
import { BaseIdentityDetail } from '../baseIdentities/BaseIdentityDetail.tsx'
import { IssueSourceIcon } from '../../../common/IssueSourceIcon.tsx'
import { IdentityTypeTag } from '../../../common/IdentityTypeTag.tsx'
import { formatDate, formatRelativeDateText } from '../../../../utils.ts'
import { GithubUser } from '../../../../schemas/identities/githubUserSchema.ts'
import { Collapse, Divider } from 'antd'
import GitHubIcon from '../../../../assets/github_icon_20.svg?react'
import { GithubReposTable } from './GithubReposTable.tsx'
import { GithubTeamsTable } from './GithubTeamsTable.tsx'
import { EntraIdUserContext } from '../entraId/EntraIdUserContext.tsx'

export const GithubUserIdentity: React.FunctionComponent<{
	identity?: Identity
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	if (!identity || !identity.githubUser) {
		return null
	}

	const githubUser: GithubUser = identity.githubUser
	let contextAmount = 0
	if (githubUser.githubReposXc && githubUser.githubReposXc.length) contextAmount++
	if (githubUser.githubTeamsXc && githubUser.githubTeamsXc.length) contextAmount++
	if (githubUser.entraIdUserXc) contextAmount++

	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail label="Username" span={1}>
					<a
						href={`https://github.com/orgs/${identity.accountLiteralFriendlyName}/people/${githubUser?.login}`}
						target="_blank"
						rel="noreferrer"
						className="text-blue-600"
					>
						{githubUser?.login}
					</a>
				</BaseIdentityDetail>
				{githubUser?.name && <BaseIdentityDetail label="Name" value={githubUser?.name} span={1} />}
				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Environment" span={1}>
					<div className="flex items-center">
						<IssueSourceIcon source={envType} />
						<div>{identity.accountLiteralFriendlyName}</div>
					</div>
				</BaseIdentityDetail>
				{githubUser?.email && <BaseIdentityDetail label="Email" value={githubUser?.email} span={1} />}
				<BaseIdentityDetail label="Organization role" value={githubUser?.role} span={1} />

				<BaseIdentityDetail label="Created At" value={formatDate(identity?.createdAt)} span={1} />
				<BaseIdentityDetail
					label="Last Activity"
					value={formatRelativeDateText(identity?.lastActivity, true)}
					tooltipTitle={formatDate(identity?.lastActivity)}
					span={1}
				/>
			</div>
			{contextAmount === 0 ? null : (
				<div className="w-full">
					<Divider />
					<div className="gap-3 space-y-4">
						<div className="text-gray-900">Context ({contextAmount})</div>
						{githubUser.githubReposXc?.length ? (
							<Collapse expandIconPosition={'end'}>
								<Collapse.Panel
									header={
										<>
											<GitHubIcon
												style={{ minWidth: 20, minHeight: 20 }}
												className="mr-2 align-middle inline-block"
											/>
											<span className="align-middle font-medium">Github Repositories</span>
										</>
									}
									key={'github-repositories'}
								>
									<GithubReposTable githubRepos={githubUser.githubReposXc} />
								</Collapse.Panel>
							</Collapse>
						) : null}
						{githubUser.githubTeamsXc?.length ? (
							<Collapse expandIconPosition={'end'}>
								<Collapse.Panel
									header={
										<>
											<GitHubIcon
												style={{ minWidth: 20, minHeight: 20 }}
												className="mr-2 align-middle inline-block"
											/>
											<span className="align-middle font-medium">Github Teams</span>
										</>
									}
									key={'github-teams'}
								>
									<GithubTeamsTable githubTeams={githubUser.githubTeamsXc} />
								</Collapse.Panel>
							</Collapse>
						) : null}
						{githubUser.entraIdUserXc && <EntraIdUserContext entraIdUserXc={[githubUser.entraIdUserXc]} />}
					</div>
				</div>
			)}
		</>
	)
}
