import { z } from 'zod'
import { camelizeSchema } from '../../utils.ts'
import { ServerAwsIamRoleXcSchema } from '../awsIamRoleXcSchema.ts'
import { ServerGcpPrincipalInfoXc } from '../gcp/gcpProjectXcSchema.ts'
import { DetailedAzureRoleAssignmentSchema } from './azureRoleAssignmentsXc.ts'
import { ServerEntraIdChangeLogSchema } from './entraIdChangeLogSchema.ts'
import { DetailedEntraIdRoleAssignmentSchema } from './entraIdRoleAssignmentsSchemas.ts'
import { ServerAwsUsageLogSchema } from '../awsIamUserSchema.ts'
import { ServerAzureUsageLogSchema } from './azureUsageLogSchema.ts'

export const ServerEntraIdUserSchema = z
	.object({
		mail: z.string().nullish(),
		given_name: z.string().nullish(),
		job_title: z.string().nullish(),
		user_principal_name: z.string().nullish(),
		entra_user_id: z.string().nullish(),
		last_activity: z.string().nullish(),
		password_last_changed: z.string().nullish(),
		account_enabled: z.boolean().nullish(),
		aws_iam_roles_xc: z.array(ServerAwsIamRoleXcSchema).nullish(),
		gcp_projects_xc: z.array(ServerGcpPrincipalInfoXc).nullish(),
		azure_role_assignments_xc: z.array(DetailedAzureRoleAssignmentSchema).nullish(),
		entra_id_role_assignments: z.array(DetailedEntraIdRoleAssignmentSchema).nullish(),
		change_logs: z.array(ServerEntraIdChangeLogSchema).nullish(),
		usage_logs: z.array(ServerAzureUsageLogSchema).nullish(),
		demo_usage_logs: z.array(ServerAwsUsageLogSchema).nullish(),
	})
	.nullish()

export const EntraIdUserSchema = camelizeSchema(ServerEntraIdUserSchema.transform((val) => val))
export type EntraIdUser = z.infer<typeof EntraIdUserSchema>
