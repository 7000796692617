import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent } from '@ag-grid-community/core'
import SuccessIcon from '../assets/success_icon_20.svg?react'
import WarningIcon from '../assets/warning_gray_icon_20.svg?react'
import { GoogleWorkspaceUserXcSchema } from '../schemas/identities/googleWorkspaceUserXcSchema.ts'

export const GoogleWorkspaceUserXcTable: React.FunctionComponent<{
	googleWorkspaceUserXc: GoogleWorkspaceUserXcSchema[] | null
}> = ({ googleWorkspaceUserXc }) => {
	const gridRef = useRef<AgGridReact<GoogleWorkspaceUserXcSchema>>(null)
	const columnDefs = useMemo<ColDef<GoogleWorkspaceUserXcSchema>[]>(() => {
		return [
			{ field: 'primaryEmail' },
			{ field: 'recoveryEmail' },
			{
				headerName: 'Name',
				cellRenderer: (params: { data?: GoogleWorkspaceUserXcSchema }) => {
					if (params.data?.name) {
						return <div>{params.data?.name?.fullName}</div>
					}
				},
			},
			{
				resizable: false,
				headerName: 'MFA',
				cellRenderer: (params: { data?: GoogleWorkspaceUserXcSchema }) => {
					if (params.data?.isEnrolledIn2Sv) {
						return <SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
					} else {
						return (
							<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20, color: '#85858C' }} />
						)
					}
				},
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback((_params: GridReadyEvent<GoogleWorkspaceUserXcSchema>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	return (
		<div className="w-full h-full iam-roles-table">
			<AgGridReact
				className={'ag-theme-alpine h-full w-full overflow-x-auto'}
				ref={gridRef}
				rowData={googleWorkspaceUserXc}
				rowHeight={54}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
