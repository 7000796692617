import { Tag, Tooltip } from 'antd'
import { IssueStatus, IssueStatusMap, UserChangeableIssueStatuses } from '../../schemas/issue'
import React from 'react'
import { EditOutlined } from '@ant-design/icons'

export const IssueStatusTag: React.FunctionComponent<{
	status?: IssueStatus | null
	onEdit?: () => void
}> = ({ status, onEdit }) => {
	if (!status) {
		return null
	}

	const isEditable = !!onEdit && UserChangeableIssueStatuses.includes(status)

	return (
		<Tag onClick={isEditable ? onEdit : undefined} className={isEditable ? 'cursor-pointer' : undefined}>
			<div className="flex items-center">
				<div className="text-gray-800 text font-medium">{IssueStatusMap[status]}</div>
				{isEditable && (
					<Tooltip title="Update issue status">
						<EditOutlined className="ml-1" />
					</Tooltip>
				)}
			</div>
		</Tag>
	)
}
