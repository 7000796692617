import { QueryFilterExpression, QueryFilterOperator } from '../schemas/query.ts'
import {
	TableDateFilterType,
	TableFilterEntry,
	TableNumberFilterType,
	TableSetFilterEntry,
	TableTextFilterEntry,
	TableTextFilterType,
} from '../schemas/tableFilter.ts'

const tableTextFilterTypeToQueryFilterOperator: Partial<Record<TableTextFilterType, QueryFilterOperator>> = {
	contains: QueryFilterOperator.ICONTAINS,
	notContains: QueryFilterOperator.NOTCONTAINS,
	equals: QueryFilterOperator.EQ,
	notEqual: QueryFilterOperator.NEQ,
	startsWith: QueryFilterOperator.STARTSWITH,
	endsWith: QueryFilterOperator.ENDSWITH,
}

export const supportedTableTextFilterTypes = Object.keys(tableTextFilterTypeToQueryFilterOperator)

const tableNumberFilterTypeToQueryFilterOperator: Partial<Record<TableNumberFilterType, QueryFilterOperator>> = {
	equals: QueryFilterOperator.EQ,
	notEqual: QueryFilterOperator.NEQ,
	greaterThan: QueryFilterOperator.GT,
	greaterThanOrEqual: QueryFilterOperator.GTE,
	lessThan: QueryFilterOperator.LT,
	lessThanOrEqual: QueryFilterOperator.LTE,
}

export const supportedTableNumberFilterTypes = Object.keys(tableNumberFilterTypeToQueryFilterOperator)

const tableDateFilterTypeToQueryFilterOperator: Partial<Record<TableDateFilterType, QueryFilterOperator>> = {
	greaterThan: QueryFilterOperator.GT,
	lessThan: QueryFilterOperator.LT,
	blank: QueryFilterOperator.EMPTY,
}

export const supportedTableDateFilterTypes = [
	...Object.keys(tableDateFilterTypeToQueryFilterOperator),
	TableDateFilterType.IN_RANGE,
]

type TableFilterEntryToQueryFilterConditionOptions = {
	tableArrayFieldsSet?: Set<string>
}

const tableFilterEntryToQueryFilterCondition = (
	field: string,
	filterEntry: TableFilterEntry,
	options?: TableFilterEntryToQueryFilterConditionOptions,
): QueryFilterExpression => {
	const { tableArrayFieldsSet } = options ?? {}
	if (filterEntry.filterType === 'set') {
		return {
			field,
			op: tableArrayFieldsSet?.has(field) ? QueryFilterOperator.OVERLAP : QueryFilterOperator.IN,
			value: filterEntry.values,
		}
	}

	if (filterEntry.filterType === 'text') {
		const op = tableTextFilterTypeToQueryFilterOperator[filterEntry.type]
		if (!op) {
			throw new Error(
				`Textual filter type ${filterEntry.type} has no operator mapping in 'tableTextFilterTypeToQueryFilterOperator'`,
			)
		}
		return { field, op, value: filterEntry.filter }
	}

	if (filterEntry.filterType === 'number') {
		const op = tableNumberFilterTypeToQueryFilterOperator[filterEntry.type]
		if (!op) {
			throw new Error(
				`Number filter type ${filterEntry.type} has no operator mapping in 'tableNumberFilterTypeToQueryFilterOperator'`,
			)
		}
		return { field, op, value: filterEntry.filter }
	}

	if (filterEntry.filterType === 'date') {
		if (filterEntry.type === TableDateFilterType.IN_RANGE) {
			return {
				AND: [
					{
						field,
						op: QueryFilterOperator.GT,
						value: filterEntry.dateFrom,
					},
					{
						field,
						op: QueryFilterOperator.LT,
						value: filterEntry.dateTo!,
					},
				],
			}
		}

		const op = tableDateFilterTypeToQueryFilterOperator[filterEntry.type]
		if (!op) {
			throw new Error(
				`Date filter type ${filterEntry.type} has no operator mapping in 'tableDateFilterTypeToQueryFilterOperator'`,
			)
		}

		return {
			field,
			op,
			value: filterEntry.dateFrom ?? '',
		}
	}

	throw new Error(`Filter type ${(filterEntry as { filterType: string }).filterType} is not supported`)
}

type TableFilterToQueryFilterOptions = TableFilterEntryToQueryFilterConditionOptions & {
	tableFieldToQueryFields?: Record<string, string[]>
	tableSetToTextContainsFields?: Set<string>
}

export const tableFilterToQueryFilter = (
	tableFilter: Record<string, TableFilterEntry>,
	options?: TableFilterToQueryFilterOptions,
): QueryFilterExpression | undefined => {
	const tableFilterEntries = Object.entries(tableFilter)
	if (tableFilterEntries.length === 0) {
		return
	}

	const { tableFieldToQueryFields, tableSetToTextContainsFields, ...tableFilterEntryToQueryFilterConditionOptions } =
		options ?? {}

	return {
		AND: [
			...tableFilterEntries.map(([field, filterEntry]) => {
				const queryFields = tableFieldToQueryFields?.[field]
				if (queryFields) {
					return {
						OR: queryFields.map((queryField) =>
							tableFilterEntryToQueryFilterCondition(
								queryField,
								filterEntry,
								tableFilterEntryToQueryFilterConditionOptions,
							),
						),
					}
				}

				if (tableSetToTextContainsFields?.has(field)) {
					return {
						OR: (filterEntry as TableSetFilterEntry).values.map((value) => {
							const textFilterEntry: TableTextFilterEntry = {
								filterType: 'text',
								type: TableTextFilterType.CONTAINS,
								filter: value as string,
							}
							return tableFilterEntryToQueryFilterCondition(
								field,
								textFilterEntry,
								tableFilterEntryToQueryFilterConditionOptions,
							)
						}),
					}
				}

				return tableFilterEntryToQueryFilterCondition(
					field,
					filterEntry,
					tableFilterEntryToQueryFilterConditionOptions,
				)
			}),
		],
	}
}
