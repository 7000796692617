import { z } from 'zod'
import { camelizeSchema } from '../utils.ts'
import { ServerAbstractEnvironmentModelSchema } from './serverAbstractEnvironmentSchema.ts'

export const ServerGoogleWorkspaceEnvironmentSchema = ServerAbstractEnvironmentModelSchema.extend({
	subject: z.string(),
	gw_customer_id: z.string().nullish(),
	gcp_environment_id: z.string().uuid().nullish(),
	use_gcp_service_account: z.boolean().default(false),
})

export type ServerGoogleWorkspaceEnvironment = z.infer<typeof ServerGoogleWorkspaceEnvironmentSchema>

export const GoogleWorkspaceEnvironmentSchema = camelizeSchema(
	ServerGoogleWorkspaceEnvironmentSchema.transform((val) => val),
)
export type GoogleWorkspaceEnvironment = z.infer<typeof GoogleWorkspaceEnvironmentSchema>
