import React, { useMemo, useState } from 'react'
import { Menu, MenuProps } from 'antd'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { IdentitiesPageLens } from '../../../schemas/identity.ts'
import { isDemo } from '../../../utils/demoUtils.ts'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { RoutePaths } from '../../RoutePaths.tsx'

type MenuItem = Required<MenuProps>['items'][number]

const MenuItemLabel = ({ children }: { children: React.ReactNode }) => (
	<div className="flex justify-between truncate">{children}</div>
)

export const IdentitiesSideBar = () => {
	const { lens } = useSearch({ from: RoutePaths.Identities })
	const navigate = useNavigate({ from: RoutePaths.Identities })
	const onClick: MenuClickEventHandler = (menuEntry) => {
		const newLens: IdentitiesPageLens | undefined = Object.values(IdentitiesPageLens).includes(
			menuEntry.key as IdentitiesPageLens,
		)
			? (menuEntry.key as IdentitiesPageLens)
			: undefined

		void navigate({
			search: () => {
				if (newLens) {
					return { lens: newLens }
				}
				return {}
			},
		})
	}

	const [items] = useState<Array<MenuItem>>(() => {
		const allItems: MenuItem[] = []
		allItems.push({
			key: IdentitiesPageLens.ALL,
			label: <MenuItemLabel>All</MenuItemLabel>,
		})
		allItems.push({ type: 'divider' })
		allItems.push({
			key: 'sub-menu-identity-type',
			label: <MenuItemLabel>Identity Type</MenuItemLabel>,
			children: [
				{
					key: IdentitiesPageLens.HUMAN,
					label: <MenuItemLabel>Human</MenuItemLabel>,
				},
				{
					key: IdentitiesPageLens.MACHINE,
					label: <MenuItemLabel>Machine</MenuItemLabel>,
				},
				{
					key: IdentitiesPageLens.HYBRID,
					label: <MenuItemLabel>Hybrid</MenuItemLabel>,
				},
				{
					key: IdentitiesPageLens.UNKNOWN,
					label: <MenuItemLabel>Unknown</MenuItemLabel>,
				},
			],
		})
		allItems.push({ type: 'divider' })
		allItems.push({
			key: 'sub-menu-risk',
			label: <MenuItemLabel>Risk</MenuItemLabel>,
			children: [
				{
					key: IdentitiesPageLens.ADMIN,
					label: <MenuItemLabel>Admin</MenuItemLabel>,
				},
				{
					key: IdentitiesPageLens.DATA_ACCESS,
					label: <MenuItemLabel>Data Access</MenuItemLabel>,
				},
				{
					key: IdentitiesPageLens.CREDENTIAL_EXPOSURE,
					label: <MenuItemLabel>Credential Exposure</MenuItemLabel>,
				},
				{
					key: IdentitiesPageLens.RESOURCE_EXPOSURE,
					label: <MenuItemLabel>Resource Exposure</MenuItemLabel>,
				},
				{
					key: IdentitiesPageLens.PRIVILEGE_ESCALATION,
					label: <MenuItemLabel>Privilege Escalation</MenuItemLabel>,
				},
			],
		})
		allItems.push({ type: 'divider' })
		allItems.push({
			key: 'sub-menu-cloud-providers',
			label: <MenuItemLabel>Cloud Providers</MenuItemLabel>,
			children: [
				{
					key: IdentitiesPageLens.AWS,
					label: <MenuItemLabel>AWS</MenuItemLabel>,
				},
				{
					key: IdentitiesPageLens.GCP,
					label: <MenuItemLabel>GCP</MenuItemLabel>,
				},
				{
					key: IdentitiesPageLens.AZURE,
					label: <MenuItemLabel>Azure</MenuItemLabel>,
				},
			],
		})
		allItems.push({ type: 'divider' })
		allItems.push({
			key: 'sub-menu-identity-providers',
			label: <MenuItemLabel>Identity Providers</MenuItemLabel>,
			children: [
				{
					key: IdentitiesPageLens.OKTA,
					label: <MenuItemLabel>Okta</MenuItemLabel>,
				},
				{
					key: IdentitiesPageLens.JUMPCLOUD,
					label: <MenuItemLabel>Jumpcloud</MenuItemLabel>,
				},
				{
					key: IdentitiesPageLens.ENTRA_ID,
					label: <MenuItemLabel>Entra ID</MenuItemLabel>,
				},
				{
					key: IdentitiesPageLens.GOOGLE_WORKSPACE,
					label: <MenuItemLabel>Google Workspace</MenuItemLabel>,
				},
			],
		})
		allItems.push({ type: 'divider' })
		const databasesChildren = [
			{
				key: IdentitiesPageLens.POSTGRES,
				label: <MenuItemLabel>Postgres</MenuItemLabel>,
			},
			{
				key: IdentitiesPageLens.SNOWFLAKE,
				label: <MenuItemLabel>Snowflake</MenuItemLabel>,
			},
		]
		if (isDemo) {
			databasesChildren.push({
				key: IdentitiesPageLens.DEMO_DATABRICKS,
				label: <MenuItemLabel>Databricks</MenuItemLabel>,
			})
		}
		allItems.push({
			key: 'sub-menu-databases',
			label: <MenuItemLabel>Databases</MenuItemLabel>,
			children: databasesChildren,
		})
		allItems.push({ type: 'divider' })
		allItems.push({
			key: 'sub-menu-workloads',
			label: <MenuItemLabel>Workloads</MenuItemLabel>,
			children: [
				{
					key: IdentitiesPageLens.KUBERNETES,
					label: <MenuItemLabel>Kubernetes</MenuItemLabel>,
				},
			],
		})
		allItems.push({ type: 'divider' })
		allItems.push({
			key: IdentitiesPageLens.OFF_BOARDED_EMPLOYEES,
			label: <MenuItemLabel>Off-Boarded Employees</MenuItemLabel>,
		})
		allItems.push({ type: 'divider' })
		allItems.push({
			key: IdentitiesPageLens.MULTIPLE_ACCESS_KEYS,
			label: <MenuItemLabel>Multiple Access Keys</MenuItemLabel>,
		})
		allItems.push({ type: 'divider' })
		allItems.push({
			key: IdentitiesPageLens.UNFEDERATED_IDENTITIES,
			label: <MenuItemLabel>Unfederated Identities</MenuItemLabel>,
		})
		allItems.push({ type: 'divider' })
		allItems.push({
			key: IdentitiesPageLens.RECENTLY_CREATED,
			label: <MenuItemLabel>Recently Created Identities</MenuItemLabel>,
		})
		allItems.push({ type: 'divider' })
		allItems.push({
			key: IdentitiesPageLens.MOST_WANTED,
			label: <MenuItemLabel>Most Wanted Identities</MenuItemLabel>,
		})
		if (isDemo) {
			allItems.push({ type: 'divider' })
			allItems.push({
				key: IdentitiesPageLens.DEMO_OVER_PRIVILEGED_IDENTITY,
				label: <MenuItemLabel>Over-Privileged Identities</MenuItemLabel>,
			})
			allItems.push({ type: 'divider' })
			allItems.push({
				key: IdentitiesPageLens.DEMO_IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR,
				label: <MenuItemLabel>3rd Party Identities</MenuItemLabel>,
			})
		}

		return allItems
	})

	const menuStyle: React.CSSProperties = {
		backgroundColor: '#353535',
		color: '#BABBBF',
		border: '1px 0px 1px 1px solid #6A6A73',
		boxShadow: '0px 0px 10px 0px #0000001A',
	}

	const defaultOpenKeys = useMemo(() => {
		if (!lens) {
			return []
		}

		const openSubMenu = items.find((item) => {
			if (!item || !('children' in item)) {
				return false
			}

			return item.children!.find((subItem) => subItem?.key === lens)
		})

		if (!openSubMenu) {
			return []
		}

		return [openSubMenu.key as string]
	}, [lens, items])

	return (
		<Menu
			onClick={onClick}
			style={menuStyle}
			selectedKeys={lens ? [lens] : [IdentitiesPageLens.ALL]}
			defaultOpenKeys={defaultOpenKeys}
			mode="inline"
			theme={'dark'}
			items={items}
		/>
	)
}
