import { Node, NodeProps } from '@xyflow/react'
import { GithubOutlined } from '@ant-design/icons'
import { GithubRepository } from '../../../../schemas/identities/githubUserSchema.ts'
import { BaseEnvironmentNode } from '../common/baseNodes/BaseEnvironmentNode.tsx'

export type GithubRepositoryNodeType = Node<{ repository: GithubRepository }, 'githubRepository'>
type GithubRepositoryNodeProps = NodeProps<GithubRepositoryNodeType>

export const GithubRepositoryNode = ({ data: { repository } }: GithubRepositoryNodeProps) => (
	<BaseEnvironmentNode type="Repository" name={{ text: repository.name }} icon={<GithubOutlined />} />
)
