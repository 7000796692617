import { DateSchema } from '../../common.ts'
import { z } from 'zod'
import { camelizeSchema } from '../../utils.ts'

// Schema for individual audit trail logs
export const ServerSalesforceSetupAuditTrailLogSchema = z.object({
	timestamp: DateSchema,
	action: z.string(),
	section: z.string().nullable(),
	display: z.string().nullable(),
})

export const SalesforceSetupAuditTrailLogSchema = camelizeSchema(
	ServerSalesforceSetupAuditTrailLogSchema.transform((val) => val),
)

// Schema for aggregated audit trail logs
export const ServerSalesforceSetupAuditTrailAggregatedLogSchema = z.object({
	day: DateSchema,
	created_by: z.string(),
	sections: z.array(z.string()),
	actions_count: z.number(),
})

export const SalesforceSetupAuditTrailAggregatedLogSchema = camelizeSchema(
	ServerSalesforceSetupAuditTrailAggregatedLogSchema.transform((val) => val),
)

// Type definitions
export type SalesforceSetupAuditTrailLog = z.infer<typeof SalesforceSetupAuditTrailLogSchema>
export type SalesforceSetupAuditTrailAggregatedLog = z.infer<typeof SalesforceSetupAuditTrailAggregatedLogSchema>
