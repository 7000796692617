import React, { useEffect, useState } from 'react'
import { Identity } from '../../../../schemas/identity.ts'
import { EntraIdUser } from '../../../../schemas/identities/entraId/entraIdUserSchema.ts'
import { BaseIdentityDetail } from '../baseIdentities/BaseIdentityDetail.tsx'
import { IdentityTypeTag } from '../../../common/IdentityTypeTag.tsx'
import { IssueSourceIcon } from '../../../common/IssueSourceIcon.tsx'
import { formatDate, formatRelativeDateText } from '../../../../utils.ts'
import { AwsIamRoleContext } from '../AwsIamRoleContext.tsx'
import { Col, Divider, Row, Space, Tag, Tooltip } from 'antd'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import { GcpProjectsContext } from '../gcp/GcpProjectsContext.tsx'
import { AzureRoleAssignmentsContext } from '../azure/azureRBAC/AzureRoleAssignmentsContext.tsx'
import { EntraIdChangeLogsContext } from './EntraIdChangeLogsContext.tsx'
import { AzureUsageLogsContext } from '../azure/AzureUsageLogsContext.tsx'

export const EntraIdUserIdentity: React.FunctionComponent<{
	identity?: Identity | null
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const [contextAmount, setContentAmount] = useState(0)
	const entraIdUser: EntraIdUser | null | undefined = identity?.entraIdUser
	useEffect(() => {
		calcAmountOfContext()
	})

	const calcAmountOfContext = () => {
		let amount = 0
		if (entraIdUser?.awsIamRolesXc?.length) amount++
		if (entraIdUser?.gcpProjectsXc?.length) amount++
		if (entraIdUser?.azureRoleAssignmentsXc?.length) amount++
		if (entraIdUser?.changeLogs?.length) amount++
		if (entraIdUser?.usageLogs?.length) amount++
		setContentAmount(amount)
	}
	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				{entraIdUser?.mail ? <BaseIdentityDetail label="Email" value={entraIdUser?.mail} span={1} /> : null}
				<BaseIdentityDetail label="Entra User Id" value={entraIdUser?.entraUserId} span={1} />
				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Environment" span={1}>
					<div className="flex flex-row items-center space-x-2">
						<IssueSourceIcon source={envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
						<Tooltip placement="topLeft" title={identity?.accountLiteral}>
							<div className="font-normal text-gray-600 items-end truncate">
								{identity?.accountLiteralFriendlyName ?? identity?.accountLiteral}
							</div>
						</Tooltip>
					</div>
				</BaseIdentityDetail>
				{entraIdUser?.givenName ? (
					<BaseIdentityDetail label="Name" value={entraIdUser?.givenName} span={1} />
				) : null}
				<BaseIdentityDetail label="User Principal Name" value={entraIdUser?.userPrincipalName} span={1} />
				{entraIdUser?.jobTitle ? (
					<BaseIdentityDetail label="Job Title" value={entraIdUser?.jobTitle} span={1} />
				) : null}
				<BaseIdentityDetail label="Created At" value={formatDate(identity?.createdAt)} span={1} />
				{entraIdUser?.lastActivity ? (
					<BaseIdentityDetail
						label="Last Login"
						value={formatRelativeDateText(entraIdUser?.lastActivity, true)}
						tooltipTitle={formatDate(entraIdUser?.lastActivity)}
						span={1}
					/>
				) : null}
				{entraIdUser?.passwordLastChanged ? (
					<BaseIdentityDetail
						label="Last Password Change"
						value={formatRelativeDateText(entraIdUser?.passwordLastChanged, true)}
						tooltipTitle={formatDate(entraIdUser?.passwordLastChanged)}
						span={1}
					/>
				) : null}
				{entraIdUser?.accountEnabled !== null ? (
					<BaseIdentityDetail label="Account status" span={1}>
						<Tag>
							<div className="flex items-center">
								<div
									className={`${entraIdUser?.accountEnabled ? 'text-green-700' : 'text-gray-700'} text font-medium`}
								>
									{entraIdUser?.accountEnabled ? 'Enabled' : 'Disabled'}
								</div>
							</div>
						</Tag>
					</BaseIdentityDetail>
				) : null}
			</div>
			<div className="w-full">
				{contextAmount > 0 && (
					<div className="w-full">
						<Divider />
						<Row>
							<Col>
								<div className="text-gray-900">Context ({contextAmount})</div>
							</Col>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{entraIdUser?.awsIamRolesXc && entraIdUser?.awsIamRolesXc.length > 0 && (
									<AwsIamRoleContext awsIamRolesXc={entraIdUser?.awsIamRolesXc} />
								)}
							</Space>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{entraIdUser?.gcpProjectsXc && entraIdUser?.gcpProjectsXc?.length > 0 && (
									<GcpProjectsContext gcpProjectsXc={entraIdUser?.gcpProjectsXc} />
								)}
							</Space>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{entraIdUser?.azureRoleAssignmentsXc &&
									entraIdUser?.azureRoleAssignmentsXc?.length > 0 && (
										<AzureRoleAssignmentsContext
											azureRoleAssignmentsXc={entraIdUser.azureRoleAssignmentsXc}
										/>
									)}
							</Space>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{identity && entraIdUser?.changeLogs && entraIdUser?.changeLogs?.length > 0 && (
									<EntraIdChangeLogsContext changeLogs={entraIdUser.changeLogs} identity={identity} />
								)}
							</Space>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{identity && entraIdUser?.usageLogs && entraIdUser?.usageLogs?.length > 0 && (
									<AzureUsageLogsContext usageLogs={entraIdUser.usageLogs} identity={identity} />
								)}
							</Space>
						</Row>
					</div>
				)}
			</div>
		</>
	)
}
