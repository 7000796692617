import { Node, NodeProps } from '@xyflow/react'
import { FileTextOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import { SalesforceConnectedAppMetadata } from '../../../../schemas/identities/salesforce/salesforceConnectedApplicationSchema.ts'

export type SalesforceConnectedAppMetadataNodeType = Node<
	{
		metadata: SalesforceConnectedAppMetadata
	},
	'salesforceConnectedAppMetadata'
>
type SalesforceConnectedAppOAuthMetadataNodeProps = NodeProps<SalesforceConnectedAppMetadataNodeType>

export const SalesforceConnectedAppMetadataNode = ({
	data: { metadata },
}: SalesforceConnectedAppOAuthMetadataNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) =>
			highlightedNode.type === 'salesforceConnectedAppMetadata' && highlightedNode.id === metadata.label,
	)

	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} ${nodeColorClassNames.permission.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
		{
			[nodeColorClassNames.permission.bg]: !isHighlighted,
			[nodeColorClassNames.permission.bgHighlighted]: isHighlighted,
		},
	)

	return (
		<BaseNode type="Connected Application Metadata" name={{ text: metadata.label }}>
			<div className={iconWrapperClassName}>
				<FileTextOutlined />
			</div>
		</BaseNode>
	)
}
