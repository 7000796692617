import { Identity } from '../../../schemas/identity.ts'
import { Card, Tooltip } from 'antd'
import { tagsIconsMap } from '../../../components/common/IdentityTag/common.tsx'
import { getTagDescription, Tag, TagsDisplayNameMap } from '../../../schemas/tags.ts'
import { getCombinedAwsIamRolePolicy, getPolicyUniqueKey } from '../../../utils/awsIdentityUtils.ts'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useHighlightedNodesContext } from '../HighlightedNodesContext.tsx'

export const SidePanelRisks = ({ identity }: { identity: Identity }) => {
	const { setHighlightedNodes } = useHighlightedNodesContext()
	if (!identity.tags?.length) {
		return 'No risks data'
	}

	const policies = getCombinedAwsIamRolePolicy(
		identity.awsIamUser?.awsIamUserDetailsXc,
		identity.awsIamRole?.awsIamRoleDetailsXc,
	)

	return (
		<div className="flex flex-col gap-2">
			{identity.tags
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((tag) => {
					const relatedPolicies = policies.filter((policy) =>
						policy.riskTags?.find((riskTag) => riskTag.name === tag.name),
					)

					const onOpenChange = (visible: boolean) => {
						if (!visible) {
							setHighlightedNodes([])
						} else {
							setHighlightedNodes(
								relatedPolicies.map((relatedPolicy) => ({
									type: 'awsPolicy',
									id: getPolicyUniqueKey(relatedPolicy),
								})),
							)
						}
					}

					const tagName = tag.name as Tag
					const Icon = tagsIconsMap[tagName].icon
					return (
						<Card
							key={tag.name}
							title={
								<div className="flex gap-2 items-center">
									{!!Icon && <Icon style={{ width: 20, height: 20 }} />}
									{TagsDisplayNameMap[tagName]}
								</div>
							}
						>
							<div className="flex flex-col gap-1">
								{relatedPolicies.length !== 0 && (
									<div className="flex gap-2 items-center text-zinc-500">
										<span>{`Related policies (${relatedPolicies.length})`}</span>
										<Tooltip
											onOpenChange={onOpenChange}
											title={
												<div className="flex flex-col">
													{relatedPolicies.map((relatedPolicy) => (
														<span key={getPolicyUniqueKey(relatedPolicy)}>
															{relatedPolicy.policyName}
														</span>
													))}
												</div>
											}
										>
											<ExclamationCircleOutlined className="cursor-pointer mt-[2px] text-base" />
										</Tooltip>
									</div>
								)}
								<span className="text-zinc-700">
									{getTagDescription(tagName, tag.relatedResourceType)}
								</span>
							</div>
						</Card>
					)
				})}
		</div>
	)
}
