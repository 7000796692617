import React, { useCallback, useMemo, useRef, useState } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, SideBarDef } from '@ag-grid-community/core'
import { formatDate, getCsvFileName } from '../../utils'
import {
	SalesforceApiTotalUsageAggregatedLogReadModel,
	SalesforceApiTotalUsageLog,
} from '../../schemas/identities/salesforce/salesforceApiTotalUsageLogSchema'
import { Button, Modal, Skeleton } from 'antd'
import ExportIcon from '../../assets/export_icon_20.svg?react'
import { Identity } from '../../schemas/identity'
import { useIdentityUsageLogs } from '../../api/identities'

type Props = {
	identity: Identity
}

export const SalesforceApiTotalUsageLogsTable: React.FunctionComponent<Props> = ({ identity }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [hasStartedLoadingDetails, setHasStartedLoadingDetails] = useState(false)

	const aggregatedGridRef = useRef<AgGridReact<SalesforceApiTotalUsageAggregatedLogReadModel>>(null)
	const detailedGridRef = useRef<AgGridReact<SalesforceApiTotalUsageLog>>(null)

	const { data: aggregatedData, isLoading: isLoadingAggregated } = useIdentityUsageLogs(identity.id, true)
	const {
		data: detailedData,
		isLoading: isLoadingDetailed,
		isFetching: isFetchingDetailed,
	} = useIdentityUsageLogs(identity.id, false, {
		enabled: hasStartedLoadingDetails,
		staleTime: 5 * 60 * 1000,
		refetchOnWindowFocus: false,
	})

	const handleOpenModal = useCallback(() => {
		setHasStartedLoadingDetails(true)
		setIsModalOpen(true)
	}, [])

	const sideBar: SideBarDef = useMemo(() => {
		return {
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
				},
			],
			position: 'left',
		}
	}, [])

	const aggregatedColumnDefs = useMemo<ColDef<SalesforceApiTotalUsageAggregatedLogReadModel>[]>(
		() => [
			{
				field: 'day',
				headerName: 'Day',
				flex: 1,
			},
			{ field: 'clientIp', headerName: 'Client IP', flex: 1 },
			{
				field: 'statusCodes',
				headerName: 'Status Codes',
				flex: 1,
				cellRenderer: (params: { value: number[] }) => params.value.join(', '),
			},
			{ field: 'totalRequests', headerName: 'Total Requests', flex: 1 },
		],
		[],
	)

	const detailedColumnDefs = useMemo<ColDef<SalesforceApiTotalUsageLog>[]>(
		() => [
			{
				field: 'timestamp',
				headerName: 'Timestamp',
				flex: 1,
				cellRenderer: (params: { value: string }) => formatDate(params.value),
			},
			{ field: 'apiResource', headerName: 'API Resource', flex: 1 },
			{ field: 'clientName', headerName: 'Client Name', flex: 1 },
			{ field: 'httpMethod', headerName: 'HTTP Method', flex: 1 },
			{ field: 'clientIp', headerName: 'Client IP', flex: 1 },
			{ field: 'entityName', headerName: 'Entity Name', flex: 1 },
			{
				field: 'statusCode',
				headerName: 'Status Code',
				flex: 1,
				cellRenderer: (params: { value: number | null }) => params.value ?? 'N/A',
			},
		],
		[],
	)

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	const exportToCsv = useCallback(() => {
		if (isModalOpen) {
			detailedGridRef?.current!.api.exportDataAsCsv({
				fileName: getCsvFileName(
					`api-total-usage-logs-${identity.salesforceConnectedApplication?.connectedApplicationId}`,
					false,
				),
			})
		} else {
			aggregatedGridRef?.current!.api.exportDataAsCsv({
				fileName: getCsvFileName(
					`api-total-usage-logs-aggregated-${identity.salesforceConnectedApplication?.connectedApplicationId}`,
					false,
				),
			})
		}
	}, [identity, isModalOpen])

	if (isLoadingAggregated) {
		return <Skeleton active />
	}

	const usageLogsAggregated = aggregatedData?.salesforceConnectedAppApiTotalUsageLogsAggregated ?? []

	if (usageLogsAggregated.length === 0 && !isModalOpen) {
		return <div className="text-center">No API Total Usage Logs available</div>
	}

	return (
		<>
			<div className="h-64 overflow-auto">
				<AgGridReact<SalesforceApiTotalUsageAggregatedLogReadModel>
					rowData={usageLogsAggregated}
					columnDefs={aggregatedColumnDefs}
					defaultColDef={defaultColDef}
					ref={aggregatedGridRef}
					enableCellTextSelection
					className="ag-theme-alpine w-full h-full overflow-x-auto"
					rowHeight={54}
				/>
			</div>
			<div className="flex justify-between">
				<button className="mt-4 underline cursor-pointer text-blue-600" onClick={handleOpenModal}>
					See all events
				</button>
			</div>
			<Modal
				centered={true}
				width={1024}
				title="API Total Usage Logs"
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				onOk={() => setIsModalOpen(false)}
				footer={[
					<div key="footer" className="flex justify-between items-center">
						<div className="flex">
							<Button
								icon={<ExportIcon />}
								onClick={exportToCsv}
								className="bg-white text-gray-500 px-2 flex items-start mr-2"
								disabled={isLoadingDetailed || isFetchingDetailed}
							>
								Export CSV
							</Button>
							<Button className="bg-black text-white" onClick={() => setIsModalOpen(false)}>
								Done
							</Button>
						</div>
					</div>,
				]}
			>
				<div className="flex overflow-y-auto h-[70vh]">
					{isLoadingDetailed || isFetchingDetailed ? (
						<div className="w-full flex items-center justify-center">
							<Skeleton active />
						</div>
					) : !detailedData?.salesforceConnectedAppApiTotalUsageLogs?.length ? (
						<div className="w-full flex items-center justify-center text-gray-500">
							No detailed logs available
						</div>
					) : (
						<AgGridReact<SalesforceApiTotalUsageLog>
							className="ag-theme-alpine w-full"
							rowData={detailedData.salesforceConnectedAppApiTotalUsageLogs}
							rowHeight={54}
							columnDefs={detailedColumnDefs}
							defaultColDef={defaultColDef}
							enableCellTextSelection
							sideBar={sideBar}
							ref={detailedGridRef}
						/>
					)}
				</div>
			</Modal>
		</>
	)
}
