import { z } from 'zod'
import { DateSchema } from '../../../common.ts'
import { camelizeSchema } from '../../../utils.ts'
import { AzureKeyVaultSchema } from './azureKeyVault.ts'
import { ServerEntraIdChangeLogSchema } from '../../entraId/entraIdChangeLogSchema.ts'

export const ServerAzureKVSecretSchema = z.object({
	name: z.string(),
	secret_id: z.string(),
	created_at: DateSchema.nullish(),
	updated_at: DateSchema.nullish(),
	expiration_date: DateSchema.nullish(),
	start_date: DateSchema.nullish(),
	is_enabled: z.boolean(),
	is_managed: z.boolean(),
	tags: z.record(z.string(), z.string()).nullable(),
	vault_name: z.string(),
	vault_id: z.string(),
	key_vault: AzureKeyVaultSchema.nullable(),
	subscription_id: z.string().uuid(),
	change_logs: z.array(ServerEntraIdChangeLogSchema).nullish(),
})

export const AzureKVSecretSchema = camelizeSchema(ServerAzureKVSecretSchema.transform((val) => val))
export type AzureKVSecret = z.infer<typeof AzureKVSecretSchema>
