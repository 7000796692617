import React, { useEffect, useState } from 'react'
import { Identity } from '../../../schemas/identity.ts'
import { BaseIdentityDetail } from './baseIdentities/BaseIdentityDetail.tsx'
import { IdentityTypeTag } from '../../common/IdentityTypeTag.tsx'
import { IssueSourceIcon } from '../../common/IssueSourceIcon.tsx'
import { formatDate, removeNulls } from '../../../utils.ts'
import { AwsIamRoleContext } from './AwsIamRoleContext.tsx'
import { Col, Divider, Row, Space, Tooltip } from 'antd'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { KubernetesResource } from '../../../schemas/identities/kubernetesResourceSchema.ts'
import { JsonViewer } from '../../common/JsonViewer.tsx'
import KubernetesIcon from '../../../assets/kubernetes_icon_20.svg?react'
import { AwsIamUserContext } from './AwsIamUserContext.tsx'
import { AccessKeyTooltip } from './utils/AccessKeyTooltip.tsx'
import { KubernetesRoleBindingsContext } from './kubernetes/KubernetesRoleBindingsContext.tsx'
import { KubernetesAwsAuthContext } from './kubernetes/KubernetesAwsAuthContext.tsx'
import { KubernetesServiceAccountsContext } from './kubernetes/KubernetesServiceAccountsContext.tsx'

export const KubernetesResourceIdentity: React.FunctionComponent<{
	identity?: Identity | null
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const [contextAmount, setContentAmount] = useState(0)
	const kubernetesResource: KubernetesResource | null | undefined = identity?.kubernetesResource
	useEffect(() => {
		calcAmountOfContext()
	})

	const calcAmountOfContext = () => {
		let amount = 0
		if (kubernetesResource?.awsIamRolesXc?.length) amount++
		if (kubernetesResource?.awsIamUsersXc?.length) amount++
		setContentAmount(amount)
	}

	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				{kubernetesResource?.accessKeys ? (
					<BaseIdentityDetail label="Access Key ID Used" span={1}>
						<Tooltip title={<AccessKeyTooltip accessKey={kubernetesResource?.accessKeys} />}>
							{kubernetesResource?.accessKeys?.accessKeyId}
						</Tooltip>
					</BaseIdentityDetail>
				) : null}
				<BaseIdentityDetail label="Cluster" value={kubernetesResource?.clusterLiteral} span={1} />
				<BaseIdentityDetail label="Resource UID" value={kubernetesResource?.resourceLiteral} span={1} />
				<BaseIdentityDetail label="Resource Name" value={kubernetesResource?.name} span={1} />

				{kubernetesResource?.kind ? (
					<BaseIdentityDetail label="Kind" value={kubernetesResource?.kind} span={1} />
				) : null}
				{kubernetesResource?.resourceMetadata?.namespace ? (
					<BaseIdentityDetail
						label="Namespace"
						value={kubernetesResource?.resourceMetadata?.namespace as string}
						span={1}
					/>
				) : null}

				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Environment" span={1}>
					<div className="flex -space-x-4 rtl:space-x-reverse">
						<IssueSourceIcon source={envType} />
						<Tooltip title="Kubernetes">
							<KubernetesIcon />
						</Tooltip>
					</div>
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Created At" value={formatDate(identity?.createdAt)} span={1} />
				{kubernetesResource?.resourceMetadata ? (
					<BaseIdentityDetail label="Metadata" span={1}>
						<JsonViewer data={removeNulls(kubernetesResource.resourceMetadata)} title="Metadata" />
					</BaseIdentityDetail>
				) : null}

				{kubernetesResource?.spec ? (
					<BaseIdentityDetail label="Spec" span={1}>
						<JsonViewer data={removeNulls(kubernetesResource.spec)} title="Spec" />
					</BaseIdentityDetail>
				) : null}
				{kubernetesResource?.data ? (
					<BaseIdentityDetail label="Data" span={1}>
						<JsonViewer data={removeNulls(kubernetesResource.data)} title="Data" />
					</BaseIdentityDetail>
				) : null}
				{kubernetesResource?.rules ? (
					<BaseIdentityDetail label="Rules" span={1}>
						<JsonViewer data={removeNulls(kubernetesResource.rules)} title="Rules" />
					</BaseIdentityDetail>
				) : null}
			</div>
			<Divider />
			<div className="w-full h-min-content">
				<Space direction="vertical" className="w-full">
					{kubernetesResource?.data &&
						kubernetesResource.kind === 'ConfigMap' &&
						kubernetesResource?.name === 'aws-auth' && (
							<KubernetesAwsAuthContext
								awsAuthConfigMapData={kubernetesResource.data as { mapRoles: string; mapUsers: string }}
							/>
						)}
				</Space>
				<Space direction="vertical" className="w-full">
					{kubernetesResource?.roleBindings &&
						kubernetesResource?.roles &&
						kubernetesResource?.roleBindings.length > 0 && (
							<KubernetesRoleBindingsContext
								roleBindings={kubernetesResource?.roleBindings}
								roles={kubernetesResource?.roles}
							/>
						)}
				</Space>
				<Space direction="vertical" className="w-full">
					{kubernetesResource?.serviceAccounts && (
						<KubernetesServiceAccountsContext serviceAccounts={kubernetesResource?.serviceAccounts} />
					)}
				</Space>

				{contextAmount > 0 && (
					<>
						<div className="text-gray-900">Context ({contextAmount})</div>
						<Row className="mt-2">
							<Col className="w-full">
								{kubernetesResource?.awsIamRolesXc && kubernetesResource?.awsIamRolesXc.length > 0 && (
									<AwsIamRoleContext awsIamRolesXc={kubernetesResource?.awsIamRolesXc} />
								)}
								{kubernetesResource?.awsIamUsersXc && kubernetesResource?.awsIamUsersXc.length > 0 && (
									<AwsIamUserContext awsIamUsersXc={kubernetesResource?.awsIamUsersXc} />
								)}
							</Col>
						</Row>
					</>
				)}
			</div>
		</>
	)
}
