import React, { MutableRefObject, useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent } from '@ag-grid-community/core'
import { AssumeRolePolicyDocument, CombinedAwsIamRolePolicy } from '../schemas/identities/awsIamRoleXcSchema.ts'
import { JsonViewer } from '../components/common/JsonViewer.tsx'

export const IamTrustRelationshipTable: React.FunctionComponent<{
	data: AssumeRolePolicyDocument
}> = ({ data }) => {
	const gridRef = useRef<AgGridReact>()
	const onGridReady = useCallback((_params: GridReadyEvent<CombinedAwsIamRolePolicy>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])
	const columnDefs = useMemo<ColDef[]>(() => {
		return [
			{
				field: 'sid',
			},
			{
				field: 'action',
			},
			{
				field: 'effect',
			},
			{
				headerName: 'statement',
				cellRenderer: (params: { data: object }) => {
					return <JsonViewer data={params.data} title="Statment" />
				},
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])
	if (!data) {
		return null
	}
	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				ref={gridRef as MutableRefObject<AgGridReact | null>}
				rowData={data.statement}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				detailRowAutoHeight={true}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
