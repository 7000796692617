import { Col, Drawer, Row, Skeleton, Tabs } from 'antd'
import { BaseIdentity } from '../identities/BaseIdentity.tsx'
import Markdown from 'react-markdown'
import { IssueDrawerTitle } from './IssueDrawerTitle.tsx'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { RelatedFindingsTable } from '../../../tables/findings/RelatedFindingsTable.tsx'
import { Issue } from '../../../schemas/issue.ts'
import { LogRocketTrackEvent, trackEvent } from '../../../services/logrocket/logrocket.ts'
import { getPriorityColor } from '../../../tables/helpers.ts'
import { TokenTag } from '../../common/TokenTag.tsx'
import remarkGfm from 'remark-gfm'
import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { useIssue } from '../../../api/issues.ts'
import { useRelatedIssues } from '../../../api/identities.ts'
import { RemediationSteps } from '../../remediationSteps/RemediationSteps.tsx'
import { issuesRemediationStepsMap } from '../../remediationSteps/remediationStepsData.ts'
import { Tag } from '../../../schemas/tags.ts'
import { PathWithIssueIdSearch } from '../../../routes/RoutePaths.tsx'

export const IssueDrawer: React.FC<{
	route: PathWithIssueIdSearch
	hideRelatedIssues?: boolean
}> = ({ route, hideRelatedIssues }) => {
	const routeApi = getRouteApi(route)
	const navigate = useNavigate({ from: route })
	const { issueId: searchIssueId } = routeApi.useSearch()
	const { data: issue, isLoading: isLoadingIssue } = useIssue(searchIssueId ?? '')

	const markdownRef = useRef<HTMLDivElement>(null)
	const [canExpand, setCanExpand] = useState(false)
	const [wasExpanded, setWasExpanded] = useState(false)
	const [tagsElements, setTagsElements] = useState<React.ReactNode[]>([])

	const { data: identityRelatedIssues, isLoading: isLoadingRelatedIssues } = useRelatedIssues(
		issue?.identity?.id,
		hideRelatedIssues,
	)
	const filteredRelatedIssues = useMemo<Issue[]>(() => {
		if (!identityRelatedIssues || !searchIssueId) {
			return []
		}
		return identityRelatedIssues?.issues?.filter((issue) => issue?.id !== searchIssueId) ?? []
	}, [identityRelatedIssues, searchIssueId])
	const description: string = useMemo(() => {
		if (!issue) {
			return ''
		}
		return issue.description!
	}, [issue])

	const toggleDescription = () => {
		setWasExpanded(true)
		const issueName: string | undefined = issue?.issueName ?? undefined
		if (canExpand) {
			trackEvent(LogRocketTrackEvent.DescriptionShowMoreClicked, { issueName })
			markdownRef.current?.classList.remove('line-clamp-5')
		} else {
			trackEvent(LogRocketTrackEvent.DescriptionShowLessClicked, { issueName })
			markdownRef.current?.classList.add('line-clamp-5')
		}
		setCanExpand(!canExpand)
	}

	const issueColor = useMemo(() => {
		return getPriorityColor(issue?.priority)
	}, [issue])

	const setHeaderColor = () => {
		const antDrawerHeaderElement = Array.from(
			document.getElementsByClassName('ant-drawer-header') as HTMLCollectionOf<HTMLElement>,
		)
		if (antDrawerHeaderElement.length > 0) {
			antDrawerHeaderElement[0].style.cssText = `box-shadow: 4px 0px 0px 0px ${issueColor} inset; border-radius: 4px 0px 0px 4px;`
		}
	}

	useEffect(() => {
		setHeaderColor()
		setWasExpanded(false)
		setCanExpand(false)
		markdownRef.current?.classList.remove('line-clamp-5')
		// Check if the Markdown content is greater than three lines
		if (markdownRef.current) {
			const lineHeight = parseInt(window.getComputedStyle(markdownRef.current).lineHeight) // height of one line
			const height = markdownRef.current.clientHeight // height of the element

			if (height >= lineHeight * 6) {
				markdownRef.current.classList.add('line-clamp-5')
				setCanExpand(true)
			} else {
				markdownRef.current.classList.remove('line-clamp-5')
				setCanExpand(false)
			}
		}
		if (issue?.identity?.tags) {
			const tags = issue?.identity?.tags.map((tag) => {
				return <TokenTag tag={tag.name as Tag} source={tag.relatedResourceType} key={tag.id} />
			})
			setTagsElements(tags)
		} else {
			setTagsElements([])
		}
	}, [issue])
	const closeIssueDrawer = () => {
		trackEvent(LogRocketTrackEvent.IssueDrawerClosed, {})
		void navigate({
			search: (prev) => ({ ...prev, issueId: undefined }),
			params: (prev) => prev,
		})
	}

	const remediationSteps =
		issue?.identity?.envType && issue?.issueName
			? issuesRemediationStepsMap[issue?.identity?.envType]?.[issue?.issueName]?.[issue?.identity?.source]
			: undefined

	return (
		<Drawer
			getContainer={false}
			title={<IssueDrawerTitle issue={issue} />}
			placement="right"
			className="rounded-md"
			style={{
				backgroundColor: '#F5F5F5',
			}}
			styles={{
				body: {
					paddingLeft: 0,
					paddingRight: 0,
					paddingTop: '16px',
				},
			}}
			onClose={closeIssueDrawer}
			open={!!searchIssueId}
			width={'60%'}
			mask={false}
			maskClosable={true}
		>
			<Skeleton loading={isLoadingIssue} active />
			{isLoadingIssue ? null : (
				<>
					{tagsElements?.length > 0 ? <div className="flex pb-4 px-6">{tagsElements}</div> : null}
					{remediationSteps ? (
						<Tabs
							className="full-width-tabs"
							defaultActiveKey="description"
							items={[
								{
									key: 'description',
									label: 'Description',
									children: (
										<Row className="pb-4 px-6">
											<Col>
												<div ref={markdownRef} className="text-zinc-500 text-s font-normal">
													<Markdown
														remarkPlugins={[remarkGfm]}
														children={description}
													></Markdown>
												</div>
												{canExpand && <a onClick={toggleDescription}>Read more</a>}
												{wasExpanded && !canExpand && (
													<a onClick={toggleDescription}>Read less</a>
												)}
											</Col>
										</Row>
									),
								},
								{
									key: 'remediation',
									label: 'Remediation',
									children: (
										<div className="pb-4 px-6">
											<RemediationSteps remediationSteps={remediationSteps} />
										</div>
									),
								},
							]}
						/>
					) : (
						<Row className="px-6">
							<Col>
								<div ref={markdownRef} className="text-zinc-500 text-s font-normal">
									<Markdown remarkPlugins={[remarkGfm]} children={description}></Markdown>
								</div>
								{canExpand && <a onClick={toggleDescription}>Read more</a>}
								{wasExpanded && !canExpand && <a onClick={toggleDescription}>Read less</a>}
							</Col>
						</Row>
					)}
					<div className="py-4 px-6">
						<Row className="w-full">
							<BaseIdentity identity={issue?.identity} envType={issue?.identity?.envType} />
						</Row>
						{!hideRelatedIssues &&
							(isLoadingRelatedIssues ? (
								<Skeleton className="m-4" loading={isLoadingRelatedIssues} active />
							) : filteredRelatedIssues?.length > 0 ? (
								<Row className="space-y-2 mt-8 w-full h-72">
									<div className="text-gray-900">Related Issues ({filteredRelatedIssues.length})</div>
									<RelatedFindingsTable issuesTableData={filteredRelatedIssues} route={route} />
								</Row>
							) : null)}
					</div>
				</>
			)}
		</Drawer>
	)
}
