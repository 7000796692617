import { DateSchema } from '../common.ts'
import { camelizeSchema } from '../utils.ts'
import { z } from 'zod'

export const ServerResolvedVsUnresolvedSchema = z.object({
	date: DateSchema,
	resolved: z.number(),
	unresolved: z.number(),
})

export type ServerResolvedVsUnresolved = z.infer<typeof ServerResolvedVsUnresolvedSchema>

export const ResolvedVsUnresolvedSchema = camelizeSchema(ServerResolvedVsUnresolvedSchema.transform((val) => val))
export type ResolvedVsUnresolved = z.infer<typeof ResolvedVsUnresolvedSchema>
