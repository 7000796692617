import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent } from '@ag-grid-community/core'
import { TagIcons } from '../components/common/TagIcons.tsx'
import { Tag, TagSchemaType, TagsDisplayNameMap } from '../schemas/tags.ts'
import { DatabricksRole } from '../schemas/identities/databricks/ServerDatabricksRoleSchema.ts'

export const DatabricksRolesTable: React.FunctionComponent<{ data: DatabricksRole[] }> = ({ data }) => {
	const gridRef = useRef<AgGridReact>(null)
	const onGridReady = useCallback((_params: GridReadyEvent<DatabricksRole>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	const columnDefs = useMemo<ColDef<DatabricksRole>[]>(() => {
		return [
			{ field: 'roleName' },
			{ field: 'createdAt' },
			{
				headerName: 'Risk',
				cellRenderer: (params: { data?: DatabricksRole }) => {
					return <TagIcons tags={params.data?.tags?.map((tag) => tag.name)} />
				},
				valueGetter: (params: { data?: DatabricksRole }) => {
					return params.data?.tags?.map((tag: TagSchemaType): string => TagsDisplayNameMap[tag.name as Tag])
				},
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				ref={gridRef}
				rowData={data}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				detailRowAutoHeight={true}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
