import { z } from 'zod'
import { camelizeSchema } from '../../utils.ts'

// Define the base schema for Azure Usage Logs
export const ServerAzureUsageLogSchema = z.object({
	caller: z.string(), // Caller field (lowercased in processing)
	association: z.string().nullable(), // Association, nullable
	ip: z.string(), // IP Address field
	day: z.string(), // Date in string format
	operations: z.array(z.string()), // Array of operations performed
	operations_count: z.number(), // Count of operations performed
})

// Camelize the schema for consistency with other schemas
export const AzureUsageLogSchema = camelizeSchema(ServerAzureUsageLogSchema.transform((val) => val))

// Export TypeScript types for better typing in the project
export type AzureUsageLog = z.infer<typeof AzureUsageLogSchema>
