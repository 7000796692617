import { Identity, IdentitySource } from '../../../schemas/identity.ts'
import { AwsUsageLogsContent } from '../../../components/drawers/identities/AwsUsageLogsContent.tsx'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { isDemo } from '../../../utils/demoUtils.ts'
import { AzureUsageLogsContent } from '../../../components/drawers/identities/azure/AzureUsageLogsContent.tsx'
import { useIdentityUsageLogs } from '../../../api/identities.ts'
import { Skeleton } from 'antd'
import { SalesforceSetupAuditTrailLogsTable } from '../../../tables/salesforce/SalesforceSetupAuditTrailLogsTable.tsx'
import { SalesforceApiTotalUsageLogsTable } from '../../../tables/salesforce/SalesforceApiTotalUsageLogsTable.tsx'
import { GithubUsageLogsContent } from '../../../tables/github/GithubUsageLogsContent.tsx'

export const SidePanelUsage = ({ identity }: { identity: Identity }) => {
	const {
		data: usageLogs,
		isLoading: isLoadingUsageLogs,
		isError: isErrorUsageLogs,
	} = useIdentityUsageLogs(identity.id)

	if (isLoadingUsageLogs) {
		return <Skeleton active />
	}

	if (isErrorUsageLogs && !isDemo) {
		return 'Could not fetch usage data'
	}
	if (identity.source === IdentitySource.AWS_IAM_ROLE) {
		return (
			<AwsUsageLogsContent
				environmentId={identity.environmentId ?? ''}
				subEnvironmentId={identity.awsIamRole?.awsAccount?.id ?? ''}
				identityArn={identity.awsIamRole?.arn ?? ''}
				usageLogs={
					usageLogs?.awsIamRoleUsageLogs?.length
						? usageLogs.awsIamRoleUsageLogs
						: identity.awsIamRole?.usageLogs?.length
							? identity.awsIamRole.usageLogs
							: []
				}
			/>
		)
	}
	if (identity.envType === EnvironmentType.AWS) {
		return (
			<AwsUsageLogsContent
				environmentId={identity.environmentId ?? ''}
				subEnvironmentId={identity.awsIamUser?.awsAccount?.id ?? ''}
				identityArn={identity.awsIamUser?.arn ?? ''}
				usageLogs={
					usageLogs?.awsIamUserUsageLogs?.length
						? usageLogs.awsIamUserUsageLogs
						: identity.awsIamUser?.usageLogs?.length
							? identity.awsIamUser.usageLogs
							: []
				}
			/>
		)
	}

	if (identity.envType === EnvironmentType.GITHUB && isDemo) {
		return (
			<AwsUsageLogsContent
				environmentId={identity.environmentId ?? ''}
				subEnvironmentId={''}
				identityArn={''}
				usageLogs={identity.githubUser?.usageLogs ?? []}
			/>
		)
	}

	if (identity.envType === EnvironmentType.GITHUB) {
		return <GithubUsageLogsContent auditLogs={usageLogs?.githubUserAuditLogs || []} identity={identity} />
	}

	if (identity.source === IdentitySource.GCP_SERVICE_ACCOUNT && isDemo) {
		return (
			<AwsUsageLogsContent
				environmentId={identity.environmentId ?? ''}
				subEnvironmentId={''}
				identityArn={''}
				usageLogs={identity.gcpServiceAccount?.usageLogs ?? []}
			/>
		)
	}

	if (identity.source === IdentitySource.ENTRA_ID_USER && isDemo) {
		return (
			<AwsUsageLogsContent
				environmentId={identity.environmentId ?? ''}
				subEnvironmentId={''}
				identityArn={''}
				usageLogs={identity.entraIdUser?.demoUsageLogs ?? []}
			/>
		)
	}

	if (identity.source === IdentitySource.ENTRA_ID_USER && !isDemo) {
		return (
			<AzureUsageLogsContent
				identity={identity}
				usageLogs={
					usageLogs?.entraIdUserUsageLogs?.length
						? usageLogs.entraIdUserUsageLogs
						: identity.entraIdUser?.usageLogs?.length
							? identity.entraIdUser.usageLogs
							: []
				}
			></AzureUsageLogsContent>
		)
	}

	if (identity.source === IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL) {
		return (
			<AzureUsageLogsContent
				identity={identity}
				usageLogs={
					usageLogs?.entraIdServicePrincipalUsageLogs?.length
						? usageLogs.entraIdServicePrincipalUsageLogs
						: identity.entraIdServicePrincipal?.usageLogs?.length
							? identity.entraIdServicePrincipal.usageLogs
							: []
				}
			/>
		)
	}

	if (identity.source === IdentitySource.OKTA && isDemo) {
		return (
			<AwsUsageLogsContent
				environmentId={identity.environmentId ?? ''}
				subEnvironmentId={''}
				identityArn={''}
				usageLogs={identity.oktaUser?.demoUsageLogs ?? []}
			/>
		)
	}

	if (identity.source === IdentitySource.SALESFORCE_USER) {
		return <SalesforceSetupAuditTrailLogsTable identity={identity} />
	}

	if (identity.source === IdentitySource.SALESFORCE_CONNECTED_APPLICATION) {
		return <SalesforceApiTotalUsageLogsTable identity={identity} />
	}

	return 'No usage data'
}
