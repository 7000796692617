import { useState } from 'react'
import { AwsIamChangeLog } from '../../../schemas/identities/awsIamChangeLogSchema.ts'
import { formatDate } from '../../../utils.ts'
import { BaseCloudtrailLogsContent } from './BaseCloudtrailLogsContent.tsx'
import { AwsIamUserChangeLogsAggregationsTable } from './AwsIamUserChangeLogsAggregationsTable.tsx'
import { ColDef, IRowNode } from '@ag-grid-community/core'
import { getActorArnFriendlyName } from './utils.tsx'
import { JsonViewer } from '../../common/JsonViewer.tsx'
import { ActorArnCell } from './utils/ActorArnCell.tsx'

type AwsIamUserChangeLogsContentProps = {
	environmentId: string
	subEnvironmentId: string
	identityArn: string
	changeLogs: AwsIamChangeLog[]
}

export const AwsIamUserChangeLogsContent = ({
	environmentId,
	subEnvironmentId,
	identityArn,
	changeLogs,
}: AwsIamUserChangeLogsContentProps) => {
	const [changeLogColumnDefs] = useState<ColDef<AwsIamChangeLog>[]>([
		{
			field: 'actorArn',
			headerName: 'Actor ID',
			valueGetter: (params: { data?: AwsIamChangeLog }) => {
				return getActorArnFriendlyName(params.data?.actorArn)
			},
			cellRenderer: (params: { data?: AwsIamChangeLog; node: IRowNode; value: string }) => {
				return <ActorArnCell actorArn={params.data?.actorArn} value={params.value} />
			},
		},
		{ field: 'eventName' },
		{
			field: 'eventTime',
			valueGetter: (params: { data?: AwsIamChangeLog }) => formatDate(params.data?.eventTime),
		},
		{ field: 'sourceIpAddress', headerName: 'IP' },
		{ field: 'userAgent' },
		{
			field: 'requestParameters',
			valueFormatter: (params: { data?: AwsIamChangeLog }) => JSON.stringify(params.data?.requestParameters),
			cellRenderer: (params: { data: AwsIamChangeLog }) => {
				return Object.keys(params.data.requestParameters).length ? (
					<JsonViewer data={params.data?.requestParameters} title="Request Parameters" />
				) : (
					'Empty'
				)
			},
		},
	])

	return (
		<BaseCloudtrailLogsContent
			environmentId={environmentId}
			subEnvironmentId={subEnvironmentId}
			identityArn={identityArn}
			entries={changeLogs}
			entriesColumnDefs={changeLogColumnDefs}
			ContentTable={AwsIamUserChangeLogsAggregationsTable}
			title={'Ownership'}
			modalTitle={'Ownership - Creator and Maintainers'}
		/>
	)
}
